// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {
    parseProfile, selectProfileFirstName, selectProfileLastName,
} from 'txp-core';

import api from '../Services/Api';
import { apiFetch, apiDelete } from './ApiSaga';
import { isObject } from '../Utils/Object';
import { pushError } from '../Redux/ApplicationActions';
import {
    finishLoading,
    startLoading,
} from '../Redux/LoadingActions';
import {
    loadedChatrooms,
    loadedActiveChatroomData,
    loadedArchivedChatroomData,
    loadChatrooms,
} from '../Redux/ChatroomActions';
import type {
    LoadArchivedChatroomData,
    LoadActiveChatroomData,
    GenerateActiveChatroomPDF,
    GenerateArchivedChatroomPDF,
    DeleteChatroom,
} from '../Redux/ChatroomActions';
import type {
    ActiveChatroom,
    ArchivedChatroom,
    ChatroomData,
    RemoteMediaUri,
    ChatroomPdfData,
    Chatroom,
} from '../Utils/types';
import {
    getColoredCellFromContent,
    getColoredCell,
    getUserName,
} from '../Utils/pdf';
import { getFileAsDataURI } from '../Utils/downloadFile';
import Colors from '../Themes/Colors';
import Images from '../Themes/Images';


// URL to the media message download api
const mediaHost = process.env.REACT_APP_API_ROOT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const chatroomPdfStyles = {
    header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
    },
    chatSpacer: {
        fontSize: 18,
        margin: [0, 0, 0, 10],
    },
    legendTitle: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
    },
    footer: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
        alignment: 'center',
    },
    logo: {
        margin: [0, 0, 0, 10],
        alignment: 'center',
    },
    boldText: {
        bold: true,
    },
    table: {
        margin: [0, 5, 0, 15],
    },
    small: {
        fontSize: 10,
    },
    fillColorOrange: {
        background: Colors.pdfFillOrange,
    },
    fillColorPurple: {
        background: Colors.pdfFillPurple,
    },
    fillColorYellow: {
        background: Colors.pdfFillYellow,
    },
    fillColorGreen: {
        background: Colors.pdfFillGreen,
    },
    fillColorRed: {
        background: Colors.pdfFillRed,
    },
    fillColorBlue: {
        background: Colors.pdfFillBlue,
    },
};

export function transformGeneralChatroomResponse(chatroom: any): Chatroom {
    return {
        visible: true,
        chatroomId: chatroom.chatroom_id,
        chatroomName: chatroom.chatroom_name,
        chatroomDescription: chatroom.chatroom_description,
        donorId: chatroom.donor_id,
        organId: chatroom.organ_id,
        unosId: chatroom.unos_id,
        matchId: chatroom.match_id,
        organType: chatroom.organ_type,
        transplantCenter: chatroom.transplant_center,
        creatorId: chatroom.creator_id,
        memberCount: chatroom.member_count,
        totalMessageCount: chatroom.total_message_count,
        organizationCount: chatroom.organization_count,
        archivedDate: chatroom.archived_date,
    };
}

function transformResponseToActiveChatroom(chatroom: any): ActiveChatroom {
    return {
        ...transformGeneralChatroomResponse(chatroom),
        lastUpdateTime: chatroom.last_update_time,
        lastInstantMessageContent: chatroom.last_instant_message_content,
        lastInstantMessageTime: chatroom.last_instant_message_time,
    };
}

function transformResponseToArchivedChatroom(chatroom: any): ArchivedChatroom {
    return {
        ...transformGeneralChatroomResponse(chatroom),
        archivedDate: chatroom.archived_date,
    };
}

export function* loadChatroomsSaga(): Saga<void> {
    yield put(startLoading('chatrooms'));

    const { result, error, } = yield apiFetch(api.txp.chatrooms);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading chatrooms failed, are you online?', error));
        } else {
            yield put(pushError('Loading chatrooms failed, try again later', error));
        }
    } else {
        const remoteArchivedChatrooms = (isObject(result) ? result.archived : result) || [];
        const archivedChatrooms: ArchivedChatroom[] = remoteArchivedChatrooms.map(
            (chatroom) => transformResponseToArchivedChatroom(chatroom)
        );

        const remoteActiveChatrooms = (isObject(result) ? result.active : result) || [];
        const activeChatrooms: ActiveChatroom[] = remoteActiveChatrooms.map(
            (chatroom) => transformResponseToActiveChatroom(chatroom)
        );

        yield put(loadedChatrooms(activeChatrooms, archivedChatrooms));
        yield put(finishLoading('chatrooms'));
    }
}

export function transformChatroomResponse(apiResult: any): ChatroomData {
    const organizations = [];
    if (Array.isArray(apiResult.organizations)) {
        for (let i = 0; i < apiResult.organizations.length; i += 1) {
            organizations.push({
                visible: true,
                organizationId: apiResult.organizations[i].organization_id,
                organizationName: apiResult.organizations[i].organization_name,
                organizationCode: apiResult.organizations[i].organization_code,
                organizationType: '',
                license: '',
                clientPermissions: {},
                templatePreferences: {},
            });
        }
    }

    const chatroomMembers = [];
    if (Array.isArray(apiResult.chatroom_members)) {
        for (let i = 0; i < apiResult.chatroom_members.length; i += 1) {
            chatroomMembers.push({
                chatroomId: apiResult.chatroom_members[i].chatroom_id,
                membershipStatus: apiResult.chatroom_members[i].membership_status,
                /* @deprecated old version of parseProfile doesn't include department here, so this should be fixed
                when txp-core is updated */
                userInformation: {
                    ...parseProfile(apiResult.chatroom_members[i].user_information),
                    department: apiResult.chatroom_members[i].user_information,
                },
            });
        }
    }

    const guestUsers = [];
    if (Array.isArray(apiResult.guest_users)) {
        for (let i = 0; i < apiResult.guest_users.length; i += 1) {
            guestUsers.push({
                guestId: apiResult.guest_users[i].guest_id,
                chatroomId: apiResult.guest_users[i].chatroom_id,
                email: apiResult.guest_users[i].email,
                phone: apiResult.guest_users[i].phone,
                membershipStatus: apiResult.guest_users[i].membership_status,
            });
        }
    }

    const messages = [];
    if (Array.isArray(apiResult.instant_messages)) {
        for (let i = 0; i < apiResult.instant_messages.length; i += 1) {
            messages.push({
                instantMessageId: apiResult.instant_messages[i].instant_message_id,
                chatroomId: apiResult.instant_messages[i].chatroom_id,
                sentTime: apiResult.instant_messages[i].sent_time,
                chatTypeId: apiResult.instant_messages[i].chat_type_id,
                senderId: apiResult.instant_messages[i].sender_id,
                senderName: apiResult.instant_messages[i].sender_name,
                textContent: apiResult.instant_messages[i].text_content,
                dataContent: apiResult.instant_messages[i].data_content,
                fileName: apiResult.instant_messages[i].file_name,
                fileSize: apiResult.instant_messages[i].file_size,
            });
        }
    }

    const messageStatuses = {};
    if (isObject(apiResult.instant_message_statuses)) {
        for (const status in apiResult.instant_message_statuses) {
            if (Object.prototype.hasOwnProperty.call(apiResult.instant_message_statuses, status)) {
                const currentStatus = [];
                for (let i = 0; i < apiResult.instant_message_statuses[status].length; i += 1) {
                    currentStatus.push({
                        chatroomId: apiResult.instant_message_statuses[status][i].chatroom_id,
                        instantMessageId: apiResult.instant_message_statuses[status][i].instant_message_id,
                        instantMessageStatusId: apiResult.instant_message_statuses[status][i].instant_message_status_id,
                        memberId: apiResult.instant_message_statuses[status][i].member_id,
                        statusTime: apiResult.instant_message_statuses[status][i].status_time,
                    });
                }
                messageStatuses[status] = currentStatus;
            }
        }
    }

    const acknowledgements = {};
    if (isObject(apiResult.acknowledgements)) {
        for (const messageId in apiResult.acknowledgements) {
            if (Object.prototype.hasOwnProperty.call(apiResult.acknowledgements, messageId)) {
                const acksForMessage = [];
                for (let i = 0; i < apiResult.acknowledgements[messageId].length; i += 1) {
                    acksForMessage.push({
                        acknowledgementId: apiResult.acknowledgements[messageId][i].acknowledgement_id,
                        ackTime: apiResult.acknowledgements[messageId][i].ack_time,
                        chatroomId: apiResult.acknowledgements[messageId][i].chatroom_id,
                        memberId: apiResult.acknowledgements[messageId][i].chatroom_member_id,
                        instantMessageId: apiResult.acknowledgements[messageId][i].instant_message_id,
                        symbol: apiResult.acknowledgements[messageId][i].symbol,
                    });
                    acknowledgements[messageId] = acksForMessage;
                }
            }
        }
    }

    return {
        organizations,
        chatroomMembers,
        guestUsers,
        instantMessages: messages,
        instantMessageStatuses: messageStatuses,
        acknowledgements,
    };
}

export function* loadActiveChatroomsData(action: LoadActiveChatroomData): Saga<void> {
    yield put(startLoading('activeChatroomData'));

    const { result, error, } = yield apiFetch(api.txp.activeChatroomData, { chatroom_id: action.chatroomId, });
    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading active chatroom data failed, are you online?', error));
        } else {
            yield put(pushError('Loading active chatroom data failed, try again later', error));
        }
    } else {
        const remoteActiveChatroomData = isObject(result) ? result : {};
        const activeChatroomData: ChatroomData = transformChatroomResponse(remoteActiveChatroomData);

        yield put(loadedActiveChatroomData(activeChatroomData));
        yield put(finishLoading('activeChatroomData'));
    }
}

export function* loadArchivedChatroomsData(action: LoadArchivedChatroomData): Saga<void> {
    yield put(startLoading('archivedChatroomData'));

    const { result, error, } = yield apiFetch(api.txp.archivedChatroomData, { chatroom_id: action.chatroomId, });
    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading archived chatroom data failed, are you online?', error));
        } else {
            yield put(pushError('Loading archived chatroom data failed, try again later', error));
        }
    } else {
        const remoteArchivedChatroomData = isObject(result) ? result : {};
        const archivedChatroomData: ChatroomData = transformChatroomResponse(remoteArchivedChatroomData);

        yield put(loadedArchivedChatroomData(archivedChatroomData));
        yield put(finishLoading('archivedChatroomData'));
    }
}

function* downloadFile(source: RemoteMediaUri) {
    const { uri, headers, } = source;

    const downloadedFileBlob = yield getFileAsDataURI({
        fromUrl: uri,
        headers,
        useLock: true,
    });

    return downloadedFileBlob;
}

function getChatroomImageSource(chatId: number, pictureUri: string, accessToken: string): RemoteMediaUri {
    const basePath = `chatrooms/${chatId}/messages/media/url/`;
    return {
        uri: `${mediaHost}${basePath}${encodeURIComponent(pictureUri)}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
}

export function* chatroomDataToPdfData(chatroomData: ChatroomData): Generator<any, ChatroomPdfData, any> {
    const token = yield select((state) => state.auth.accessToken);

    const userInformationArray = [];
    const organizationArray = [];
    const tableBody = [];

    for (let i = 0; i < chatroomData.chatroomMembers.length; i += 1) {
        const currentMember = chatroomData.chatroomMembers[i].userInformation;

        const organization = (currentMember.organizationName);
        if (!organizationArray.includes(organization) && organization !== '') {
            organizationArray.push(organization);
        }

        const userName = `${selectProfileFirstName(currentMember)} ${selectProfileLastName(currentMember)}`;
        const userRole = currentMember.role ? ` (${currentMember.role}),` : (organization ? ',' : '');
        const userOrg = organization ? ` ${organization}` : '';
        const userInformation = `${userName}${userRole}${userOrg}`;
        userInformationArray.push(userInformation);
    }

    chatroomData.instantMessages.sort((a, b) => a.instantMessageId - b.instantMessageId);
    tableBody.push(['Sender', 'Content', 'Sent Time', 'Readers', 'Acknowledgements']);
    for (let i = 0; i < chatroomData.instantMessages.length; i += 1) {
        let { textContent, } = chatroomData.instantMessages[i];
        const chatType = (chatroomData.instantMessages[i].chatTypeId);
        if (chatType !== 32 || (chatType === 32 && textContent)) {
            const bodyLine = [];
            const { senderName, } = chatroomData.instantMessages[i];
            const readableTime = new Date(chatroomData.instantMessages[i].sentTime).toLocaleString();

            const cellContent = [];
            if (textContent) {
                textContent = textContent.replace(/\\n/g, '\n');
                textContent = textContent.replace(/\\t/g, ' ');
                cellContent.push(textContent);
            }

            if ((chatType === 2 || chatType === 3 || chatType === 4)) {
                // .jpg, .jpeg, .png
                const { chatroomId, fileName, } = chatroomData.instantMessages[i];
                const source = getChatroomImageSource(chatroomId, fileName, token);
                let imageContent;
                try {
                    const fileResponse = yield downloadFile(source);

                    if (fileResponse.error) {
                        imageContent = 'Attached Image Unavailable';
                    } else {
                        imageContent = {
                            image: fileResponse.result.dataURI,
                            fit: [200, 200],
                        };
                    }
                } catch (err) {
                    imageContent = 'Attached Image Unavailable';
                }

                cellContent.push(imageContent);
            } else if (chatType === 5 || chatType === 6 || chatType === 8) {
                // .gif, .mp4, .mov
                cellContent.push('Attached Video');
            } else if (chatType === 7) {
                cellContent.push('Attached Application PDF');
            }

            bodyLine.push(getColoredCell(senderName, chatType));
            bodyLine.push(getColoredCellFromContent(cellContent, chatType));
            bodyLine.push(getColoredCell(readableTime, chatType));

            const messageStatus = chatroomData.instantMessageStatuses[chatroomData.instantMessages[i].instantMessageId];
            const readNamesArray = [];
            if (messageStatus) {
                for (let j = 0; j < messageStatus.length; j += 1) {
                    const readMemberId = messageStatus[j].memberId;
                    const readerName = getUserName(readMemberId, chatroomData);

                    readNamesArray.push(readerName);
                }
            }

            bodyLine.push(getColoredCell(readNamesArray.join(', '), chatType));

            const ackIndex = chatroomData.instantMessages[i].instantMessageId;
            const ack = chatroomData.acknowledgements[ackIndex];
            const acks = [];
            if (ack) {
                for (let j = 0; j < ack.length; j += 1) {
                    const chatroomMemberId = ack[j].memberId;
                    const time = new Date(ack[j].ackTime).toLocaleString();
                    const userName = getUserName(parseInt(chatroomMemberId, 10), chatroomData);
                    acks.push(`${userName}\n${time}\n`);
                }
            }
            bodyLine.push(getColoredCell(acks.join('\n'), chatType));
            tableBody.push(bodyLine);
        }
    }

    return {
        userInformationArray,
        organizationArray,
        tableBody,
    };
}

export function chatroomPdfContent(chatroom: Chatroom, tableData: ChatroomPdfData, followedTasks: string[] = []): any[] {
    return [
        { text: [{ text: 'Name: ', style: 'boldText', }, `${chatroom.chatroomName}`], },
        { text: [{ text: 'Description: ', style: 'boldText', }, `${chatroom.chatroomDescription}`], },
        chatroom.unosId
            ? { text: [{ text: 'UNOS ID: ', style: 'boldText', }, `${chatroom.unosId}`], } : null,
        chatroom.matchId
            ? { text: [{ text: 'Match ID: ', style: 'boldText', }, `${chatroom.matchId}`], } : null,
        chatroom.organType
            ? { text: [{ text: 'Organ Type: ', style: 'boldText', }, `${chatroom.organType}`], } : null,
        { text: '', style: 'chatSpacer', },
        { text: `Participating Room Members: ${chatroom.memberCount}`, bold: true, },
        { ul: tableData.userInformationArray, },
        { text: '', style: 'chatSpacer', },
        { text: `Participating Organizations: ${chatroom.organizationCount}`, bold: true, },
        { ul: tableData.organizationArray, },
        followedTasks.length > 0
            ? [
                { text: '', style: 'chatSpacer', },
                { text: `Followed Tasks: ${followedTasks.length}`, bold: true, },
                { ul: followedTasks, }
            ]
            : null,
        { text: '', style: 'chatSpacer', },
        { text: 'Legend:', style: 'legendTitle', },
        {
            style: 'tableExample',
            fontSize: 10,
            margin: [0, 0, 0, 10],
            table: {
                body: [
                    [
                        [
                            [{ text: ['Created Room - ', { text: 'Purple', style: 'fillColorPurple', }], }],
                            [{ text: ['Added Member - ', { text: 'Green', style: 'fillColorGreen', }], }],
                            [{ text: ['Removed Member - ', { text: 'Red', style: 'fillColorRed', }], }],
                            [{ text: ['Invited Guest - ', { text: 'Orange', style: 'fillColorOrange', }], }]
                        ],
                        [
                            [{ text: ['Removed Guest - ', { text: 'Orange', style: 'fillColorOrange', }], }],
                            [{ text: ['Invited User - ', { text: 'Yellow', style: 'fillColorYellow', }], }],
                            [{ text: ['Uninvited User - ', { text: 'Yellow', style: 'fillColorYellow', }], }],
                            [{ text: ['Join By Invitation - ', { text: 'Yellow', style: 'fillColorYellow', }], }]
                        ],
                        [
                            [{ text: ['Ask Alan Recipient - ', { text: 'Blue', style: 'fillColorBlue', }], }],
                            [{ text: ['Ask Alan Candidate - ', { text: 'Blue', style: 'fillColorBlue', }], }],
                            [{ text: ['Ask Alan Donor - ', { text: 'Blue', style: 'fillColorBlue', }], }],
                            [{ text: ['Ask Alan Voided - ', { text: 'Blue', style: 'fillColorBlue', }], }]
                        ]
                    ]
                ],
            },
        },
        {
            style: 'table',
            table: {
                headerRows: 1,
                widths: ['auto', '*', 'auto', 'auto', 'auto'],
                body: tableData.tableBody,
            },
        }
    ];
}

export function* generateActiveChatroomPDFSaga(action: GenerateActiveChatroomPDF): Saga<void> {
    const {
        activeChatroomData,
        activeChatroom,
    } = action;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const chatroomTableData: ChatroomPdfData = yield chatroomDataToPdfData(activeChatroomData);

    const activeData = {
        pageOrientation: 'landscape',
        content: [
            { image: Images.logoLarge, width: 50, style: 'logo', },
            { text: 'OmniLife Active Room', style: 'header', },
            ...chatroomPdfContent(activeChatroom, chatroomTableData),
            { text: '', style: 'chatSpacer', },
            { text: '* ROOM ACTIVE - MESSAGES MAY BE ADDED AFTER THIS TRANSCRIPT HAS BEEN PRODUCED *', style: 'footer', }
        ],
        styles: chatroomPdfStyles,
    };

    if (activeChatroomData) {
        pdfMake.createPdf(activeData).open();
    }
}

export function* generateArchivedChatroomPDFSaga(action: GenerateArchivedChatroomPDF): Saga<void> {
    const {
        archivedChatroomData,
        archivedChatroom,
    } = action;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const chatroomTableData: ChatroomPdfData = yield chatroomDataToPdfData(archivedChatroomData);

    const archivedData = {
        pageOrientation: 'landscape',
        content: [
            { image: Images.logoLarge, width: 50, style: 'logo', },
            { text: 'OmniLife Archived Room', style: 'header', },
            ...chatroomPdfContent(archivedChatroom, chatroomTableData),
            { text: '', style: 'chatSpacer', },
            { text: '* ROOM CLOSED *', style: 'footer', }
        ],
        styles: chatroomPdfStyles,
    };

    if (archivedChatroomData) {
        pdfMake.createPdf(archivedData).open();
    }
}

export function* deleteChatroomSaga(action: DeleteChatroom): Saga<void> {
    yield put(startLoading('closingChatroom'));

    const { result, error, } = yield apiDelete(api.txp.closeChatroom, { chatroom_id: action.chatroomId, });
    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Closing chatroom failed, are you online?', error));
        } else {
            yield put(pushError('Closing chatroom failed, try again later', error));
        }
    } else {
        yield put(loadChatrooms());
        yield put(finishLoading('closingChatroom'));
    }
}


