// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Autocomplete from 'react-toolbox/lib/autocomplete';

import {
    setAddUserOrgEdit as _setAddUserOrgEdit,
    addOrganizationMember as _addOrganizationMember,
    toggleAddUserToOrganizationPanel as _toggleAddUserToOrganizationPanel,
} from '../Redux/UserActions';
import {
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type { UserProfile, Organization } from '../Utils/types';
import MemberRoleDropdown from './MemberRoleDropdown';
import DepartmentDropdown from './DepartmentDropdown';

type Props = {
    user: UserProfile,
    organizations: Array<Organization>,
    organizationNames: Array<string>,
    accessLevel: ?string,

    setAddUserOrgEdit: (user: UserProfile) => *,
    addOrganizationMember: (organizationId: number, userId: number, accessLevel: string, role: ?string, department: ?string) => *,
    toggleAddUserToOrganizationPanel: (isAddUserToOrganizationPanelOpen: boolean,) => *,
    setSagaMessage: (heading: string, message: string, label: string, isError: boolean) => *,
    closeUserDetailsPanel: () => *,
};

type State = {
    dropdownKey: number,
};

class AddUserToOrganizationPanel extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dropdownKey: 0,
        };
    }

    onChangeOrganizationMember(value: string, property: string) {
        const {
            setAddUserOrgEdit,
            user,
        } = this.props;

        setAddUserOrgEdit({ ...user, [property]: value, });

        this.setState((state) => {
            // FIXME When PR #1872 is published and released to npm, this can be removed
            const dropdownKey = (property !== 'accessLevel') ? (state.dropdownKey) : (state.dropdownKey === 0) ? 1 : 0;

            return {
                ...state,
                dropdownKey,
            };
        });
    }

    onAddClick = () => {
        const {
            user,
            organizations,
            addOrganizationMember,
            setSagaMessage,
            closeUserDetailsPanel,
        } = this.props;

        let i = 0;
        let organizationFound = false;

        while (i < organizations.length && !organizationFound) {
            if (organizations[i].organizationName === user.organizationName && user.accessLevel) {
                addOrganizationMember(organizations[i].organizationId, user.userId, user.accessLevel, user.role, user.department);
                organizationFound = true;
            }
            i += 1;
        }

        setSagaMessage('Success', 'User added to organization', '', false);

        this.onClose();

        closeUserDetailsPanel();
    };

    onClose = () => {
        const {
            toggleAddUserToOrganizationPanel,
        } = this.props;

        toggleAddUserToOrganizationPanel(false);
    };

    render() {
        const {
            user,
            organizationNames,
        } = this.props;

        if (user === null) {
            return null;
        }

        const {
            dropdownKey,
        } = this.state;

        // NOTE: accessLevels coming from the server are converted to the 'label' value using the util convertAccessLevel,
        // so the check here is for 'Basic User' and not 'BasicUser'. 'BasicUser' is the enum value on the server
        const isEnabled = (user.organizationName !== '') && (user.role !== null) && (user.accessLevel !== 'Basic User');

        const accessLevels = [
            { value: 'OrgManager', label: 'Organization Manager', },
            { value: 'OrgMember', label: 'Organization Member', }
        ];

        const fullName = (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}`
            : (user.firstName) ? user.firstName
                : (user.lastName) ? user.lastName
                    : user.email;

        return (
            <div style={PanelStyles.panelBodyContainer}>
                <div style={PanelStyles.inputPaddingAddUser}>
                    {fullName}
                    <Autocomplete
                        direction="down"
                        onChange={(newVal) => this.onChangeOrganizationMember(newVal, 'organizationName')}
                        label="Organization"
                        multiple={false}
                        suggestionMatch="anywhere"
                        source={organizationNames}
                        value={user.organizationName}
                        required
                    />
                    <MemberRoleDropdown
                        type="text"
                        label="Role"
                        value={user.role}
                        onChange={(newVal) => this.onChangeOrganizationMember(newVal, 'role')}
                    />
                    <DepartmentDropdown
                        value={user.department}
                        onChange={(newVal) => this.onChangeOrganizationMember(newVal, 'department')}
                    />
                    <Dropdown
                        key={dropdownKey}
                        auto
                        label="Permission"
                        value={user.accessLevel}
                        onChange={(newVal) => this.onChangeOrganizationMember(newVal, 'accessLevel')}
                        source={accessLevels}
                        required
                    />
                </div>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        style={ApplicationStyles.cancelButton}
                        label="Cancel"
                        onClick={this.onClose}
                    />
                    <Button
                        style={!isEnabled ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                        label="Add"
                        onClick={this.onAddClick}
                        disabled={!isEnabled}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.addUserOrgEdit,
});

export default connect(mapStateToProps, {
    setAddUserOrgEdit: _setAddUserOrgEdit,
    addOrganizationMember: _addOrganizationMember,
    toggleAddUserToOrganizationPanel: _toggleAddUserToOrganizationPanel,
    setSagaMessage: _setSagaMessage,
})(AddUserToOrganizationPanel);
