// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Checkbox from 'react-toolbox/lib/checkbox';
import Button from 'react-toolbox/lib/button/Button';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type { Organization } from '../Utils/types';
import {
    updateOrganization as _updateOrganization,
    updateOrganizationEdit as _updateOrganizationEdit,
    setOrganizationEdit as _setOrganizationEdit,
    closeTemplatePreferencesPanel as _closeTemplatePreferencesPanel,
} from '../Redux/OrganizationActions';

type Props = {
    templateOptions: any,
    organizationEditChanged: boolean,
    organizationEdit: Organization,
    closeTemplatePreferencesPanel: () => *,
    updateOrganization: (organizationId: number, organizationName: string, organizationCode: string,
        organizationType: string, clientPermissions: any, templatePreferences: ?any) => *,
    updateOrganizationEdit: (organization: Organization) => *,
    setOrganizationEdit: (organizationId: number) => *,
};

class TemplatePreferencesPanel extends PureComponent<Props> {
    onChangeTemplatePreferences = (type: string, property: string, value: string) => {
        const {
            updateOrganizationEdit,
            organizationEdit,
        } = this.props;

        const updatedTemplatePreferences = {
            ...organizationEdit.templatePreferences,
            [type]: {
                ...organizationEdit.templatePreferences[type],
                [property]: value,
            },
        };

        updateOrganizationEdit({ ...organizationEdit, templatePreferences: updatedTemplatePreferences, });
    };

    onSaveTemplatePreferences = () => {
        const {
            updateOrganization,
            closeTemplatePreferencesPanel,
            organizationEdit,
        } = this.props;

        updateOrganization(organizationEdit.organizationId, organizationEdit.organizationName,
            organizationEdit.organizationCode, organizationEdit.organizationType,
            organizationEdit.clientPermissions, organizationEdit.templatePreferences);

        closeTemplatePreferencesPanel();
    };

    onCloseClearFields = () => {
        const {
            closeTemplatePreferencesPanel,
            setOrganizationEdit,
            organizationEdit,
        } = this.props;

        setOrganizationEdit(organizationEdit.organizationId);
        closeTemplatePreferencesPanel();
    }

    renderDonorOptions = () => {
        const {
            templateOptions,
        } = this.props;

        const donorOptions = templateOptions.donor;
        const options = [];

        for (const prop in donorOptions) {
            if (Object.prototype.hasOwnProperty.call(donorOptions, prop)) {
                options.push(
                    <div key={prop}>
                        <h4>{prop}</h4>
                        <div>
                            {this.renderDonorCheckboxes(prop)}
                        </div>
                    </div>

                );
            }
        }

        return options;
    };

    renderDonorCheckboxes = (group: string) => {
        const {
            templateOptions,
            organizationEdit,
        } = this.props;

        const updatedTemplatePreferences = organizationEdit.templatePreferences ? (organizationEdit.templatePreferences.donor || {}) : {};
        const options = templateOptions.donor[group];
        const checkboxes = [];

        for (const prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                checkboxes.push(
                    <Checkbox
                        key={prop}
                        label={options[prop]}
                        checked={updatedTemplatePreferences[prop]}
                        onChange={(val) => this.onChangeTemplatePreferences('donor', prop, val)}
                    />
                );
            }
        }

        return checkboxes;
    };

    renderRecipientOptions = () => {
        const {
            templateOptions,
        } = this.props;

        const recipientOptions = templateOptions.recipient;
        const options = [];

        for (const prop in recipientOptions) {
            if (Object.prototype.hasOwnProperty.call(recipientOptions, prop)) {
                options.push(
                    <div key={prop}>
                        <h4>{prop}</h4>
                        <div>
                            {this.renderRecipientCheckboxes(prop)}
                        </div>
                    </div>

                );
            }
        }

        return options;
    };

    renderRecipientCheckboxes = (group: string) => {
        const {
            templateOptions,
            organizationEdit,
        } = this.props;

        const updatedTemplatePreferences = organizationEdit.templatePreferences ? (organizationEdit.templatePreferences.recipient || {}) : {};
        const options = templateOptions.recipient[group];
        const checkboxes = [];

        for (const prop in options) {
            if (Object.prototype.hasOwnProperty.call(options, prop)) {
                checkboxes.push(
                    <Checkbox
                        key={prop}
                        label={options[prop]}
                        checked={updatedTemplatePreferences[prop]}
                        onChange={(val) => this.onChangeTemplatePreferences('recipient', prop, val)}
                    />
                );
            }
        }
        return checkboxes;
    };

    render() {
        const {
            organizationEditChanged,
        } = this.props;

        return (
            <div style={{ paddingLeft: 30, paddingTop: 10, }}>
                <h3>Donor Template</h3>
                {this.renderDonorOptions()}
                <h3>Recipient Template</h3>
                {this.renderRecipientOptions()}
                <div style={{ paddingRight: 30, }}>
                    <div style={PanelStyles.optionButtonsContainer}>
                        <Button
                            style={ApplicationStyles.cancelButton}
                            label="Cancel"
                            onClick={this.onCloseClearFields}
                        />
                        <Button
                            style={!organizationEditChanged ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                            label="Update"
                            onClick={this.onSaveTemplatePreferences}
                            disabled={!organizationEditChanged}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    organizationEdit: state.organization.organizationEdit,
    organizationEditChanged: state.organization.organizationEditChanged,
    templateOptions: state.organization.templateOptions,
});

export default connect(mapStateToProps, {
    updateOrganizationEdit: _updateOrganizationEdit,
    updateOrganization: _updateOrganization,
    setOrganizationEdit: _setOrganizationEdit,
    closeTemplatePreferencesPanel: _closeTemplatePreferencesPanel,
})(TemplatePreferencesPanel);

