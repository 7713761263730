// @flow
import React, { PureComponent } from 'react';
import FontIcon from 'react-toolbox/lib/font_icon';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import info from '../Utils/info';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    infoId: number,
};

type State = {
    showInfo: boolean,
};

class InfoButton extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showInfo: false,
        };
    }

    onToggleInfo = () => {
        this.setState((state) => ({
            showInfo: !state.showInfo,
        }));
    };

    render() {
        const {
            infoId,
        } = this.props;

        const {
            showInfo,
        } = this.state;


        return (
            <div style={ApplicationStyles.flexCenter}>
                <FontIcon
                    style={ApplicationStyles.infoButton}
                    value="info"
                    onClick={this.onToggleInfo}
                />
                <Dialog
                    active={showInfo}
                    type="small"
                    onEscKeyDown={this.onToggleInfo}
                    onOverlayClick={this.onToggleInfo}
                >
                    <FontIcon
                        style={ApplicationStyles.closeButton}
                        value="cancel"
                        onClick={this.onToggleInfo}
                    />
                    <div style={{ ...ApplicationStyles.horizontalCenter, padding: 25, }}>
                        <h3>{info[infoId].title}</h3>
                        <p>{info[infoId].description}</p>
                    </div>
                </Dialog>
            </div>

        );
    }
}

export default InfoButton;


