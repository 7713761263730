// @flow

const search = (content: Array<any>, searchTerm: string, searchHeaders: Array<string>): Array<any> => {
    const uppercaseSearchTerm = searchTerm.toUpperCase();
    const newContent = content;

    for (let i = 0; i < content.length; i += 1) {
        let added = false;
        for (const property of searchHeaders) {
            if (!added) {
                newContent[i].visible = !(content[i][property]) ? false
                    : !(content[i][property].toString().toUpperCase().indexOf(uppercaseSearchTerm) === -1);
                if (newContent[i].visible) {
                    added = true;
                }
            }
        }
    }

    return newContent;
};

export default search;
