// @flow
import { selectProfileName } from 'txp-core';

import type { ChatroomData } from './types';
import Colors from '../Themes/Colors';

export function getUserName(userId: number, chatroomData: ChatroomData): string {
    for (let i = 0; i < chatroomData.chatroomMembers.length; i += 1) {
        if (chatroomData.chatroomMembers[i].userInformation.userId === userId) {
            return selectProfileName(chatroomData.chatroomMembers[i].userInformation);
        }
    }
    return '';
}

function getCellColor(type: number): string {
    let cellColor = '';

    switch (type) {
        case 28:
            cellColor = Colors.pdfFillPurple;
            break;
        case 24:
        case 25:
            cellColor = Colors.pdfFillOrange;
            break;
        case 19:
        case 20:
        case 21:
            cellColor = Colors.pdfFillYellow;
            break;
        case 17:
            cellColor = Colors.pdfFillGreen;
            break;
        case 18:
            cellColor = Colors.pdfFillRed;
            break;
        case 29:
        case 30:
        case 23:
        case 31:
            cellColor = Colors.pdfFillBlue;
            break;
        default:
            cellColor = Colors.white;
    }

    return cellColor;
}

export function getColoredCellFromContent(content: any[], type: number): any {
    const cellColor = getCellColor(type);

    return {
        fillColor: cellColor,
        stack: content,
    };
}

export function getColoredCell(value: string, type: number): any {
    const cellColor = getCellColor(type);

    return {
        fillColor: cellColor,
        text: value,
    };
}
