// @flow
import Colors from '../../Themes/Colors';

const InviteUsersButtonStyles = {
    helpDialogContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
        textAlign: 'center',
    },

    displayNone: {
        display: 'none',
    },

    actionButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },

    OkButton: {
        backgroundColor: Colors.brandPrimary,
        color: Colors.white,
    },

    helpTable: {
        margin: 30,
        border: '1px black solid',
        borderCollapse: 'collapse',
    },

    helpTableCell: {
        border: '1px black solid',
        paddingTop: 8,
        paddingBottom: 8,
    },
};

export default InviteUsersButtonStyles;
