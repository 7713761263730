// @flow

import Colors from '../../Themes/Colors';
import Fonts from '../../Themes/Fonts';

const ClientStyles = {
    title: {
        color: Colors.black,
        fontSize: Fonts.size.h3,
        style: Fonts.type.base,
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        margin: 20,
        backgroundColor: 'white',
        padding: '2%',
        paddingRight: '20%',
        ...Fonts.size.h3,
    },
    titleNoBull: {
        ...Fonts.style.h4,
        color: Colors.black,
        listStyleType: 'none',
    },
    fontLarge: {
        ...Fonts.size.h3,
    },
    iconStyle: {
        margin: 5,
        marginRight: 30,
        fontSize: 140,
    },
};

export default ClientStyles;
