// @flow
import { getFormattedDateTimeString, getDateTimeLastLogin } from './datetime';
import { immutableSort } from './sort';
import type { UserProfile, UserActivityMap } from './types';

const mime = require('mime-types');

export function getTableCellValue(obj: Object, header: Object, lengthyContent: string = 'truncated') {
    if (header.dataKey.includes('content') && obj.file_name) {
        if (mime.lookup(obj.file_name).includes('video')) {
            return 'Attached Video';
        }
        if (mime.lookup(obj.file_name).includes('image')) {
            return 'Attached Image';
        }
    }

    if (obj[header.dataKey] || obj[header.dataKey] === false) {
        if (header.dataKey === 'phone' && typeof obj[header.dataKey] === 'string') {
            return obj[header.dataKey].replace(/[^\d]+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        }
        if (header.dataKey === 'lastInstantMessageTime' && typeof obj[header.dataKey] === 'string') {
            return new Date(obj[header.dataKey]).toLocaleString();
        }
        if (header.dataKey === 'archivedDate' && typeof obj[header.dataKey] === 'string') {
            return new Date(obj[header.dataKey]).toLocaleString();
        }
        if (header.dataKey === 'joinDate' && typeof obj[header.dataKey] === 'string') {
            return new Date(obj[header.dataKey]).toLocaleString();
        }
        if (typeof obj[header.dataKey] === 'string' && obj[header.dataKey].length > 50) {
            switch (lengthyContent) {
                case 'new-lines':
                    return obj[header.dataKey].replace(/(.{50})/g, '$1\n');
                case 'truncated':
                    return `${obj[header.dataKey].substring(0, 50)}...`;
                default:
                    return obj[header.dataKey];
            }
        } else {
            return obj[header.dataKey];
        }
    } else return 'N/A';
}

export function getPDFReportValue(obj: Object, header: Object, lengthyContent: string = 'truncated') {
    switch (header.dataKey) {
        case 'recipients': {
            let tableOutput = '';
            obj[header.dataKey].forEach((recipient, index) => {
                tableOutput += recipient.full_name;
                if (recipient.read_flg === 1) {
                    tableOutput += ` - ${getFormattedDateTimeString(recipient.read_time)}`;
                } else {
                    tableOutput += ' - not read';
                }
                if (index < obj[header.dataKey].size - 1) {
                    tableOutput += '\n';
                }
            });
            return tableOutput;
        }
        case 'sender_details': {
            return `${obj.sender_full_name}\n${obj.sender_role} - ${obj.sender_organization}`;
        }
        case 'main_details': {
            return `${getFormattedDateTimeString(obj.created_at)}\nChat${obj.room_name}`;
        }
        default:
            if (obj[header.dataKey].length > 50) {
                switch (lengthyContent) {
                    case 'new-lines':
                        return obj[header.dataKey].replace(/(.{50})/g, '$1\n');
                    case 'truncated':
                        return `${obj[header.dataKey].substring(0, 50)}...`;
                    default:
                        return obj[header.dataKey];
                }
            } else {
                return obj[header.dataKey];
            }
    }
}

export function maybeAddLastLogin(
    accessLevel: $PropertyType<UserProfile, 'accessLevel'>,
    users: UserProfile[],
    organizationUserActivity: UserActivityMap,
    sortDirection: string,
    sortHeader: string
): UserProfile[] {
    return accessLevel === 'OrgManager'
        ? immutableSort(getDateTimeLastLogin(users, organizationUserActivity), sortDirection, sortHeader)
        : users;
}
