// @flow


import Colors from '../../Themes/Colors';

const MessagesHomeStyles = {

    pageWrapper: {
        backgroundColor: Colors.lighterGray,
        height: '100%',
        overflow: 'hidden',
    },

    columnsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '100%',
    },

    rowsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },

    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'flex-start',
        margin: 20,
        height: '90%',
    },

    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        justifyContent: 'flex-start',
    },

    card: {
        overflow: 'scroll',
        textAlign: 'center',
        padding: 14,
        marginBottom: 20,
        position: 'relative',
    },

    cardTitle: {
        fontSize: 18,
    },

    button: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 18,
    },

    chatroomCountText: {
        fontSize: 60,
        margin: 10,
    },

    icon: {
        fontSize: 20,
        color: Colors.brandPrimary,
    },

    donorListWrapper: {
        height: '100%',
        overflow: 'scroll',
    },

    donorListDropdowns: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    donorListSearch: {
        width: '92%',
    },

    dataText: {
        fontSize: 16,
        color: Colors.brandPrimary,
        margin: 10,
    },

    noDataText: {
        fontSize: 16,
        margin: 10,
    },

    noDataWrapper: {
        margin: 0,
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },

    floatButton: {
        position: 'absolute',
        right: 14,
        top: 14,
    },

    caseActionButtonContainer: {
        display: 'flex',
        flex: 1,
        marginBottom: 20,
        marginRight: 20,
    },

    caseActionButton: {
        height: '100%',
        width: '100%',
    },
};

export default MessagesHomeStyles;
