// @flow
import type { UserProfile } from '../Utils/types';

// URL to the media message download api
const mediaHost = process.env.REACT_APP_API_IMAGE_ROOT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const getPictureLocation = (pictureUri: string, accessToken: string, prefix: string = '') => {
    const basePath = 'user/profile/pic/';
    let finalUri = pictureUri;
    let cacheBuster = '';
    const pictureUriParts = pictureUri.split('&');

    if (pictureUriParts.length > 1) {
        [finalUri] = pictureUriParts;
        cacheBuster = `&${pictureUriParts.slice(1).join('&')}`;
    }

    return {
        uri: `${mediaHost}${basePath}${prefix}${encodeURIComponent(finalUri)}${cacheBuster}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

const selectOtherProfileImage = (state: UserProfile, accessToken: string): * => {
    let image = '';
    if (state) {
        if (state.profilePicture && state.profilePicture !== 'null') {
            image = getPictureLocation(state.profilePicture, accessToken);
        }
    }
    return image;
};

export default selectOtherProfileImage;
