// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import EditSelectionText from './EditSelectionText';
import UserDetailsPanel from '../UserDetailsPanel';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import DeactivateAccountsButton from './DeactivateAccountsButton';
import { onClosePanel } from '../../Utils/panels';
import { maybeAddLastLogin } from '../../Utils/tableHelper';
import { isUserTypeLoaded } from '../../Utils/user';
import {
    loadUsers as _loadUsers,
    updateOrganizationMember as _updateOrganizationMemberAction,
    addAndUpdateOrganizationMember as _addAndUpdateOrganizationMemberAction,
    removeOrganizationMembers as _removeOrganizationMembers,
} from '../../Redux/UserActions';

import type { UserProfile, Organization } from '../../Utils/types';

const dialogType = 'ApprovedMember';
const contentType = 'organization members';
const organizationMemberHeadersOrg = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
        isInternal: true,
        isInvisible: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
    },
    {
        dataKey: 'email',
        title: 'Email',
    },
    {
        dataKey: 'phone',
        title: 'Phone',
        isUneditable: true,
    },
    {
        dataKey: 'role',
        title: 'Role',
    },
    {
        dataKey: 'department',
        title: 'Department',
    },
    {
        dataKey: 'accessLevel',
        title: 'Permission',
    },
    {
        dataKey: 'lastLogin',
        title: 'Last Login',
        isUneditable: true,
    }
];

export const organizationMemberHeadersSalesSys = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
    },
    {
        dataKey: 'email',
        title: 'Email',
    },
    {
        dataKey: 'emailUpdate',
        title: 'Pending Email',
        isUneditable: true,
    },
    {
        dataKey: 'phone',
        title: 'Phone',
        isUneditable: true,
    },
    {
        dataKey: 'organizationName',
        title: 'Organization',
    },
    {
        dataKey: 'role',
        title: 'Role',
    },
    {
        dataKey: 'department',
        title: 'Department',
    },
    {
        dataKey: 'accessLevel',
        title: 'Permission',
    }
];

export const updateOrganizationMember = (updatedUser: UserProfile, {
    organizations,
    profile,
    addAndUpdateOrganizationMemberAction,
    updateOrganizationMemberAction,
    selectedUser,
}: {
    organizations: Organization[],
    profile: UserProfile,
    addAndUpdateOrganizationMemberAction: typeof _addAndUpdateOrganizationMemberAction,
    updateOrganizationMemberAction: typeof _updateOrganizationMemberAction,
    selectedUser: UserProfile,
}) => {
    const { organizationName, } = updatedUser;
    const organizationId = profile.accessLevel === 'OrgManager'
        ? profile.organizationId
        : organizations.find((org) => org.organizationName === organizationName)?.organizationId;

    if (organizationId) {
        const {
            userId,
            role,
            firstName,
            lastName,
            email,
            accessLevel,
            department,
        } = updatedUser;
        const updateMember = selectedUser.organizationName !== updatedUser.organizationName
            ? addAndUpdateOrganizationMemberAction
            : updateOrganizationMemberAction;

        updateMember(organizationId, userId, role, firstName, lastName, email, null, accessLevel, department);
    }
};

type Props = {
    profile: UserProfile,
    selectedUser: UserProfile,
    users: Array<UserProfile>,
    usersLoaded: boolean,
    bulkEditUsers: Array<UserProfile>,

    organizations: Array<Organization>,

    loadUsers: typeof _loadUsers,
    updateOrganizationMemberAction: typeof _updateOrganizationMemberAction,
    addAndUpdateOrganizationMemberAction: typeof _addAndUpdateOrganizationMemberAction,
    removeOrganizationMembers: typeof _removeOrganizationMembers,
};

class ApprovedMembers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('approved');
    }

    onUpdateUser = (updatedUser: UserProfile) => {
        const {
            organizations,
            profile,
            addAndUpdateOrganizationMemberAction,
            updateOrganizationMemberAction,
            selectedUser,
        } = this.props;

        updateOrganizationMember(updatedUser, {
            organizations,
            profile,
            addAndUpdateOrganizationMemberAction,
            updateOrganizationMemberAction,
            selectedUser,
        });
    };

    onBulkRemoveMembers = () => {
        const { bulkEditUsers, removeOrganizationMembers, } = this.props;
        const numSelected = bulkEditUsers.length;
        const confirm = window.confirm(`Are you sure you want to remove the ${
            numSelected > 1 ? `${numSelected} ` : ''
        }selected user${numSelected > 1 ? 's' : ''} from the organization?`);

        if (confirm) {
            removeOrganizationMembers(bulkEditUsers);
        }
    };

    render() {
        const {
            profile, users, bulkEditUsers, usersLoaded,
        } = this.props;
        const numSelected = bulkEditUsers.length;
        const disableActions = numSelected < 1;
        const isAdmin = profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales';
        const headers = isAdmin ? organizationMemberHeadersSalesSys : organizationMemberHeadersOrg;

        return (
            <UserPanel
                sidebar={(
                    <UserDetailsPanel
                        headers={headers}
                        dialogType={dialogType}
                        contentType={contentType}
                        onUpdateUser={this.onUpdateUser}
                        onClose={onClosePanel}
                    />
                )}
            >
                <div className="user-actions">
                    <EditSelectionText numSelected={numSelected} disableActions={disableActions} />

                    <Button
                        className="caution-button"
                        disabled={disableActions}
                        raised
                        accent
                        label="Remove from Organization"
                        onClick={this.onBulkRemoveMembers}
                    />
                    {isAdmin ? (
                        <DeactivateAccountsButton
                            disabled={disableActions}
                            label="Deactivate Accounts"
                        />
                    ) : null}
                </div>

                <UserTable
                    users={users}
                    headers={headers}
                    contentType={contentType}
                    usersLoaded={usersLoaded}
                    multiSelect
                />
            </UserPanel>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        users: maybeAddLastLogin(
            profile.accessLevel,
            state.user.loadedUsers.approved ?? [],
            state.organization.organizationUserActivity,
            state.user.sortDirection,
            state.user.sortHeader
        ),
        usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'approved'),
        bulkEditUsers: state.user.bulkEditUsers,
        organizations: state.organization.organizations,
        selectedUser: state.user.selectedUser,
    };
};

export default connect(mapStateToProps, {
    loadUsers: _loadUsers,
    updateOrganizationMemberAction: _updateOrganizationMemberAction,
    addAndUpdateOrganizationMemberAction: _addAndUpdateOrganizationMemberAction,
    removeOrganizationMembers: _removeOrganizationMembers,
})(ApprovedMembers);
