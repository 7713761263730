// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Table, TableHead, TableRow, TableCell,
} from 'react-toolbox/lib/table';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Input from 'react-toolbox/lib/input/Input';

import type {
    Donor, DonorStatus, DonorType, DonorMap, DonorSort,
} from '../Utils/types';
import search from '../Utils/search';
import getDonorValues from '../Utils/donors';
import {
    setSelectedDonor as _setSelectedDonor,
    clearSelectedDonor as _clearSelectedDonor,
} from '../Redux/DonorActions';
import ApplicationStyles from '../Themes/ApplicationStyles';
import TablePanelStyles from './Styles/TablePanelStyles';
import { getTableCellValue } from '../Utils/tableHelper';
import Colors from '../Themes/Colors';
import MessagesHomeStyles from '../Containers/Styles/MessagesHomeStyles';

type Props = {
    donors: DonorMap,
    selectedDonorId: number,
    setSelectedDonor: (donorId: number) => *,
    clearSelectedDonor: () => *,
};

type State = {
    statusDropdownKey: number,
    sortDropdownKey: number,
    donorStatus: DonorStatus,
    donorType: DonorType,
    donorSort: DonorSort,
    searchTerm: string,
    filteredDonors: DonorMap,
    sortedDonors: Array<Donor>;
};

const donorHeaders = [
    {
        dataKey: 'opoDonorId',
        title: 'Referral ID',
    },
    {
        dataKey: 'currentLocation',
        title: 'Hospital',
    },
    {
        dataKey: 'age',
        title: 'Age',
    },
    {
        dataKey: 'sex',
        title: 'Sex',
    }
];

class DonorList extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            statusDropdownKey: 0,
            sortDropdownKey: 2,
            donorStatus: 'All',
            donorType: 'All',
            donorSort: 'Last Active',
            searchTerm: '',
            filteredDonors: {},
            sortedDonors: [],
        };
    }

    componentDidMount() {
        const {
            clearSelectedDonor,
        } = this.props;

        clearSelectedDonor();
    }

    componentDidUpdate(prevProps) {
        // Check if donors is updated
        if (this.props.donors !== prevProps.donors) {
            const donorArray = getDonorValues(this.props.donors);
            const sortedDonors = donorArray.sort((a, b) => (('lastModified' in b && 'lastModified' in a)
                ? new Date(b.lastModified) - new Date(a.lastModified)
                : 0));
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ sortedDonors, });
        }
    }

    componentWillUnmount() {
        const {
            clearSelectedDonor,
        } = this.props;

        clearSelectedDonor();
    }

    onFilterStatus = (newVal: DonorStatus) => {
        const {
            clearSelectedDonor,
        } = this.props;

        this.setState((state) => ({
            statusDropdownKey: (state.statusDropdownKey ? 0 : 1),
            donorStatus: newVal,
        }));

        clearSelectedDonor();
    };

    onSearch = (searchTerm: string) => {
        this.setState({ searchTerm, }, () => {
            this.filterDonors();
        });
    };

    onSort = (sortType: DonorSort) => {
        const donorArray: Array<Donor> = getDonorValues(this.props.donors);
        const sortedDonors = donorArray.sort((a, b) => {
            if (sortType === 'Create Date') {
                return new Date(a.createDate) - new Date(b.createDate);
            } if (sortType === 'Last Active') {
                return new Date(b.lastModified) - new Date(a.lastModified);
            }
            return 0;
        });


        this.setState((state) => ({
            sortedDonors,
            donorSort: sortType,
            sortDropdownKey: state.sortDropdownKey === 2 ? 3 : 2,
        }));
    };


    onClickDonorRow = (donor: Donor) => {
        const {
            setSelectedDonor,
        } = this.props;

        setSelectedDonor(donor.donorId);
    };

    filterDonors = () => {
        const { searchTerm, sortedDonors, } = this.state;

        const searchResults = search(Object.values(sortedDonors), searchTerm, donorHeaders.map((header) => header.dataKey));

        const filteredDonors = searchResults.reduce((acc, donor) => {
            acc[donor.donorId] = donor;
            return acc;
        }, {});

        this.setState({ filteredDonors, });
    }

    createContentRows = () => {
        const {
            selectedDonorId,
        } = this.props;

        const {
            donorStatus,
            donorType,
            filteredDonors,
            sortedDonors,
            searchTerm,
        } = this.state;

        const contentRows = [];

        const filteredCases: Array<Donor> = searchTerm ? getDonorValues(filteredDonors) : sortedDonors;

        filteredCases.forEach((donor: Donor) => {
            const status = donor.closed ? 'Closed' : 'Open';
            if ((donor.visible || donor.visible === undefined)
                && ((donorStatus === 'All' && donorType === 'All')
                || (donorStatus === 'All' && donor.donorType === donorType)
                || (donorType === 'All' && status === donorStatus)
                || (donor.donorType === donorType && status === donorStatus))) {
                contentRows.push(
                    <TableRow
                        key={donor.donorId}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: selectedDonorId && selectedDonorId === donor.donorId ? Colors.brandSecondary : null,
                        }}
                        onClick={() => this.onClickDonorRow(donor)}
                    >
                        {donorHeaders.map((header, index) => (
                            <TableCell key={(`${donor.donorId + index}_${header.title}`)}>
                                {getTableCellValue(donor, header)}
                            </TableCell>
                        ))}
                    </TableRow>
                );
            }
        });

        return contentRows;
    };

    render() {
        const {
            donors,
        } = this.props;

        const {
            donorStatus,
            donorSort,
            statusDropdownKey,
            sortDropdownKey,
            searchTerm,
        } = this.state;

        const contentRowsArray = this.createContentRows();

        if (Object.keys(donors).length > 0) {
            return (
                <div style={MessagesHomeStyles.donorListWrapper}>
                    <div style={MessagesHomeStyles.donorListDropdowns}>
                        <Dropdown
                            key={statusDropdownKey}
                            auto
                            onChange={(newVal) => this.onFilterStatus(newVal)}
                            source={[{ value: 'All', label: 'All', }, { value: 'Open', label: 'Open', }, { value: 'Closed', label: 'Closed', }]}
                            value={donorStatus}
                            label="Case Status"
                        />
                        <Dropdown
                            key={sortDropdownKey}
                            auto
                            onChange={(sortType) => this.onSort(sortType)}
                            source={[
                                { value: 'Create Date', label: 'Create Date', },
                                { value: 'Last Active', label: 'Last Active', }
                            ]}
                            value={donorSort}
                            label="Sort By"
                        />
                    </div>
                    <div style={MessagesHomeStyles.donorListSearch}>
                        <Input
                            type="text"
                            label="Search"
                            value={searchTerm}
                            name="search"
                            icon="search"
                            onChange={this.onSearch}
                        />
                    </div>
                    <Table selectable={false} id="table">
                        <TableHead>
                            {
                                donorHeaders.map((header, index) => (
                                    <TableCell
                                        key={index.toString()}
                                        style={TablePanelStyles.headerText}
                                    >
                                        {header.title}
                                    </TableCell>
                                ), this)
                            }
                        </TableHead>
                        {contentRowsArray}
                    </Table>
                </div>
            );
        }

        return (
            <h4 style={ApplicationStyles.footerMessage}>
                There are no cases to be displayed.
            </h4>
        );
    }
}

const mapStateToProps = (state) => ({
    donors: state.donor.donors,
    selectedDonorId: state.donor.selectedDonorId,
});

export default connect(mapStateToProps, {
    setSelectedDonor: _setSelectedDonor,
    clearSelectedDonor: _clearSelectedDonor,
})(DonorList);
