import moment from 'moment';
import differenceInYears from 'date-fns/differenceInYears';

export function getDateTimeStringAtMidnight(date, addedDays = 0) {
    const dateCopy = new Date(date);
    if (addedDays > 0) {
        dateCopy.setDate(dateCopy.getDate() + addedDays);
    }
    const fullMonth = dateCopy.getMonth() < 9 ? `0${dateCopy.getMonth() + 1}` : dateCopy.getMonth() + 1;
    const fullDate = dateCopy.getDate() < 10 ? `0${dateCopy.getDate()}` : dateCopy.getDate();
    return `${dateCopy.getFullYear()}-${fullMonth}-${fullDate}T00:00:00.000Z`;
}

export function getFormattedDateTimeString(dt) {
    const momentDate = moment(dt, 'YYYY-MM-DDThh:mm:ss.SSSZ');
    if (momentDate.isValid()) {
        return momentDate.format('MM/DD/YY, hh:mm A');
    }
    return 'N/A';
}

export function getDateString(d) {
    return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

// d1: first (earlier) date
// d2: second (later) date
export function getElapsedTimeString(d1, d2) {
    const secDiff = (new Date(d1) - new Date(d2)) / 1000;
    const days = Math.floor(secDiff / 86400);
    const hours = Math.floor((secDiff - (days * 86400)) / 3600);
    const minutes = Math.floor((secDiff - (days * 86400) - (hours * 3600)) / 60);
    const seconds = Math.floor(secDiff - (days * 86400) - (hours * 3600) - (minutes * 60));

    let timeElapsed = '';

    if (days) {
        timeElapsed += days === 1 ? `${days} Day ` : `${days} Days `;
    }

    if (hours) {
        timeElapsed += hours === 1 ? `${hours} Hour ` : `${hours} Hours `;
    }

    if (minutes) {
        timeElapsed += minutes === 1 ? `${minutes} Minute ` : `${minutes} Minutes `;
    }

    if (seconds) {
        timeElapsed += seconds === 1 ? `${seconds} Second ` : `${seconds} Seconds`;
    }

    return timeElapsed;
}

export const calculateAgeFromDOB = (dob) => differenceInYears(new Date(), new Date(dob));

export const getDateTimeLastLogin = (users, organizationUserActivity) => users.map((user) => {
    const userIdKey = user.userId;
    const userActivity = organizationUserActivity.userActivity[userIdKey]
        ? organizationUserActivity.userActivity[userIdKey].filter((activity) => activity.actionName.toLowerCase() === 'login') : [];

    if (userActivity && userActivity.length > 0) {
        // Sort the userActivity logins by date, in chronological order
        userActivity.sort((loginA, loginB) => {
            // Some safe-guards
            if (!loginA.actionDate && !loginB.actionDate) return 0;
            if (!loginA.actionDate) return 1;
            if (!loginB.actionDate) return -1;

            const dateA = new Date(loginA.actionDate);
            const dateB = new Date(loginB.actionDate);

            if (dateA.getTime() < dateB.getTime()) return -1;
            if (dateA.getTime() > dateB.getTime()) return 1;
            return 0;
        });
        const lastActivity = userActivity[userActivity.length - 1];

        if (lastActivity.actionDate) {
            return {
                ...user,
                lastLogin: getFormattedDateTimeString(lastActivity.actionDate),
            };
        }
    }

    // just return user if no activity found for the user within the organization
    return user;
});
