// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import EditSelectionText from './EditSelectionText';
import UserDetailsPanel from '../UserDetailsPanel';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import { onClosePanel } from '../../Utils/panels';
import { isUserTypeLoaded } from '../../Utils/user';
import {
    updateUser as _updateUser,
    loadRegisteringUsers as _loadRegisteringUsers,
    deleteRegisteringUsers as _deleteRegisteringUsers,
} from '../../Redux/UserActions';

import type { UserProfile } from '../../Utils/types';

const dialogType = 'NonMember';
const contentType = 'registering users';
const registeringUsersHeadersSalesSys = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
    },
    {
        dataKey: 'email',
        title: 'Email',
    },
    {
        dataKey: 'phone',
        title: 'Phone',
    },
    {
        dataKey: 'oktaId',
        title: 'Okta ID',
    },
    {
        dataKey: 'factorId',
        title: 'Factor ID',
    },
    {
        dataKey: 'registrationTime',
        title: 'Registration Time',
        isUneditable: true,
        isDate: true,
    }
];

type Props = {
    profile: UserProfile,
    users: Array<UserProfile>,
    usersLoaded: boolean,
    bulkEditUsers: Array<UserProfile>,

    updateUser: (userId: number, firstName: ?string, lastName: ?string, email: ?string, templatePreferences: ?any,
        accessLevel: ?string, role: ?string) => *,
    loadRegisteringUsers: typeof _loadRegisteringUsers,
    deleteRegisteringUsers: typeof _deleteRegisteringUsers,
};

class RegisteringUsers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadRegisteringUsers();
    }

    onUpdateUser = ({
        userId, firstName, lastName, email, accessLevel, role,
    }: UserProfile) => {
        this.props.updateUser(userId, firstName, lastName, email, null, accessLevel, role);
    };

    onDeleteRegisteringUsers = () => {
        const { bulkEditUsers, deleteRegisteringUsers, } = this.props;
        const numSelected = bulkEditUsers.length;
        const confirm = window.confirm(`Are you sure you want to delete the ${
            numSelected > 1 ? `${numSelected} ` : ''
        }selected registering user${numSelected > 1 ? 's' : ''}? This action cannot be undone.`);

        if (confirm) {
            deleteRegisteringUsers(bulkEditUsers.map((user) => user.userId));
        }
    };

    render() {
        const {
            profile, users, bulkEditUsers, usersLoaded,
        } = this.props;
        const numSelected = bulkEditUsers.length;
        const disableActions = numSelected < 1;
        const headers = profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales'
            ? registeringUsersHeadersSalesSys
            : [];

        return (
            <UserPanel
                sidebar={(
                    <UserDetailsPanel
                        headers={headers}
                        dialogType={dialogType}
                        contentType={contentType}
                        onUpdateUser={this.onUpdateUser}
                        onClose={onClosePanel}
                    />
                )}
            >
                <div className="user-actions">
                    <EditSelectionText numSelected={numSelected} disableActions={disableActions} />

                    <Button
                        className="warning-button"
                        disabled={disableActions}
                        raised
                        accent
                        label="Delete Users"
                        onClick={this.onDeleteRegisteringUsers}
                    />
                </div>

                <UserTable
                    users={users}
                    headers={headers}
                    contentType={contentType}
                    usersLoaded={usersLoaded}
                    multiSelect
                />
            </UserPanel>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
    users: state.user.loadedUsers.registering ?? [],
    usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'registering'),
    bulkEditUsers: state.user.bulkEditUsers,
});

export default connect(mapStateToProps, {
    updateUser: _updateUser,
    loadRegisteringUsers: _loadRegisteringUsers,
    deleteRegisteringUsers: _deleteRegisteringUsers,
})(RegisteringUsers);
