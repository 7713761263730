/* eslint-disable no-template-curly-in-string */
import Router, { Resource, parseErrors } from 'tg-resources';

import isString from '../Utils/isString';

const api = new Router({

    txp: new Router({
        // API(s) that do not need access token
        anonymous: new Router({
            login: new Resource('/auth/user/login', {
                statusValidationError: [400, 401, 403],
            }),
            refreshToken: new Resource('/auth/user/refresh'),
        }, {
            withCredentials: false,
        }),

        versions: new Resource('/.well-known/release-version'),
        updateiOSVersion: new Resource('/application/version/ios'),
        updateAndroidVersion: new Resource('/application/version/android'),
        donors: new Resource('/donors'),
        donor: new Resource('/donor/${donorId}'),
        donorFiles: new Resource('/donor/${donorId}/files'),
        tasks: new Resource('/donors/tasks'),
        donorTasks: new Resource('/donor/${donorId}/tasks'),
        donorTimeline: new Resource('/donor/${donorId}/tasks/timeline'),
        donorNetPDFFields: new Resource('/donor/pdf_fields'),
        caseNotes: new Resource('/case/${caseId}/notes'),
        caseFollowersDetailed: new Resource('/case/${caseId}/followers_detailed'),
        updateCaseStatus: new Resource('/admin/case/${caseId}/status'),
        users: new Resource('/admin/users'),
        invitedUsers: new Resource('/admin/users/invite'),
        pendingMembers: new Resource('/admin/organizations/${organizationId}/members/pending'),
        removedMembers: new Resource('/admin/organizations/${organizationId}/members/removed'),
        organizationMembers: new Resource('/admin/organizations/${organizationId}/members'),
        registeringUsers: new Resource('/admin/registrations'),
        templateOptions: new Resource('/offer/template'),
        verifyEmail: new Resource('/admin/users/${userId}/email/verify'),
        cancelEmailUpdate: new Resource('/admin/users/${userId}/email/cancel'),
        resendVerificationEmail: new Resource('/registration/email/send'),
        resendInvitationEmail: new Resource('/admin/users/invite/resend'),
        chatrooms: new Resource('/admin/chatrooms'),
        activeChatroomData: new Resource('/admin/active/chatroom/${chatroom_id}'),
        archivedChatroomData: new Resource('/admin/archived/chatroom/${chatroom_id}'),
        closeChatroom: new Resource('/admin/delete/chatroom/${chatroom_id}'),
        organizations: new Resource('/admin/organizations'),
        partialOrganizations: new Resource('/organization/all'),
        organizationTypes: new Resource('/admin/organizations/types'),
        organizationPermissions: new Resource('/admin/organizations/permissions'),
        addOrganization: new Resource('/admin/organization/create'),
        updateOrganization: new Resource('/admin/organization/${organizationId}'),
        updateOrganizationLicense: new Resource('/admin/organization/${organizationId}/license'),
        deleteOrganization: new Resource('/admin/organization/${organizationId}'),
        organizationMetrics: new Resource('/admin/metrics/organization/${organizationId}'),
        homeOrganizationMetrics: new Resource('/admin/metrics/organization/${organizationId}/homepage'),
        organizationUserActivity: new Resource('/admin/metrics/organization/${organizationId}/users/activity'),
        businessMetrics: new Resource('/admin/metrics/business'),
        removeAllMembers: new Resource('/admin/organizations/${organizationId}/members'),
        handlePendingMember: new Resource('/admin/organizations/${organizationId}/pending-members/add'),
        handlePendingMembers: new Resource('/admin/organizations/pending-members/bulk'),
        addOrganizationMember: new Resource('/admin/organizations/${organizationId}/members/add'),
        updateOrganizationMember: new Resource('/admin/organizations/${organizationId}/members/${userId}'),
        removeOrganizationMember: new Resource('/admin/organizations/${organizationId}/members/${userId}'),
        removeOrganizationMembers: new Resource('/admin/organizations/bulk/members'),
        addUser: new Resource('/admin/user/demo'),
        updateUser: new Resource('/admin/users/${userId}'),
        deleteUser: new Resource('/admin/users/${userId}'),
        deleteUsers: new Resource('/admin/users/bulk'),
        inviteUsers: new Resource('/admin/users/invite'),
        updateUserUIPermissions: new Resource('/admin/users/${userId}/permissions/ui'),
        completeRegisteringUser: new Resource('/admin/registration/${userId}/complete'),
        deleteRegisteringUser: new Resource('/registration/${userId}'),
        deleteRegisteringUsers: new Resource('/admin/registrations/bulk'),
        resourcePermission: new Resource('/permissions/${entityType}/${entityId}'),
        addPermission: new Resource('/permission'),
        removePermission: new Resource('/permission/${permissionId}'),
        referenceData: new Resource('/reference/all'),
        uploadReference: new Resource('/reference/${domain}/${name}'),
        updateInactive: new Resource('/reference/${domain}/${name}/status'),
        updateWorkflowDetails: new Resource('/reference/workflow/${key}/details'),
        createWorkflow: new Resource('/admin/reference/workflow'),
        updateWorkflow: new Resource('/admin/reference/workflow/${key}'),
        updateWorkflowPreferences: new Resource('/admin/reference/workflow/preferences/${preferencesId}'),
        createWorkflowPreferences: new Resource('/admin/reference/workflow/${key}/preferences'),
        teams: new Resource('/teams'),
        // Do not use saga helper for this api call
        logout: new Resource('/auth/user/logout'),
    }, {
        apiRoot: (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, ''),
        parseErrors: (errorText, parentConfig) => {
            let error = null;

            if (isString(errorText)) {
                if (errorText) {
                    try {
                        error = JSON.parse(errorText);
                    } catch (e) {
                        // if json parsing fails, handle as text
                        error = errorText;
                    }
                } else {
                    error = '';
                }
            } else {
                error = errorText;
            }

            if (error && error.request_error) {
                const preparedError = {};

                (error.request_error || []).forEach((fieldError) => {
                    preparedError[fieldError.parameter_name] = preparedError[fieldError.parameter_name] || [];
                    (fieldError.violated_constraints || []).forEach((msg) => {
                        preparedError[fieldError.parameter_name].push(msg);
                    });
                });
                return parseErrors(preparedError, parentConfig);
            } if (error && error.error) {
                ({ error, } = error);
                // Hackish solution to be able to handle recovery endpoint errors
                //  the errors for it are a json string with prepended statuscode
                if (isString(error) && (error.startsWith('403 - {') || error.startsWith('400 - {'))) {
                    try {
                        error = JSON.parse(error.substring(6));

                        if (error && error.errorSummary) {
                            error = error.errorSummary;
                        }
                    } catch (er) {
                        // nothing to do
                    }
                }

                error = {
                    non_field_errors: error,
                };
            }

            return parseErrors(error, parentConfig);
        },
    }),
}, {
    headers: () => ({
        Accept: 'application/json',
        Apikey: process.env.REACT_APP_API_KEY || '',
    }),
    withCredentials: true,
});

export default api;
