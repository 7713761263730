// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import { isUserTypeLoaded } from '../../Utils/user';

import {
    loadUsers as _loadUsers,
} from '../../Redux/UserActions';
import type { UserProfile } from '../../Utils/types';

const contentType = 'archived users';
const headers = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
        isInternal: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
    },
    {
        dataKey: 'email',
        title: 'Email',
    }
];

type Props = {
    users: Array<UserProfile>,
    usersLoaded: boolean,
    loadUsers: typeof _loadUsers,
};

class ArchivedUsers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('archived');
    }

    render() {
        const { users, usersLoaded, } = this.props;

        return (
            <UserPanel sidebar={<></>}>
                <UserTable
                    users={users}
                    headers={headers}
                    contentType={contentType}
                    usersLoaded={usersLoaded}
                />
            </UserPanel>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.user.loadedUsers.archived ?? [],
    usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'archived'),
});

export default connect(mapStateToProps, {
    loadUsers: _loadUsers,
})(ArchivedUsers);
