// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { Bar, Line } from 'react-chartjs-2';

import LoadingIndicatorStyles from './Styles/LoadingIndicatorStyles';
import LoadingIndicator from './LoadingIndicator';
import ApplicationStyles from '../Themes/ApplicationStyles';
import Colors from '../Themes/Colors';
import type { BusinessMetrics } from '../Utils/types';
import { binArrayData } from '../Utils/arrays';

type Props = {
    businessMetrics: BusinessMetrics,
    organizationNameMap: { [number]: string },
    businessMetricsLoaded: boolean,
};

class InternalDashboard extends PureComponent<Props> {
    render() {
        const {
            businessMetrics,
            businessMetricsLoaded,
            organizationNameMap,
        } = this.props;

        if (!businessMetricsLoaded) {
            return (
                <div style={LoadingIndicatorStyles.verticalCenter}>
                    <h4 style={LoadingIndicatorStyles.formLabel}>Loading metrics - please wait.</h4>
                    <h1><LoadingIndicator /></h1>
                </div>
            );
        }

        const messagesByUserData = businessMetrics.sentMessagesByUser.concat(businessMetrics.readMessagesByUser);
        const binnedSentMessages: Array<number> = [];
        const binnedReadMessages: Array<number> = [];
        const messageBinLabels: Array<string> = [];

        if (messagesByUserData.length > 0) {
            for (let i = 0; i < 10; i += 1) {
                binnedSentMessages.push(0);
                binnedReadMessages.push(0);
            }

            const messageBinThresholds = binArrayData(messagesByUserData);
            messageBinLabels.push(`0-${messageBinThresholds[0]}`);

            // Create bin names
            for (let i = 1; i < 9; i += 1) {
                messageBinLabels.push(`${messageBinThresholds[i - 1]}-${messageBinThresholds[i]}`);
            }
            messageBinLabels.push(`${messageBinThresholds[9]}+`);

            // Bin the data
            for (let i = 0; i < businessMetrics.sentMessagesByUser.length; i += 1) {
                switch (true) {
                    case businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[0]:
                        binnedSentMessages[0] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[0]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[1]:
                        binnedSentMessages[1] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[1]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[2]:
                        binnedSentMessages[2] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[2]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[3]:
                        binnedSentMessages[3] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[3]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[4]:
                        binnedSentMessages[4] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[4]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[5]:
                        binnedSentMessages[5] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[5]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[6]:
                        binnedSentMessages[6] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[6]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[7]:
                        binnedSentMessages[7] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[7]
                            && businessMetrics.sentMessagesByUser[i] <= messageBinThresholds[8]:
                        binnedSentMessages[8] += 1;
                        break;
                    case businessMetrics.sentMessagesByUser[i] > messageBinThresholds[8]:
                        binnedSentMessages[9] += 1;
                        break;
                    default:
                        break;
                }
            }

            for (let i = 0; i < businessMetrics.readMessagesByUser.length; i += 1) {
                switch (true) {
                    case businessMetrics.readMessagesByUser[i] <= messageBinThresholds[0]:
                        binnedReadMessages[0] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[0]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[1]:
                        binnedReadMessages[1] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[1]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[2]:
                        binnedReadMessages[2] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[2]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[3]:
                        binnedReadMessages[3] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[3]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[4]:
                        binnedReadMessages[4] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[4]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[5]:
                        binnedReadMessages[5] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[5]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[6]:
                        binnedReadMessages[6] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[6]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[7]:
                        binnedReadMessages[7] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[7]
                            && businessMetrics.readMessagesByUser[i] <= messageBinThresholds[8]:
                        binnedReadMessages[8] += 1;
                        break;
                    case businessMetrics.readMessagesByUser[i] > messageBinThresholds[8]:
                        binnedReadMessages[9] += 1;
                        break;
                    default:
                        break;
                }
            }
        }

        const options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        precision: 0,
                    },
                }],
            },
        };

        const optionsAxis = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        precision: 0,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of Users',
                    },
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of Messages',
                    },
                }],
            },
        };

        const usersByRole = {
            labels: Object.keys(businessMetrics.organizationalRoleCounts),
            datasets: [{
                backgroundColor: Colors.brandMid,
                data: Object.values(businessMetrics.organizationalRoleCounts),
            }],
        };

        const messagesByUser = {
            labels: messageBinLabels,
            datasets: [{
                label: 'Sent',
                backgroundColor: Colors.brandPrimary,
                data: binnedSentMessages,
            },
            {
                label: 'Read',
                backgroundColor: Colors.brandSecondary,
                data: binnedReadMessages,
            }],
        };

        const organizationLabels = [];
        for (const id of Object.keys(businessMetrics.sentMessagesByOrganization)) {
            organizationLabels.push(organizationNameMap[parseInt(id, 10)]);
        }
        const messagesByOrganization = {
            labels: organizationLabels,
            datasets: [{
                label: 'Sent',
                backgroundColor: Colors.brandPrimary,
                data: Object.values(businessMetrics.sentMessagesByOrganization),
            },
            {
                label: 'Read',
                backgroundColor: Colors.brandSecondary,
                data: Object.values(businessMetrics.readMessagesByOrganization),
            }],
        };

        const userLabelsDuplicates = Object.keys(businessMetrics.newUserCounts)
            .concat(Object.keys(businessMetrics.convertedUserCounts))
            .concat(Object.keys(businessMetrics.monthlyActiveUserCounts))
            .concat(Object.keys(businessMetrics.runningStandardUserCounts))
            .concat(Object.keys(businessMetrics.runningPremiumUserCounts));
        const userLabels = userLabelsDuplicates.filter((item, index) => userLabelsDuplicates.indexOf(item) === index).sort();
        const newUserCounts = [];
        const convertedUserCounts = [];
        const monthlyActiveUserCounts = [];
        const runningStandardUserCounts = [];
        const runningPremiumUserCounts = [];

        for (let i = 0; i < userLabels.length; i += 1) {
            newUserCounts.push(businessMetrics.newUserCounts[userLabels[i]] || 0);
            convertedUserCounts.push(businessMetrics.convertedUserCounts[userLabels[i]] || 0);
            monthlyActiveUserCounts.push(businessMetrics.monthlyActiveUserCounts[userLabels[i]] || 0);
            runningStandardUserCounts.push(businessMetrics.runningStandardUserCounts[userLabels[i]] || 0);
            runningPremiumUserCounts.push(businessMetrics.runningPremiumUserCounts[userLabels[i]] || 0);
        }

        const userCounts = {
            labels: userLabels,
            datasets: [{
                label: 'New Registrations',
                fill: false,
                borderColor: Colors.brandSecondary,
                backgroundColor: Colors.brandSecondary,
                data: newUserCounts,
            },
            {
                label: 'Converted Users',
                fill: false,
                borderColor: Colors.brandSecondaryLight,
                backgroundColor: Colors.brandSecondaryLight,
                data: convertedUserCounts,
            },
            {
                label: 'Monthly Active Users',
                fill: false,
                borderColor: Colors.brandPrimaryDark,
                backgroundColor: Colors.brandPrimaryDark,
                data: monthlyActiveUserCounts,
            },
            {
                label: 'Running Standard Count',
                fill: false,
                borderColor: Colors.brandPrimary,
                backgroundColor: Colors.brandPrimary,
                data: runningStandardUserCounts,
            },
            {
                label: 'Running Premium Count',
                fill: false,
                borderColor: Colors.brandPrimaryLight,
                backgroundColor: Colors.brandPrimaryLight,
                data: runningPremiumUserCounts,
            },
            {
                label: 'Total Users (Standard + Premium)',
                fill: false,
                borderColor: Colors.brandMid,
                backgroundColor: Colors.brandMid,
                data: runningStandardUserCounts.map((num, idx) => {
                    const standardCount: number = parseInt(num, 10);
                    const premiumCount: number = parseInt(runningPremiumUserCounts[idx], 10);
                    return standardCount + premiumCount;
                }),
            }],
        };

        const actionCountsLabelsDuplicates = Object.keys(businessMetrics.createdChatroomCounts);
        const actionCountsLabels = actionCountsLabelsDuplicates.filter((item, index) => userLabelsDuplicates.indexOf(item) === index).sort();
        const createdChatroomCounts = [];

        for (let i = 0; i < actionCountsLabels.length; i += 1) {
            createdChatroomCounts.push(businessMetrics.createdChatroomCounts[actionCountsLabels[i]] || 0);
        }

        const actionCounts = {
            labels: actionCountsLabels,
            datasets: [{
                label: 'Chatrooms Created',
                fill: false,
                borderColor: Colors.brandSecondary,
                backgroundColor: Colors.brandSecondary,
                data: createdChatroomCounts,
            }],
        };

        const messageCountsLabelsDuplicates = Object.keys(businessMetrics.sentMessageCounts).concat(Object.keys(businessMetrics.readMessageCounts));
        const messageCountsLabels = messageCountsLabelsDuplicates.filter((item, index) => messageCountsLabelsDuplicates.indexOf(item) === index).sort();
        const sentMessageCounts = [];
        const readMessageCounts = [];

        for (let i = 0; i < messageCountsLabels.length; i += 1) {
            sentMessageCounts.push(businessMetrics.sentMessageCounts[messageCountsLabels[i]] || 0);
            readMessageCounts.push(businessMetrics.readMessageCounts[messageCountsLabels[i]] || 0);
        }

        const messageCounts = {
            labels: messageCountsLabels,
            datasets: [{
                label: 'Sent',
                fill: false,
                borderColor: Colors.brandPrimary,
                backgroundColor: Colors.brandPrimary,
                data: sentMessageCounts,
            },
            {
                label: 'Read',
                fill: false,
                borderColor: Colors.brandSecondary,
                backgroundColor: Colors.brandSecondary,
                data: readMessageCounts,
            }],
        };

        return (
            <div style={ApplicationStyles.internalHomePagePadding}>
                <Row style={ApplicationStyles.fullHeight}>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Users By Role</p>
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Messages by Organization</p>
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Messages by User</p>
                    </Col>
                </Row>
                <Row style={ApplicationStyles.fullHeight}>
                    <Col sm={4}>
                        <Bar
                            data={usersByRole}
                            options={{
                                ...options,
                                legend: {
                                    display: false,
                                },
                            }}
                        />
                    </Col>
                    <Col sm={4}>
                        <Bar data={messagesByOrganization} options={options} />
                    </Col>
                    <Col sm={4}>
                        <Bar data={messagesByUser} options={optionsAxis} />
                    </Col>
                </Row>
                <Row style={ApplicationStyles.fullHeight}>
                    <Col sm={6}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>All Messages</p>
                        <Line data={messageCounts} />
                    </Col>
                    <Col sm={6}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Chatrooms Created</p>
                        <Line data={actionCounts} />
                    </Col>
                </Row>
                <Row style={ApplicationStyles.fullHeight}>
                    <Col sm={12}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Users</p>
                        <Line data={userCounts} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const organizationNameMap: { [number]: string } = {};
    for (const organization of state.organization.organizations) {
        organizationNameMap[organization.organizationId] = organization.organizationCode;
    }

    return {
        businessMetricsLoaded: state.organization.businessMetricsLoaded,
        businessMetrics: state.organization.businessMetrics,
        organizationNameMap,
    };
};
export default connect(mapStateToProps, null)(InternalDashboard);
