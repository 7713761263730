// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, IconButton } from 'react-toolbox/lib/button';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import InviteUsersButtonStyles from './Styles/InviteUsersButtonStyles';
import {
    inviteUsers as _inviteUsers,
} from '../Redux/UserActions';
import parseCsv from '../Utils/parseCsv';

type Props = {
    disableButton: boolean,
    inviteUsers: typeof _inviteUsers,
};

type State = {
    confirmUploadOpen: boolean,
    helpDialogOpen: boolean,
    errorText: string,
    inviteUserFileRead: any[],
};

const helpDialogContent = (
    <>
        <span>
            Please upload a csv or tsv file with the names and emails of the individuals you would like to invite.
            Do not include headers in the file. See the example below.
        </span>
        <table style={InviteUsersButtonStyles.helpTable}>
            <tbody>
                <tr>
                    <td style={InviteUsersButtonStyles.helpTableCell}>Member One</td>
                    <td style={InviteUsersButtonStyles.helpTableCell}>member1@example.com</td>
                </tr>
                <tr>
                    <td style={InviteUsersButtonStyles.helpTableCell}>Member Two</td>
                    <td style={InviteUsersButtonStyles.helpTableCell}>member2@example.com</td>
                </tr>
            </tbody>
        </table>
    </>
);

class InviteUsersButton extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            confirmUploadOpen: false,
            helpDialogOpen: false,
            errorText: '',
            inviteUserFileRead: [],
        };
    }

    onInviteFileClick = () => {
        if (this.hiddenFileInput.current) {
            this.hiddenFileInput.current.click();
        }
    };

    onCloseConfirmUpload = () => {
        this.setState({
            confirmUploadOpen: false,
            inviteUserFileRead: [],
            errorText: '',
        });
    };

    onInviteConfirm = () => {
        const {
            inviteUsers,
        } = this.props;

        const {
            inviteUserFileRead,
        } = this.state;
        if (inviteUserFileRead) {
            inviteUsers(inviteUserFileRead);
        }
        this.onCloseConfirmUpload();
    };

    onOpenHelpDialog = () => {
        this.setState({
            helpDialogOpen: true,
        });
    }

    onCloseHelpDialog = () => {
        this.setState({
            helpDialogOpen: false,
        });
    };

    handleFileUpload = (event: any) => {
        event.preventDefault();

        if (this.hiddenFileInput.current && this.hiddenFileInput.current.files && this.hiddenFileInput.current.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    // NOTE: reader.result could technically be ArrayBuffer, so checking to make sure its a string for flow
                    if (this.hiddenFileInput.current && reader.result && typeof reader.result === 'string') {
                        const inviteUserFileRead = parseCsv(reader.result, this.hiddenFileInput.current.files[0].type);
                        this.setState({
                            inviteUserFileRead,
                        });
                    }
                } catch (error) {
                    this.setState({
                        errorText: error.message,
                    });
                }
            };
            reader.readAsText(this.hiddenFileInput.current.files[0]);
        }

        this.setState({
            confirmUploadOpen: true,
        });
    };

    hiddenFileInput: {|current: null | HTMLInputElement|} = React.createRef();

    render() {
        const {
            confirmUploadOpen,
            inviteUserFileRead,
            helpDialogOpen,
            errorText,
        } = this.state;
        const { disableButton, } = this.props;

        const personPluralityText = inviteUserFileRead.length === 1 ? 'person' : 'people';
        const invitePluralityText = inviteUserFileRead.length === 1 ? 'an email invite' : 'email invites';
        const dialogText = errorText || `You are about to send ${invitePluralityText} to ${inviteUserFileRead.length} ${personPluralityText}.`;

        return (
            <>
                <div>
                    <Button
                        raised
                        primary
                        label="Invite Users"
                        disabled={disableButton}
                        onClick={this.onInviteFileClick}
                    />
                    <input
                        style={InviteUsersButtonStyles.displayNone}
                        id="files"
                        type="file"
                        accept=".csv, .tsv"
                        ref={this.hiddenFileInput}
                        onChange={this.handleFileUpload}
                    />
                    <IconButton
                        icon="help"
                        accent
                        onClick={this.onOpenHelpDialog}
                    />
                </div>
                <Dialog
                    active={confirmUploadOpen}
                    type="normal"
                    onEscKeyDown={this.onCloseConfirmUpload}
                    onOverlayClick={this.onCloseConfirmUpload}
                >
                    <div style={InviteUsersButtonStyles.helpDialogContainer}>
                        {dialogText}
                    </div>
                    <div className="user-actions" style={InviteUsersButtonStyles.actionButtonContainer}>
                        {!errorText ? (
                            <Button
                                style={InviteUsersButtonStyles.OkButton}
                                label="OK"
                                flat
                                primary
                                onClick={this.onInviteConfirm}
                            />
                        ) : null}
                        <Button
                            className="warning-button"
                            label="Cancel"
                            raised
                            accent
                            onClick={this.onCloseConfirmUpload}
                        />
                    </div>
                </Dialog>
                <Dialog
                    active={helpDialogOpen}
                    type="normal"
                    onEscKeyDown={this.onCloseHelpDialog}
                    onOverlayClick={this.onCloseHelpDialog}
                >
                    <div style={InviteUsersButtonStyles.helpDialogContainer}>
                        {helpDialogContent}
                        <Button
                            style={InviteUsersButtonStyles.OkButton}
                            label="OK"
                            raised
                            accent
                            onClick={this.onCloseHelpDialog}
                        />
                    </div>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    inviteUsers: _inviteUsers,
})(InviteUsersButton);
