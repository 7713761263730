// @flow
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import {
    TextField, Switch, MenuItem, InputAdornment,
} from '@material-ui/core';
import PhoneInput from 'react-phone-number-input/input';

import type { MainTask, SubtaskType } from '../Utils/types';
import WorkflowPreviewStyles from './Styles/WorkflowPreviewStyles';
import Colors from '../Themes/Colors';
import FormCheckbox from './FormCheckbox';
import Radios from './Radios';
import PhoneInputTextField from './PhoneInputTextField';

type Props = {
    mainTask: MainTask,
    onClosePreview: () => *,
}

type State = {
    openSections: Set<string>,
    formData: any,
}

class FormPreview extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openSections: new Set<string>(),
            formData: {},
        };
    }

    onToggleSection(sectionId: string) {
        this.setState((prevState) => {
            const openSections = new Set(prevState.openSections);

            if (openSections.has(sectionId)) {
                openSections.delete(sectionId);
            } else {
                openSections.add(sectionId);
            }

            return { openSections, };
        });
    }

    static onTrackFlight(tailNumber: string) {
        window.open(`https://flightaware.com/live/flight/${tailNumber}`, 'FlightAware', `width=550,height=${window.innerHeight},left=0,menubar=0,status=0`);
    }

    onFieldUpdate(value: string, subtaskId: string) {
        const { formData, } = this.state;
        const newFormData = {
            ...formData,
            [subtaskId]: value,
        };
        this.setState({ formData: newFormData, });
    }

    renderTypeText(subtaskField: SubtaskType) {
        const { formData, } = this.state;
        const value = formData && formData[subtaskField.id] ? formData[subtaskField.id] : '';
        const isFlightTracker = subtaskField.attributes.filter((attribute) => attribute.value === 'flightTracker').length;
        let flightTrackerAdornment = {};

        if (isFlightTracker) {
            flightTrackerAdornment = {
                endAdornment:
    <InputAdornment position="end">
        <Button
            onClick={() => FormPreview.onTrackFlight(value)}
            size="small"
        >
            {'Track Flight >'}
        </Button>
    </InputAdornment>,
            };
        }

        return (
            <div style={WorkflowPreviewStyles.padding10}>
                <TextField
                    type="text"
                    variant="outlined"
                    autoCapitalize="sentences"
                    placeholder={`Enter ${subtaskField.title} here...`}
                    fullWidth
                    color="primary"
                    multiline
                    label={subtaskField.title}
                    value={value}
                    onChange={(event) => this.onFieldUpdate(event.target.value, subtaskField.id)}
                    InputLabelProps={{ shrink: true, }}
                    InputProps={isFlightTracker ? flightTrackerAdornment : {}}
                />
            </div>
        );
    }

    static renderTypeSwitch(subtaskField: SubtaskType) {
        return (
            <div style={WorkflowPreviewStyles.formSwitchCheck}>
                <div style={WorkflowPreviewStyles.labelText}>
                    {subtaskField.title}
                </div>
                <Switch
                    defaultValue={false}
                    color="primary"
                />
            </div>
        );
    }

    static renderTypeCheck(subtaskField: SubtaskType) {
        return (
            <div style={WorkflowPreviewStyles.formSwitchCheck}>
                <div style={WorkflowPreviewStyles.labelText}>
                    {subtaskField.title}
                </div>
                <FormCheckbox
                    disabled={false}
                    isSelect={false}
                    label=""
                />
            </div>
        );
    }

    static renderTypeDatetime(subtaskField: SubtaskType) {
        return (
            <div style={WorkflowPreviewStyles.padding10}>
                <TextField
                    label={subtaskField.title}
                    type="datetime-local"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                />
            </div>
        );
    }

    static renderTypeDate(subtaskField: SubtaskType) {
        return (
            <div style={WorkflowPreviewStyles.padding10}>
                <TextField
                    label={subtaskField.title}
                    type="date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                />
            </div>
        );
    }

    static renderTypeSelectOption(subtaskField: SubtaskType) {
        return (
            <div style={WorkflowPreviewStyles.marginVertical10}>
                <div style={WorkflowPreviewStyles.labelText}>
                    {subtaskField.title}
                </div>
                {subtaskField.responseOptions.map((option: string) => (
                    <div
                        style={WorkflowPreviewStyles.padding10}
                        key={option}
                    >
                        <FormCheckbox
                            disabled={false}
                            label={option}
                            isSelect
                        />
                    </div>
                ))}
            </div>
        );
    }

    // NOTE: making this TextField controlled because it seemes like the select
    // property makes it controlled
    renderTypePicker(subtaskField: SubtaskType) {
        const { formData, } = this.state;
        const value = formData && formData[subtaskField.id] ? formData[subtaskField.id] : '';

        return (
            <div style={WorkflowPreviewStyles.padding10}>
                <TextField
                    variant="outlined"
                    fullWidth
                    select
                    color="primary"
                    label={subtaskField.title}
                    type="text"
                    value={value}
                    onChange={(event) => this.onFieldUpdate(event.target.value, subtaskField.id)}
                    InputLabelProps={{ shrink: true, }}
                >
                    {subtaskField.responseOptions.map((option: any) => (
                        (option.display && option.value)
                            ? (
                                <MenuItem key={option.display + option.value} value={option.value}>{option.display}</MenuItem>
                            )
                            : (<MenuItem key={option} value={option}>{option}</MenuItem>)
                    ))}
                </TextField>
            </div>
        );
    }

    renderTypeTel(subtaskField: SubtaskType) {
        const { formData, } = this.state;
        const value = formData && formData[subtaskField.id] ? formData[subtaskField.id] : '';

        return (
            <div style={WorkflowPreviewStyles.marginVertical10}>
                <div style={WorkflowPreviewStyles.padding10}>
                    <PhoneInput
                        international
                        onChange={(text) => this.onFieldUpdate(text, subtaskField.id)}
                        withCountryCallingCode
                        defaultCountry="US"
                        inputComponent={PhoneInputTextField}
                        // TextInput props
                        variant="outlined"
                        label={subtaskField.title}
                        fullWidth
                        value={value}
                    />
                </div>
            </div>
        );
    }

    static renderTypeRadio(subtaskField: SubtaskType) {
        return (
            <Radios subtask={subtaskField} />
        );
    }

    static renderTypeReferring(subtaskField: SubtaskType) {
        const { title, } = subtaskField;
        const referredValue = 'No value entered yet';
        const noteText = 'This field is controlled by Task Name - Field Name';
        return (
            <div style={WorkflowPreviewStyles.padding10}>
                <div style={WorkflowPreviewStyles.labelText}>{title}</div>
                <div style={WorkflowPreviewStyles.valueTextDisabled}>{referredValue}</div>
                <div style={WorkflowPreviewStyles.noteText}>{noteText}</div>
            </div>
        );
    }

    renderFields(subtaskField: SubtaskType) {
        if (subtaskField.uiType === 'text') return this.renderTypeText(subtaskField);
        if (subtaskField.uiType === 'switch') return FormPreview.renderTypeSwitch(subtaskField);
        if (subtaskField.uiType === 'check') return FormPreview.renderTypeCheck(subtaskField);
        if (subtaskField.uiType === 'timepicker') return FormPreview.renderTypeDatetime(subtaskField);
        if (subtaskField.uiType === 'datepicker') return FormPreview.renderTypeDate(subtaskField);
        if (subtaskField.uiType === 'select') return FormPreview.renderTypeSelectOption(subtaskField);
        if (subtaskField.uiType === 'picker') return this.renderTypePicker(subtaskField);
        if (subtaskField.uiType === 'tel') return this.renderTypeTel(subtaskField);
        if (subtaskField.uiType === 'radio') return FormPreview.renderTypeRadio(subtaskField);
        if (subtaskField.uiType === 'referring') return FormPreview.renderTypeReferring(subtaskField);

        return (
            <div style={WorkflowPreviewStyles.labelText}>
                {subtaskField.title}
            </div>
        );
    }

    render() {
        const {
            mainTask,
            onClosePreview,
        } = this.props;
        const { openSections, } = this.state;

        return (
            <div style={WorkflowPreviewStyles.marginLeft10}>
                <div style={WorkflowPreviewStyles.buttonsWrapper}>
                    <Button
                        style={WorkflowPreviewStyles.backButton}
                        type="button"
                        ripple={false}
                        onClick={onClosePreview}
                    >
                        <FontIcon
                            value="arrow_back_ios"
                        />
                        Back
                    </Button>
                    Task Details
                    <Button
                        style={WorkflowPreviewStyles.backButton}
                        component="button"
                        ripple={false}
                    >
                        Save
                    </Button>
                </div>
                <div style={WorkflowPreviewStyles.detailsTitleWrapper}>
                    {mainTask.name}
                </div>
                <div>
                    {mainTask.sections.map((section) => {
                        const sectionId = section.id;
                        const hasTitle = section.title.trim() !== '';
                        const showTaskFields = !hasTitle || openSections.has(sectionId);

                        return (
                            <div key={sectionId}>
                                {hasTitle
                                    ? (
                                        <div
                                            style={WorkflowPreviewStyles.donorCard}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => this.onToggleSection(sectionId)}
                                            onKeyPress={() => {}}
                                        >
                                            <div style={WorkflowPreviewStyles.detailsTitleWrapper}>
                                                {section.title}

                                                <span style={{ padding: '10px', }}>
                                                    <FontAwesomeIcon
                                                        color={Colors.blue}
                                                        icon={showTaskFields ? faCaretDown : faCaretRight}
                                                        size="lg"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                {showTaskFields && section.fields.map((subtask) => (
                                    <div key={subtask.id}>
                                        {this.renderFields(subtask)}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default FormPreview;
