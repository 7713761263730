// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import List from 'react-toolbox/lib/list/List';
import ListItem from 'react-toolbox/lib/list/ListItem';
import ListDivider from 'react-toolbox/lib/list/ListDivider';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import Button from 'react-toolbox/lib/button/Button';

import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type {
    ArchivedChatroom,
    ActiveChatroom,
    ChatroomData,
    UserProfile,
} from '../Utils/types';
import {
    loadActiveChatroomData as _loadActiveChatroomData,
    loadArchivedChatroomData as _loadArchivedChatroomData,
    generateActiveChatroomPDF as _generateActiveChatroomPDF,
    generateArchivedChatroomPDF as _generateArchivedChatroomPDF,
    deleteChatroom as _deleteChatroom,
} from '../Redux/ChatroomActions';


type Props = {
    headers: any,
    contentType: any,
    activeChatroom: ActiveChatroom,
    archivedChatroom: ArchivedChatroom,
    activeChatroomData: ChatroomData,
    archivedChatroomData: ChatroomData,
    organizationMembers: Array<UserProfile>,

    generateActiveChatroomPDF: (activeChatroom: ActiveChatroom, activeChatroomData: ChatroomData) => *,
    generateArchivedChatroomPDF: (archivedChatroom: ArchivedChatroom, archivedChatroomData: ChatroomData) => *,
    deleteChatroom: (chatroomId: number) =>*,
    onClose: () => *,
}

class ChatroomPanel extends PureComponent<Props> {
    generateActivePdf = () => {
        const {
            activeChatroomData,
            activeChatroom,
            generateActiveChatroomPDF,
        } = this.props;

        generateActiveChatroomPDF(activeChatroom, activeChatroomData);
    };

    generateArchivedPdf = () => {
        const {
            archivedChatroomData,
            archivedChatroom,
            generateArchivedChatroomPDF,
        } = this.props;

        generateArchivedChatroomPDF(archivedChatroom, archivedChatroomData);
    };

    closeChatroom = () => {
        const {
            activeChatroom,
            deleteChatroom,
            onClose,
        } = this.props;

        deleteChatroom(activeChatroom.chatroomId);
        onClose();
    };

    render() {
        const {
            headers,
            onClose,
            activeChatroom,
            archivedChatroom,
            contentType,
            activeChatroomData,
            archivedChatroomData,
            organizationMembers,
        } = this.props;


        const activeDate = new Date(activeChatroom.lastInstantMessageTime).toLocaleString();
        const archivedDate = new Date(archivedChatroom.archivedDate).toLocaleString();

        let activeCreatorName;
        const creator = organizationMembers.find((member) => member.userId === activeChatroom.creatorId);
        if (!creator) {
            activeCreatorName = 'External User';
        } else {
            const firstName = creator.firstName ? creator.firstName : '';
            const lastName = creator.lastName ? creator.lastName : '';
            activeCreatorName = `${firstName} ${lastName}`;
        }

        let archivedCreatorName;
        const archivedCreator = organizationMembers.find((member) => member.userId === archivedChatroom.creatorId);
        if (!archivedCreator) {
            archivedCreatorName = 'External User';
        } else {
            const firstName = archivedCreator.firstName ? archivedCreator.firstName : '';
            const lastName = archivedCreator.lastName ? archivedCreator.lastName : '';
            archivedCreatorName = `${firstName} ${lastName}`;
        }

        return (
            <div style={PanelStyles.panelBodyContainer}>
                <div style={PanelStyles.inputPadding}>
                    <List>
                        {headers.map((prop, index) => {
                            if (prop.dataKey === 'chatroomName' || prop.isInvisible) {
                                return null;
                            }

                            switch (contentType) {
                                case 'active chatrooms':
                                    if (prop.dataKey === 'lastInstantMessageTime') {
                                        return (
                                            <div key={index.toString()}>
                                                <ListItem
                                                    key={index.toString()}
                                                    legend={[prop.title]}
                                                    caption={activeDate}
                                                    ripple={false}
                                                    className="list"
                                                />
                                                <ListDivider />
                                            </div>
                                        );
                                    }
                                    if (prop.dataKey === 'creatorId') {
                                        return (
                                            <div key={index.toString()}>
                                                <ListItem
                                                    key={index.toString()}
                                                    legend={[prop.title]}
                                                    caption={activeCreatorName}
                                                    ripple={false}
                                                    className="list"
                                                />
                                                <ListDivider />
                                            </div>
                                        );
                                    }
                                    return activeChatroom[prop.dataKey] ? (
                                        <div key={index.toString()}>
                                            <ListItem
                                                key={index.toString()}
                                                legend={[prop.title]}
                                                caption={activeChatroom[prop.dataKey]}
                                                ripple={false}
                                                className="list"
                                            />
                                            <ListDivider />
                                        </div>
                                    ) : null;

                                case 'archived chatrooms':
                                    if (prop.dataKey === 'archivedDate') {
                                        return (
                                            <div key={index.toString()}>
                                                <ListItem
                                                    key={index.toString()}
                                                    legend={[prop.title]}
                                                    caption={archivedDate}
                                                    ripple={false}
                                                    className="list"
                                                />
                                                <ListDivider />
                                            </div>
                                        );
                                    }
                                    if (prop.dataKey === 'creatorId') {
                                        return (
                                            <div key={index.toString()}>
                                                <ListItem
                                                    key={index.toString()}
                                                    legend={[prop.title]}
                                                    caption={archivedCreatorName}
                                                    ripple={false}
                                                    className="list"
                                                />
                                                <ListDivider />
                                            </div>
                                        );
                                    }
                                    return archivedChatroom[prop.dataKey] ? (
                                        <div key={index.toString()}>
                                            <ListItem
                                                key={index.toString()}
                                                legend={[prop.title]}
                                                caption={archivedChatroom[prop.dataKey]}
                                                ripple={false}
                                                className="list"
                                            />
                                            <ListDivider />
                                        </div>
                                    ) : null;

                                default:
                                    return (
                                        <ListItem
                                            key={index.toString()}
                                            legend={[prop.title]}
                                            caption={[prop.dataKey]}
                                            ripple={false}
                                        />
                                    );
                            }
                        }, this)}
                    </List>
                    {
                        ((contentType === 'active chatrooms'))
                            ? (
                                <>
                                    <Button
                                        label="Download PDF Report"
                                        style={!activeChatroomData ? ApplicationStyles.disabledButton : PanelStyles.primaryPanelButton}
                                        onClick={this.generateActivePdf}
                                        disabled={!activeChatroomData}
                                    />
                                    <MenuDivider />
                                    <Button
                                        label="Close Chatroom"
                                        style={!activeChatroomData ? ApplicationStyles.disabledButton : PanelStyles.warningPanelButton}
                                        onClick={this.closeChatroom}
                                        disabled={!activeChatroomData}
                                    />
                                </>


                            ) : (
                                <Button
                                    label="Download PDF Report"
                                    style={!archivedChatroomData ? ApplicationStyles.disabledButton : PanelStyles.primaryPanelButton}
                                    onClick={this.generateArchivedPdf}
                                    disabled={!archivedChatroomData}
                                />
                            )
                    }
                    <MenuDivider />
                    <Button
                        label="Close"
                        style={PanelStyles.secondaryPanelButton}
                        onClick={onClose}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sortHeader: state.user.sortHeader,
    sortDirection: state.user.sortDirection,
    profile: state.auth.profile,
    activeChatroomData: state.chatroom.activeChatroomData,
    archivedChatroomData: state.chatroom.archivedChatroomData,
    organizationMembers: state.user.loadedUsers.approved ?? [],
});
export default connect(mapStateToProps, {
    generateActiveChatroomPDF: _generateActiveChatroomPDF,
    generateArchivedChatroomPDF: _generateArchivedChatroomPDF,
    loadActiveChatroomData: _loadActiveChatroomData,
    loadArchivedChatroomData: _loadArchivedChatroomData,
    deleteChatroom: _deleteChatroom,
})(ChatroomPanel);
