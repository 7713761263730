// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { Bar, Doughnut } from 'react-chartjs-2';
import Button from 'react-toolbox/lib/button/Button';
import Dropdown from 'react-toolbox/lib/dropdown';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import FontIcon from 'react-toolbox/lib/font_icon';
import { Card } from 'react-toolbox/lib/card';
import { isUserTypeLoaded } from '../Utils/user';
import HomeStyles from './Styles/HomeStyles';
import Colors from '../Themes/Colors';
import {
    loadAllUsers as _loadAllUsers,
    loadUsers as _loadUsers,
} from '../Redux/UserActions';
import {
    organizationMetrics as _organizationMetrics,
} from '../Redux/OrganizationActions';
import type {
    Organization,
    UserProfile,
} from '../Utils/types';
import MetricsGraphicsStyles from './Styles/MetricsGraphicsStyles';

import LoadingIndicatorStyles from './Styles/LoadingIndicatorStyles';
import LoadingIndicator from './LoadingIndicator';
import {
    getProlificNames, getAcknowledgementData, getLabels, getCounts, getQuarters,
} from '../Utils/metrics';
import ApplicationStyles from '../Themes/ApplicationStyles';


type Props = {
    metricsCache: Object,
    organizationId: number,
    quarterA: string,
    quarterB: string,
    quarterBMetricsLoaded: boolean,
    quarterAMetricsLoaded: boolean,
    pendingMembers: Array<UserProfile>,
    pendingMembersLoaded: boolean,
    organizationMembers: Array<UserProfile>,
    organizationMembersLoaded: boolean,
    profile: UserProfile,
    organizations: Array<Organization>,

    organizationMetrics: (organizationId: ?number, quarterA: string, quarterB: string) => *,
    loadAllUsers: typeof _loadAllUsers,
    loadUsers: typeof _loadUsers,
};

type State = {
    organizationNames: { [number]: string },
    selectedOrgName: string,
    selectedOrgId: ?number,
    selectedQuarterAName: string,
    selectedQuarterBName: string,
    typeDropdownKey: string,
}

class MetricsGraphics extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const organizationsSorted = props.organizations.sort((a, b) => {
            if (a.organizationName < b.organizationName) { return -1; }
            if (a.organizationName > b.organizationName) { return 1; }
            return 0;
        });

        const quarters = getQuarters();

        const organizationNames = {};
        for (let i = 0; i < organizationsSorted.length; i += 1) {
            organizationNames[organizationsSorted[i].organizationId] = organizationsSorted[i].organizationName;
        }

        this.state = {
            organizationNames,
            selectedOrgName: props.profile.organizationName,
            selectedOrgId: props.profile.organizationId,
            selectedQuarterAName: quarters[1],
            selectedQuarterBName: quarters[0],
            typeDropdownKey: 'typeKey0',
        };
    }

    componentDidMount() {
        if (this.props.profile.accessLevel === 'Admin' || this.props.profile.accessLevel === 'SuperAdmin') {
            // Loads "platform users" and not actually all users
            this.props.loadAllUsers();
        } else if (this.props.profile.accessLevel === 'OrgManager') {
            const orgId = parseInt(this.props.profile.organizationId, 10);
            if (orgId && !Number.isNaN(orgId)) {
                this.props.loadUsers('approved', orgId);
                this.props.loadUsers('pending', orgId);
            }
        }
    }

    onSubmit = () => {
        const {
            organizationMetrics,
        } = this.props;

        const {
            selectedOrgId,
            selectedQuarterAName,
            selectedQuarterBName,
        } = this.state;

        organizationMetrics(selectedOrgId, selectedQuarterAName, selectedQuarterBName);
    };

    handleOrgChange = (value) => {
        const {
            organizationNames,
        } = this.state;

        this.setState({
            selectedOrgName: organizationNames[value],
            selectedOrgId: value,
        });

        this.handleTypeDropdownKey();
    };

    handleQuarterAChange = (value) => {
        this.setState({
            selectedQuarterAName: value,
        });

        this.handleTypeDropdownKey();
    };

    handleQuarterBChange = (value) => {
        this.setState({
            selectedQuarterBName: value,
        });

        this.handleTypeDropdownKey();
    };

    handleTypeDropdownKey = () => {
        this.setState((state) => {
            // FIXME When PR #1872 is published and released to npm, this can be removed
            const typeDropdownKey = (state.typeDropdownKey === 'typeKey0') ? 'typeKey1' : 'typeKey0';
            return {
                ...state,
                typeDropdownKey,
            };
        });
    };

    render() {
        const {
            metricsCache,
            organizationId,
            quarterA,
            quarterB,
            quarterBMetricsLoaded,
            quarterAMetricsLoaded,
            organizationMembersLoaded,
            pendingMembersLoaded,
            pendingMembers,
            organizationMembers,
            profile,
        } = this.props;

        const {
            selectedOrgName,
            organizationNames,
            selectedQuarterAName,
            selectedQuarterBName,
            typeDropdownKey,
        } = this.state;

        let quarterAMetrics = {};
        let quarterBMetrics = {};

        if (quarterAMetricsLoaded && quarterBMetricsLoaded) {
            quarterAMetrics = metricsCache[organizationId][quarterA];
            quarterBMetrics = metricsCache[organizationId][quarterB];
        }

        const quarters = getQuarters();
        const labelArrayA = [];
        const labelArrayB = [];
        for (let i = 0; i < quarters.length; i += 1) {
            if (quarters[i] !== selectedQuarterBName) {
                labelArrayA.push({ value: quarters[i], label: quarters[i], });
            }
        }
        for (let i = 0; i < quarters.length; i += 1) {
            if (quarters[i] !== selectedQuarterAName) {
                labelArrayB.push({ value: quarters[i], label: quarters[i], });
            }
        }

        if (!quarterBMetricsLoaded || !quarterAMetricsLoaded || !organizationMembersLoaded || !pendingMembersLoaded) {
            return (
                <div style={LoadingIndicatorStyles.verticalCenter}>
                    <h4 style={LoadingIndicatorStyles.formLabel}>Loading metrics - please wait.</h4>
                    <h1><LoadingIndicator /></h1>
                </div>
            );
        }
        const organizationSelector = (profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales') ? (
            <div>
                <div style={MetricsGraphicsStyles.organizationSelector}>
                    <div style={MetricsGraphicsStyles.legendContainer}>
                        <ul style={MetricsGraphicsStyles.legend}>
                            <li> Q1 - Jan-Mar </li>
                            <li> Q2 - Apr-Jun </li>
                            <li> Q3 - Jul-Sep </li>
                            <li> Q4 - Oct-Dec </li>
                        </ul>
                    </div>
                    <div style={MetricsGraphicsStyles.quarterDropdownA}>
                        <Dropdown
                            auto
                            onChange={(value) => this.handleQuarterAChange(value)}
                            label="Quarter"
                            source={labelArrayA}
                            allowBlank={false}
                            value={selectedQuarterAName}
                            key={typeDropdownKey} // FIXME when PR #1870 on react-toolbox is merged
                        />
                    </div>
                    <div style={MetricsGraphicsStyles.versus}>vs</div>
                    <div style={MetricsGraphicsStyles.quarterDropdownB}>
                        <Dropdown
                            auto
                            onChange={(value) => this.handleQuarterBChange(value)}
                            label="Quarter"
                            source={labelArrayB}
                            allowBlank={false}
                            value={selectedQuarterBName}
                            key={typeDropdownKey} // FIXME when PR #1870 on react-toolbox is merged
                        />
                    </div>
                    <div style={MetricsGraphicsStyles.dropdown}>
                        <Autocomplete
                            direction="down"
                            onChange={(value) => this.handleOrgChange(value)}
                            label="Organization"
                            multiple={false}
                            suggestionMatch="anywhere"
                            source={organizationNames}
                            value={selectedOrgName}
                        />
                    </div>
                </div>
                <div style={MetricsGraphicsStyles.submitButtonContainer}>
                    <Button
                        label="Submit"
                        onClick={() => this.onSubmit()}
                        style={MetricsGraphicsStyles.submitButton}
                    />
                </div>
            </div>
        ) : null;

        const {
            collatedProlificReaders: collatedQuarterBProlificReaders,
            collatedProlificSenders: collatedQuarterBProlificSenders,
        } = getProlificNames(quarterBMetrics, pendingMembers, organizationMembers);
        const {
            collatedProlificReaders: collatedQuarterAProlificReaders,
            collatedProlificSenders: collatedQuarterAProlificSenders,
        } = getProlificNames(quarterAMetrics, pendingMembers, organizationMembers);
        const quarterBAckData = getAcknowledgementData(quarterBMetrics);
        const quarterAAckData = getAcknowledgementData(quarterAMetrics);

        const colors = [Colors.mediumBlue, Colors.lightBlue, Colors.brandPrimary, Colors.brandSecondary, Colors.brandPrimaryLight,
            Colors.brandSecondaryLight, Colors.orange, Colors.lightOrange, Colors.brandPrimaryDark, Colors.cyanBlue, Colors.brandSecondaryDark,
            Colors.lighterGray];

        const dataSize = 10;
        const prA = {
            labels: getLabels(collatedQuarterAProlificReaders, dataSize),
            datasets: [{ data: getCounts(collatedQuarterAProlificReaders, dataSize), backgroundColor: colors, }],
        };
        const prB = {
            labels: getLabels(collatedQuarterBProlificReaders, dataSize),
            datasets: [{ data: getCounts(collatedQuarterBProlificReaders, dataSize), backgroundColor: colors, }],
        };
        const psA = {
            labels: getLabels(collatedQuarterAProlificSenders, dataSize),
            datasets: [{ data: getCounts(collatedQuarterAProlificSenders, dataSize), backgroundColor: colors, }],
        };
        const psB = {
            labels: getLabels(collatedQuarterBProlificSenders, dataSize),
            datasets: [{ data: getCounts(collatedQuarterBProlificSenders, dataSize), backgroundColor: colors, }],
        };
        const ackA = {
            labels: getLabels(quarterAAckData.ackData),
            datasets: [{ data: getCounts(quarterAAckData.ackData), backgroundColor: colors, }],
        };
        const ackB = {
            labels: getLabels(quarterBAckData.ackData),
            datasets: [{ data: getCounts(quarterBAckData.ackData), backgroundColor: colors, }],
        };
        const dateRangeLabel = [selectedQuarterAName, selectedQuarterBName];

        const options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        precision: 0,
                    },
                }],
            },
        };

        const barGraphOptions = {
            ...options,
            legend: {
                display: false,
            },
        };

        const pieGraphOptions = {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15,
                },
            },
        };

        const quarterBMedianReadTime = (quarterBMetrics.median_read_time === 0) ? 0 : quarterBMetrics.median_read_time;
        const quarterAMedianReadTime = (quarterAMetrics.median_read_time === 0) ? 0 : quarterAMetrics.median_read_time;

        const medianReadTimeOptions = {
            ...options,
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label(tooltipItem, data) {
                        const fullLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                        let label = '';
                        if (quarterBMedianReadTime < 3570 && quarterAMedianReadTime < 3570) {
                            label = fullLabel.replace(' (min)', ': ');
                            label += Math.round(tooltipItem.yLabel);
                            label += ' min';
                        } else {
                            label = fullLabel.replace(' (hrs)', ': ');
                            const hours = Math.floor(tooltipItem.yLabel);
                            const minutes = Math.round((tooltipItem.yLabel - hours) * 60);
                            if (minutes === 60) {
                                label += `${(hours + 1) + ((hours + 1) > 1 ? ' hrs ' : ' hr ') + 0} min`;
                            } else {
                                label += `${hours + (hours > 1 ? ' hrs ' : ' hr ') + minutes} min`;
                            }
                        }

                        return label;
                    },
                },
            },
        };

        const loginSuccessData = {
            labels: dateRangeLabel,
            datasets: [{
                label: 'Logins',
                backgroundColor: Colors.brandSecondary,
                data: [quarterAMetrics.login_success_count, quarterBMetrics.login_success_count],
            }],
        };
        const memberCountData = {
            labels: dateRangeLabel,
            datasets: [{
                label: 'New Member Count',
                backgroundColor: Colors.brandPrimary,
                data: [quarterAMetrics.member_count, quarterBMetrics.member_count],
            }],
        };
        const createdChatroomData = {
            labels: dateRangeLabel,
            datasets: [{
                label: 'Created Chatrooms',
                backgroundColor: Colors.lightBlue,
                data: [quarterAMetrics.created_chatroom_count, quarterBMetrics.created_chatroom_count],
            }],
        };
        const fileUploadData = {
            labels: dateRangeLabel,
            datasets: [{
                label: 'File Uploads',
                backgroundColor: Colors.mediumBlue,
                data: [quarterAMetrics.file_upload_count, quarterBMetrics.file_upload_count],
            }],
        };

        const medianReadTimeData = {
            labels: dateRangeLabel,
            datasets: [{
                label: `Median Read Time ${quarterBMedianReadTime < 3570 && quarterAMedianReadTime < 3570 ? '(min)' : '(hrs)'}`,
                backgroundColor: Colors.brandPrimaryLight,
                data: quarterBMedianReadTime < 3570 && quarterAMedianReadTime < 3570
                    ? [quarterAMedianReadTime / 60, quarterBMedianReadTime / 60]
                    : [quarterAMedianReadTime / 60 / 60, quarterBMedianReadTime / 60 / 60],
            }],
        };

        const multiChatroomData = {
            labels: dateRangeLabel,
            datasets: [{
                label: 'Multi-Org Chatroom Count',
                backgroundColor: Colors.brandSecondaryLight,
                data: [quarterAMetrics.multi_chatroom_count, quarterBMetrics.multi_chatroom_count],
            }],
        };

        const emptyData = (
            <p style={{
                textAlign: 'center', color: 'grey', fontStyle: 'italic', fontSize: 26, fontWeight: 'bold',
            }}
            >
No Data
            </p>
        );
        const previousMessagesTextSent = (quarterAMetrics.sent_message_count === 1) ? ' Message' : ' Messages';
        const previousMessagesTextRead = (quarterAMetrics.read_message_count === 1) ? ' Message' : ' Messages';
        const currentMessagesTextSent = (quarterBMetrics.sent_message_count === 1) ? ' Message' : ' Messages';
        const currentMessagesTextRead = (quarterBMetrics.read_message_count === 1) ? ' Message' : ' Messages';

        const previousOrganizations = (quarterAMetrics.organization_interactions)
            ? quarterAMetrics.organization_interactions.map((id: any) => <li key={id}>{this.state.organizationNames[id]}</li>) : [];

        const currentOrganizations = (quarterBMetrics.organization_interactions)
            ? quarterBMetrics.organization_interactions.map((id: any) => <li key={id}>{this.state.organizationNames[id]}</li>) : [];

        return (

            <div style={HomeStyles.homePagePadding}>
                <div style={HomeStyles.analyticsPagePadding}>
                    {organizationSelector}
                </div>
                <Row style={ApplicationStyles.fullHeight}>
                    <Col sm={3}>
                        <Card style={HomeStyles.messagesSent}>
                            <FontIcon value="mail_outline" style={HomeStyles.icons} />
                            <ul style={{ listStyle: 'none', }}>
                                <li>{selectedQuarterAName}</li>
                                <li>{`${quarterAMetrics.sent_message_count + previousMessagesTextSent} Sent`}</li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Card style={HomeStyles.messagesRead}>
                            <FontIcon value="chat" style={HomeStyles.icons} />
                            <ul style={{ listStyle: 'none', }}>
                                <li>{selectedQuarterAName}</li>
                                <li>{`${quarterAMetrics.read_message_count + previousMessagesTextRead} Read`}</li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Card style={HomeStyles.activeMembers}>
                            <FontIcon value="mail_outline" style={HomeStyles.icons} />
                            <ul style={{ listStyle: 'none', }}>
                                <li>{selectedQuarterBName}</li>
                                <li>{`${quarterBMetrics.sent_message_count + currentMessagesTextSent} Sent`}</li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Card style={HomeStyles.casesOpened}>
                            <FontIcon value="chat" style={HomeStyles.icons} />
                            <ul style={{ listStyle: 'none', }}>
                                <li>{selectedQuarterBName}</li>
                                <li>{`${quarterBMetrics.read_message_count + currentMessagesTextRead} Read`}</li>
                            </ul>
                        </Card>
                    </Col>
                </Row>
                <Row style={{ ...ApplicationStyles.fullHeight, ...MetricsGraphicsStyles.rowPadding, }}>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Prolific Readers</p>
                        {(prA.labels.length > 0) ? (
                            <Doughnut
                                data={prA}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterAName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                        {(prB.labels.length > 0) ? (
                            <Doughnut
                                data={prB}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterBName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Prolific Senders</p>
                        {(psA.labels.length > 0) ? (
                            <Doughnut
                                data={psA}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterAName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                        {(psB.labels.length > 0) ? (
                            <Doughnut
                                data={psB}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterBName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Acknowledgement Type</p>
                        {(ackA.labels.length > 0) ? (
                            <Doughnut
                                data={ackA}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterAName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                        {(ackB.labels.length > 0) ? (
                            <Doughnut
                                data={ackB}
                                options={{
                                    ...pieGraphOptions,
                                    title: {
                                        display: true,
                                        text: selectedQuarterBName,
                                        position: 'top',
                                    },
                                }}
                            />
                        ) : emptyData}
                    </Col>
                </Row>
                <Row style={{ ...ApplicationStyles.fullHeight, ...MetricsGraphicsStyles.rowPadding, }}>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>New Member Count</p>
                        <Bar data={memberCountData} options={barGraphOptions} />
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Successful Logins</p>
                        <Bar data={loginSuccessData} options={barGraphOptions} />
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>
                            Median Read Time
                            {(quarterBMedianReadTime < 3570 && quarterAMedianReadTime < 3570 ? ' (minutes)' : ' (hours)')}
                        </p>
                        <Bar data={medianReadTimeData} options={medianReadTimeOptions} />
                    </Col>
                </Row>
                <Row style={{ ...ApplicationStyles.fullHeight, ...MetricsGraphicsStyles.rowPadding, }}>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Created Chatrooms</p>
                        <Bar data={createdChatroomData} options={barGraphOptions} />
                    </Col>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Files Uploaded</p>
                        <Bar data={fileUploadData} options={barGraphOptions} />
                    </Col>
                </Row>
                <Row style={{ ...ApplicationStyles.fullHeight, ...MetricsGraphicsStyles.rowPadding, }}>
                    <Col sm={4}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Multi-Org Chatrooms</p>
                        <Bar data={multiChatroomData} options={barGraphOptions} />
                    </Col>
                    <Col sm={8}>
                        <p style={{ textAlign: 'center', fontStyle: 'italic', fontWeight: 'bold', }}>Organization Interactions</p>
                        <Row>
                            <Col sm={6}>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', }}>{selectedQuarterAName}</p>
                                <ul style={MetricsGraphicsStyles.organizationNameList}>
                                    {previousOrganizations}
                                </ul>
                            </Col>
                            <Col sm={6}>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', }}>{selectedQuarterBName}</p>
                                <ul style={MetricsGraphicsStyles.organizationNameList}>
                                    {currentOrganizations}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    metricsCache: state.organization.metricsCache,
    organizationId: state.organization.organizationId,
    quarterA: state.organization.quarterA,
    quarterB: state.organization.quarterB,
    quarterBMetricsLoaded: state.organization.quarterBMetricsLoaded,
    quarterAMetricsLoaded: state.organization.quarterAMetricsLoaded,
    pendingMembers: state.user.loadedUsers.pending ?? [],
    pendingMembersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'pending'),
    organizationMembers: state.user.loadedUsers.approved ?? [],
    organizationMembersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'approved'),
    profile: state.auth.profile,
    organizations: state.organization.organizations,
});

export default connect(mapStateToProps, {
    organizationMetrics: _organizationMetrics,
    loadAllUsers: _loadAllUsers,
    loadUsers: _loadUsers,
})(MetricsGraphics);
