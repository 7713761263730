// @flow
import Colors from '../../Themes/Colors';

const CasePreferencesStyles = {
    flexBasis50: {
        flexBasis: '50%',
    },
    preferencesWrapper: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    preferencesContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        marginBottom: 10,
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: Colors.lightGray,
    },
    saveButtonContainer: {
        marginTop: 50,
    },
    addFollowerContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};

export default CasePreferencesStyles;
