// @flow
import React, { PureComponent } from 'react';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Input from 'react-toolbox/lib/input/Input';

type Props = {
    value: ?string,
    onChange: (newVal: string) => *
};

type State = {
    dropdownKey: number,
};

const departments = [
    {
        label: 'Tissue',
        value: 'Tissue',
    },
    {
        label: 'Organ',
        value: 'Organ',
    },
    {
        label: 'Quality',
        value: 'Quality',
    },
    {
        label: 'Family Support',
        value: 'Family Support',
    },
    {
        label: 'Management',
        value: 'Management',
    },
    {
        label: 'Medical Examiner/Funeral Home Liaison',
        value: 'Medical Examiner/Funeral Home Liaison',
    },
    {
        label: 'Hospital Development',
        value: 'Hospital Development',
    },
    {
        label: 'Community Outreach',
        value: 'Community Outreach',
    },
    {
        label: 'Legal',
        value: 'Legal',
    },
    {
        label: 'Preservation',
        value: 'Preservation',
    },
    {
        label: 'Eye',
        value: 'Eye',
    },
    {
        label: 'Other',
        value: 'Other',
    }
];

class DepartmentDropdown extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownKey: 0,
        };
    }

    onSelect = (newVal: string) => {
        const {
            onChange,
        } = this.props;

        this.setState((state) => ({
            dropdownKey: (state.dropdownKey) ? 0 : 1,
        }));

        if (this.getDropdownValue(newVal) === 'Other') {
            onChange('');
        } else {
            onChange(newVal);
        }
    };

    onTextChange = (newVal: string) => {
        const {
            onChange,
        } = this.props;
        onChange(newVal);
    };

    getDropdownValue = (value: ?string) => {
        // If the department isn't set, we'll keep it null
        if (value === null) {
            return null;
        }

        const departmentValues = departments.map((d) => d.value);
        if (departmentValues.includes(value)) {
            return value;
        }
        return 'Other';
    };

    render() {
        const {
            value,
        } = this.props;

        const {
            dropdownKey,
        } = this.state;

        return (
            <>
                <Dropdown
                    key={dropdownKey}
                    auto
                    onChange={(newVal) => this.onSelect(newVal)}
                    source={departments}
                    value={this.getDropdownValue(value)}
                    label="Department"
                    required
                />
                {this.getDropdownValue(value) === 'Other'
                    ? (
                        <Input
                            type="text"
                            label="Department, if Other"
                            value={value}
                            onChange={(newVal) => this.onTextChange(newVal)}
                        />
                    ) : null }
            </>
        );
    }
}

export default DepartmentDropdown;


