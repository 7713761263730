// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Button from 'react-toolbox/lib/button/Button';
import Sidebar from 'react-sidebar';

import {
    setDomain as _setDomain,
    setName as _setName,
    setData as _setData,
    uploadReferenceData as _uploadReferenceData,
    resetUpload as _resetUpload,
    searchReferenceData as _searchReferenceData,
    setSortParameters as _setSortParameters,
    clearSearch as _clearSearch,
    resetReference as _resetReference,
    setSelectedReferenceDataRow as _setSelectedReferenceDataRow,
} from '../Redux/ReferenceDataActions';
import { setSagaMessage as _setSagaMessage } from '../Redux/ApplicationActions';
import type { ReferenceDomain, UserProfile } from '../Utils/types';
import ResearchStyles from './Styles/ResearchStyles';
import AuthStyles from './Styles/AuthStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import SagaMessage from '../Components/SagaMessage';
import SortableTable from '../Components/SortableTable';
import ReferenceDetailsPanel from '../Components/ReferenceDetailsPanel';
import Colors from '../Themes/Colors';

type Props = {
  referenceData: Array<any>,
  selectedReferenceData: any,
  domain: ReferenceDomain,
  name: string,
  data: string,
  success: boolean,
  error: string,
  searchTerm: string,
  sortDirection: string,
  sortHeader: string,
  profile: UserProfile,
  isLoading: boolean,

  setSagaMessage: (
    heading: string,
    message: string,
    label: string,
    isError: boolean
  ) => *,
  setDomain: (domain: ReferenceDomain) => *,
  setName: (name: string) => *,
  setData: (data: string) => *,
  uploadReferenceData: (
    domain: ReferenceDomain,
    name: string,
    data: string
  ) => *,
  resetUpload: () => *,
  searchReferenceData: (searchTerm: string, searchHeaders: Array<string>) => *,
  setSortParameters: (sortHeader: string, sortDirection: string) => *,
  clearSearch: () => *,
  resetReference: () => *,
  setSelectedReferenceDataRow: (row: any) => *,
};

type State = {
  dropdownKey: number,
  uploadOpen: boolean,
  update: boolean,
  isSidePanelOpen: boolean,
};

const genericSagaToast: number = 10007;

const referenceDataHeaders = [
    {
        dataKey: 'domain',
        title: 'Domain',
    },
    {
        dataKey: 'key',
        title: 'Key',
    },
    {
        dataKey: 'name',
        title: 'Name',
    },
    {
        dataKey: 'version',
        title: 'Version',
    },
    {
        dataKey: 'status',
        title: 'Status',
    }
];

class ConfigurationPage extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownKey: 0,
            uploadOpen: false,
            update: false,
            isSidePanelOpen: false,
        };
    }

    componentDidMount() {
        const { clearSearch, resetReference, } = this.props;

        clearSearch();
        resetReference();
    }

    componentDidUpdate(prevProps: Props) {
        const { success, setSagaMessage, } = this.props;

        const { update, } = this.state;

        if (!prevProps.success && success) {
            if (update) {
                this.onCloseUpload();
                setSagaMessage('Success', 'Update successful', '', false);
            }
        }
    }

    componentWillUnmount() {
        const { resetReference, } = this.props;

        resetReference();
    }

  onOpenUpload = () => {
      this.setState({
          uploadOpen: true,
      });
  };

  onCloseUpload = () => {
      const { resetUpload, } = this.props;

      this.setState({
          uploadOpen: false,
          update: false,
      });

      resetUpload();
  };

  onSetDomain = (type: ReferenceDomain) => {
      const { setDomain, } = this.props;

      this.setState((state) => ({
          dropdownKey: state.dropdownKey ? 0 : 1,
      }));

      setDomain(type);
  };

  onSetName = (name: string) => {
      const { setName, } = this.props;

      setName(name);
  };

  onSetData = (data: string) => {
      const { setData, setName, setDomain, } = this.props;

      let dt;
      try {
          dt = JSON.parse(data);
      } catch (error) {
      // Don't worry - just can't extract keys yet
      }
      if (dt && dt.domain && dt.key) {
          setDomain(dt.domain);
          setName(dt.key);
      }

      setData(data);
  };

  onUpload = () => {
      const {
          domain, name, data, uploadReferenceData,
      } = this.props;

      uploadReferenceData(domain, name, data);
      // NOTE: Closing the side panel after uploading because the side panel won't currently
      // appropriately update after uploading a new JSON. We should just appropriately update state
      // so it actually works. Could fix by just having setSelectedReference just be an id (and not an object)
      this.onCloseSidePanel();
  };

  onUpdateReferenceData = (data: any) => {
      const { setDomain, setName, } = this.props;

      setDomain(data.domain);
      setName(data.key);

      this.setState({
          update: true,
      });
      this.onOpenUpload();
  };

  onSearch = (searchTerm) => {
      const { searchReferenceData, } = this.props;

      searchReferenceData(searchTerm, ['domain', 'key', 'name']);
  };

  onSetSortParameters = (sortHeader, sortDirection) => {
      const { setSortParameters, } = this.props;

      if (sortHeader && sortDirection) {
          setSortParameters(sortHeader, sortDirection);
      }
  };

  onOpenSidePanel = () => {
      this.setState({ isSidePanelOpen: true, });
  };

  onCloseSidePanel = () => {
      const { setSelectedReferenceDataRow, } = this.props;
      setSelectedReferenceDataRow({});
      this.setState({ isSidePanelOpen: false, });
  };

  render() {
      const {
          referenceData,
          selectedReferenceData,
          domain,
          name,
          data,
          success,
          error,
          searchTerm,
          sortDirection,
          sortHeader,
          profile,
          isLoading,
      } = this.props;

      const {
          dropdownKey, uploadOpen, update, isSidePanelOpen,
      } = this.state;

      const domainTypes = [
          {
              value: 'SURVEY',
              label: 'Survey',
          },
          {
              value: 'FORM',
              label: 'Form',
          },
          {
              value: 'WORKFLOW',
              label: 'Workflow',
          },
          {
              value: 'RESEARCHER',
              label: 'Researcher',
          }
      ];

      const uploadDisabled = !domain || !name || !data || isLoading;
      const transformedReferenceData = referenceData.map((item) => ({
          ...item,
          status: item.inactive ? 'Inactive' : 'Active',
      }));

      return (
          <div>
              <Sidebar
                  sidebar={(
                      <div>
                          <ReferenceDetailsPanel
                              allReferenceData={referenceData}
                              selectedReferenceData={selectedReferenceData}
                              onClose={this.onCloseSidePanel}
                              onUpdateReferenceData={this.onUpdateReferenceData}
                          />
                      </div>
                  )}
                  open={isSidePanelOpen}
                  docked={isSidePanelOpen}
                  pullRight
                  sidebarClassName="rightSidebar"
                  shadow={false}
                  styles={{
                      sidebar: {
                          background: 'white',
                          marginTop: 'var(--header-height)',
                          zIndex: 1,
                      },
                  }}
              >
                  <div style={ApplicationStyles.pagePadding}>
                      <SagaMessage toastId={genericSagaToast} />
                      <div>
                          <Input
                              type="text"
                              label="Search"
                              value={searchTerm}
                              name="search"
                              icon="search"
                              onChange={this.onSearch}
                          />
                          <SortableTable
                              accessLevel={profile.accessLevel || ''}
                              searchTerm={searchTerm}
                              content={transformedReferenceData}
                              headers={referenceDataHeaders}
                              sortDirection={sortDirection}
                              sortHeader={sortHeader}
                              contentType="reference data"
                              onRowClick={this.onOpenSidePanel}
                              onHeaderClick={this.onSetSortParameters}
                              onRowClose={this.onCloseSidePanel}
                              onUpdateReferenceData={this.onUpdateReferenceData}
                          />
                          <div style={ApplicationStyles.footerMessage}>
                              <Button
                                  onClick={this.onOpenUpload}
                                  icon="add"
                                  floating
                                  style={{
                                      backgroundColor: Colors.brandPrimary,
                                      color: 'white',
                                  }}
                              />
                          </div>
                      </div>
                  </div>
              </Sidebar>
              <Dialog
                  active={uploadOpen}
                  type="normal"
                  onEscKeyDown={this.onCloseUpload}
                  onOverlayClick={this.onCloseUpload}
              >
                  <div style={ResearchStyles.instructionContainer}>
                      <span style={AuthStyles.formLabel}>
                          {update
                              ? 'Enter the JSON to update the reference data for the selected domain and key.'
                              : 'Select the domain and enter the key and data of the reference data you would like to upload.'}
                      </span>
                  </div>
                  <div style={ResearchStyles.inputContainer}>
                      <div>
                          <Dropdown
                              key={dropdownKey}
                              auto
                              onChange={(newVal) => this.onSetDomain(newVal)}
                              source={domainTypes}
                              value={domain}
                              label="Domain"
                              required
                              disabled={update}
                          />
                      </div>
                      <div>
                          <Input
                              type="text"
                              label="Key"
                              value={name}
                              onChange={(newVal) => this.onSetName(newVal)}
                              required
                              disabled={update}
                          />
                      </div>
                      <div>
                          <Input
                              type="text"
                              label="Data"
                              value={data}
                              onChange={(newVal) => this.onSetData(newVal)}
                              required
                              multiline
                              style={{ maxHeight: 300, }}
                          />
                      </div>
                  </div>
                  <div style={ResearchStyles.errorContainer}>
                      <span style={AuthStyles.formError}>{error}</span>
                  </div>
                  {success && !update ? (
                      <div style={ResearchStyles.errorContainer}>
                          <span style={AuthStyles.formLabel}>Upload complete</span>
                      </div>
                  ) : null}
                  <div style={ResearchStyles.submitButtonContainer}>
                      <Button
                          style={
                              uploadDisabled
                                  ? ResearchStyles.submitButtonDisabled
                                  : ResearchStyles.submitButton
                          }
                          disabled={uploadDisabled}
                          label="Upload"
                          flat
                          primary
                          onClick={this.onUpload}
                      />
                  </div>
              </Dialog>
          </div>
      );
  }
}

const mapStateToProps = (state) => {
    const workflows = state.reference.workflows || [];
    const surveys = state.reference.surveys || [];
    const forms = state.reference.forms || [];
    const researchers = state.reference.researchers || [];

    const referenceData = workflows.concat(surveys, forms, researchers);
    const { selectedReferenceData, } = state.reference;

    return {
        referenceData,
        selectedReferenceData,
        domain: state.reference.domain || '',
        name: state.reference.name || '',
        data: state.reference.data || '',
        success: state.reference.success || false,
        error: state.reference.error || '',
        searchTerm: state.reference.searchTerm,
        sortDirection: state.reference.sortDirection,
        sortHeader: state.reference.sortHeader,
        profile: state.auth.profile,
        isLoading: state.loading.updateReferenceData,
    };
};

export default connect(mapStateToProps, {
    setSagaMessage: _setSagaMessage,
    setDomain: _setDomain,
    setName: _setName,
    setData: _setData,
    uploadReferenceData: _uploadReferenceData,
    resetUpload: _resetUpload,
    searchReferenceData: _searchReferenceData,
    setSortParameters: _setSortParameters,
    clearSearch: _clearSearch,
    resetReference: _resetReference,
    setSelectedReferenceDataRow: _setSelectedReferenceDataRow,
})(ConfigurationPage);
