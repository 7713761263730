// @flow
import Colors from '../../Themes/Colors';

const HeaderStyles = {
    textButton: {
        fontSize: 18,
        width: '100%',
        textTransform: 'none',
    },

    textButtonWrapper: {
        marginRight: 10,
        marginTop: 3,
        width: '100%',
        borderBottomStyle: 'solid',
        borderWidth: 3,
    },

    paddingHorizontal: {
        paddingLeft: 10,
        paddingRight: 10,
    },

    versionText: {
        color: Colors.lightGray,
        fontSize: 16,
    },
};

export default HeaderStyles;
