// @flow

export function binArrayData(input: Array<any>, numberBins: number = 10): Array<number> {
    // Calculate bin size (1/10 of max element rounded to the nearest 5)
    const max = Math.max(...input);
    const binSize = Math.floor((max / 10) / 5) * 5;

    const binThresholds: Array<number> = [];
    for (let i = 1; i <= numberBins; i += 1) {
        binThresholds.push(binSize * i);
    }

    return binThresholds;
}

export function objectArrayToDictionary(key: string | number, array: any[]) {
    return array.reduce((dictionary, item) => {
        dictionary[item[key]] = item;

        return dictionary;
    }, {});
}

export function moveArrayItem<T>(array: T[], itemIndex: number, newIndex: number): T[] {
    // Make a copy so we don't modify original array
    const newArray = [...array];
    const item = newArray[itemIndex];

    // remove subtask from current position
    newArray.splice(itemIndex, 1);

    // move subtask to new position
    newArray.splice(newIndex, 0, item);

    return newArray;
}
