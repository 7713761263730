// @flow

function serverAccessLevel(accessLevel: ?string): string {
    switch (accessLevel) {
        case 'System Administrator':
            return 'SysAdmin';
        case 'Sales':
            return 'Sales';
        case 'Organization Manager':
            return 'OrgManager';
        case 'Organization Member':
            return 'OrgMember';
        case 'Basic User':
            return 'BasicUser';
        case 'Archived':
            return 'Archived';
        default:
            return 'Unknown';
    }
}

export default serverAccessLevel;
