// @flow
import React from 'react';
import Radio from '@material-ui/core/Radio';
import type { SubtaskType } from '../Utils/types';
import WorkflowPreviewStyles from './Styles/WorkflowPreviewStyles';

type Props = {
    subtask: SubtaskType,
};

/* eslint-disable jsx-a11y/label-has-associated-control */

export default function Radios(props: Props) {
    const { subtask, } = props;
    const [selectedValue, setSelectedValue] = React.useState('');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <div>
            <div style={WorkflowPreviewStyles.labelText}>
                {subtask.title}
            </div>
            {subtask.responseOptions.map((option) => (
                <div key={option}>
                    <label>
                        <Radio
                            checked={selectedValue === option}
                            onChange={handleChange}
                            value={option}
                            color="primary"
                        />
                        {option}
                    </label>
                </div>
            ))}
        </div>
    );
}
