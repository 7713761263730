// @flow

import Colors from '../../Themes/Colors';

const WorkflowPreviewStyles = {
    taskItemWrapper: {
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
    },
    taskItemRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10,
    },
    taskItemButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px',
    },
    marginLeft10: {
        marginLeft: 10,
    },
    padding10: {
        padding: 10,
    },
    marginVertical10: {
        marginVertical: 10,
    },
    checkboxWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 10,
        marginBottom: 10,
    },
    checkboxWrapperDisabled: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'default',
        marginTop: 10,
        marginBottom: 10,
    },
    label: {
        fontSize: 16,
        marginLeft: 10,
        color: Colors.black,
    },
    backButton: {
        backgroundColor: Colors.white,
        color: Colors.blue,
        textTransform: 'none',
        fontSize: 16,
        marginLeft: 0,
    },
    donorCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    donorHeaderWrapper: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        marginBottom: 15,
    },
    donorHeaderRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    donorHeaderId: {
        fontWeight: 'bold',
        fontSize: 16,
        color: Colors.black,
    },
    donorHeaderValue: {
        fontSize: 16,
        color: Colors.black,
    },
    donorHeaderLabel: {
        fontWeight: 'bold',
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    detailsTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    labelText: {
        textAlign: 'left',
        fontSize: 14,
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: '600',
        flex: 0.95,
        overflowWrap: 'anywhere',
        wordBreak: 'normal',
    },
    formSwitchCheck: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
    },
    iconBlue24: {
        color: Colors.blue,
        fontSize: 24,
    },
    valueTextDisabled: {
        textAlign: 'left',
        fontSize: 17,
        marginLeft: 30,
        marginBottom: 5,
        color: Colors.textInputTextDisabled,
    },
    noteText: {
        textAlign: 'left',
        fontSize: 10,
        color: Colors.formLabel,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: '600',
    },
    taskItemDueDate: {
        paddingTop: 10,
        paddingBottom: 3,
        marginLeft: 'auto',
        color: Colors.blue,
        fontSize: 12,
        cursor: 'pointer',
    },
    displayOnItem: {
        marginLeft: 45,
        lineHeight: 1.5,
        color: Colors.gray,
        fontSize: 12,
    },
};

export default WorkflowPreviewStyles;
