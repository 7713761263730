/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import LoginPage from '../Containers/LoginPage';
import AuthenticatedContainer from '../Containers/AuthenticatedContainer';
import ErrorBoundary from '../Containers/ErrorBoundary';

class Routes extends Component {
    render() {
        return (
            <ErrorBoundary>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/portal" component={AuthenticatedContainer} />
                    <Route path="*" component={LoginPage} />
                </Switch>
            </ErrorBoundary>
        );
    }
}

export default Routes;
