// @flow

import { hasOwnProperty } from './Object';
import type { LoadedUsersType, AnyUserType } from '../Redux/UserActions';

/* eslint-disable import/prefer-default-export */
export function isUserTypeLoaded(usersObject: LoadedUsersType, userType: AnyUserType) {
    return hasOwnProperty(usersObject, userType);
}

export function isUserSuperAdmin(accessLevel: string) {
    return accessLevel === 'SysAdmin';
}

export function isUserAdmin(accessLevel: string) {
    return isUserSuperAdmin(accessLevel) || accessLevel === 'Sales';
}
