// @flow
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import IconButton from 'react-toolbox/lib/button/IconButton';
import type { FollowerPreference, MainTask } from '../Utils/types';
import CasePreferencesFollower from './CasePreferencesFollower';
import { loadTeams } from '../Redux/UserActions';
import CasePreferencesStyles from './Styles/CasePreferencesStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import Colors from '../Themes/Colors';

type Props = {
  mainTasks: MainTask[],
  followerPreferences: FollowerPreference[],
  onFollowerChange: (follower: FollowerPreference, index: number) => *,
  onAddNewFollower: () => *,
  onFollowerDelete: (index: number) => *,
};

export default function CasePreferences(props: Props) {
    const {
        mainTasks,
        followerPreferences,
        onFollowerChange,
        onAddNewFollower,
        onFollowerDelete,
    } = props;
    const dispatch = useDispatch();

    const [openFollower, setOpenFollower] = useState<number>(-1);

    useEffect(() => {
        dispatch(loadTeams());
    }, []);
    const teams = useSelector((state) => state.user.teams);

    const onFollowerClick = (index: number) => {
        if (index === openFollower) {
            setOpenFollower(-1);
        } else {
            setOpenFollower(index);
        }
    };

    const onAddNewFollowerClick = () => {
        onAddNewFollower();
        setOpenFollower(followerPreferences.length);
    };

    const onFollowerDeleteClick = (index: number) => {
        onFollowerDelete(index);
    };

    return (
        <div style={CasePreferencesStyles.flexBasis50}>
            <h2>Case Preferences</h2>
            <p>Add or manage preferences for follower groups that can be created with this workflow.</p>
            <div>
                {followerPreferences.map((preference, index) => (
                    <div key={preference.id} style={CasePreferencesStyles.preferencesContainer}>
                        <div style={ApplicationStyles.flexSpaceBetween}>
                            <h3>{preference.name}</h3>
                            <div>
                                <IconButton
                                    icon={openFollower === index ? 'close' : 'edit'}
                                    style={{ color: Colors.brandPrimary, }}
                                    flat
                                    onClick={() => onFollowerClick(index)}
                                />
                                <IconButton
                                    icon="delete"
                                    style={{ color: Colors.red, }}
                                    flat
                                    onClick={() => onFollowerDeleteClick(index)}
                                />
                            </div>
                        </div>
                        {(openFollower === index) ? (
                            <CasePreferencesFollower
                                mainTasks={mainTasks}
                                follower={preference}
                                teams={teams}
                                onFollowerChange={(follower) => onFollowerChange(follower, index)}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
            <div style={CasePreferencesStyles.addFollowerContainer}>
                <Button
                    icon="add"
                    style={ApplicationStyles.button}
                    label="Add New Follower"
                    onClick={onAddNewFollowerClick}
                />
            </div>
        </div>
    );
}
