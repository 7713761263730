const Colors = {
    black: '#000000',
    white: '#FFFFFF',
    gray: '#646464',
    darkGrayLogo: '#231f20',
    lightGray: '#A9A9A9',
    lighterGray: '#F4F4F4',
    evenLighterGray: '#F9F9F9',
    red: '#E21755',
    green: '#008000',
    transparent: 'rgba(0, 0, 0, 0)',
    blue: '#0069FF',
    mediumBlue: '#0477BF',
    lightBlue: 'rgba(0, 105, 255, 0.2)',
    cyanBlue: '#00FAFA',
    orange: '#FF8000',
    lightOrange: '#FFB649',

    iosBlue: 'rgb(49, 118, 255)',

    // Note: If you change this, also change the UIColor inside AppDelegate.m (rootView.backgroundColor)
    mainBackground: '#FFFFFF',
    statusBar: 'rgba(255, 255, 255, 0.8)',

    tabBarBlue: '#007AFF',
    pendingYellow: '#FEDF00',

    bottomNavigationBorder: '#EBEBEB',
    linkText: '#0069FF',
    linkTextDisabled: '#C5C5C5',

    brandPrimary: '#2E3192',
    brandPrimaryLight: '#6468ff',
    brandPrimaryDark: '#1A1B4F',
    brandSecondary: '#00A79D',
    brandSecondaryLight: '#1BDED3',
    brandSecondaryDark: '#005952',
    brandMid: '#2C96CF',

    buttonBackground: '#0069FF',
    buttonBackgroundDisabled: 'rgba(0,0,0,.12)',
    buttonText: '#FFFFFF',
    buttonTextDisabled: 'rgba(0,0,0,.26)',

    chatListItemBackground: '#FFFFFF',
    chatListItemBorder: '#EBEBEB',
    chatListItemBorderLight: '#EFEFEF',

    chatAreaBackground: '#F1F1F1',
    chatItemBackground: '#FFFFFF',
    chatItemBackgroundSelf: '#0069FF',
    chatItemBorder: '#EBEBEB',
    chatItemBorderSelf: '#0069FF',

    lineButtonBackground: '#FFFFFF',
    lineButtonBorder: '#EBEBEB',

    contactListItemBackground: '#FFFFFF',

    walkthroughTitle: '#000000',
    walkthroughDescription: '#646464',

    formLabel: '#646464',
    formError: '#E21755',
    textInputText: '#000000',
    textInputTextDisabled: 'rgba(0, 0, 0, 0.4)',
    textInputPlaceholder: '#9B9B9B',
    textInputBorderBottom: '#9B9B9B',
    fakeTextInputBorderBottom: '#D7D7D7',

    text: 'rgb(3, 3, 3)',

    transparentBlack: 'rgba(0, 0, 0, 0.7)',
    transparentRed: 'rgba(255, 0, 0, 0.8)',
    transparentYellow: 'rgba(255, 192, 0, 0.8)',
    transparentWhite: 'rgba(255, 255, 255, 0.7)',

    pdfFillPurple: '#DCC0FF',
    pdfFillOrange: '#FED292',
    pdfFillRed: '#FEB9B9',
    pdfFillGreen: '#C4FDCA',
    pdfFillBlue: '#C3F8FE',
    pdfFillYellow: '#FFFCBE',
};

export default Colors;
