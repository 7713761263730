// @flow
import React, { PureComponent } from 'react';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCalendar } from '@fortawesome/free-solid-svg-icons';

import FormCheckbox from './FormCheckbox';
import type { MainTask } from '../Utils/types';
import WorkflowPreviewStyles from './Styles/WorkflowPreviewStyles';
import Colors from '../Themes/Colors';

type Props = {
    tasks: MainTask[],
    organizationName: string,
    onClosePreview: () => *,
    openSubtask: (task: MainTask) => *,
};

class WorkflowPreview extends PureComponent<Props> {
    onOpenSubtask = (task: MainTask) => {
        const {
            openSubtask,
        } = this.props;

        openSubtask(task);
    };

    renderDisplayOnItems = (task: MainTask) => {
        if (!task.sections || !task.sections.length) {
            return (
                <></>
            );
        }

        // We have sections, build up our Display on Item tasks
        const displayItems = [];
        task.sections.forEach((section) => {
            (section.fields || []).forEach((subTask) => {
                const subtaskAttrs = subTask.attributes.map((attribute) => attribute.value);
                if (subtaskAttrs.includes('flightTracker') || subtaskAttrs.includes('displayOnItem')) {
                    displayItems.push(subTask.displayAs || subTask.title);
                }
            });
        });

        return (
            <>
                {displayItems.map((item, idx) => <div key={idx.toString()} style={WorkflowPreviewStyles.displayOnItem}>{item}</div>)}
            </>
        );
    };

    render() {
        const {
            tasks,
            organizationName,
            onClosePreview,
        } = this.props;
        return (
            <>
                <Button
                    style={WorkflowPreviewStyles.backButton}
                    type="button"
                    ripple={false}
                    onClick={onClosePreview}
                >
                    <FontIcon
                        value="arrow_back_ios"
                    />
                    Close
                </Button>
                <div style={WorkflowPreviewStyles.donorHeaderWrapper}>
                    <div style={WorkflowPreviewStyles.donorHeaderRow}>
                        <span style={WorkflowPreviewStyles.donorHeaderId}>
                            Donor ID
                        </span>
                        <span style={WorkflowPreviewStyles.donorHeaderValue}>
                            <span style={WorkflowPreviewStyles.donorHeaderLabel}>Sex: </span>
                            M
                        </span>
                    </div>
                    <div style={WorkflowPreviewStyles.donorHeaderRow}>
                        <span style={WorkflowPreviewStyles.donorHeaderValue}>
                            <span style={WorkflowPreviewStyles.donorHeaderLabel}>Hospital: </span>
                            {organizationName || 'Example Hospital'}
                        </span>
                        <span style={WorkflowPreviewStyles.donorHeaderValue}>
                            <span style={WorkflowPreviewStyles.donorHeaderLabel}>Age: </span>
                            45
                        </span>
                    </div>
                </div>
                <div>
                    {tasks && tasks.length > 0 ? tasks.map((task) => (
                        <div style={WorkflowPreviewStyles.taskItemWrapper} key={task.id}>
                            <div style={WorkflowPreviewStyles.taskItemRow}>
                                <div role="button" style={WorkflowPreviewStyles.taskItemDueDate}>
                                    <FontAwesomeIcon
                                        color={Colors.blue}
                                        icon={faCalendar}
                                        size="lg"
                                    />
                                    <span role="link" style={{ textDecoration: 'underline', paddingLeft: 5, }}>
                                        Set Due Date
                                    </span>
                                </div>
                            </div>
                            <div style={WorkflowPreviewStyles.taskItemRow}>
                                <div style={WorkflowPreviewStyles.taskItemButton}>
                                    <FormCheckbox
                                        disabled={false}
                                        isSelect={false}
                                        label=""
                                    />
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => this.onOpenSubtask(task)}
                                        onKeyPress={() => {}}
                                    >
                                        <span>
                                            {task.name}
                                        </span>
                                        <FontAwesomeIcon
                                            fixedWidth={!false}
                                            color={Colors.blue}
                                            icon={faChevronRight}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingBottom: 5, }}>
                                {this.renderDisplayOnItems(task)}
                            </div>
                        </div>
                    )) : null}
                </div>
            </>
        );
    }
}

export default WorkflowPreview;
