// @flow
import * as Sentry from '@sentry/browser';
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { loginFailure } from 'txp-core';


export default function* authorized(): Saga<void> {
    const profile = yield select((state) => state.auth.profile);

    if (profile.isEmailVerified) {
        Sentry.configureScope((scope) => {
            scope.setUser({ id: profile.userId, email: profile.email, });
        });
    } else {
        yield put(loginFailure({
            nonFieldError: 'Please click the verification link we sent to your email before logging in',
        }));
    }
}
