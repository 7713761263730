// @flow
/* eslint-disable max-len */
import type { Saga } from 'redux-saga';
import { put, delay, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import api from '../Services/Api';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import { apiFetch, apiPut, apiPost } from './ApiSaga';
import {
    type DownloadReferenceData,
    type UpdateInactive,
    type UploadReferenceData,
    type UpdateWorkflowDetails,
    type CreateWorkflow,
    type UpdateWorkflow,
    type UpdateWorkflowPreferences,
    type CreateWorkflowPreferences,
    getReferenceData,
    receiveForms,
    receiveLastModifiedDate,
    receiveSurveys,
    receiveWorkflows,
    receiveSingleWorkflow,
    receiveResearchers,
    uploadReferenceFailed,
    uploadReferenceSuccess,
    setBuilderWorkflow,
    setBuilderData,
    setSelectedReferenceDataRow,
} from '../Redux/ReferenceDataActions';
import { setSagaMessage, pushError } from '../Redux/ApplicationActions';
import uuidv4 from '../Utils/fakeUuid';
import parseWorkflow from '../Utils/referenceData';

export function* getReferenceDataSaga(): Saga<void> {
    yield put(startLoading('referenceData'));

    const { result, error, } = yield apiFetch(api.txp.referenceData, null, {
        admin: true,
        key: uuidv4().replace(/-/gi, ''),
    });

    if (error) {
        if (error.statusCode === 304) {
            yield put(finishLoading('referenceData'));
            return;
        }
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(
                setSagaMessage('Retrieving data failed', errorMessage, '', false)
            );
        } else if (error.isNetworkError) {
            yield put(
                setSagaMessage(
                    'Retrieving data failed',
                    'Loading reference data failed, are you online?',
                    '',
                    false
                )
            );
        } else {
            yield put(
                setSagaMessage(
                    'Retrieving data failed',
                    'Loading reference data failed, try again later',
                    '',
                    false
                )
            );
        }
        yield put(finishLoading('referenceData'));
    } else {
        const lastModifiedDate = result.last_modified;

        const workflowResult = result.workflows || [];
        const workflow = [];
        for (let i = 0; i < workflowResult.length; i += 1) {
            workflow.push(parseWorkflow(workflowResult[i]));
        }

        const formNameMapping = {};
        workflow.forEach((workflowItem) => {
            workflowItem.tasks.forEach((task) => {
                if (task.details.formId && task.details.description) {
                    formNameMapping[task.details.formId] = task.details.description;
                }
            });
        });

        const surveyResult = result.surveys || [];
        const surveys = [];
        for (let i = 0; i < surveyResult.length; i += 1) {
            surveys.push({
                domain: 'SURVEY',
                key: surveyResult[i].key,
                name: surveyResult[i].reference_data.name,
                type: surveyResult[i].reference_data.type,
                ownerId: surveyResult[i].reference_data.ownerId,
                ownerType: surveyResult[i].reference_data.ownerType,
                organizationType: surveyResult[i].reference_data.organizationType,
                inactive: surveyResult[i].inactive,
                label: surveyResult[i].reference_data.label || '',
                passMark: surveyResult[i].reference_data.pass_mark || 0,
                questions: surveyResult[i].reference_data.questions || {},
                visible: true,
            });
        }

        const formResult = result.forms || [];
        const forms = [];
        for (let i = 0; i < formResult.length; i += 1) {
            const formName = formResult[i].reference_data.name || formNameMapping[formResult[i].key];
            forms.push({
                domain: 'FORM',
                key: formResult[i].key,
                name: formName,
                type: formResult[i].reference_data.type,
                ownerId: formResult[i].reference_data.ownerId,
                ownerType: formResult[i].reference_data.ownerType,
                organizationType: formResult[i].reference_data.organizationType,
                inactive: formResult[i].inactive,
                visible: true,
                sections: formResult[i].reference_data.sections,
                behaviors: formResult[i].reference_data.behaviors,
            });
        }
        const researchersResult = result.researchers || [];
        const researchers = [];

        for (let i = 0; i < researchersResult.length; i += 1) {
            researchers.push({
                domain: 'RESEARCHER',
                key: researchersResult[i].key,
                name: researchersResult[i].reference_data.name,
                type: researchersResult[i].reference_data.type,
                ownerId: researchersResult[i].reference_data.ownerId,
                ownerType: researchersResult[i].reference_data.ownerType,
                organizationType: researchersResult[i].reference_data.organizationType,
                inactive: researchersResult[i].inactive,
                visible: true,
            });
        }

        yield put(receiveLastModifiedDate(lastModifiedDate));
        yield put(receiveWorkflows(workflow));
        yield put(receiveSurveys(surveys));
        yield put(receiveForms(forms));
        yield put(receiveResearchers(researchers));

        yield put(finishLoading('referenceData'));
    }
}

export function* uploadReferenceDataSaga(
    action: UploadReferenceData
): Saga<void> {
    const { domain, name, data, } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('uploadReferenceData'));

    try {
        const dataJSON = JSON.parse(data);

        const { error, } = yield apiPut(
            api.txp.uploadReference,
            {
                domain,
                name,
            },
            {
                payload: dataJSON,
            }
        );

        if (error) {
            if (error.isValidationError) {
                const errorMessage = error && error.errors
                    ? error.errors
                    : error || 'Something went wrong';
                yield put(uploadReferenceFailed(`${errorMessage}`));
            } else if (error.isInvalidResponseCode && error.statusCode === 409) {
                yield put(
                    uploadReferenceFailed(
                        'Reference data with this domain and name has already been uploaded'
                    )
                );
            } else if (error.isNetworkError) {
                yield put(
                    uploadReferenceFailed(
                        'Uploading reference data failed, are you online?'
                    )
                );
            } else {
                yield put(
                    uploadReferenceFailed(
                        'Uploading reference data failed, try again later'
                    )
                );
            }
            yield put(finishLoading('uploadReferenceData'));
        } else {
            yield put(getReferenceData());
            yield put(uploadReferenceSuccess());
            yield put(finishLoading('uploadReferenceData'));
        }
    } catch (err) {
        yield put(uploadReferenceFailed('Invalid JSON data'));
        yield put(finishLoading('uploadReferenceData'));
    }
}

export function* updateInactiveSaga(action: UpdateInactive): Saga<void> {
    const { domain, name, inactive, } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('updateInactive'));

    const { result, error, } = yield apiPut(
        api.txp.updateInactive,
        {
            domain,
            name,
        },
        {
            inactive,
        }
    );

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('Update failed', errorMessage, '', false));
        } else if (error.isNetworkError) {
            yield put(
                setSagaMessage(
                    'Update failed',
                    'Updating inactive status failed, are you online?',
                    '',
                    false
                )
            );
        } else {
            yield put(
                setSagaMessage(
                    'Update failed',
                    'Updating inactive status failed, try again later',
                    '',
                    false
                )
            );
        }
        yield put(finishLoading('updateInactive'));
    } else {
        if (result.domain === 'WORKFLOW') {
            const workflows = yield select((state) => state.reference.workflows);
            const index = workflows.findIndex((x) => x.key === result.key);

            if (index !== -1) {
                workflows[index].inactive = inactive;
                yield put(receiveWorkflows(workflows));
            }
        } else if (result.domain === 'SURVEY') {
            const surveys = yield select((state) => state.reference.surveys);
            const index = surveys.findIndex((x) => x.key === result.key);

            if (index !== -1) {
                surveys[index].inactive = inactive;
                yield put(receiveSurveys(surveys));
            }
        } else if (result.domain === 'FORM') {
            const forms = yield select((state) => state.reference.forms);
            const index = forms.findIndex((x) => x.key === result.key);

            if (index !== -1) {
                forms[index].inactive = inactive;
                yield put(receiveForms(forms));
            }
        } else if (result.domain === 'RESEARCHER') {
            const researchers = yield select((state) => state.reference.researchers);
            const index = researchers.findIndex((x) => x.key === result.key);

            if (index !== -1) {
                researchers[index].inactive = inactive;
                yield put(receiveResearchers(researchers));
            }
        }

        yield put(
            setSagaMessage(
                'Status updated',
                `Status updated for ${domain} ${name}`,
                '',
                false
            )
        );

        yield put(finishLoading('updateInactive'));
    }
}

export function* downloadReferenceDataSaga(
    action: DownloadReferenceData
): Saga<void> {
    const { domain, name, } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('downloadReference'));

    try {
        const token = yield select((state) => state.auth.accessToken);

        const root = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');
        const apiPath = `${root}/reference/${domain}/${name}`;

        const headers = {
            Apikey: process.env.REACT_APP_API_KEY || '',
            Authorization: `Bearer ${token}`,
        };

        const fileName = `${domain}_${name}.json`;

        const finalResult = yield fetch(apiPath, {
            method: 'GET',
            headers,
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return response.json();
            })
            .then((result) => {
                if (result) {
                    if (result.error) {
                        return result.error;
                    }

                    return saveAs(result, fileName);
                }
                return null;
            });

        if (finalResult) {
            if (typeof finalResult === 'string') {
                yield put(setSagaMessage('Download failed', finalResult, 'OK', true));
            } else {
                yield put(
                    setSagaMessage(
                        'Download failed',
                        'Failed to download selected file',
                        'OK',
                        true
                    )
                );
            }
        } else {
            yield put(
                setSagaMessage(
                    'Download success',
                    `Downloaded ${domain} ${name}`,
                    '',
                    false
                )
            );
        }

        yield put(finishLoading('downloadReference'));
    } catch (err) {
        yield put(setSagaMessage('Download failed', err.toString(), 'OK', true));
        yield put(finishLoading('downloadReference'));
    }
}

export function* updateWorkflowDetailsSaga(
    action: UpdateWorkflowDetails
): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    // NOTE: likely to prevent accidental double-clicks
    yield delay(50);

    yield put(startLoading('updateWorkflowDetails'));

    const {
        key, ownerId, ownerType, flat, organizationType, name,
    } = action.workflow;

    const { result, error, } = yield apiPut(
        api.txp.updateWorkflowDetails,
        {
            key,
        },
        {
            owner_id: ownerId,
            owner_type: ownerType,
            flat,
            organization_type: organizationType,
            name,
        }
    );

    if (error) {
        if (error.isNetworkError) {
            yield put(
                pushError('Updating workflow details failed, are you online?', error)
            );
        } else {
            yield put(
                pushError('Updating workflow details failed, try again later', error)
            );
        }
    } else {
        const workflowResult = parseWorkflow(result);
        yield put(
            setSagaMessage(
                'Update successful',
                `Updated ${workflowResult.domain} ${workflowResult.key}`,
                '',
                false
            )
        );
        // NOTE: we have to set the selected reference data so that state.reference.workflows and selectedReferenceData
        // reference the same object. It would probably be better to have selectedReferenceData to just be an id/key (not the whole object)
        yield put(setSelectedReferenceDataRow(workflowResult));
        yield put(receiveSingleWorkflow(workflowResult));
    }

    yield put(finishLoading('updateWorkflowDetails'));
}

export function* createWorkflowSaga(action: CreateWorkflow): Saga<void> {
    yield delay(50);

    yield put(startLoading('createWorkflow'));

    const {
        workflow: {
            id,
            ownerId,
            ownerType,
            flat,
            progressBar,
            tasks,
            trackers,
            organizationType,
            name,
            type,
        },
        followerPreferences,
    } = action;
    const preferences = {
        followers: followerPreferences,
    };

    const { result, error, } = yield apiPost(api.txp.createWorkflow, null, {
        id,
        ownerId,
        ownerType,
        flat,
        progressBar,
        tasks,
        trackers,
        organizationType,
        name,
        type,
        preferences,
    });

    if (error) {
        yield put(pushError('Workflow creation failed', error));
    } else {
        yield put(
            setSagaMessage(
                'Workflow Created',
                `${action.workflow.name} was successfully created.`,
                '',
                false
            )
        );
        yield put(getReferenceData());
        yield put(
            setBuilderData({
                mode: 'update',
                workflow: result.key,
            })
        );
    }

    yield put(finishLoading('createWorkflow'));
}

export function* updateWorkflowSaga(action: UpdateWorkflow): Saga<void> {
    yield delay(50);

    yield put(startLoading('updateWorkflow'));

    const {
        workflow: {
            id,
            ownerId,
            ownerType,
            flat,
            progressBar,
            tasks,
            trackers,
            organizationType,
            name,
            type,
            key,
        },
        followerPreferences,
    } = action;
    const preferences = {
        followers: followerPreferences,
    };

    const { result, error, } = yield apiPut(
        api.txp.updateWorkflow,
        {
            key,
        },
        {
            id,
            ownerId,
            ownerType,
            flat,
            progressBar,
            tasks,
            trackers,
            organizationType,
            name,
            type,
            preferences,
        }
    );

    if (error) {
        yield put(pushError('Update Workflow failed', error));
    } else {
        yield put(
            setSagaMessage(
                'Workflow Updated',
                `${action.workflow.name} was successfully updated.`,
                '',
                false
            )
        );
        yield put(getReferenceData());
        yield put(setBuilderWorkflow(result.key));
    }

    yield put(finishLoading('updateWorkflow'));
}

export function* createWorkflowPreferencesSaga(action: CreateWorkflowPreferences): Saga<void> {
    yield put(startLoading('updateWorkflowPreferences'));

    const {
        workflow,
        followerPreferences,
    } = action;

    const preferences = {
        followers: followerPreferences,
    };

    const { error, } = yield apiPost(api.txp.createWorkflowPreferences,
        { key: workflow, },
        { preferences, });

    if (error) {
        yield put(pushError('Update Workflow Preferences failed', error));
    } else {
        yield put(
            setSagaMessage(
                'Workflow Preferences Updated',
                'Workflow Preferences Updated',
                '',
                false
            )
        );
        yield put(getReferenceData());
    }
    yield put(finishLoading('updateWorkflowPreferences'));
}

export function* updateWorkflowPreferencesSaga(action: UpdateWorkflowPreferences): Saga<void> {
    yield put(startLoading('updateWorkflowPreferences'));

    const {
        preferencesId,
        followerPreferences,
    } = action;

    const preferences = {
        followers: followerPreferences,
    };

    const { error, } = yield apiPut(api.txp.updateWorkflowPreferences,
        { preferencesId, },
        { preferences, });

    if (error) {
        yield put(pushError('Update Workflow Preferences failed', error));
    } else {
        yield put(
            setSagaMessage(
                'Workflow Preferences Updated',
                'Workflow Preferences Updated',
                '',
                false
            )
        );
        yield put(getReferenceData());
    }
    yield put(finishLoading('updateWorkflowPreferences'));
}
