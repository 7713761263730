// @flow
import Colors from '../../Themes/Colors';

const HomeStyles = {
    activeMembers: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.brandPrimary,
        color: '#FFFFFF',
        fontSize: 25,
        height: 110,
    },
    casesOpened: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.mediumBlue,
        color: '#FFFFFF',
        fontSize: 25,
        height: 110,
    },
    messagesSent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.brandSecondary,
        color: '#FFFFFF',
        fontSize: 25,
        height: 110,
    },
    messagesRead: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.brandPrimaryLight,
        color: '#FFFFFF',
        fontSize: 25,
        height: 110,
    },
    tasks: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderRadius: 0.5,
        backgroundColor: '#FFFFFF',
        color: Colors.brandPrimary,
        fontSize: 30,
        marginTop: 30,
    },

    tasksCard: {
        height: 615,
        marginTop: 30,
    },
    notification: {
        textIndent: 50,
        fontSize: 22,
        color: '#424242',
    },
    links: {
        display: 'flex',
        justifyContent: 'left',
        textTransform: 'none',
        fontSize: 20,
        textIndent: 35,
        backgroundColor: 'white',
        color: Colors.brandPrimary,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#d3d3d3',
        borderRadius: 0.5,
    },
    signinMetrics: {
        marginTop: 30,
        marginBottom: 20,
    },
    image: {
        position: 'relative',
        width: '100%',
    },
    icons: {
        fontSize: 80,
    },
    homePagePadding: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
    },
    analyticsPagePadding: {
        paddingBottom: 20,
    },
};

export default HomeStyles;
