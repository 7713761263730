// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import Button from 'react-toolbox/lib/button/Button';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Chance from 'chance';

import MemberRoleDropdown from './MemberRoleDropdown';
import AccessLevelDropdown from './AccessLevelDropdown';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type { NewDemoUser, Organization } from '../Utils/types';

type Props = {
    organizations: Array<Organization>,
    onCreateDemoUser: (demoUser: NewDemoUser) => *,
    onClose: () => *,
};

type State = {
    newDemoUser: NewDemoUser,
    dropdownKey: number,
}

const randomGenerator = new Chance();
class CreateDemoUserPanel extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dropdownKey: 0,
            newDemoUser: {
                firstName: '',
                lastName: '',
                password: '',
                organizationName: '',
                role: 'Other',
                accessLevel: 'Organization Member',
            },
        };
    }

    onChangeUserDetails = (value: string, property: string) => {
        this.setState((state) => ({
            ...state,
            newDemoUser: {
                ...state.newDemoUser,
                [property]: value,
            },
            dropdownKey: (state.dropdownKey) ? 0 : 1,
        }));
    };

    onSubmit = () => {
        const {
            onCreateDemoUser,
        } = this.props;

        const {
            newDemoUser,
        } = this.state;

        onCreateDemoUser(newDemoUser);

        this.setState({
            newDemoUser: {
                firstName: '',
                lastName: '',
                password: '',
                organizationName: '',
                role: 'Other',
                accessLevel: 'Organization Member',
            },
        });
    };

    onCancel = () => {
        const {
            onClose,
        } = this.props;

        onClose();

        this.setState({
            newDemoUser: {
                firstName: '',
                lastName: '',
                password: '',
                organizationName: '',
                role: '',
                accessLevel: 'Organization Member',
            },
        });
    };

    render() {
        const {
            organizations,
        } = this.props;

        const {
            dropdownKey,
            newDemoUser,
        } = this.state;

        const isEnabled = newDemoUser.firstName && newDemoUser.lastName && newDemoUser.organizationName && newDemoUser.password.length > 8;

        const orgs = organizations.filter((item) => item.organizationType === 'Demo');

        const orgNames = orgs.map((org) => ({ value: org.organizationName, label: org.organizationName, }));

        return (
            <div>
                <div style={ApplicationStyles.inputPadding}>
                    <div>
                        <Input
                            type="text"
                            label="First Name"
                            value={newDemoUser.firstName}
                            onChange={(newVal) => this.onChangeUserDetails(newVal, 'firstName')}
                            required
                        >
                            <Button
                                style={{
                                    position: 'absolute',
                                    right: '0px',
                                    top: '20px',
                                    textTransform: 'none',
                                    margin: 0,
                                }}
                                label="Random"
                                primary
                                onClick={() => this.onChangeUserDetails(randomGenerator.first(), 'firstName')}
                            />
                        </Input>

                    </div>
                    <Input
                        type="text"
                        label="Last Name"
                        value={newDemoUser.lastName}
                        onChange={(newVal) => this.onChangeUserDetails(newVal, 'lastName')}
                        required
                    >
                        <Button
                            style={{
                                position: 'absolute',
                                right: '0px',
                                top: '20px',
                                textTransform: 'none',
                                margin: 0,
                            }}
                            label="Random"
                            primary
                            onClick={() => this.onChangeUserDetails(randomGenerator.last({ nationality: 'en', }), 'lastName')}
                        />
                    </Input>
                    <Input
                        label="Password"
                        value={newDemoUser.password}
                        onChange={(newVal) => this.onChangeUserDetails(newVal, 'password')}
                        required
                    >
                        <Button
                            style={{
                                position: 'absolute',
                                right: '0px',
                                top: '20px',
                                textTransform: 'none',
                                margin: 0,
                            }}
                            label="Random"
                            primary
                            onClick={() => this.onChangeUserDetails(randomGenerator.hash({ length: 10, }), 'password')}
                        />
                    </Input>
                    <Dropdown
                        key={dropdownKey}
                        auto
                        onChange={(newVal) => this.onChangeUserDetails(newVal, 'organizationName')}
                        source={orgNames}
                        value={newDemoUser.organizationName}
                        label="Organization"
                        required
                    />
                    <MemberRoleDropdown
                        type="text"
                        label="Organizational Role"
                        value={newDemoUser.role}
                        onChange={(newVal) => this.onChangeUserDetails(newVal, 'role')}
                        required
                    />
                    <AccessLevelDropdown
                        value={newDemoUser.accessLevel}
                        onChange={(newVal) => this.onChangeUserDetails(newVal, 'accessLevel')}
                        accessLevel={newDemoUser.accessLevel}
                        dialogType="DemoUsers"
                        organizationId={0}
                    />
                    <div style={PanelStyles.optionButtonsContainer}>
                        <Button
                            label="Cancel"
                            onClick={this.onCancel}
                            style={ApplicationStyles.rightRedIconButton}
                        />
                        <Button
                            disabled={!isEnabled}
                            style={!isEnabled ? PanelStyles.disabledButton : PanelStyles.button}
                            label="Save"
                            onClick={this.onSubmit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isCreateDemoUserPanelOpen: state.user.isCreateDemoUserPanelOpen,
    organizations: state.organization.organizations,
});

export default connect(mapStateToProps, null)(CreateDemoUserPanel);
