import assembleStore from './Redux';

// create our store
const {
    store,
    persistor,
} = assembleStore();

export {
    store,
    persistor
};
