// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';

import type { TaskMap, TimelineItem } from '../Utils/types';
import Colors from '../Themes/Colors';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    tasks: TaskMap,
    donorTimeline: Array<TimelineItem>,
};

class GraphicalDonorTimeline extends PureComponent<Props> {
    getTimelineChartData = () => {
        const {
            donorTimeline,
        } = this.props;

        const data = [];
        const labels = [];
        let currStep = 0;
        for (let i = 0; i < donorTimeline.length; i += 1) {
            if (donorTimeline[i].completed === true || donorTimeline[i].completed === false) {
                currStep = donorTimeline[i].completed === true ? currStep + 1 : currStep - 1;
                data.push({
                    x: new Date(donorTimeline[i].updateTime),
                    y: currStep,
                });
                labels.push({
                    taskId: donorTimeline[i].taskId,
                    completed: donorTimeline[i].completed,
                });
            }
        }
        return [labels, data];
    };

    render() {
        const {
            tasks,
        } = this.props;

        const [tlabels, tdata] = this.getTimelineChartData();

        if (tdata.length === 0) {
            return (
                <div style={ApplicationStyles.relativeVerticalCenter}>
                    No data for selected donor
                </div>
            );
        }

        return (
            <div style={{ position: 'relative', height: '100%', }}>
                <Line
                    data={{
                        labels: tlabels,
                        datasets: [{
                            lineTension: 0,
                            fill: false,
                            borderColor: Colors.brandPrimary,
                            backgroundColor: Colors.brandPrimary,
                            data: tdata,
                        }],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        scales: {
                            xAxes: [{
                                type: 'time',
                                distribution: 'linear',
                                ticks: {
                                    source: 'data',
                                    min: tdata[0].x,
                                    max: tdata[tdata.length - 1].x,
                                    precision: 0,
                                },
                                time: {
                                    unit: 'minute',
                                    displayFormats: {
                                        minute: 'MMM D h:mm a',
                                    },
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    stepSize: 1,
                                },
                            }],

                        },
                        tooltips: {
                            callbacks: {
                                title(tooltipItem, data) {
                                    if (tasks && tasks[data.labels[tooltipItem[0].index].taskId]) {
                                        return tasks[data.labels[tooltipItem[0].index].taskId].description || '';
                                    }
                                    return '';
                                },
                                label(tooltipItem, data) {
                                    return `Marked as ${data.labels[tooltipItem.index].completed === true ? 'complete' : 'incomplete'}`;
                                },
                            },
                        },
                    }}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    tasks: state.donor.tasks,
    donorTimeline: state.donor.selectedDonorId && state.donor.donors[state.donor.selectedDonorId]
        ? state.donor.donors[state.donor.selectedDonorId].timeline : [],
});

export default connect(mapStateToProps, null)(GraphicalDonorTimeline);
