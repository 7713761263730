// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import ProgressBar from 'react-toolbox/lib/progress_bar/ProgressBar';
import Sidebar from 'react-sidebar';
import Input from 'react-toolbox/lib/input/Input';
import ApplicationStyles from '../Themes/ApplicationStyles';
import SortableTable from '../Components/SortableTable';
import SagaMessage from '../Components/SagaMessage';
import ChatroomPanel from '../Components/ChatroomPanel';

import {
    loadChatrooms as _loadChatrooms,
    searchChatrooms as _searchChatrooms,
    setSortParameters as _setSortParameters,
    loadActiveChatroomData as _loadActiveChatroomData,
    loadArchivedChatroomData as _loadArchivedChatroomData,
    clearSearch as _clearSearch,
    setSelectedChatroomRow as _setSelectedChatroomRow,
} from '../Redux/ChatroomActions';
import type {
    UserProfile,
    ArchivedChatroom,
    ActiveChatroom,
} from '../Utils/types';


type Props = {
    chatroomType: string,
    searchTerm: string,
    sortHeader: string,
    sortDirection: string,
    profile: UserProfile,
    activeChatrooms: Array<ActiveChatroom>,
    archivedChatrooms: Array<ArchivedChatroom>,

    loadChatrooms: () => *,
    loadActiveChatroomData: (chatroomId: number) => *,
    loadArchivedChatroomData: (chatroomId: number) => *,
    searchChatrooms: (searchTerm: string, searchHeaders: Array<string>) => *,
    setSortParameters: (sortHeader: string, sortDirection: string) => *,
    clearSearch: () => *,
    setSelectedChatroomRow: (row: any)=> *,
}

type State = {
    isChatroomPanelOpen: boolean,
    prevPropsChatroomType: string,
    selectedActiveChatroom: ActiveChatroom,
    selectedArchivedChatroom: ArchivedChatroom,
};

const genericSagaToast: number = 10007;

const activeChatroomsHeader = [
    {
        dataKey: 'chatroomId',
        title: 'ID',
        isInvisible: true,
    },
    {
        dataKey: 'chatroomName',
        title: 'Name',
    },
    {
        dataKey: 'chatroomDescription',
        title: 'Description',
    },
    // Pending API update
    // {
    //     dataKey: 'startDate',
    //     title: 'Start Date',
    // },
    {
        dataKey: 'lastInstantMessageTime',
        title: 'Last Active',
    },
    {
        dataKey: 'creatorId',
        title: 'Creator',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'memberCount',
        title: 'Members',
    },
    {
        dataKey: 'totalMessageCount',
        title: 'Messages',
    },
    {
        dataKey: 'organizationCount',
        title: 'Organizations',
    },
    {
        dataKey: 'unosId',
        title: 'UNOS ID',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'matchId',
        title: 'Match ID',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'transplantCenter',
        title: 'Center',
        isInvisibleInTable: true,
    }
];
const archivedChatroomsHeader = [
    {
        dataKey: 'chatroomId',
        title: 'ID',
        isInvisible: true,
    },
    {
        dataKey: 'chatroomName',
        title: 'Name',
    },
    {
        dataKey: 'chatroomDescription',
        title: 'Description',
    },
    // Pending API update
    // {
    //     dataKey: 'startDate',
    //     title: 'Start Date',
    // },
    {
        dataKey: 'archivedDate',
        title: 'Archived Date',
    },
    {
        dataKey: 'creatorId',
        title: 'Creator',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'memberCount',
        title: 'Members',
    },
    {
        dataKey: 'totalMessageCount',
        title: 'Messages',
    },
    {
        dataKey: 'organizationCount',
        title: 'Organizations',
    },
    {
        dataKey: 'unosId',
        title: 'UNOS ID',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'matchId',
        title: 'Match ID',
        isInvisibleInTable: true,
    },
    {
        dataKey: 'transplantCenter',
        title: 'Center',
        isInvisibleInTable: true,
    }
];

class ChatroomPage extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isChatroomPanelOpen: false,
            selectedActiveChatroom: {
                chatroomId: -1,
                chatroomName: '',
                chatroomDescription: '',
                donorId: 0,
                organId: 0,
                unosId: '',
                matchId: '',
                organType: '',
                transplantCenter: 0,
                creatorId: 0,
                memberCount: 0,
                totalMessageCount: 0,
                organizationCount: 0,
                lastUpdateTime: '',
                lastInstantMessageContent: '',
                lastInstantMessageTime: '',
                visible: true,
            },
            selectedArchivedChatroom: {
                chatroomId: -1,
                chatroomName: '',
                chatroomDescription: '',
                donorId: 0,
                organId: 0,
                unosId: '',
                matchId: '',
                organType: '',
                transplantCenter: 0,
                creatorId: 0,
                memberCount: 0,
                totalMessageCount: 0,
                organizationCount: 0,
                archivedDate: '',
                visible: true,
            },
            prevPropsChatroomType: props.chatroomType,
        };
    }

    static getDerivedStateFromProps(props, state) {
        // Any time the current chatroomType changes in props, reset sidebar in state
        if (props.chatroomType !== state.prevPropsChatroomType) {
            return {
                prevPropsChatroomType: props.chatroomType,
                isChatroomPanelOpen: false,
            };
        }
        return null;
    }

    componentDidMount() {
        const {
            loadChatrooms,
            clearSearch,
        } = this.props;

        loadChatrooms();
        clearSearch();
    }

    onClose = () => {
        const {
            setSelectedChatroomRow,
        } = this.props;

        this.setState({
            isChatroomPanelOpen: false,
        });

        setSelectedChatroomRow(null);
    };

    onChatroomRowClick = async (selectedChatroom: any) => {
        const {
            chatroomType,
            loadActiveChatroomData,
            loadArchivedChatroomData,
        } = this.props;

        this.setState({
            isChatroomPanelOpen: true,
            selectedActiveChatroom: selectedChatroom,
            selectedArchivedChatroom: selectedChatroom,
        });

        if (chatroomType === 'Archived') {
            loadArchivedChatroomData(selectedChatroom.chatroomId);
            this.setState({
                isChatroomPanelOpen: true,
                selectedArchivedChatroom: selectedChatroom,
            });
        } else {
            loadActiveChatroomData(selectedChatroom.chatroomId);
            this.setState({
                isChatroomPanelOpen: true,
                selectedActiveChatroom: selectedChatroom,
            });
        }
    };

    onSearch = (searchTerm) => {
        const {
            searchChatrooms,
        } = this.props;

        searchChatrooms(searchTerm, ['chatroomName', 'chatroomDescription', 'startDate', 'lastActive', 'creator',
            'memberCount', 'totalMessageCount', 'organizationCount']);
    };

    onSetSortParameters = (sortHeader, sortDirection) => {
        const {
            setSortParameters,
        } = this.props;

        if (sortHeader && sortDirection) {
            setSortParameters(sortHeader, sortDirection);
        }
    };

    render() {
        const {
            chatroomType,
            searchTerm,
            profile,
            sortHeader,
            sortDirection,
            activeChatrooms,
            archivedChatrooms,
        } = this.props;

        const {
            selectedActiveChatroom,
            selectedArchivedChatroom,
            isChatroomPanelOpen,
        } = this.state;

        let contentType = '';
        let headers = activeChatroomsHeader;
        let chatrooms = [];
        let chatroomNameArray = [];

        if (chatroomType === 'Archived') {
            contentType = 'archived chatrooms';
            headers = archivedChatroomsHeader;
            chatrooms = archivedChatrooms;
            chatroomNameArray = selectedArchivedChatroom.chatroomName;
        } else {
            contentType = 'active chatrooms';
            headers = activeChatroomsHeader;
            chatrooms = activeChatrooms;
            chatroomNameArray = selectedActiveChatroom.chatroomName;
        }

        if (activeChatrooms || archivedChatrooms) {
            return (
                <div>
                    <Sidebar
                        sidebar={(
                            <div>
                                <div
                                    style={{
                                        ...ApplicationStyles.detailsHeader,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <ul style={ApplicationStyles.chatroomTitle}>
                                        <li>
                                            {chatroomNameArray}
                                        </li>
                                    </ul>
                                </div>
                                <ChatroomPanel
                                    headers={headers}
                                    contentType={contentType}
                                    onClose={this.onClose}
                                    activeChatroom={selectedActiveChatroom}
                                    archivedChatroom={selectedArchivedChatroom}
                                />
                            </div>
                        )}
                        open={isChatroomPanelOpen}
                        docked={isChatroomPanelOpen}
                        pullRight
                        sidebarClassName="rightSidebar"
                        shadow={false}
                        styles={{
                            sidebar: {
                                background: 'white',
                                marginTop: 'var(--header-height)',
                                zIndex: 1,
                            },
                        }}
                    >
                        <div style={ApplicationStyles.pagePadding}>
                            <SagaMessage toastId={genericSagaToast} />
                            <div>
                                <Input
                                    type="text"
                                    label="Search"
                                    value={searchTerm}
                                    name="search"
                                    icon="search"
                                    onChange={this.onSearch}
                                />
                                <SortableTable
                                    accessLevel={profile.accessLevel || ''}
                                    searchTerm={searchTerm}
                                    content={chatrooms}
                                    headers={headers}
                                    sortDirection={sortDirection}
                                    sortHeader={sortHeader}
                                    contentType={contentType}
                                    onRowClick={this.onChatroomRowClick}
                                    onHeaderClick={this.onSetSortParameters}
                                    onRowClose={this.onClose}
                                />
                            </div>
                        </div>
                    </Sidebar>
                </div>
            );
        }
        return (
            <Dialog
                active
                title="Loading..."
            >
                <ProgressBar type="linear" mode="indeterminate" />
            </Dialog>
        );
    }
}
const mapStateToProps = (state) => ({
    sortHeader: state.chatroom.sortHeader,
    sortDirection: state.chatroom.sortDirection,
    searchTerm: state.chatroom.searchTerm,
    profile: state.auth.profile,
    activeChatrooms: state.chatroom.activeChatrooms,
    archivedChatrooms: state.chatroom.archivedChatrooms,
});
export default connect(mapStateToProps, {
    loadChatrooms: _loadChatrooms,
    searchChatrooms: _searchChatrooms,
    setSortParameters: _setSortParameters,
    loadActiveChatroomData: _loadActiveChatroomData,
    loadArchivedChatroomData: _loadArchivedChatroomData,
    clearSearch: _clearSearch,
    setSelectedChatroomRow: _setSelectedChatroomRow,
})(ChatroomPage);

