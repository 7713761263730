// @flow

import * as Sentry from '@sentry/browser';

export type DownloadConfig = {
    fromUrl: string,
    headers: *,
    useLock?: boolean,
};

export type DownloadResult = {
    dataURI: null | string | ArrayBuffer,
    statusCode: number,
};

async function downloadAvatar({ fromUrl, headers, }: DownloadConfig) {
    try {
        return await fetch(fromUrl, {
            method: 'GET',
            headers,
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob();
                }
                return null;
            })
            .then((result) => {
                if (result) {
                    return URL.createObjectURL(result);
                }
                return null;
            });
    } catch (error) {
        return null;
    }
}

export const downloadFileBlobStart = async ({
    fromUrl,
    headers,
}: DownloadConfig): Promise<*> => fetch(fromUrl, {
    method: 'GET',
    headers,
}).then((response) => {
    let error = null;
    let result = null;
    const { status, statusText, ok, } = response;
    return response.blob().then((blob) => {
        if (ok) {
            result = {
                status,
                statusText,
                blob,
            };
        } else {
            error = {
                status,
                statusText,
            };
        }
        return {
            result,
            error,
        };
    });
});

export const getDataURIFromBlob = async (fileBlobTask: Promise<*>) => {
    let result: ?DownloadResult = null;
    let error = null;

    const fileBlobResponse = await fileBlobTask;

    return new Promise((resolve, reject) => {
        if (fileBlobResponse.result) {
            const { blob, } = fileBlobResponse.result;
            try {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    result = {
                        dataURI: reader.result,
                        statusCode: 200,
                    };
                    resolve({
                        result,
                        error,
                    });
                }, false);

                reader.readAsDataURL(blob);
            } catch (err) {
                error = err;
                Sentry.captureException(`Download file Failed: ${JSON.stringify(error)}`);
                /* eslint-disable-next-line prefer-promise-reject-errors */
                reject({
                    result,
                    error,
                });
            }
        } else {
            reject(fileBlobResponse.error);
        }
    });
};

export const getFileAsDataURI = async (
    {
        fromUrl,
        headers,
        useLock,
    }: DownloadConfig): Promise<any> => {
    const fileBlobTask = downloadFileBlobStart({
        fromUrl,
        headers,
        useLock,
    });

    const dataURI = await getDataURIFromBlob(fileBlobTask);
    return dataURI;
};

export function jsonObjectToBlob(obj: any): Blob {
    const str = JSON.stringify(obj, undefined, 2);
    const bytes = new TextEncoder().encode(str);
    return new Blob([bytes], {
        type: 'application/json;charset=utf-8',
    });
}

export const downloadBlobAsAttachment = (blob: Blob, fileName: string) => {
    if (!document.body) {
        throw new Error('Page not loaded');
    }

    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Make Flow happy
    if (document.body) {
        // some browser needs the anchor to be in the doc
        document.body.append(link);
    }

    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export default downloadAvatar;
