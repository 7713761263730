// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import EditSelectionText from './EditSelectionText';
import UserDetailsPanel from '../UserDetailsPanel';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import InviteUsersButton from '../InviteUsersButton';
import DeactivateAccountsButton from './DeactivateAccountsButton';
import { onClosePanel } from '../../Utils/panels';
import { maybeAddLastLogin } from '../../Utils/tableHelper';
import { isUserTypeLoaded } from '../../Utils/user';
import {
    updateUser as _updateUser,
    loadUsers as _loadUsers,
    approvePendingMembers as _approvePendingMembers,
} from '../../Redux/UserActions';
import {
    setSagaMessage as _setSagaMessage,
} from '../../Redux/ApplicationActions';

import type { UserProfile } from '../../Utils/types';

const dialogType = 'PendingMember';
const contentType = 'pending members';
const pendingMemberHeadersOrg = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
        isInternal: true,
        isInvisible: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
        isUneditable: true,
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
        isUneditable: true,
    },
    {
        dataKey: 'email',
        title: 'Email',
        isUneditable: true,
    },
    {
        dataKey: 'phone',
        title: 'Phone',
        isUneditable: true,
    },
    {
        dataKey: 'role',
        title: 'Role',
        isUneditable: true,
    },
    {
        dataKey: 'accessLevel',
        title: 'Permission',
        isUneditable: true,
    },
    {
        dataKey: 'lastLogin',
        title: 'Last Login',
        isUneditable: true,
    }
];

const pendingMemberHeadersSalesSys = [
    {
        dataKey: 'userId',
        title: 'ID',
        isUneditable: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
    },
    {
        dataKey: 'email',
        title: 'Email',
    },
    {
        dataKey: 'emailUpdate',
        title: 'Pending Email',
        isUneditable: true,
    },
    {
        dataKey: 'phone',
        title: 'Phone',
        isUneditable: true,
    },
    {
        dataKey: 'organizationName',
        title: 'Organization',
        isUneditable: true,
    },
    {
        dataKey: 'role',
        title: 'Role',
    },
    {
        dataKey: 'department',
        title: 'Department',
    },
    {
        dataKey: 'accessLevel',
        title: 'Permission',
        isUneditable: true,
    }
];

type Props = {
    profile: UserProfile,
    users: Array<UserProfile>,
    usersLoaded: boolean,
    bulkEditUsers: Array<UserProfile>,

    updateUser: (userId: number, firstName: ?string, lastName: ?string, email: ?string, templatePreferences: ?any,
        accessLevel: ?string, role: ?string, department: ?string) => *,
    loadUsers: typeof _loadUsers,
    approvePendingMembers: typeof _approvePendingMembers,
    setSagaMessage: typeof _setSagaMessage,
};

class PendingMembers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('pending');
    }

    onUpdateUser = ({
        userId, firstName, lastName, email, accessLevel, role, department,
    }: UserProfile) => {
        this.props.updateUser(userId, firstName, lastName, email, null, accessLevel, role, department);
    };

    onApprovePendingMembers = () => {
        const {
            bulkEditUsers,
            setSagaMessage,
        } = this.props;

        const anyUserWithoutRole = bulkEditUsers.filter((user) => !user.role).length > 0;

        if (anyUserWithoutRole) {
            setSagaMessage('Unable to approve membership', 'Please ensure all selected users are assigned a role before approving memberships.', 'OK', true);
        }

        this.approveSelectedMembers(true);
    };

    onDenyPendingMembers = () => {
        this.approveSelectedMembers(false);
    };

    approveSelectedMembers = (approve) => {
        const { bulkEditUsers, approvePendingMembers, } = this.props;
        const approvalText = approve ? 'approve' : 'deny';
        const numSelected = bulkEditUsers.length;
        const confirm = window.confirm(`Are you sure you want to ${approvalText} the ${
            numSelected > 1 ? `${numSelected} ` : ''
        }selected membership request${numSelected > 1 ? 's' : ''}?`);

        if (confirm) {
            approvePendingMembers(bulkEditUsers.map(({ organizationId, userId, }) => ({
                organizationId: ((organizationId: any): number),
                userId,
                approve,
            })));
        }
    };

    render() {
        const {
            profile, users, bulkEditUsers, usersLoaded,
        } = this.props;
        const numSelected = bulkEditUsers.length;
        const disableActions = numSelected < 1;
        const isAdmin = profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales';
        const headers = isAdmin ? pendingMemberHeadersSalesSys : pendingMemberHeadersOrg;

        return (
            <>
                <UserPanel
                    sidebar={(
                        <UserDetailsPanel
                            headers={headers}
                            dialogType={dialogType}
                            contentType={contentType}
                            onUpdateUser={this.onUpdateUser}
                            onClose={onClosePanel}
                        />
                    )}
                >
                    {isAdmin || profile.accessLevel === 'OrgManager' ? (
                        <div className="user-actions">
                            <InviteUsersButton
                                disableButton={!disableActions}
                            />
                        </div>
                    ) : null}
                    <div className="user-actions">
                        <EditSelectionText numSelected={numSelected} disableActions={disableActions} />

                        <Button
                            disabled={disableActions}
                            raised
                            primary
                            label="Approve Membership Requests"
                            onClick={this.onApprovePendingMembers}
                        />
                        <Button
                            disabled={disableActions}
                            raised
                            accent
                            label="Deny Membership Requests"
                            onClick={this.onDenyPendingMembers}
                        />
                        {isAdmin ? (
                            <DeactivateAccountsButton
                                disabled={disableActions}
                                label="Deactivate Accounts"
                            />
                        ) : null}
                    </div>
                    <UserTable
                        users={users}
                        headers={headers}
                        contentType={contentType}
                        usersLoaded={usersLoaded}
                        multiSelect
                    />
                </UserPanel>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile, } = state.auth;

    return {
        profile,
        users: maybeAddLastLogin(
            profile.accessLevel,
            state.user.loadedUsers.pending ?? [],
            state.organization.organizationUserActivity,
            state.user.sortDirection,
            state.user.sortHeader
        ),
        usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'pending'),
        bulkEditUsers: state.user.bulkEditUsers,
    };
};

export default connect(mapStateToProps, {
    updateUser: _updateUser,
    loadUsers: _loadUsers,
    approvePendingMembers: _approvePendingMembers,
    setSagaMessage: _setSagaMessage,
})(PendingMembers);
