// @flow
import React, { Component } from 'react';
import type { Node } from 'react';

type Props = {
    children: Node,
};

const OLD_HOST = 'txpchat.com';
const NEW_HOST = 'omnilife.io';

// eslint-disable-next-line react/prefer-stateless-function
class Redirect extends Component<Props> {
    render() {
        if (
            typeof window !== 'undefined' && window.location
            && window.location.hostname.toLowerCase().includes(OLD_HOST)
        ) {
            const regExp = new RegExp(OLD_HOST, 'i');
            const newURL = window.location.href.replace(regExp, NEW_HOST);
            window.location.href = newURL;
            return null;
        }

        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default Redirect;
