// @flow
import type { Logout } from 'txp-core';
import type { UIPermissionsMap, ResourcePermissionMap } from '../Utils/types';

export type PermissionState = {
    resourcePermissions: ResourcePermissionMap,
    adminPortalPermissions: UIPermissionsMap,
};

const initialState: PermissionState = {
    resourcePermissions: {},
    adminPortalPermissions: {},
};

export type GetResourcePermissions = {
    type: 'Permission/GET_RESOURCE_PERMISSIONS',
    entityType: string,
    entityId: number,
};

export type GetAdminPortalPermissions = {
    type: 'Permission/GET_ADMIN_PORTAL_PERMISSIONS',
};

export type ReceiveResourcePermissions = {
    type: 'Permission/RECEIVE_RESOURCE_PERMISSIONS',
    resourcePermissions: ResourcePermissionMap,
};

export type ReceiveAdminPortalPermissions = {
    type: 'Permission/RECEIVE_ADMIN_PORTAL_PERMISSIONS',
    adminPortalPermissions: UIPermissionsMap,
}

export type AddPermission = {
    type: 'Permission/ADD',
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    authorizedType: string,
    authorizedId: number,
};
export type RemovePermission = { type: 'Permission/REMOVE', permissionId: number, authorizedId: number };

type Action =
    | GetResourcePermissions
    | GetAdminPortalPermissions
    | ReceiveResourcePermissions
    | ReceiveAdminPortalPermissions
    | AddPermission
    | RemovePermission
    | Logout;

export const getResourcePermissions = (entityType: string, entityId: number): GetResourcePermissions => ({
    type: 'Permission/GET_RESOURCE_PERMISSIONS',
    entityType,
    entityId,
});

export const getAdminPortalPermissions = (): GetAdminPortalPermissions => ({
    type: 'Permission/GET_ADMIN_PORTAL_PERMISSIONS',
});

export const receiveResourcePermissions = (
    resourcePermissions: ResourcePermissionMap
): ReceiveResourcePermissions => ({
    type: 'Permission/RECEIVE_RESOURCE_PERMISSIONS',
    resourcePermissions,
});

export const receiveAdminPortalPermissions = (
    adminPortalPermissions: UIPermissionsMap
): ReceiveAdminPortalPermissions => ({
    type: 'Permission/RECEIVE_ADMIN_PORTAL_PERMISSIONS',
    adminPortalPermissions,
});

export const addPermission = (
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
    read: boolean,
    update: boolean,
    canDelete: boolean,
    authorizedType: string,
    authorizedId: number
): AddPermission => ({
    type: 'Permission/ADD',
    entityType,
    entityId,
    subEntityType,
    subEntityId,
    cascade,
    read,
    update,
    canDelete,
    authorizedType,
    authorizedId,
});

export const removePermission = (permissionId: number, authorizedId: number): RemovePermission => ({
    type: 'Permission/REMOVE',
    permissionId,
    authorizedId,
});

const reducer = (state: PermissionState = initialState, action: Action): PermissionState => {
    switch (action.type) {
        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'Permission/RECEIVE_RESOURCE_PERMISSIONS':
            return {
                ...state,

                resourcePermissions: action.resourcePermissions,
            };

        case 'Permission/RECEIVE_ADMIN_PORTAL_PERMISSIONS':
            return {
                ...state,

                adminPortalPermissions: action.adminPortalPermissions,
            };

        default:
            return state;
    }
};

export default reducer;
