// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import type { TimelineItem } from '../Utils/types';
import ApplicationStyles from '../Themes/ApplicationStyles';
import MessagesHomeStyles from '../Containers/Styles/MessagesHomeStyles';
import { getElapsedTimeString } from '../Utils/datetime';

type Props = {
    donorTimeline: Array<TimelineItem>,
};

class DonorMetricPane extends PureComponent<Props> {
    getORCCTime = () => {
        const {
            donorTimeline,
        } = this.props;

        let OR = -1;
        let CC = -1;

        for (let i = 0; i < donorTimeline.length; i += 1) {
            if (donorTimeline[i].completed === true) {
                if (donorTimeline[i].taskId === 22) {
                    OR = i;
                } else if (donorTimeline[i].taskId === 27) {
                    CC = i;
                }
            }
            if (donorTimeline[i].completed === false) {
                if (donorTimeline[i].taskId === 22) {
                    OR = -1;
                } else if (donorTimeline[i].taskId === 27) {
                    CC = -1;
                }
            }
        }

        if (OR !== -1 && CC !== -1) {
            return getElapsedTimeString(donorTimeline[CC].updateTime, donorTimeline[OR].updateTime);
        }

        return null;
    };

    render() {
        const time = this.getORCCTime();

        if (!time) {
            return (
                <div style={MessagesHomeStyles.noDataText}>
                    N/A
                </div>
            );
        }

        return (
            <div style={ApplicationStyles.flexRow}>
                <div style={MessagesHomeStyles.dataText}>
                    {time}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    donorTimeline: state.donor.selectedDonorId && state.donor.donors[state.donor.selectedDonorId]
        ? state.donor.donors[state.donor.selectedDonorId].timeline : [],
});

export default connect(mapStateToProps, null)(DonorMetricPane);
