// @flow
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Input from 'react-toolbox/lib/input/Input';

type Props = {
    value: ?string,
    onChange: (newVal: string) => void
};

const memberRoles = [
    {
        value: 'Administrator',
        label: 'Administrator',
    },
    {
        value: 'Coordinator',
        label: 'Coordinator',
    },
    {
        value: 'Medicine',
        label: 'Medicine',
    },
    {
        value: 'Specialist',
        label: 'Specialist',
    },
    {
        value: 'Surgeon',
        label: 'Surgeon',
    },
    {
        value: 'Technologist',
        label: 'Technologist',
    },
    {
        value: 'Other',
        label: 'Other',
    }
];

const roles = memberRoles.map((d) => d.value);

const MemberRoleDropdown = (props: Props) => {
    const { value, onChange, } = props;

    const [dropdownKey, setDropdownKey] = useState(0);
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    useEffect(() => {
        const isOther = !roles.includes(value) || value === 'Other';
        setIsOtherSelected(isOther);
    }, [value]);

    const onSelect = (newVal: string) => {
        setDropdownKey((prevKey) => (prevKey ? 0 : 1));
        setIsOtherSelected(newVal === 'Other');

        onChange(newVal);
    };

    const getDropdownValue = () => (roles.includes(value) ? value : 'Other');

    const handleCustomRoleChange = (newVal) => {
        onChange(newVal);
    };

    return (
        <div>
            <Dropdown
                key={dropdownKey}
                auto
                onChange={onSelect}
                source={memberRoles}
                value={getDropdownValue()}
                label="Organizational Role"
                required
            />
            { isOtherSelected
                    && (
                        <Input
                            type="text"
                            label="Specify Role"
                            value={value}
                            onChange={handleCustomRoleChange}
                            required
                        />
                    )}
        </div>
    );
};

export default MemberRoleDropdown;


