// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import ProgressBar from 'react-toolbox/lib/progress_bar/ProgressBar';
import Input from 'react-toolbox/lib/input/Input';
import 'react-tabs/style/react-tabs.css';
import Button from 'react-toolbox/lib/button/Button';
import Sidebar from 'react-sidebar';
import Colors from '../Themes/Colors';

import ApplicationStyles from '../Themes/ApplicationStyles';
import SortableTable from '../Components/SortableTable';
import OrganizationDetailsPanel from '../Components/OrganizationDetailsPanel';
import AddOrganizationPanel from '../Components/AddOrganizationPanel';
import type {
    Organization, NewOrganization, UserProfile,
} from '../Utils/types';
import {
    addOrganization as _addOrganization,
    searchOrganizations as _searchOrganizations,
    clearSearch as _clearSearch,
    setOrganizationEdit as _setOrganizationEdit,
    setSortParameters as _setSortParameters,
    setAddOrgPanel as _setAddOrgPanel,
    openOrgDetailsPanel as _openOrgDetailsPanel,
    closeOrgDetailsPanel as _closeOrgDetailsPanel,
    closeTemplatePreferencesPanel as _closeTemplatePreferencesPanel,
    closeClientPermissionsPanel as _closeClientPermissionsPanel,
    setSelectedOrganizationRow as _setSelectedOrganizationRow,
    closeOrganizationWorkflowsPanel as _closeOrganizationWorkflowsPanel,
} from '../Redux/OrganizationActions';
import SagaMessage from '../Components/SagaMessage';

type Props = {
    profile: UserProfile,
    organizations: Array<Organization>,
    searchTerm: string,
    sortHeader: string,
    sortDirection: string,

    isAddOrganizationPanelOpen: boolean,
    isOrganizationDetailsPanelOpen: boolean,
    addOrganization: (organizationName: string, organizationCode: string, organizationType: string, templatePreferences: ?any) => *,
    searchOrganizations: (searchTerm: string, searchHeaders: Array<string>) => *,
    clearSearch: () => *,
    setSortParameters: (sortHeader: string, sortDirection: string) => *,
    setOrganizationEdit: (organizationId: number) => *,
    setSelectedOrganizationRow: (row: any) => *,
    setAddOrgPanel: (isAddOrganizationPanelOpen: boolean) => *,
    openOrgDetailsPanel: () => *,
    closeOrgDetailsPanel: () => *,
    closeTemplatePreferencesPanel: () => *,
    closeClientPermissionsPanel: () => *,
    closeOrganizationWorkflowsPanel: () => *,
};

const genericSagaToast: number = 10007;

const organizationHeaders = [
    {
        dataKey: 'organizationId',
        title: 'ID',
        isUneditable: true,
    },
    {
        dataKey: 'organizationCode',
        title: 'Code',
    },
    {
        dataKey: 'organizationName',
        title: 'Name',
    },
    {
        dataKey: 'organizationType',
        title: 'Type',
    },
    {
        dataKey: 'license',
        title: 'License',
    }
];

class OrganizationsPage extends PureComponent<Props> {
    componentDidMount() {
        const {
            clearSearch,
            closeOrgDetailsPanel,
        } = this.props;

        clearSearch();
        closeOrgDetailsPanel();
    }

    onSelectOrganization = (organizationEdit: Organization) => {
        const {
            setOrganizationEdit,
            openOrgDetailsPanel,
            closeTemplatePreferencesPanel,
            closeClientPermissionsPanel,
            closeOrganizationWorkflowsPanel,
        } = this.props;
        setOrganizationEdit(organizationEdit.organizationId);
        openOrgDetailsPanel();
        closeTemplatePreferencesPanel();
        closeClientPermissionsPanel();
        closeOrganizationWorkflowsPanel();
    };

    onAddOrganizationClick = () => {
        const {
            setAddOrgPanel,
            closeOrgDetailsPanel,
        } = this.props;

        closeOrgDetailsPanel();
        setAddOrgPanel(true);
    };

    onCloseOrgDetailsPanel = () => {
        const {
            closeOrgDetailsPanel,
        } = this.props;

        closeOrgDetailsPanel();
    };

    onAddOrganization = (newOrganization: NewOrganization) => {
        const {
            addOrganization,
            clearSearch,
        } = this.props;

        addOrganization(newOrganization.organizationName, newOrganization.organizationCode,
            newOrganization.organizationType, newOrganization.templatePreferences);

        clearSearch();

        this.onClose();
    };

    onClose = () => {
        const {
            setAddOrgPanel,
            setSelectedOrganizationRow,
        } = this.props;

        setAddOrgPanel(false);
        setSelectedOrganizationRow(null);
    };

    onSetSortParameters = (sortHeader, sortDirection) => {
        const {
            setSortParameters,
        } = this.props;

        if (sortHeader && sortDirection) {
            setSortParameters(sortHeader, sortDirection);
        }
    };

    onSearchOrganization = (searchTerm) => {
        const {
            searchOrganizations,
        } = this.props;

        searchOrganizations(searchTerm, ['organizationId', 'organizationName', 'organizationCode', 'organizationType', 'license']);
    };

    render() {
        const {
            profile,
            organizations,
            searchTerm,
            sortHeader,
            sortDirection,
            isAddOrganizationPanelOpen,
            isOrganizationDetailsPanelOpen,
        } = this.props;

        if (organizations) {
            return (
                <Sidebar
                    sidebar={(
                        <div>
                            <div style={{
                                ...ApplicationStyles.detailsHeader,
                                paddingTop: 35,
                                paddingBottom: 35,
                            }}
                            >
                                New Organization
                            </div>
                            <AddOrganizationPanel
                                onAddOrganization={this.onAddOrganization}
                                onClose={this.onClose}
                            />
                        </div>
                    )}
                    open={isAddOrganizationPanelOpen}
                    docked={isAddOrganizationPanelOpen}
                    pullRight
                    sidebarClassName="rightSidebar"
                    shadow={false}
                    styles={{
                        sidebar: {
                            background: 'white',
                            marginTop: 'var(--header-height)',
                            zIndex: 1,
                        },
                    }}
                >
                    <Sidebar
                        sidebar={(
                            <div>
                                <OrganizationDetailsPanel
                                    headers={organizationHeaders}
                                />
                            </div>
                        )}
                        open={isOrganizationDetailsPanelOpen}
                        docked={isOrganizationDetailsPanelOpen}
                        pullRight
                        sidebarClassName="rightSidebar"
                        shadow={false}
                        styles={{
                            sidebar: {
                                background: 'white',
                                marginTop: 'var(--header-height)',
                                zIndex: 1,
                            },
                        }}
                    >
                        <div style={ApplicationStyles.pagePadding}>
                            <SagaMessage toastId={genericSagaToast} />
                            <Input
                                type="text"
                                label="Search"
                                value={searchTerm}
                                name="search"
                                icon="search"
                                onChange={this.onSearchOrganization}
                            />
                            <SortableTable
                                accessLevel={profile.accessLevel || ''}
                                content={organizations}
                                headers={organizationHeaders}
                                sortDirection={sortDirection}
                                sortHeader={sortHeader}
                                searchTerm={searchTerm}
                                contentType="organizations"
                                onHeaderClick={this.onSetSortParameters}
                                onRowClick={this.onSelectOrganization}
                                onRowClose={this.onCloseOrgDetailsPanel}
                            />
                            <div style={ApplicationStyles.footerMessage}>
                                <Button
                                    onClick={() => this.onAddOrganizationClick()}
                                    icon="add"
                                    floating
                                    style={{
                                        backgroundColor: Colors.brandPrimary,
                                        color: 'white',
                                    }}
                                />
                            </div>
                        </div>
                    </Sidebar>
                </Sidebar>
            );
        }
        return (
            <Dialog
                active
                title="Loading..."
            >
                <ProgressBar type="linear" mode="indeterminate" />
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
    searchTerm: state.organization.searchTerm,
    organizations: state.organization.organizations,
    organizationTypes: state.organization.organizationTypes,
    clientPermissions: state.organization.clientPermissions,
    sortHeader: state.organization.sortHeader,
    sortDirection: state.organization.sortDirection,
    isAddOrganizationPanelOpen: state.organization.isAddOrganizationPanelOpen,
    isOrganizationDetailsPanelOpen: state.organization.isOrganizationDetailsPanelOpen,
});

export default connect(mapStateToProps, {
    addOrganization: _addOrganization,
    searchOrganizations: _searchOrganizations,
    clearSearch: _clearSearch,
    setSortParameters: _setSortParameters,
    setOrganizationEdit: _setOrganizationEdit,
    setSelectedOrganizationRow: _setSelectedOrganizationRow,
    setAddOrgPanel: _setAddOrgPanel,
    openOrgDetailsPanel: _openOrgDetailsPanel,
    closeOrgDetailsPanel: _closeOrgDetailsPanel,
    closeTemplatePreferencesPanel: _closeTemplatePreferencesPanel,
    closeClientPermissionsPanel: _closeClientPermissionsPanel,
    closeOrganizationWorkflowsPanel: _closeOrganizationWorkflowsPanel,
})(OrganizationsPage);
