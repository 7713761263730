// @flow

const itemSortCheck = (a, b, sortHeader, desc): boolean => {
    if (sortHeader === 'registrationTime' || sortHeader === 'lastLogin') {
        const convertedDateA = a === undefined ? new Date(1700, 1, 1) : new Date(a);
        const convertedDateB = b === undefined ? new Date(1700, 1, 1) : new Date(b);
        if (desc) {
            return convertedDateA < convertedDateB;
        }
        return convertedDateA > convertedDateB;
    }
    if (desc) {
        return a < b;
    }
    return a > b;
};

const sort = (content: Array<any>, sortDirection: string, sortHeader: string): Array<any> => {
    const sortDesc = sortDirection === 'desc';

    content.sort((a, b) => (
        (a[sortHeader] === null || a[sortHeader] === 'No Verified Email') ? 1
            : b[sortHeader] === null ? -1
                : itemSortCheck(a[sortHeader], b[sortHeader], sortHeader, sortDesc) ? 1
                    : -1
    ));

    return content;
};

export const immutableSort = (content: Array<any>, sortDirection: string, sortHeader: string): Array<any> => sort([...content], sortDirection, sortHeader);

export default sort;
