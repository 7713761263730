// @flow
import type { Saga } from 'redux-saga';
import { put } from 'redux-saga/effects';

import api from '../Services/Api';
import { apiFetch, apiPut } from './ApiSaga';
import { pushError } from '../Redux/ApplicationActions';
import {
    finishLoading,
    startLoading,
} from '../Redux/LoadingActions';
import type {
    UpdateiOSVersion,
    UpdateAndroidVersion,
} from '../Redux/ClientActions';
import {
    loadVersions,
    loadedVersions,
} from '../Redux/ClientActions';

export function* loadVersionsSaga(): Saga<void> {
    yield put(startLoading('versions'));

    const { result, error, } = yield apiFetch(api.txp.versions);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading versions failed, are you online?', error));
        } else {
            yield put(pushError('Loading versions failed, try again later', error));
        }
    } else {
        yield put(loadedVersions(result.ios_version, result.android_version, result.ios_deployment_time, result.android_deployment_time));
    }

    yield put(finishLoading('versions'));
}

export function* updateiOSVersion(action: UpdateiOSVersion): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateiOSVersion, null, {
        version: action.version,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating iOS version failed, are you online?', error));
        } else {
            yield put(pushError('Updating iOS version failed, try again later', error));
        }
    } else {
        yield put(loadVersions());
    }
}

export function* updateAndroidVersion(action: UpdateAndroidVersion): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateAndroidVersion, null, {
        version: action.version,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating Android version failed, are you online?', error));
        } else {
            yield put(pushError('Updating Android version failed, try again later', error));
        }
    } else {
        yield put(loadVersions());
    }
}
