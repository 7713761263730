// @flow

import Colors from '../../Themes/Colors';
import Fonts from '../../Themes/Fonts';

const PanelStyles = {
    button: {
        color: Colors.brandPrimary,
    },
    disabledButton: {
        backgroundColor: Colors.lighterGray,
    },
    optionButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 20,
        backgroundColor: Colors.white,
    },
    panelBodyContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
    },
    rowItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    primaryPanelButton: {
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        color: Colors.brandPrimary,
    },
    secondaryPanelButton: {
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        color: Colors.brandSecondary,
    },
    warningPanelButton: {
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        color: Colors.red,
    },
    cautionPanelButton: {
        width: '100%',
        textTransform: 'none',
        fontSize: 16,
        color: Colors.orange,
    },
    ValueInput: {
        border: 0,
        width: '100%',
        outline: 'none',
        ...Fonts.style.input,
    },
    ValueInputError: {
        width: '100%',
        outline: 'none',
        ...Fonts.style.input,
        border: '1px solid red',
        color: Colors.formError,
    },
    formError: {
        color: Colors.formError,
        fontSize: 12,
        marginTop: 2,
    },
    input: {
        fontSize: 25,
        textAlign: 'center',
        width: 75,
    },
    noBorder: {
        border: 'none',
    },
    inputPadding: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    inputPaddingAddUser: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
    },
    editablePadding: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    infoText: {
        ...Fonts.style.description,
        whiteSpace: 'break-spaces',
        display: 'inline-block',
    },

    table: {
        width: '100%',
        overflow: 'auto',
    },

    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: '#f2f2f2',
        },
    },

    closeButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: '1em',
        color: Colors.white,
        fontSize: 16,
        textTransform: 'none',
    },

    noWorkflows: {
        textAlign: 'center',
        marginTop: '20%',
        fontSize: 18,
        color: Colors.gray,
        fontStyle: 'italic',
    },
};

export default PanelStyles;
