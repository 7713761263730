import { call, put, select } from 'redux-saga/effects';
import { accessTokenExpired } from 'txp-core';


function* apiCall(resource, method, ...args) {
    let result = null;

    const token = yield select((state) => state.auth.accessToken);

    // Create new headers config because we might need to set token
    const headers = {
        Accept: 'application/json',
    };

    // Add Authorization header unless withCredentials is false for the resource
    if (token && resource.config().withCredentials) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        result = yield call([resource, method], ...args, {
            headers,
        });
    } catch (error) {
        return {
            error,
        };
    }

    return {
        result,
    };
}


export function* apiFetch(resource, kwargs, query) {
    const { result, error, } = yield call(apiCall, resource, 'fetch', kwargs, query);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiPost(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'post', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiDelete(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'del', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}

export function* apiPut(resource, kwargs, data, query, attachments) {
    const { result, error, } = yield call(apiCall, resource, 'put', kwargs, data, query, attachments);

    if (error && error.statusCode === 401) {
        yield put(accessTokenExpired());
    }

    return { result, error, };
}
