// @flow

import Fonts from './Fonts';
import Colors from './Colors';

const ApplicationStyles = {
    flex1: {
        flex: 1,
    },
    fullHeight: {
        height: '100%',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 30,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    flexSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    horizontalCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    verticalCenter: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    relativeVerticalCenter: {
        margin: 0,
        position: 'relative',
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    },
    textInputError: {
        color: Colors.formError,
    },
    infoButton: {
        fontSize: 18,
        paddingLeft: 5,
        paddingRight: 5,
        cursor: 'pointer',
    },
    closeButton: {
        fontSize: 18,
        float: 'right',
        color: Colors.lightGray,
        cursor: 'pointer',
    },
    button: {
        backgroundColor: Colors.buttonBackground,
        color: Colors.white,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    margin20: {
        margin: 20,
    },
    buttonInverse: {
        backgroundColor: Colors.white,
        color: Colors.buttonBackground,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    buttonLabel: {
        color: Colors.buttonText,
        backgroundColor: Colors.transparent,
    },
    buttonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
        borderWidth: 1,
        alignItems: 'center',
        textTransform: 'none',
    },
    titleText: {
        color: Colors.black,
        fontSize: 24,
        lineHeight: 29,
        marginBottom: 12,
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    textRight: {
        textAlign: 'right',
    },
    textCenterEmphasized: {
        textAlign: 'center',
        color: Colors.brandPrimary,
        fontWeight: 'bolder',
    },
    Loginh1: {
        ...Fonts.style.h1,
        color: Colors.black,
        textAlign: 'center',
    },
    column3: {
        columnCount: 3,
    },
    detailsDialog: {
        overflowY: 'scroll',
    },

    footerMessage: {
        textAlign: 'center',
        color: '#555555',
        padding: 15,
    },
    containerButton: {
        float: 'right',
    },
    greenIconButton: {
        color: Colors.green,
    },
    checkIconButton: {
        color: Colors.white,
        marginRight: 10,
    },

    clearIconButton: {
        color: Colors.white,
        backgroundColor: Colors.red,
    },

    rightRedIconButton: {
        color: Colors.red,
        float: 'right',
    },
    redIconButton: {
        color: Colors.red,
    },
    buttonLeft: {
        justifyContent: 'left',
        display: 'flex',
    },
    headerCenter: {
        ...Fonts.style.h4,
        paddingTop: 4,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    headerRight: {
        marginLeft: 'auto',
        paddingTop: 3,
    },
    noBull: {
        listStyleType: 'none',
        fontSize: Fonts.size.h5,
    },
    tableCell: {
        color: Colors.white,
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        height: 90,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        verticalAlign: 'middle',
    },
    clientTableCell: {
        width: 50,
    },
    addHeader: {
        color: Colors.white,
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        height: 100,
        textAlign: 'center',
        fontSize: 34,
        verticalAlign: 'middle',
        paddingTop: '53px',
        width: '20vw',
    },
    detailsHeader: {
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        fontSize: 18,
        color: Colors.white,
        minWidth: 300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sidebarDetailsHeader: {
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        fontSize: 18,
        color: Colors.white,
        minWidth: 300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 35,
        paddingBottom: 35,
    },
    userDetailsHeader: {
        backgroundImage: 'linear-gradient(to right, #2E3192, #00A79D)',
        fontSize: 18,
        color: Colors.white,
        minWidth: 300,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    },
    profilePicture: {
        borderRadius: '50%',
        backgroundColor: Colors.white,
        width: 100,
        height: 100,
    },
    disabledButton: {
        color: Colors.buttonTextDisabled,
        fontSize: 16,
        textTransform: 'none',
        alignItems: 'center',
    },
    saveButton: {
        color: Colors.blue,
        fontSize: 16,
        textTransform: 'none',
        alignItems: 'center',
    },
    cancelButton: {
        color: Colors.red,
        fontSize: 16,
        textTransform: 'none',
    },
    tableHeader: {
        color: Colors.gray,
        textAlign: 'center',
        fontSize: 16,
    },
    inputPadding: {
        paddingLeft: '1vw',
        paddingRight: '1vw',
    },
    marginTop: {
        marginTop: 'var(--header-height)',
    },
    internalHomePagePadding: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
    },
    pagePadding: {
        paddingTop: 80,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
    },
    chatroomTitle: {
        listStyleType: 'none',
        fontSize: Fonts.size.h5,
        textAlign: 'center',
        paddingRight: 30,
    },
    deleteButton: {
        backgroundColor: Colors.red,
        color: Colors.white,
    },
    resendEmailButton: {
        backgroundColor: Colors.brandSecondary,
        color: Colors.white,
    },
    resendEmailButtonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
    },
    tableCellButton: {
        outline: 0,
        border: 'none',
        backgroundColor: Colors.brandSecondary,
        color: Colors.white,
        fontSize: 14,
        fontWeight: 'bold',
        padding: 7,
        borderRadius: 2,
        cursor: 'pointer',
    },
    overlayWrapper: {
        width: '100%',
        height: '100%',
        position: 'Absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overlay: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        opacity: 0.3,
        position: 'Absolute',
    },
    whiteSpaceWrap: {
        whiteSpace: 'pre-wrap',
    },
    workflowSelection: {
        paddingLeft: 20,
        paddingRight: '30%',
    },
    mainTaskHeading: {
        paddingLeft: 20,
    },
    addMainTasks: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
        alignItems: 'center',
        padding: '0.5rem 0 0.5rem 1.5rem',
    },
    mainTaskItem: {
        marginRight: '70%',
    },
    noMainTasks: {
        color: Colors.gray,
        fontStyle: 'italic',
    },
    subtasks: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
    },
    editTaskFooter: {
        display: 'flex',
        textAlign: 'center',
        color: '#555555',
        padding: 15,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    workflowHeaderButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 20,
        paddingBottom: 20,
    },
};

export default ApplicationStyles;
