// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Table from 'react-toolbox/lib/table/Table';
import TableHead from 'react-toolbox/lib/table/TableHead';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import TCheckbox, { CheckboxProps } from 'react-toolbox/lib/checkbox';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';

import ApplicationStyles from '../Themes/ApplicationStyles';
import { getTableCellValue } from '../Utils/tableHelper';
import Colors from '../Themes/Colors';
import Fonts from '../Themes/Fonts';
import type {
    Organization,
    RegisteringUser,
    UserProfile,
    ActiveChatroom,
    ArchivedChatroom,
    Workflow,
    RefDataSurvey,
    Form,
    Researcher,
} from '../Utils/types';
import {
    setSelectedUserRow as _setSelectedUserRow,
    completeRegisteringUser as _completeRegisteringUser,
    deleteRegisteringUser as _deleteRegisteringUser,
    resendRegistrationEmail as _resendVerificationEmail,
    resendInvitationEmail as _resendInvitationEmail,
} from '../Redux/UserActions';
import {
    setSelectedReferenceDataRow as _setSelectedReferenceDataRow,
} from '../Redux/ReferenceDataActions';
import { setSelectedOrganizationRow as _setSelectedOrganizationRow } from '../Redux/OrganizationActions';
import { setSelectedChatroomRow as _setSelectedChatroomRow } from '../Redux/ChatroomActions';

const Checkbox = (props: CheckboxProps) => (
    <TCheckbox
        {...props}
        style={{ borderWidth: '0.124rem', }}
    />
);

type Props = {
    accessLevel: string,
    content: | Array<RegisteringUser>
            | Array<UserProfile>
            | Array<Organization>
            | Array<ActiveChatroom>
            | Array<ArchivedChatroom>
            | Array<Workflow>
            | Array<RefDataSurvey>
            | Array<Form>
            | Array<Researcher>,
    contentType: string,
    headers: Array<any>,
    resendEmailLoading: boolean,
    sortHeader: string,
    sortDirection: string,
    selectedUser: any,
    selectedOrganization: any,
    selectedChatroom: any,
    selectedReferenceData: any,
    multiSelect: boolean,
    resendInvitationLoading: boolean,

    onClickCheckbox: (checked: boolean, item: any) => *,
    onClickHeaderCheckbox: (checked: boolean, item: any[]) => *,
    isCheckboxChecked: (item: any) => boolean,
    isHeaderCheckboxChecked: (item: any[]) => boolean,
    onRowClick: (obj: any) => *,
    onRowClose: () => *,
    onHeaderClick: (sortHeader: string, sortDirection: string) => *,
    completeRegisteringUser: (userId: number) => *,
    deleteRegisteringUser: (userId: number) => *,
    resendVerificationEmail: (email: string) => *,
    resendInvitationEmail: (email: string) => *,
    setSelectedUserRow: (row: any) => *,
    setSelectedOrganizationRow: (row: any) => *,
    setSelectedChatroomRow: (row: any) => *,
    setSelectedReferenceDataRow: (row: any) => *,
};

type State = {
    offset: number,
};

class SortableTable extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            offset: 0,
        };
    }

    onClickRight = () => {
        const {
            offset,
        } = this.state;

        const {
            content,
        } = this.props;

        let vis = 0;
        for (let i = 0; i < content.length; i += 1) {
            if (this.props.content[i].visible) {
                vis += 1;
            }
        }
        if (!((content.length - offset) < 25) && !((content.length - offset) > 0)) {
            this.setState({
                offset: offset + 25,
            });
        }
        if (!((vis - offset) < 25)) {
            this.setState({
                offset: offset + 25,
            });
        }
        if (offset > vis) {
            this.setState({
                offset: 25,
            });
        }
    };

    onClickLeft = () => {
        const {
            offset,
        } = this.state;

        const {
            content,
        } = this.props;

        if (offset >= 25) {
            this.setState({
                offset: offset - 25,
            });
        }
        let vis = 0;
        for (let i = 0; i < content.length; i += 1) {
            if (this.props.content[i].visible) {
                vis += 1;
            }
        }
        if (offset > vis) {
            this.setState({
                offset: 0,
            });
        }
    };

    onSelectRow = (row: any) => {
        const {
            onRowClick,
            setSelectedUserRow,
            setSelectedOrganizationRow,
            setSelectedChatroomRow,
            setSelectedReferenceDataRow,
        } = this.props;

        if (row.userId) {
            setSelectedUserRow(row);
        } else if (row.organizationId) {
            setSelectedOrganizationRow(row);
        } else if (row.chatroomId) {
            setSelectedChatroomRow(row);
        } else if (row.key) {
            setSelectedReferenceDataRow(row);
        }

        onRowClick(row);
    };

    onCompleteRegUser = (user: any) => {
        const {
            completeRegisteringUser,
        } = this.props;

        const response = window.confirm(
            `Are you sure you want to bypass the phone and email verification process and complete the registration process for user ${user.email}?`
        );

        if (response) {
            if (user.userId) {
                completeRegisteringUser(user.userId);
            }
        }
    };

    onDeleteRegUser = (user: any) => {
        const {
            deleteRegisteringUser,
        } = this.props;

        const response = window.confirm(`Are you sure you want to delete ${user.email
        } as a registering user? This action cannot be undone.`);

        if (response) {
            if (user.userId) {
                deleteRegisteringUser(user.userId);
            }
        }
    };

    onResendEmail = (user: any) => {
        const {
            resendVerificationEmail,
        } = this.props;

        if (user.email) {
            const response = window.confirm(`Are you sure you want to resend the registration email to ${user.email}?`);
            if (response) {
                resendVerificationEmail(user.email);
            }
        }
    };

    onResendInvitationEmail = (user: any) => {
        const {
            resendInvitationEmail,
        } = this.props;
        if (user.email) {
            const response = window.confirm(`Are you sure you want to resend an invitation email to ${user.email}?`);
            if (response) {
                resendInvitationEmail(user.email);
            }
        }
    };

    onUnselectRow = (row: any) => {
        const {
            setSelectedUserRow,
            setSelectedOrganizationRow,
            setSelectedChatroomRow,
            setSelectedReferenceDataRow,
            onRowClose,
        } = this.props;

        if (row.userId) {
            setSelectedUserRow(-1);
        } else if (row.organizationId) {
            setSelectedOrganizationRow(-1);
        } else if (row.chatroomId) {
            setSelectedChatroomRow(-1);
        } else if (row.key) {
            setSelectedReferenceDataRow(-1);
        }
        onRowClose();
    };

    getCurrentId = (row: any) => {
        const {
            contentType,
        } = this.props;

        if (!row) {
            return 0;
        }

        if (contentType === 'reference data') {
            return row.key || '';
        }

        if (contentType === 'organizations') {
            return row.organizationId || 0;
        }

        if (contentType === 'archived chatrooms' || contentType === 'active chatrooms') {
            return row.chatroomId || 0;
        }

        if (contentType === 'basic users' || contentType === 'pending members'
            || contentType === 'organization members' || contentType === 'demo users') {
            return row.userId || 0;
        }

        return 0;
    };

    getSelectedId = () => {
        const {
            contentType,
            selectedUser,
            selectedOrganization,
            selectedChatroom,
            selectedReferenceData,
        } = this.props;

        if (contentType === 'organizations' && selectedOrganization) {
            return selectedOrganization.organizationId || 0;
        }

        if ((contentType === 'archived chatrooms' || contentType === 'active chatrooms') && selectedChatroom) {
            return selectedChatroom.chatroomId || 0;
        }

        if ((contentType === 'basic users' || contentType === 'pending members'
            || contentType === 'organization members' || contentType === 'demo users') && selectedUser) {
            return selectedUser.userId || 0;
        }

        if ((contentType === 'reference data' && selectedReferenceData)) {
            return selectedReferenceData.key || 0;
        }

        return 0;
    };

    createContentRows = () => {
        const {
            accessLevel,
            contentType,
            headers,
            content,
            multiSelect,
            onClickCheckbox,
            isCheckboxChecked,
            resendEmailLoading,
            resendInvitationLoading,
        } = this.props;

        const selectedId = this.getSelectedId();

        const isClickable = ['registering users', 'archived users', 'invited users'].indexOf(contentType) < 0;

        const contentRows = [];
        let visibleCount = 0;

        let offsetInFunc = this.state.offset;

        let vis = 0;
        let nonVis = 0;
        let j = 0;

        while (vis < offsetInFunc && j < content.length) {
            if (content[j].visible) {
                vis += 1;
            } else {
                nonVis += 1;
            }
            j += 1;
        }

        if (offsetInFunc > vis) {
            offsetInFunc = 0;
        } else {
            offsetInFunc = vis + nonVis;
        }

        for (let i = offsetInFunc; i < content.length; i += 1) {
            if (visibleCount < 25) {
                if (content[i].visible) {
                    visibleCount += 1;

                    const currentId = this.getCurrentId(content[i]);

                    contentRows.push(
                        <TableRow
                            key={i.toString()}
                            style={isClickable
                                ? { cursor: 'pointer', backgroundColor: selectedId === currentId ? Colors.brandSecondary : null, }
                                : { cursor: 'default', backgroundColor: Colors.white, }}
                        >
                            {
                                multiSelect ? (
                                    <TableCell className="sortable-table-multiselect">
                                        <Checkbox
                                            checked={isCheckboxChecked(content[i])}
                                            onChange={(checked) => onClickCheckbox(checked, content[i])}
                                        />
                                    </TableCell>
                                ) : null
                            }

                            {headers.map((header, headerIndex) => {
                                const isHidden = (header.isInternal && accessLevel !== 'SysAdmin' && accessLevel !== 'Sales');

                                if (header.isInvisible || header.isInvisibleInTable || isHidden) {
                                    return null;
                                }

                                return (
                                    <TableCell
                                        key={(i + headerIndex).toString()}
                                        onClick={
                                            isClickable
                                                ? (selectedId === currentId
                                                    ? () => this.onUnselectRow(content[i])
                                                    : () => this.onSelectRow(content[i])
                                                )
                                                : null
                                        }
                                    >
                                        {getTableCellValue(content[i], header)}
                                    </TableCell>
                                );
                            }, this)}
                            {contentType === 'registering users'
                                ? (
                                    <TableCell>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', }}>
                                            <Button
                                                type="button"
                                                raised
                                                primary
                                                onClick={() => this.onCompleteRegUser(content[i])}
                                                label="Complete"
                                            />

                                            <Button
                                                type="button"
                                                // In this case, we know that we are looking at registering users, so our content type is UserRegistration,
                                                // we take advantage of that fact by adjusting button state. To do that, we need to tell flow we know what we
                                                // are doing here and that our content type DOES have a isPhoneVerified property, so we use the flow fix me
                                                // annotation to tell flow to ignore it
                                                // $FlowFixMe: In registering uses contentType, content is UserRegistration so tell Flow we know that
                                                style={(!content[i].isPhoneVerified || resendEmailLoading)
                                                    ? ApplicationStyles.resendEmailButtonDisabled : ApplicationStyles.resendEmailButton}
                                                onClick={() => this.onResendEmail(content[i])}
                                                // $FlowFixMe: In registering uses contentType, content is UserRegistration so tell Flow we know that
                                                disabled={!content[i].isPhoneVerified || resendEmailLoading}
                                                label="Resend Email"
                                            />

                                            <Button
                                                type="button"
                                                style={ApplicationStyles.deleteButton}
                                                onClick={() => this.onDeleteRegUser(content[i])}
                                                label="Delete User"
                                            />
                                        </div>
                                    </TableCell>
                                ) : null}
                            {contentType === 'invited users'
                                ? (
                                    <TableCell
                                        style={ApplicationStyles.textRight}
                                    >
                                        <Button
                                            type="button"
                                            style={resendInvitationLoading
                                                ? ApplicationStyles.resendEmailButtonDisabled : ApplicationStyles.resendEmailButton}
                                            onClick={() => this.onResendInvitationEmail(content[i])}
                                            disabled={resendInvitationLoading}
                                            label="Resend Invite"
                                        />
                                    </TableCell>
                                ) : null}
                        </TableRow>
                    );
                }
            }
        }
        return contentRows;
    };

    render() {
        const {
            accessLevel,
            content,
            contentType,
            headers,
            sortHeader,
            sortDirection,
            multiSelect,
            onClickHeaderCheckbox,
            isHeaderCheckboxChecked,
            onHeaderClick,
        } = this.props;

        const {
            offset,
        } = this.state;

        const contentRowsArray = this.createContentRows();

        if (content.length > 0) {
            const tableHeaders = headers.map((header, index) => {
                const isHidden = (header.isInternal && accessLevel !== 'SysAdmin' && accessLevel !== 'Sales');

                if (header.isInvisible || header.isInvisibleInTable || isHidden) {
                    return null;
                }

                return (
                    <TableCell
                        key={index.toString()}
                        sorted={sortHeader === header.dataKey ? sortDirection : null}
                        onClick={() => onHeaderClick(
                            header.dataKey,
                            (sortHeader === header.dataKey && sortDirection === 'asc') ? 'desc' : 'asc'
                        )}
                        style={{
                            color: Colors.gray,
                            fontSize: Fonts.size.h5,
                        }}
                    >
                        {header.title}
                    </TableCell>
                );
            }, this);

            // $FlowFixMe
            const visibleContent = content.filter((item) => item.visible);
            const totalRows = visibleContent.length;
            const offsetSearch = offset > totalRows ? 0 : offset;
            const displayFirstNumber = offsetSearch + 1;
            const displayLastNumber = offsetSearch + contentRowsArray.length;

            const footerMessage = (displayLastNumber > 0) ? (
                <div style={ApplicationStyles.footerMessage}>
                    <Button
                        style={{ minWidth: 75, visibility: (displayFirstNumber > 1 ? 'visible' : 'hidden'), }}
                        onClick={() => this.onClickLeft()}
                        primary
                        raised
                    >
                        <FontIcon value="keyboard_arrow_left" />
                    </Button>
                    <span style={{ paddingLeft: 75, paddingRight: 75, }}>
                        {' '}
                        Displaying
                        {' '}
                        {contentType}
                        {' '}
                        {displayFirstNumber}
                        -
                        {displayLastNumber}
                        {' '}
                        out of
                        {' '}
                        {totalRows}
                        {' '}
                    </span>
                    <Button
                        style={{ minWidth: 75, visibility: (totalRows > displayLastNumber ? 'visible' : 'hidden'), }}
                        onClick={() => this.onClickRight()}
                        primary
                        raised
                    >
                        <FontIcon value="keyboard_arrow_right" />
                    </Button>
                </div>
            ) : (
                <p style={ApplicationStyles.footerMessage}>
                    No
                    {' '}
                    {contentType}
                    {' '}
                    match the criteria
                </p>
            );

            return (
                <div>
                    <Table selectable={false} id="table">
                        <TableHead>
                            {
                                multiSelect && totalRows > 0 ? (
                                    <TableCell className="sortable-table-multiselect">
                                        <Checkbox
                                            checked={isHeaderCheckboxChecked(visibleContent)}
                                            onChange={(checked) => onClickHeaderCheckbox(checked, visibleContent)}
                                        />
                                    </TableCell>
                                ) : null
                            }
                            {tableHeaders}
                        </TableHead>
                        {contentRowsArray}
                    </Table>
                    {footerMessage}
                </div>
            );
        }

        const verb = contentType === 'reference data' ? 'is' : 'are';
        return (
            <h4 style={ApplicationStyles.footerMessage}>
                There
                {` ${verb} `}
                no
                {' '}
                {contentType}
                {' '}
                to be displayed.
            </h4>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedUser: state.user.selectedUser,
    selectedOrganization: state.organization.selectedOrganization,
    selectedChatroom: state.chatroom.selectedChatroom,
    selectedReferenceData: state.reference.selectedReferenceData,
    resendEmailLoading: state.loading.resendEmail,
    resendInvitationLoading: state.loading.resendInvitation,
});

export default connect(mapStateToProps, {
    completeRegisteringUser: _completeRegisteringUser,
    deleteRegisteringUser: _deleteRegisteringUser,
    resendVerificationEmail: _resendVerificationEmail,
    resendInvitationEmail: _resendInvitationEmail,
    setSelectedUserRow: _setSelectedUserRow,
    setSelectedOrganizationRow: _setSelectedOrganizationRow,
    setSelectedChatroomRow: _setSelectedChatroomRow,
    setSelectedReferenceDataRow: _setSelectedReferenceDataRow,
})(SortableTable);
