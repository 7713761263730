// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ProgressBar from 'react-toolbox/lib/progress_bar/ProgressBar';
import SortableTable from '../SortableTable';
import { onClosePanel } from '../../Utils/panels';
import { objectArrayToDictionary } from '../../Utils/arrays';
import { hasOwnProperty } from '../../Utils/Object';
import {
    setSortParameters as _setSortParameters,
    setUserEdit as _setUserEdit,
    setAddUserOrgEdit as _setAddUserOrgEdit,
    toggleUserDetailsPanel as _toggleUserDetailsPanel,
    toggleAddUserToOrganizationPanel as _toggleAddUserToOrganizationPanel,
    toggleCreateDemoUserPanel as _toggleCreateDemoUserPanel,
    setBulkEditUsers as _setBulkEditUsers,
    addBulkEditUsers as _addBulkEditUsers,
    removeBulkEditUsers as _removeBulkEditUsers,
} from '../../Redux/UserActions';
import type { UserProfile } from '../../Utils/types';

type Props = {
    users: Array<UserProfile>,
    usersLoaded?: boolean,
    headers: Array<any>,
    contentType: String,
    multiSelect: boolean,

    profile: UserProfile,
    searchTerm: string,
    sortHeader: string,
    sortDirection: string,
    bulkEditUsers: Array<UserProfile>,
    bulkEditUsersObj: {[string]: UserProfile},

    setBulkEditUsers: (users: Array<UserProfile>) => *,
    addBulkEditUsers: (users: Array<UserProfile>) => *,
    removeBulkEditUsers: (users: Array<UserProfile>) => *,
    setSortParameters: (sortHeader: string, sortDirection: string) => *,
    setUserEdit: (userId: number) => *,
    setAddUserOrgEdit: (user: UserProfile) => *,
    toggleUserDetailsPanel: (isUserDetailsPanelOpen: boolean) => *,
    toggleAddUserToOrganizationPanel: (isAddUserToOrganizationPanelOpen: boolean) => *,
    toggleCreateDemoUserPanel: (isCreateDemoUserPanelOpen: boolean) => *,
};

class UserTable extends PureComponent<Props> {
    /* eslint-disable-next-line react/static-property-placement */
    static defaultProps = {
        usersLoaded: false,
    };

    componentWillUnmount() {
        this.props.setBulkEditUsers([]);
    }

    onSetSortParameters = (sortHeader, sortDirection) => {
        if (sortHeader && sortDirection) {
            this.props.setSortParameters(sortHeader, sortDirection);
        }
    };

    onUserRowClick = async (user: UserProfile) => {
        this.props.setUserEdit(user.userId);
        this.props.setAddUserOrgEdit(user);
        this.props.toggleUserDetailsPanel(true);
        this.props.toggleAddUserToOrganizationPanel(false);
        this.props.toggleCreateDemoUserPanel(false);
        this.props.setBulkEditUsers([]);
    };

    handleClickAllUsers = (checked: boolean, users: UserProfile[]) => {
        this.props.setBulkEditUsers(checked ? users : []);
    };

    handleClickUser = (checked: boolean, user: UserProfile) => {
        if (checked) {
            this.props.addBulkEditUsers([user]);
        } else {
            this.props.removeBulkEditUsers([user]);
        }
    };

    isUserInBulkEdit = (user: UserProfile) => hasOwnProperty(this.props.bulkEditUsersObj, user.userId);

    areAllUsersInBulkEdit = (users: UserProfile[]) => {
        const { bulkEditUsers, } = this.props;
        return bulkEditUsers.length > 0 && bulkEditUsers.length === users.length;
    }

    render() {
        const {
            users,
            usersLoaded,
            bulkEditUsers,
            headers,
            contentType,
            multiSelect,
            profile,
            searchTerm,
            sortHeader,
            sortDirection,
        } = this.props;

        return (usersLoaded
            ? (
                <SortableTable
                    multiSelect={multiSelect}
                    multiSelectContent={bulkEditUsers}
                    onClickCheckbox={this.handleClickUser}
                    isCheckboxChecked={this.isUserInBulkEdit}
                    onClickHeaderCheckbox={this.handleClickAllUsers}
                    isHeaderCheckboxChecked={this.areAllUsersInBulkEdit}
                    accessLevel={profile.accessLevel || ''}
                    searchTerm={searchTerm}
                    content={users}
                    headers={headers}
                    sortDirection={sortDirection}
                    sortHeader={sortHeader}
                    contentType={contentType}
                    onRowClick={this.onUserRowClick}
                    onRowClose={onClosePanel}
                    onHeaderClick={this.onSetSortParameters}
                />
            )

            : (
                <div>
                    <p>Loading...</p>
                    <ProgressBar type="linear" mode="indeterminate" />
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
    searchTerm: state.user.searchTerm,
    sortHeader: state.user.sortHeader,
    sortDirection: state.user.sortDirection,
    bulkEditUsers: state.user.bulkEditUsers,
    bulkEditUsersObj: objectArrayToDictionary('userId', state.user.bulkEditUsers),
});

export default connect(mapStateToProps, {
    setSortParameters: _setSortParameters,
    setUserEdit: _setUserEdit,
    setAddUserOrgEdit: _setAddUserOrgEdit,
    toggleUserDetailsPanel: _toggleUserDetailsPanel,
    toggleAddUserToOrganizationPanel: _toggleAddUserToOrganizationPanel,
    toggleCreateDemoUserPanel: _toggleCreateDemoUserPanel,
    setBulkEditUsers: _setBulkEditUsers,
    addBulkEditUsers: _addBulkEditUsers,
    removeBulkEditUsers: _removeBulkEditUsers,
})(UserTable);
