// @flow
import type { Logout } from 'txp-core';

import type { CDSFileType } from '../Utils/types';

type OfferState = {
    fileType: ?CDSFileType,
    fileName: ?string,

    error: ?string,
};

const initialState: OfferState = {
    fileType: null,
    fileName: null,

    error: null,
};

export type SetFileType = { type: 'Offer/FILE_TYPE', fileType: CDSFileType };
export type SetFileName = { type: 'Offer/FILE_NAME', fileName: string };

export type DownloadFile = { type: 'Offer/FILE_DOWNLOAD', fileType: CDSFileType, fileName: string };
export type DownloadFileSuccess = { type: 'Offer/FILE_DOWNLOAD_SUCCESS' };
export type DownloadFileFailed = { type: 'Offer/FILE_DOWNLOAD_FAILED', error: string };

export type ResetCDSDownload = { type: 'Offer/RESET' };

type Action =
    | SetFileType
    | SetFileName
    | DownloadFile
    | DownloadFileSuccess
    | DownloadFileFailed
    | ResetCDSDownload
    | Logout;

export const setFileType = (fileType: CDSFileType): SetFileType => ({
    type: 'Offer/FILE_TYPE',
    fileType,
});

export const setFileName = (fileName: string): SetFileName => ({
    type: 'Offer/FILE_NAME',
    fileName,
});

export const downloadFile = (fileType: CDSFileType, fileName: string): DownloadFile => ({
    type: 'Offer/FILE_DOWNLOAD',
    fileType,
    fileName,
});

export const downloadFileSuccess = (): DownloadFileSuccess => ({
    type: 'Offer/FILE_DOWNLOAD_SUCCESS',
});

export const downloadFileFailed = (error: string): DownloadFileFailed => ({
    type: 'Offer/FILE_DOWNLOAD_FAILED',
    error,
});

export const resetCDSDownload = (): ResetCDSDownload => ({
    type: 'Offer/RESET',
});

const reducer = (state: OfferState = initialState, action: Action): OfferState => {
    switch (action.type) {
        case 'Auth/LOGOUT': {
            return {
                ...initialState,
            };
        }
        case 'Offer/FILE_TYPE':
            return {
                ...state,

                fileType: action.fileType,
            };

        case 'Offer/FILE_NAME':
            return {
                ...state,

                fileName: action.fileName,
            };

        case 'Offer/FILE_DOWNLOAD_SUCCESS':
            return {
                ...state,

                error: initialState.error,
                fileType: initialState.fileType,
                fileName: initialState.fileName,
            };

        case 'Offer/FILE_DOWNLOAD_FAILED':
            return {
                ...state,

                error: action.error,
            };

        case 'Offer/RESET':
            return {
                ...state,

                error: initialState.error,
                fileType: initialState.fileType,
                fileName: initialState.fileName,
            };

        default:
            return state;
    }
};

export default reducer;
