// @flow
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import 'react-tabs/style/react-tabs.css';
import Sidebar from 'react-sidebar';

import ApplicationStyles from '../../Themes/ApplicationStyles';
import SagaMessage from '../SagaMessage';
import {
    searchUsers as _searchUsers,
} from '../../Redux/UserActions';

type Props = {
    sidebar: Node,
    children: Node,

    searchTerm: string,
    searchUsers: (searchTerm: string, searchHeaders: Array<string>) => *,

    isUserDetailsPanelOpen: boolean,
};

const genericSagaToast: number = 10007;

class UserPanel extends PureComponent<Props> {
    onSearch = (searchTerm) => {
        const { searchUsers, } = this.props;

        searchUsers(searchTerm, [
            'userId',
            'email',
            'emailUpdate',
            'firstName',
            'lastName',
            'phone',
            'registrationTime',
            'organizationName',
            'role',
            'accessLevel',
            'lastLogin',
            'oktaId',
            'factorId'
        ]);
    };

    render() {
        const {
            sidebar,
            children,
            searchTerm,
            isUserDetailsPanelOpen,
        } = this.props;

        return (
            <Sidebar
                sidebar={sidebar}
                open={isUserDetailsPanelOpen}
                docked={isUserDetailsPanelOpen}
                pullRight
                sidebarClassName="rightSidebar"
                shadow={false}
                styles={{
                    sidebar: {
                        background: 'white',
                        marginTop: 'var(--header-height)',
                        zIndex: 1,
                    },
                }}
            >
                <div style={ApplicationStyles.pagePadding}>
                    <SagaMessage toastId={genericSagaToast} />
                    <div>
                        <Input
                            type="text"
                            label="Search"
                            value={searchTerm}
                            name="search"
                            icon="search"
                            onChange={this.onSearch}
                        />
                        {children}
                    </div>
                </div>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => ({
    searchTerm: state.user.searchTerm,
    isUserDetailsPanelOpen: state.user.isUserDetailsPanelOpen,
});

export default connect(mapStateToProps, {
    searchUsers: _searchUsers,
})(UserPanel);
