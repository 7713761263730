// @flow
import type { Logout } from 'txp-core';

import search from '../Utils/search';
import sort from '../Utils/sort';
import type { Organization, UserActivityMap, BusinessMetrics } from '../Utils/types';

type OrganizationState = {
    searchTerm: string,
    organizations: Array<Organization>,
    clientPermissions: Array<string>,
    templateOptions: any,
    businessMetrics: Object,
    quarterBMetricsLoaded: boolean,
    quarterAMetricsLoaded: boolean,
    businessMetricsLoaded: boolean,
    sortHeader: string,
    sortDirection: string,
    organizationEdit: ?Organization,
    organizationEditChanged: boolean,
    isAddOrganizationPanelOpen: boolean,
    isOrganizationDetailsPanelOpen: boolean,
    isTemplatePreferencesPanelOpen: boolean,
    isClientPermissionsPanelOpen: boolean,
    isOrganizationWorkflowsPanelOpen: boolean,
    organizationId: number,
    quarterString: string,
    quarterA: string,
    quarterB: string,
    metricsCache: Object,
    typeDropdownKey: number,
    licenseDropdownKey: number,
    selectedOrganization: any,
};

const initialState: OrganizationState = {
    searchTerm: '',
    organizations: [],
    clientPermissions: [],
    templateOptions: {},
    homeOrganizationMetrics: {},
    organizationUserActivity: {},
    businessMetrics: {},
    quarterBMetricsLoaded: false,
    quarterAMetricsLoaded: false,
    businessMetricsLoaded: false,
    organizationUserActivityLoaded: false,
    sortHeader: 'organizationId',
    sortDirection: 'asc',
    setOrganizationEdit: false,
    organizationEdit: null,
    organizationEditChanged: false,
    isAddOrganizationPanelOpen: false,
    isOrganizationDetailsPanelOpen: false,
    isTemplatePreferencesPanelOpen: false,
    isClientPermissionsPanelOpen: false,
    isOrganizationWorkflowsPanelOpen: false,
    organizationId: -1,
    quarterString: '',
    quarterA: '',
    quarterB: '',
    metricsCache: {},
    typeDropdownKey: 0,
    licenseDropdownKey: 2,
    selectedOrganization: null,
};

export type LoadOrganizations = { type: 'Organizations/LOAD' };
export type LoadedOrganizations = { type: 'Organizations/LOADED', organizations: Array<Organization>, };
export type SetSortParameters = {
    type: 'Organizations/SET_SORT_PARAMETERS',
    sortHeader: string,
    sortDirection: string,
};
export type LoadOrganizationTypes = { type: 'Organizations/LOAD_TYPES' };
export type LoadedOrganizationTypes = { type: 'Organizations/LOADED_TYPES', organizationTypes: Array<string>, };
export type LoadTemplateOptions = { type: 'Organizations/LOAD_TEMPLATE_OPTIONS', };
export type LoadedTemplateOptions = { type: 'Organizations/LOADED_TEMPLATE_OPTIONS', templateOptions: any, };
export type LoadedBusinessMetrics = { type: 'Organizations/LOADED_BUSINESS_METRICS', metrics: Object, };
export type LoadedOrganizationMetrics = { type: 'Organizations/LOADED_METRICS', metrics: Object, organizationId: number, quarter: string, };
export type SetOrganizationMetricsComplete = { type: 'Organizations/SET_ORG_METRICS_COMPLETE', quarterId: string, };
export type LoadedHomeOrganizationMetrics = { type: 'Organizations/LOADED_HOME_METRICS', metrics: Object, period: string, start: string, end: string, };
export type LoadedOrganizationUserActivity = { type: 'Organizations/LOADED_ORG_USER_ACTIVITY_METRICS', metrics: UserActivityMap, };
export type AddOrganization = { type: 'Organizations/ADD', organizationName: string, organizationCode: string, organizationType: string, };
export type UpdateOrganization = {
    type: 'Organizations/UPDATE',
    organizationId: number,
    organizationName: string,
    organizationCode: string,
    organizationType: string,
    clientPermissions: any,
    templatePreferences: any,
};
export type UpdateOrganizationLicense = {
    type: 'Organizations/UPDATE_LICENSE',
    organizationId: number,
    license: string,
};
export type DeleteOrganization = { type: 'Organizations/DELETE', organizationId: number, };
export type RemoveAllMembers = { type: 'Organizations/REMOVE_ALL_MEMBERS', organizationId: number, };
export type OrganizationMetrics = { type: 'Organizations/ORGANIZATION_METRICS', organizationId: number, quarterA: string, quarterB: string };
export type LoadHomeOrganizationMetrics = { type: 'Organizations/LOAD_HOME_ORGANIZATION_METRICS', organizationId: number, };
export type OrganizationUserActivity = { type: 'Organizations/ORGANIZATION_USER_ACTIVITY', organizationId: number, };
export type LoadBusinessMetrics = { type: 'Organizations/BUSINESS_METRICS', };
export type SearchOrganizations = { type: 'Organizations/SEARCH', searchTerm: string, searchHeaders: Array<string> };
export type ClearSearch = { type: 'Organizations/CLEAR_SEARCH', };
export type ResetMetrics = { type: 'Organizations/RESET_METRICS', organizationId: number, quarterA: string, quarterB: string };
export type SetOrganizationEdit = { type: 'Organizations/SET_ORGANIZATION_EDIT', organizationId: number, };
export type UpdateOrganizationEdit = { type: 'Organizations/UPDATE_ORGANIZATION_EDIT', organization: Organization };
export type SetOrgEditStatus = { type: 'Organizations/SET_ORG_EDIT_STATUS', organizationEditChanged: boolean };
export type SetAddOrgPanel = { type: 'Organizations/SET_ADD_ORG_PANEL', isAddOrganizationPanelOpen: boolean };
export type SetOrgDetailsPanel = { type: 'Organizations/SET_ORG_DETAILS_PANEL', isOrganizationDetailsPanelOpen: boolean };
export type ToggleTypeDropdownKey = { type: 'Organizations/TOGGLE_TYPE_DROPDOWN_KEY' };
export type ToggleLicenseDropdownKey = { type: 'Organizations/TOGGLE_LICENSE_DROPDOWN_KEY' };
export type OpenOrgDetailsPanel = { type: 'Organizations/OPEN_ORG_DETAILS_PANEL' };
export type CloseOrgDetailsPanel = { type: 'Organizations/CLOSE_ORG_DETAILS_PANEL' };
export type SetSelectedOrganizationRow = { type: 'Organizations/SET_SELECTED_ORGANIZATION_ROW', row: any };
export type OpenTemplatePreferencesPanel = { type: 'Organizations/OPEN_TEMPLATE_PREFERENCES_PANEL' };
export type CloseTemplatePreferencesPanel = { type: 'Organizations/CLOSE_TEMPLATE_PREFERENCES_PANEL' };
export type OpenClientPermissionsPanel = { type: 'Organizations/OPEN_CLIENT_PERMISSIONS_PANEL' };
export type CloseClientPermissionsPanel = { type: 'Organizations/CLOSE_CLIENT_PERMISSIONS_PANEL' };
export type OpenOrganizationWorkflowsPanel = { type: 'Organizations/OPEN_ORGANIZATION_WORKFLOWS_PANEL' };
export type CloseOrganizationWorkflowsPanel = { type: 'Organizations/CLOSE_ORGANIZATION_WORKFLOWS_PANEL' };

type Action =
    | Logout
    | LoadOrganizations
    | LoadedOrganizations
    | SetSortParameters
    | LoadedOrganizationMetrics
    | LoadedBusinessMetrics
    | LoadedHomeOrganizationMetrics
    | LoadedOrganizationUserActivity
    | LoadOrganizationTypes
    | LoadedOrganizationTypes
    | LoadTemplateOptions
    | LoadedTemplateOptions
    | LoadBusinessMetrics
    | AddOrganization
    | UpdateOrganization
    | UpdateOrganizationLicense
    | DeleteOrganization
    | RemoveAllMembers
    | OrganizationMetrics
    | LoadHomeOrganizationMetrics
    | OrganizationUserActivity
    | SearchOrganizations
    | ClearSearch
    | ResetMetrics
    | SetOrganizationEdit
    | UpdateOrganizationEdit
    | SetOrganizationMetricsComplete
    | SetOrgEditStatus
    | SetAddOrgPanel
    | SetOrgDetailsPanel
    | ToggleTypeDropdownKey
    | ToggleLicenseDropdownKey
    | OpenOrgDetailsPanel
    | CloseOrgDetailsPanel
    | SetSelectedOrganizationRow
    | OpenTemplatePreferencesPanel
    | CloseTemplatePreferencesPanel
    | OpenClientPermissionsPanel
    | CloseClientPermissionsPanel
    | OpenOrganizationWorkflowsPanel
    | CloseOrganizationWorkflowsPanel;

export const loadOrganizations = (): LoadOrganizations => ({
    type: 'Organizations/LOAD',
});

export const loadedOrganizations = (organizations: Array<Organization>): LoadedOrganizations => ({
    type: 'Organizations/LOADED',
    organizations,
});

export const setSortParameters = (
    sortHeader: string,
    sortDirection: string
): SetSortParameters => ({
    type: 'Organizations/SET_SORT_PARAMETERS',
    sortHeader,
    sortDirection,
});

export const loadOrganizationTypes = (): LoadOrganizationTypes => ({
    type: 'Organizations/LOAD_TYPES',
});

export const loadedOrganizationTypes = (organizationTypes: Array<string>): LoadedOrganizationTypes => ({
    type: 'Organizations/LOADED_TYPES',
    organizationTypes,
});

export const loadTemplateOptions = (): LoadTemplateOptions => ({
    type: 'Organizations/LOAD_TEMPLATE_OPTIONS',
});

export const loadedTemplateOptions = (
    templateOptions: any
): LoadedTemplateOptions => ({
    type: 'Organizations/LOADED_TEMPLATE_OPTIONS',
    templateOptions,
});

export const loadedOrganizationMetrics = (metrics: Object, organizationId: number, quarter: string): LoadedOrganizationMetrics => ({
    type: 'Organizations/LOADED_METRICS',
    metrics,
    organizationId,
    quarter,
});

export const setOrganizationMetricsComplete = (quarterId: string): SetOrganizationMetricsComplete => ({
    type: 'Organizations/SET_ORG_METRICS_COMPLETE',
    quarterId,
});

export const loadedHomeOrganizationMetrics = (metrics: Object, period: string, start: string, end: string): LoadedHomeOrganizationMetrics => ({
    type: 'Organizations/LOADED_HOME_METRICS',
    metrics,
    period,
    start,
    end,
});

export const loadedOrganizationUserActivity = (metrics: UserActivityMap): LoadedOrganizationUserActivity => ({
    type: 'Organizations/LOADED_ORG_USER_ACTIVITY_METRICS',
    metrics,
});

export const loadedBusinessMetrics = (metrics: BusinessMetrics): LoadedBusinessMetrics => ({
    type: 'Organizations/LOADED_BUSINESS_METRICS',
    metrics,
});

export const addOrganization = (organizationName: string, organizationCode: string, organizationType: string): AddOrganization => ({
    type: 'Organizations/ADD',
    organizationName,
    organizationCode,
    organizationType,
});

export const updateOrganization = (
    organizationId: number,
    organizationName: string,
    organizationCode: string,
    organizationType: string,
    clientPermissions: any,
    templatePreferences: any
): UpdateOrganization => ({
    type: 'Organizations/UPDATE',
    organizationId,
    organizationName,
    organizationCode,
    organizationType,
    clientPermissions,
    templatePreferences,
});

export const updateOrganizationLicense = (organizationId: number, license: string): UpdateOrganizationLicense => ({
    type: 'Organizations/UPDATE_LICENSE',
    organizationId,
    license,
});

export const deleteOrganization = (organizationId: number): DeleteOrganization => ({
    type: 'Organizations/DELETE',
    organizationId,
});

export const removeAllMembers = (organizationId: number): RemoveAllMembers => ({
    type: 'Organizations/REMOVE_ALL_MEMBERS',
    organizationId,
});

export const organizationMetrics = (organizationId: number, quarterA: string, quarterB: string): OrganizationMetrics => ({
    type: 'Organizations/ORGANIZATION_METRICS',
    organizationId,
    quarterA,
    quarterB,
});


export const loadHomeOrganizationMetrics = (organizationId: number): LoadHomeOrganizationMetrics => ({
    type: 'Organizations/LOAD_HOME_ORGANIZATION_METRICS',
    organizationId,
});

export const organizationUserActivity = (organizationId: number): OrganizationUserActivity => ({
    type: 'Organizations/ORGANIZATION_USER_ACTIVITY',
    organizationId,
});

export const loadBusinessMetrics = (): LoadBusinessMetrics => ({
    type: 'Organizations/BUSINESS_METRICS',
});


export const searchOrganizations = (searchTerm: string, searchHeaders: Array<string>): SearchOrganizations => ({
    type: 'Organizations/SEARCH',
    searchTerm,
    searchHeaders,
});

export const clearSearch = (): ClearSearch => ({
    type: 'Organizations/CLEAR_SEARCH',
});

export const resetMetrics = (organizationId: number, quarterA: string, quarterB: string): ResetMetrics => ({
    type: 'Organizations/RESET_METRICS',
    organizationId,
    quarterA,
    quarterB,
});

export const setOrganizationEdit = (organizationId: number): SetOrganizationEdit => ({
    type: 'Organizations/SET_ORGANIZATION_EDIT',
    organizationId,
});

export const updateOrganizationEdit = (organization: Organization): UpdateOrganizationEdit => ({
    type: 'Organizations/UPDATE_ORGANIZATION_EDIT',
    organization,
});

export const setOrgEditStatus = (organizationEditChanged: boolean): SetOrgEditStatus => ({
    type: 'Organizations/SET_ORG_EDIT_STATUS',
    organizationEditChanged,
});

export const setAddOrgPanel = (isAddOrganizationPanelOpen: boolean): SetAddOrgPanel => ({
    type: 'Organizations/SET_ADD_ORG_PANEL',
    isAddOrganizationPanelOpen,
});

export const openOrgDetailsPanel = (): OpenOrgDetailsPanel => ({
    type: 'Organizations/OPEN_ORG_DETAILS_PANEL',
});

export const closeOrgDetailsPanel = (): CloseOrgDetailsPanel => ({
    type: 'Organizations/CLOSE_ORG_DETAILS_PANEL',
});

export const toggleTypeDropdownKey = (): ToggleTypeDropdownKey => ({
    type: 'Organizations/TOGGLE_TYPE_DROPDOWN_KEY',
});

export const toggleLicenseDropdownKey = (): ToggleLicenseDropdownKey => ({
    type: 'Organizations/TOGGLE_LICENSE_DROPDOWN_KEY',
});

export const setSelectedOrganizationRow = (row: any): SetSelectedOrganizationRow => ({
    type: 'Organizations/SET_SELECTED_ORGANIZATION_ROW',
    row,
});

export const openTemplatePreferencesPanel = (): OpenTemplatePreferencesPanel => ({
    type: 'Organizations/OPEN_TEMPLATE_PREFERENCES_PANEL',
});

export const closeTemplatePreferencesPanel = (): CloseTemplatePreferencesPanel => ({
    type: 'Organizations/CLOSE_TEMPLATE_PREFERENCES_PANEL',
});

export const openClientPermissionsPanel = (): OpenClientPermissionsPanel => ({
    type: 'Organizations/OPEN_CLIENT_PERMISSIONS_PANEL',
});

export const closeClientPermissionsPanel = (): CloseClientPermissionsPanel => ({
    type: 'Organizations/CLOSE_CLIENT_PERMISSIONS_PANEL',
});

export const openOrganizationWorkflowsPanel = (): OpenOrganizationWorkflowsPanel => ({
    type: 'Organizations/OPEN_ORGANIZATION_WORKFLOWS_PANEL',
});

export const closeOrganizationWorkflowsPanel = (): CloseOrganizationWorkflowsPanel => ({
    type: 'Organizations/CLOSE_ORGANIZATION_WORKFLOWS_PANEL',
});

const reducer = (state: OrganizationState = initialState, action: Action): OrganizationState => {
    switch (action.type) {
        case 'Auth/LOGOUT': {
            return {
                ...initialState,
            };
        }

        case 'Organizations/LOADED': {
            return {
                ...state,

                organizations: sort(action.organizations, state.sortDirection, state.sortHeader),
            };
        }

        case 'Organizations/SET_SORT_PARAMETERS': {
            return {
                ...state,

                organizations: sort(state.organizations, action.sortDirection, action.sortHeader),

                sortHeader: action.sortHeader,
                sortDirection: action.sortDirection,
            };
        }

        case 'Organizations/LOADED_PERMISSIONS': {
            return {
                ...state,
                clientPermissions: action.clientPermissions,
            };
        }

        case 'Organizations/LOADED_METRICS': {
            const { metricsCache, } = state;

            if (metricsCache[action.organizationId] === undefined) {
                metricsCache[action.organizationId] = {};
            }
            metricsCache[action.organizationId][action.quarter] = action.metrics;

            return {
                ...state,
                metricsCache,
            };
        }

        case 'Organizations/LOADED_HOME_METRICS': {
            return {
                ...state,
                homeOrganizationMetrics: action.metrics,
            };
        }

        case 'Organizations/LOADED_ORG_USER_ACTIVITY_METRICS': {
            return {
                ...state,
                organizationUserActivityLoaded: true,
                organizationUserActivity: action.metrics,
            };
        }

        case 'Organizations/LOADED_BUSINESS_METRICS': {
            return {
                ...state,
                businessMetricsLoaded: true,
                businessMetrics: action.metrics,
            };
        }

        case 'Organizations/LOADED_TYPES': {
            return {
                ...state,
                organizationTypes: action.organizationTypes,
            };
        }

        case 'Organizations/LOADED_TEMPLATE_OPTIONS': {
            return {
                ...state,

                templateOptions: action.templateOptions,
            };
        }

        case 'Organizations/SEARCH': {
            return {
                ...state,

                searchTerm: action.searchTerm,
                organizations: search(state.organizations, action.searchTerm, action.searchHeaders),
            };
        }

        case 'Organizations/CLEAR_SEARCH': {
            return {
                ...state,

                searchTerm: '',
            };
        }

        case 'Organizations/RESET_METRICS': {
            return {
                ...state,
                organizationId: action.organizationId,
                quarterA: action.quarterA,
                quarterB: action.quarterB,
                quarterAMetricsLoaded: initialState.quarterAMetricsLoaded,
                quarterBMetricsLoaded: initialState.quarterBMetricsLoaded,
            };
        }

        case 'Organizations/SET_ORG_METRICS_COMPLETE': {
            const quarterAStatus = (action.quarterId === 'A') ? true : state.quarterAMetricsLoaded;
            const quarterBStatus = (action.quarterId === 'B') ? true : state.quarterBMetricsLoaded;
            return {
                ...state,
                quarterAMetricsLoaded: quarterAStatus,
                quarterBMetricsLoaded: quarterBStatus,
            };
        }

        case 'Organizations/SET_ORGANIZATION_EDIT': {
            let organizationEdit;

            for (let i = 0; i < state.organizations.length; i += 1) {
                if (state.organizations[i].organizationId === action.organizationId) {
                    organizationEdit = state.organizations[i];
                }
            }
            return {
                ...state,
                organizationEdit,
                organizationEditChanged: false,
            };
        }

        case 'Organizations/UPDATE_ORGANIZATION_EDIT': {
            return {
                ...state,

                organizationEdit: action.organization,
                organizationEditChanged: true,
            };
        }

        case 'Organizations/SET_ORG_EDIT_STATUS': {
            return {
                ...state,

                organizationEditChanged: action.organizationEditChanged,
            };
        }

        case 'Organizations/SET_ADD_ORG_PANEL': {
            return {
                ...state,

                isAddOrganizationPanelOpen: action.isAddOrganizationPanelOpen,
            };
        }

        case 'Organizations/OPEN_ORG_DETAILS_PANEL': {
            return {
                ...state,

                isOrganizationDetailsPanelOpen: true,
            };
        }

        case 'Organizations/CLOSE_ORG_DETAILS_PANEL': {
            return {
                ...state,

                isOrganizationDetailsPanelOpen: false,
                selectedOrganization: null,
            };
        }

        case 'Organizations/TOGGLE_TYPE_DROPDOWN_KEY': {
            return {
                ...state,

                typeDropdownKey: (state.typeDropdownKey === 0) ? 1 : 0,
            };
        }

        case 'Organizations/TOGGLE_LICENSE_DROPDOWN_KEY': {
            return {
                ...state,

                licenseDropdownKey: (state.licenseDropdownKey === 2) ? 3 : 2,
            };
        }

        case 'Organizations/SET_SELECTED_ORGANIZATION_ROW': {
            return {
                ...state,

                selectedOrganization: action.row,
            };
        }

        case 'Organizations/OPEN_TEMPLATE_PREFERENCES_PANEL': {
            return {
                ...state,

                isTemplatePreferencesPanelOpen: true,
            };
        }

        case 'Organizations/CLOSE_TEMPLATE_PREFERENCES_PANEL': {
            return {
                ...state,

                isTemplatePreferencesPanelOpen: false,
            };
        }

        case 'Organizations/OPEN_CLIENT_PERMISSIONS_PANEL': {
            return {
                ...state,

                isClientPermissionsPanelOpen: true,
            };
        }

        case 'Organizations/CLOSE_CLIENT_PERMISSIONS_PANEL': {
            return {
                ...state,

                isClientPermissionsPanelOpen: false,
            };
        }

        case 'Organizations/OPEN_ORGANIZATION_WORKFLOWS_PANEL': {
            return {
                ...state,

                isOrganizationWorkflowsPanelOpen: true,
            };
        }

        case 'Organizations/CLOSE_ORGANIZATION_WORKFLOWS_PANEL': {
            return {
                ...state,

                isOrganizationWorkflowsPanelOpen: false,
            };
        }

        default:
            return state;
    }
};

export default reducer;
