// @flow
import * as Sentry from '@sentry/browser';

import { maybeLogNetworkError } from '../Utils/flattenError';
import type { AppError } from '../Utils/types';

type ApplicationState = {
    // If the verification of the access token is in progress
    inProgress: ?boolean,

    errors: Array<AppError>,
    deviceToken: ?string,

    sagaHeading: ?string,
    sagaMessage: ?string,
    sagaLabel: ?string,
    sagaError: ?boolean,
};

const initialState: ApplicationState = {
    inProgress: null,

    errors: [],
    deviceToken: null,

    sagaHeading: '',
    sagaMessage: '',
    sagaLabel: '',
    sagaError: false,
};

export type PushErrorAction = { type: 'Application/PUSH_ERROR', message: string, label: string };
export type PopErrorAction = { type: 'Application/POP_ERROR' };

export type SetSagaMessage = { type: 'Application/SET_SAGA_MESSAGE', heading: string, message: string, label: string, isError: boolean, };

type Action =
    | PushErrorAction
    | PopErrorAction
    | SetSagaMessage;

export const setSagaMessage = (heading: string, message: string, label: string, isError: boolean): SetSagaMessage => ({
    type: 'Application/SET_SAGA_MESSAGE',
    heading,
    message,
    label,
    isError,
});

export const pushError = (message: string, error: ?Error, label?: string = 'Whoops!'): PushErrorAction => {
    if (error) {
        // Ensure we log the error properly to sentry
        if (!maybeLogNetworkError(error)) {
            Sentry.captureException(JSON.stringify(error));
        }

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('Error occured', error);
        }
    }

    return {
        type: 'Application/PUSH_ERROR',
        message,
        label,
    };
};

export const popError = (): PopErrorAction => ({
    type: 'Application/POP_ERROR',
});

const reducer = (state: ApplicationState = initialState, action: Action): ApplicationState => {
    switch (action.type) {
        case 'Application/PUSH_ERROR':
            return {
                ...state,

                sagaHeading: 'ERROR',
                sagaMessage: action.message,
                sagaLabel: 'OK',

            };

        case 'Application/POP_ERROR':
            return {
                ...state,

                errors: state.errors.slice(0, state.errors.length - 1),
            };
        case 'Application/SET_SAGA_MESSAGE':
            return {
                ...state,

                sagaHeading: action.heading,
                sagaMessage: action.message,
                sagaLabel: action.label,
                sagaError: action.isError,
            };
        default:
            return state;
    }
};

export default reducer;
