// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectProfileAccessLevel, selectProfileOrganizationId } from 'txp-core';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import { isUserTypeLoaded } from '../../Utils/user';

import {
    loadInvitedUsers as _loadInvitedUsers,
} from '../../Redux/UserActions';
import type { InvitedUserDisplay } from '../../Utils/types';

const contentType = 'invited users';
const invitedUserHeaders = [
    {
        dataKey: 'email',
        title: 'Email',
        isUneditable: true,
    },
    {
        dataKey: 'inviteDate',
        title: 'Invited',
        isUneditable: true,
    },
    {
        dataKey: 'inviterName',
        title: 'By',
        isUneditable: true,
    },
    {
        dataKey: 'inviterOrganization',
        title: 'At',
        isUneditable: true,
        isInternal: true,
    },
    {
        dataKey: 'joinDate',
        title: 'Registered',
        isUneditable: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
        isUneditable: true,
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
        isUneditable: true,
    }
];

type Props = {
    users: Array<InvitedUserDisplay>,
    usersLoaded: boolean,
    organizationId: number,
    accessLevel: string,
    loadInvitedUsers: typeof _loadInvitedUsers,
};

class InvitedUsers extends PureComponent<Props> {
    componentDidMount() {
        const {
            organizationId,
            accessLevel,
        } = this.props;
        // Load all invites if Sales or SysAdmin, otherwise load by organization
        if (accessLevel === 'Sales' || accessLevel === 'SysAdmin') {
            this.props.loadInvitedUsers();
        } else {
            this.props.loadInvitedUsers(organizationId);
        }
    }

    render() {
        const { users, usersLoaded, } = this.props;

        return (
            <UserPanel sidebar={<></>}>
                <UserTable
                    users={users}
                    headers={invitedUserHeaders}
                    contentType={contentType}
                    usersLoaded={usersLoaded}
                />
            </UserPanel>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.user.loadedUsers.invited ?? [],
    usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'invited'),
    organizationId: selectProfileOrganizationId(state.auth),
    accessLevel: selectProfileAccessLevel(state.auth),
});

export default connect(mapStateToProps, {
    loadInvitedUsers: _loadInvitedUsers,
})(InvitedUsers);

