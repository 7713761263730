// @flow

export type AppError = {
    message: string,
    label: string,
};

export type NewDemoUser = {
    accessLevel: string,
    firstName: string,
    lastName: string,
    role: string,
    organizationName: string,
    password: string
};

export type UserProfile = {
    visible: boolean,
    userId: number,
    email: string,
    phone: string,
    joinDate: string,
    accessLevel: ?string,
    firstName: ?string,
    lastName: ?string,
    profilePicture: ?string,
    templatePreferences: ?any,
    organizationId: ?number,
    organizationName: string,
    role: ?string,
    department: ?string,
    verifiedOrgMember: boolean,
    isEmailVerified: boolean,
    emailUpdate: string,
    isGuest: false,
    lastLogin: ?string,
};

type ISODateString = string;

export type UserInvite = {
    invite: {
        id: number;
        date: ISODateString;
        email: string;
    };
    inviter: {
        firstName: string;
        lastName: string;
        organization: string;
    };
};

export type UserProfileWithInvite = UserProfile & UserInvite;

export type UserLastLogin = {
    actionName: string,
    actionDate: string,
    application: string,
};

export type UserActivity = {
    [number]: Array<UserLastLogin>,
};

export type UserActivityMap = {
    userActivity: UserActivity,
};

export type RegisteringUser = {
    email: string,
    factorId: string,
    isEmailVerified: boolean,
    isPhoneVerified: boolean,
    oktaId: string,
    phone: string,
    registrationTime: string,
    userId: number,
    visible: boolean,
};

export type InvitedUser = UserInvite & {
    user?: {
        id: number,
        email: string,
        firstName: string,
        lastName: string,
        joinDate: ISODateString,
    },
};

/**
 * A flattened version of UserInvite data used for
 * easily displaying data in a UserTable
 */
export type InvitedUserDisplay = {
    visible: boolean,
    email: string,
    inviteDate: string,
    inviterName: string,
    inviterOrganization: string,
    firstName: string,
    lastName: string,
    joinDate: string,
};

export type RemoteUserProfile = {
    user_id: number,
    phone: string,
    first_name: ?string,
    last_name: ?string,
    email: string,
    profile_picture: ?string,
    access_level: ?string,
    template_preferences: ?any,

    organization_id: ?number,
    organization_name: string,
    organizational_role: string,
    verified_organization_member: boolean,

    is_email_verified: boolean,
    email_update: string,

    lastLogin: ?string,
};

export type BusinessMetrics = {
    runningPremiumUserCounts: { [string]: number },
    runningStandardUserCounts: { [string]: number },
    newUserCounts: { [string]: number },
    convertedUserCounts: { [string]: number },
    sentMessageCounts: { [string]: number },
    readMessageCounts: { [string]: number },
    createdChatroomCounts: { [string]: number },
    monthlyActiveUserCounts: { [string]: number },
    organizationalRoleCounts: { [string]: number },
    sentMessagesByUser: Array<number>,
    readMessagesByUser: Array<number>,
    sentMessagesByOrganization: { [number]: number },
    readMessagesByOrganization: { [number]: number },
}

export type Organization = {
    visible: boolean,
    organizationId: number,
    organizationName: string,
    organizationCode: string,
    organizationType: string,
    license: string,
    clientPermissions: any,
    templatePreferences: any
};

// NOTE: For the Workflow Builder permission,it appears that we should be using '16' as the permission ID, however
//     : on the server it looks like there is a stored procedure that (I think in error) is checking for permission 16
//     : authorization also, it is in relation to public users (I think maybe the intended permission is 10), so for now
//     : to avoid unknown consequences I am using 17 here
export const UIPermissions = {
    notificationFeature: {
        name: 'Notification View',
        id: 1,
    },
    donorFeature: {
        name: 'Create Cases',
        id: 2,
    },
    donorView: {
        name: 'Case View',
        id: 13,
    },
    templatedCaseFeature: {
        name: 'Templated Case Flow',
        id: 3,
    },
    teamFeature: {
        name: 'Team View',
        id: 4,
    },
    localUpload: {
        name: 'Local Upload',
        id: 5,
    },
    demoFeature: {
        name: 'Demo Case',
        id: 6,
    },
    createChatrooms: {
        name: 'Create Chatrooms',
        id: 7,
    },
    alertToCenter: {
        name: 'Alert PN to Center',
        id: 8,
    },
    showCDSOffers: {
        name: 'Show CDS Offers',
        id: 9,
    },
    showDirectory: {
        name: 'Show Directory',
        id: 10,
    },
    defaultToLocal: {
        name: 'Default Case to Local',
        id: 12,
    },
    viewReplyThread: {
        name: 'View Reply Thread',
        id: 14,
    },
    donorWebBeta: {
        name: 'Case Web Beta',
        id: 15,
    },
    workflowBuilder: {
        name: 'Workflow Builder',
        id: 17,
    },
};

export const UserUIPermissions = {
    uploadCDSOffers: {
        name: 'Upload CDS Offers',
        id: 11,
    },
};

export type NewOrganization = {
    organizationName: string,
    organizationCode: string,
    organizationType: string,
    templatePreferences: any
};

export type RemoteMediaUri = {
    uri: string,
    headers?: {
        Apikey: string,
        Authorization: string,
    },
};

export type AvatarMap = {
    [number]: string
};

export type NewOrganizationPermission = {
    permissionName: string,
    permissionType: string,
};

export type RemoteOrganizationPermission = {
    permission_name: string,
    permission_type: string,
};

export type Chatroom = {
    chatroomId: number,
    chatroomName: string,
    chatroomDescription: string,
    donorId: number,
    organId: number,
    unosId: string,
    matchId: string,
    organType: string,
    transplantCenter: number,
    creatorId: number,
    memberCount: number,
    totalMessageCount: number,
    organizationCount: number,
    visible: boolean,
};

export type ActiveChatroom = Chatroom & {
    lastUpdateTime: string,
    lastInstantMessageContent: string,
    lastInstantMessageTime: string,
};

export type ArchivedChatroom = Chatroom & {
    archivedDate: string,
};

export type Chatrooms = {
    active: Chatroom[];
    archived: Chatroom[];
};

export type ChatroomPdfData = {
    userInformationArray: any[];
    organizationArray: any[];
    tableBody: any[]
};

export type ChatroomMember = {
    chatroomId: number,
    membershipStatus: string,
    userInformation: UserProfile,
};

export type GuestUser = {
    guestId: number,
    chatroomId: number,
    email: string,
    phone: string,
    membershipStatus: string,
};

export type InstantMessage = {
    instantMessageId: number,
    chatroomId: number,
    sentTime: string,
    senderId: number,
    senderName: string,
    chatTypeId: number,
    textContent: string,
    dataContent: Object,
    fileName: string,
    fileSize: boolean,
};

export type Acknowledgement = {
    acknowledgementId: string,
    instantMessageId: string,
    chatroomId: string,
    memberId: string,
    ackTime: string,
    symbol: string,
};

export type AcknowledgementMap = {
    // messageId => Array of Acknowledgement
    [number]: Array<Acknowledgement>,
};

export type InstantMessageStatus = {
    instantMessageStatusId: number,
    instantMessageId: number,
    chatroomId: number,
    memberId: number,
    statusTime: string,
};

export type InstantMessageStatusMap = {
    // messageId => Array of InstantMessageStatus
    [number]: Array<InstantMessageStatus>,
}

export type ChatroomData = {
    organizations: Array<Organization>,
    chatroomMembers: Array<ChatroomMember>,
    guestUsers: Array<GuestUser>,
    instantMessages: Array<InstantMessage>,
    acknowledgements: AcknowledgementMap,
    instantMessageStatuses: InstantMessageStatusMap,
};

export type OrganType = 'Kidneys Enbloc' | 'Right Kidney' | 'Left Kidney'
    | 'Liver' | 'Liver Split Left' | 'Liver Split Right'
    | 'Left Lung' | 'Right Lung' | 'Lungs Enbloc'
    | 'Pancreas' | 'Heart' | 'Intestine';

export type Organ = {
    organId: number,
    organType: OrganType,
    hasChatroom?: boolean,
};

export type DonorTask = {
    taskId: number,
    userId: number,
    updateTime: string,
    completed: boolean,
    notApplicable: boolean,
    noteContent: string,
    taskData: any,
};

export type DonorTaskMap = {
    // taskId -> DonorTask
    [number]: DonorTask,
};

export type DonorType = 'All' | 'Import' | 'Local';
export type DonorStatus = 'All' | 'Closed' | 'Open';
export type DonorSort = 'Create Date' | 'Last Active';

export type TimelineItem = {
    taskId: number,
    userId: number,
    completed: ?boolean,
    notApplicable: ?boolean,
    noteContent: ?string,
    updateTime: string,
};

export type Donor = {
    donorId: number,
    donorType: DonorType,
    opoDonorId: string,
    unosId: string,
    userId: number, // user who created the donor
    createDate: string,
    organs: Array<Organ>,
    closed: boolean,
    workflow: string,
    currentLocation?: string,
    age?: string,
    dob?: string,
    race?: string,
    sex?: string,
    weight?: string,
    height?: string,
    typeOfDeath?: string,
    unetLink?: string,
    tasks: DonorTaskMap,
    timeline: Array<TimelineItem>,
    lastModified: string,
    lastModifiedBy: number,
    visible?: boolean,
};

export type DonorMap = {
    // donorId -> Donor
    [number]: Donor,
};

export type FollowerGroupMember = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    profilePicture: string;
};

export type FollowerGroup = {
    id: number;
    chatroomId: number;
    name: string;
    taskIds: number[];
    members: FollowerGroupMember[];
};

export type FollowerRoomTasks = {
    [key: number]: string[];
};

export type ApiCaseNote = {
    note_id: number;
    user_id: number;
    case_id: number;
    note: string;
    create_date: ISODateString;
};

export type CaseNote = {
    id: number;
    userId: number;
    caseId: number;
    note: string;
    createDate: ISODateString;
};

export type Task = {
    sectionPosition: number,
    section: string,
    description: string,
    formId?: string,
};

export type TaskMap = {
    // taskId -> Task
    [number]: Task,
};

// NOTE: This data structure doesn't really
//  make much sense.  It should just be an
//  array of the resource permissions allowed
//  for the user or a map containing resource type number
//  (ie 11 for CDS Offer Upload) as key and value being true or false.
export type ResourcePermission = {
    permissionId: number,
    resourcePermissions: number,
};

export type ResourcePermissionMap = {
    // userId -> ResourcePermissions
    [number]: ResourcePermission,
};

export type UIPermissionsMap = {
    [number]: boolean,
};

export type CDSFileType = 'offer' | 'candidate' | 'roo';

export const OrganAllocationTasks = [13, 14, 15, 16, 17, 18];
export const OrganRecoveryTasks = [29, 30, 31, 32, 33, 34];

export type ReferenceDomain = 'SURVEY' | 'FORM' | 'WORKFLOW' | 'RESEARCHER';

export type StaticTask = {
    taskId: number,
    details: {
        sectionPosition: number,
        section: string,
        description: string,
        formId: string,
    },
};

export type FollowerPreference = {
    id: string,
    name: string,
    members: number[],
    teams: number[],
    tasks: (number | string)[],
    canEdit: boolean,
}

export type CasePreferences = {
    followers: FollowerPreference[],
}

export type Workflow = {
    domain: ReferenceDomain,
    key: string,
    name: ?string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    inactive: boolean,
    flat: boolean,
    progressBar: boolean,
    tasks: Array<StaticTask>,
    sectionOrder: Array<string>,
    visible: boolean,
    casePreferences: CasePreferences
};

export type RefDataSurvey = {
    domain: ReferenceDomain,
    key: string,
    name: string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    inactive: boolean,
    label: ?string,
    passMark: number,
    questions: any,
    visible: boolean,
};

export type Form = {
    domain: ReferenceDomain,
    key: string,
    name: string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    inactive: boolean,
    visible: boolean,
}
export type Researcher = {
    domain: ReferenceDomain,
    key: string,
    name: string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    inactive: boolean,
    visible: boolean,
}

export type FieldOption = string;

export type AttributeType = {
    value: string,
    label: string
}

export type SubtaskType = {
    id: string,
    title: string,
    uiType: string,
    displayAs?: string,
    responseOptions: FieldOption[],
    attributes: Array<AttributeType>,
    mapping: string,
}

export type TaskSectionType = {
    id: string,
    title: string,
    fields: SubtaskType[]
}

export type MainTask = {
    id: string,
    name: string,
    sections: TaskSectionType[],
}

export type EditorWorkflow = {
    ownerId: number,
    ownerType: string,
    selectedOrganization: string,
    workflowName: string,
    mainTasks: MainTask[],
}

export type WorkflowPreferences = {
    id: number,
    workflowId: number,
    preferences: CasePreferences,
}

export type Team = {
    teamId: number,
    creatorId: number,
    teamName: string,
    shared: boolean,
    teamMembers: Array<UserProfile>,
}
