// @flow

type ClientState = {
    iosVersion: string,
    androidVersion: string,
    iosReleaseTime: string,
    androidReleaseTime: string,
};

const initialState: ClientState = {
    iosVersion: '',
    androidVersion: '',
    iosReleaseTime: '',
    androidReleaseTime: '',
};

export type LoadVersions = {type: 'Clients/LOAD_VERSIONS' };

export type LoadedVersions = {
    type: 'Clients/LOADED_VERSIONS',
    iosVersion: string,
    androidVersion: string,
    iosReleaseTime: string,
    androidReleaseTime: string,
};

export type UpdateiOSVersion = {
    type: 'Clients/UPDATE_IOS_VERSION',
    version: string,
};

export type UpdateAndroidVersion = {
    type: 'Clients/UPDATE_ANDROID_VERSION',
    version: string,
};

type Action =
    | LoadVersions
    | LoadedVersions
    | UpdateiOSVersion
    | UpdateAndroidVersion;

export const loadVersions = (): LoadVersions => ({
    type: 'Clients/LOAD_VERSIONS',
});

export const loadedVersions = (
    iosVersion: string,
    androidVersion: string,
    iosReleaseTime: string,
    androidReleaseTime: string
): LoadedVersions => ({
    type: 'Clients/LOADED_VERSIONS',
    iosVersion,
    androidVersion,
    iosReleaseTime,
    androidReleaseTime,
});

export const updateiOSVersion = (
    version: string
): UpdateiOSVersion => ({
    type: 'Clients/UPDATE_IOS_VERSION',
    version,
});

export const updateAndroidVersion = (
    version: string
): UpdateAndroidVersion => ({
    type: 'Clients/UPDATE_ANDROID_VERSION',
    version,
});

const reducer = (state: ClientState = initialState, action: Action): ClientState => {
    switch (action.type) {
        case 'Clients/LOADED_VERSIONS': {
            return {
                ...state,

                iosVersion: action.iosVersion,
                androidVersion: action.androidVersion,
                iosReleaseTime: action.iosReleaseTime,
                androidReleaseTime: action.androidReleaseTime,
            };
        }

        default:
            return state;
    }
};

export default reducer;
