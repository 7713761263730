// @flow
import { parse } from 'csv-parse/lib/sync';

export default function parseCsv(fileRead: string, fileType: string) {
    let delimiter = '';
    if (fileType === 'text/csv') {
        delimiter = ',';
    } else if (fileType === 'text/tab-separated-values') {
        delimiter = '\t';
    }

    let parsedRows = [];
    try {
        parsedRows = parse(fileRead, {
            delimiter,
            trim: true,
        });
    } catch (error) {
        if (error.message.includes('Invalid Record Length')) {
            throw new Error(`Error: File contains the incorrect number of columns: ${error.message}`);
        } else {
            throw error;
        }
    }

    return parsedRows;
}
