// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Table from 'react-toolbox/lib/table/Table';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import Button from 'react-toolbox/lib/button/Button';
import { getFormattedDateTimeString } from '../Utils/datetime';

import type { UserProfile, UserActivityMap } from '../Utils/types';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    user: UserProfile,
    organizationUserActivity: UserActivityMap,
    onClose: () => *,
};

class UserActivityPanel extends PureComponent<Props> {
    onCloseClearFields = () => {
        const {
            onClose,
        } = this.props;

        onClose();
    };

    renderTableRows = () => {
        const {
            user,
            organizationUserActivity,
        } = this.props;

        const rows = [];

        if (user) {
            rows.push(
                <TableRow
                    key={rows.length.toString()}
                >
                    <TableCell>
                        <div>
                            Event
                        </div>
                    </TableCell>
                    <TableCell>
                        <div>
                            Time
                        </div>
                    </TableCell>
                    <TableCell>
                        <div>
                            Application
                        </div>
                    </TableCell>
                </TableRow>
            );

            const userActivity = organizationUserActivity.userActivity?.[user.userId];
            let userActivityEvents = '';
            let userActivityTimes = '';
            let userActivityApplications = '';

            if (userActivity) {
                for (let i = 0; i < userActivity.length; i += 1) {
                    userActivityEvents = userActivity[i].actionName;
                    userActivityTimes = getFormattedDateTimeString(userActivity[i].actionDate);
                    userActivityApplications = (userActivity[i].application).toLowerCase()
                        .split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
                    rows.push(
                        <TableRow
                            key={rows.length.toString()}
                        >
                            <TableCell>
                                <div>
                                    {`${userActivityEvents} `}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {`${userActivityTimes} `}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {`${userActivityApplications} `}
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                }
            }
        }
        return rows;
    };

    render() {
        return (
            <div>
                <Table
                    id="table"
                    selectable={false}
                >
                    {this.renderTableRows()}
                </Table>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        style={ApplicationStyles.saveButton}
                        label="Close"
                        onClick={this.onCloseClearFields}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.userEdit,
    organizationUserActivity: state.organization.organizationUserActivity,
});

export default connect(mapStateToProps, {

})(UserActivityPanel);
