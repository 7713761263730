import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

const textFieldInput = (props, ref) => (
    <TextField
        {...props}
        inputRef={ref}
    />
);

export default forwardRef(textFieldInput);
