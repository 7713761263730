module.exports = {
    RTAppBar: {
        appBar: '_3Py1Z',
        scrollHide: 'PyOfn',
        flat: '_1J9he',
        fixed: '_2dwFx',
        inner: 'lGWLJ',
        title: '_37vtt',
        leftIcon: '_30BcY',
        rightIcon: '_1hv3P',
    },
    RTButton: {
        button: '_2Agdx',
        rippleWrapper: '_3AVBi',
        squared: '_2GH_L',
        icon: '_3aBSX',
        solid: '_1ZxqC',
        raised: '_221ic _2Agdx _2GH_L _1ZxqC',
        flat: '_1jWAQ _2Agdx _2GH_L',
        floating: '_3IRMZ _2Agdx _1ZxqC',
        mini: '_2DCN-',
        toggle: 'hC5Z2 _2Agdx',
        primary: '_3tTAW',
        accent: '_2wp6F',
        neutral: '_2CPs4',
        inverse: '_2SPZr',
    },
    RTRipple: {
        rippleWrapper: '_16N7o',
        ripple: '_3SV_u',
        rippleRestarting: '_2OZWa',
        rippleActive: '_3O2Ue',
    },
    RTAutocomplete: {
        autocomplete: '_07g5',
        focus: '_3qQkg',
        suggestions: '_3-Nb6',
        values: '_14fVf',
        value: 'cBUoJ',
        up: '_3rmye',
        suggestion: '_1erPE',
        active: 'kDY3Z',
        input: '_1ryxc',
    },
    RTChip: {
        chip: '_3Iv9P',
        avatar: '_2o8mD',
        deletable: '_2hlBs',
        delete: '_3nnfj',
        deleteIcon: '_2sopz',
        deleteX: '_10NaZ',
    },
    RTAvatar: {
        avatar: '_3OjJz',
        image: '_2Ueo9',
        letter: '_26GdB',
    },
    RTInput: {
        input: 'lFVgC',
        withIcon: '_1nKdf',
        icon: '_3ga1V',
        inputElement: '_4bZUj',
        bar: '_3FySS',
        label: '_34120',
        fixed: 'GRQEP',
        required: '_2G0aY',
        hint: 'bMyi_',
        filled: '_34NWn',
        error: '_2k5Jz',
        counter: '_1oTuT',
        disabled: '_3ZfJq',
        errored: '_2s74E',
        hidden: '_2gAMv',
    },
    RTCard: {
        card: '_8pay8',
        raised: '_1AHwB',
        cardMedia: '_3Yc6z',
        wide: '_35NNe',
        square: '_1HBxg',
        content: 'ewAVM',
        contentOverlay: '_1bBKz',
        cardTitle: '_1dU3o',
        cardActions: 'ZoLIG',
        cardText: 'K_kzH',
        title: '_3qCP3',
        subtitle: '_3p3mO',
        large: '_2iwOK',
        small: '_1RHxe',
    },
    RTCheckbox: {
        field: '_3Tq32',
        ripple: '_2NWrZ',
        text: 'dXU7C',
        input: '_271V1',
        check: '_1CXAo',
        checked: 'nSz7s',
        'checkmark-expand': '_3GU9D',
        disabled: '_2jVLS',
    },
    RTDatePicker: {
        input: '_2ISvI',
        disabled: 'Cf3yF',
        inputElement: 'x7MhN',
        header: '_2vLUd',
        year: '_1VWY-',
        date: '_3K2Ws',
        calendarWrapper: '_1t-4v',
        yearsDisplay: '_2OzvT',
        monthsDisplay: '_2DDdC',
        dialog: '_3fCV6',
        button: '_2hL6u',
        calendar: '_1X9ls',
        prev: 'Nv9Bc',
        next: '_3iPkS',
        title: '_2ESpD',
        years: 'zEdgW',
        active: '_1pjXb',
        week: 'PcByv',
        days: '_1qh3T',
        day: '_2qF_L',
        month: '_1hSm5',
        slideRightEnter: 'Rk89h',
        slideRightLeave: '_1nam4',
        slideRightEnterActive: 'm5B3T',
        slideRightLeaveActive: '_2bZap',
        slideLeftEnter: 'bGml_',
        slideLeftLeave: '_2WGqM',
        slideLeftEnterActive: '_3Ghls',
        slideLeftLeaveActive: '_2WLHG',
    },
    RTDialog: {
        wrapper: '_3nrqp',
        dialog: '_3lw90',
        active: '_3ea_1',
        small: '_38VTT',
        normal: '_1K3iz',
        large: '_10LcP',
        fullscreen: '_3tLXQ',
        title: '_2J-aP',
        body: '_1Ivuq',
        navigation: 'wgwdj',
        button: '_22_c6',
    },
    RTOverlay: {
        overlay: '_2LA9x',
        active: '_1mb5R',
    },
    RTDrawer: {
        wrapper: '_3eRY8',
        drawer: '_1sAOY',
        active: 'EWFXC',
        right: '_2-4-H',
        left: 'FKhpR',
    },
    RTDropdown: {
        dropdown: 'ZzBNK',
        active: '_1DQ-E',
        values: '_2767w',
        label: '_2KjGM',
        value: '_6c1D5',
        up: '_1OA-G',
        disabled: '_1skVH',
        field: 'd5bru',
        errored: '_3dZUG',
        templateValue: '_6dCtT',
        required: '_1j4LX',
        error: 'fySw3',
        selected: '_3uiEo',
    },
    RTLayout: {
        layout: 'wiKya',
        panel: '_3aW3s',
        bodyScroll: '_2uzOU',
        sidebarDrawer: '_2kCN0',
        navDrawerDrawer: 'Cte92',
        pinned: 'qf0ha',
        clipped: '_1y5eh',
        appbarInner: '_1S9wz',
        appbarFixed: '_2Q-xL',
        appbarAppBar: 'F9Fy2',
        navDrawerPinned: '_2eOy5',
        appbarLeftIcon: 'fZ13o',
        navDrawerClipped: '_2kROG',
        navDrawerWrapper: '_2gpOZ',
        sidebarPinned: '_3yo9c',
        sidebarClipped: '_1paQt',
        sidebarWrapper: '_1TUxm',
        sidebarWidth1: '_1EWpa',
        sidebarWidth2: '_37z5O',
        sidebarWidth3: '_24Dtc',
        sidebarWidth4: '_28mqi',
        sidebarWidth5: 'K39iB',
        sidebarWidth6: '_2PjBX',
        sidebarWidth7: '_16Oxc',
        sidebarWidth8: '_3fr9v',
        sidebarWidth9: 'iF_4K',
        sidebarWidth10: '_3mnwI',
        sidebarWidth11: '_2uccf',
        sidebarWidth12: '_1pU-9',
        sidebarWidth25: '_10h-v',
        sidebarWidth33: 'BYRr2',
        sidebarWidth50: '_2L3ft',
        sidebarWidth66: '_31jol',
        sidebarWidth75: '_2Xvmh',
        sidebarWidth100: '_3T7B6',
    },
    RTLink: {
        icon: '_1-mD4',
        link: '_1Od3D',
        active: '_3blKB',
    },
    RTList: {
        list: 'caNNQ',
        divider: '_2Jg3-',
        subheader: 'q2l8C',
        inset: '_1HHo_',
        listItem: 'ni6RH',
        ripple: '_2mi0Y',
        item: '_2GtDw',
        selectable: '_1OoR-',
        disabled: '_38DD6',
        checkboxItem: 'OVyge',
        checkbox: '_3SG-0',
        left: 'bHOJq',
        right: 'OQ3Je',
        itemAction: 'VB7pN',
        itemContentRoot: '_2FBCh',
        large: 'EO5bo',
        itemText: '_12FqV',
        primary: '_3SxNr',
    },
    RTMenu: {
        iconMenu: '_2aMxm',
        icon: '_1b8Ml',
        menu: '_1gvr5',
        topLeft: 'SYeW8',
        outline: '_2PdTB',
        topRight: 'DFQvY',
        bottomLeft: '_3i7lA',
        bottomRight: '_3q-zB',
        static: '_2xf5n',
        menuInner: '_2t8UE',
        rippled: '_3o1JI',
        active: '_2Cekp',
        menuItem: 'lyzBJ',
        disabled: 'zGTpA',
        selected: '_2-j_P',
        ripple: '_2m_Cl',
        caption: '_3MsfE',
        shortcut: '_1anRY',
        menuDivider: 'VX5Lv',
    },
    RTNavigation: {
        horizontal: '_1MJ9B',
        vertical: 'xUlwz',
    },
    RTProgressBar: {
        linear: '_3vxHj',
        indeterminate: '_1gPzb',
        value: 'I0PhY',
        'linear-indeterminate-bar': '_1cU21',
        buffer: 'SzbNd',
        circular: '_2j3vC',
        circle: 'DlWjM',
        'circular-indeterminate-bar-rotate': 'zfZzh',
        path: '_1xZSU',
        'circular-indeterminate-bar-dash': '_3DSlU',
        multicolor: '_3XHT8',
        colors: 'Z_PDt',
    },
    RTRadio: {
        radio: '_1vWJb',
        ripple: '_78FVB',
        radioChecked: '_210O6 _1vWJb',
        field: '_36UDg',
        text: '_3guDD',
        input: '_2CPDD',
        disabled: '_39I6g _36UDg',
    },
    RTSlider: {
        container: '_-dLk',
        knob: 'kq8Om',
        innerknob: '_8VjZ5',
        snaps: '_2x5j_',
        snap: '_12aGJ',
        input: '_2JHGy',
        progress: '_2R4jW',
        innerprogress: '_3p0mR',
        slider: '_3-BtZ',
        editable: 'hkAL6',
        pinned: '_28Oo0',
        pressed: '_292qK',
        ring: '_2Oh5L',
    },
    RTSnackbar: {
        snackbar: 'zDi3X',
        accept: '_2Y0Cy',
        button: '_2pCxU',
        warning: '_2li3o',
        cancel: '_3731C',
        active: '_38CsO',
        label: '_1JIbY',
    },
    RTSwitch: {
        field: '_1T2D0',
        text: 'rxx-p',
        thumb: '_1pMry',
        ripple: '_1I9tv',
        on: 'p92Yp',
        off: '_25ui_',
        input: '_3BTU_',
        'switch-on': '_2Bwve',
        'switch-off': '_1ZBFp',
        disabled: '_1CQ_q _1T2D0',
    },
    RTTable: {
        table: '_2xofu',
        head: '_1eF5Z',
        row: '_1qppP',
        selected: '_1H1dU',
        rowCell: '_18bqN',
        headCell: 'gfcPv',
        numeric: '_3suRQ',
        checkboxCell: '_2yhwA',
        sorted: '_2r5OG',
        sortIcon: '_3NJs5',
        asc: 'wZHUA',
    },
    RTTabs: {
        tabs: '_2EaQV',
        navigation: '_3e55Z',
        navigationContainer: '_3oZmF',
        arrow: 'wPL4g',
        arrowContainer: 'i_TGW',
        label: '_1yb8L',
        rippleWrapper: '_3c0W3',
        active: '_2LZ7Z',
        disabled: '_2gi1s',
        hidden: '_3kq1J',
        withIcon: '_1OFOx',
        withText: '_1Yf4A',
        icon: '_1LUZH',
        pointer: '_1xgdB',
        tab: '_26SP9',
        fixed: '_3bROj',
        inverse: '_33mT_',
    },
    RTTooltip: {
        tooltip: '_1v8bI',
        tooltipActive: '_2xWjx',
        tooltipTop: '_1PfOK',
        tooltipLeft: '_3uj3d',
        tooltipRight: '_3UQWj',
        tooltipInner: '_9q2WH',
    },
    RTTimePicker: {
        input: '_2APuy',
        disabled: '_2Vc_4',
        inputElement: '_2Z4kT',
        header: '_2u1sB',
        hours: '_3Kl2E',
        minutes: '_3Bp7w',
        separator: '_1c2VQ',
        ampm: '_1vAVQ',
        am: '_14hQA',
        pm: 'aU9C9',
        dialog: '_1YlHq',
        button: '_1Kf0L',
        hoursDisplay: '_2JeOG',
        minutesDisplay: '_3UMNx',
        amFormat: '_26MJk',
        pmFormat: '_3H_-m',
        clock: '_2CwF0',
        placeholder: 'zCpyM',
        clockWrapper: '_3paoD',
        face: '_3ui0r',
        number: 'qr9pw',
        active: '_30yS_',
        hand: '_1rtAF',
        small: '_3eEHh',
        knob: 'VqPQb',
        zoomInEnter: '_3DTnI',
        zoomInLeave: '_1tgJ3',
        zoomInEnterActive: '_1Fr4_',
        zoomInLeaveActive: '_1EUpH',
        zoomOutEnter: '_1Lb15',
        zoomOutLeave: '_3LDEu',
        zoomOutEnterActive: '_3y67C',
        zoomOutLeaveActive: '_18Ean',
    },
};
