// @flow
import type {
    TaskMap,
    Workflow,
} from './types';

export function selectDonorWorkflow(donorWorkflow: string, workflows: Array<Workflow>): ?Workflow {
    return workflows.find((workflow) => donorWorkflow === workflow.key);
}

export function getWorkflowTaskMap(workflow: Workflow): TaskMap {
    const tasks = (workflow && workflow.tasks) ? workflow.tasks : [];
    const taskMap = {};
    for (let i = 0; i < tasks.length; i += 1) {
        taskMap[tasks[i].taskId] = {
            sectionPosition: tasks[i].details.sectionPosition,
            section: tasks[i].details.section,
            description: tasks[i].details.description,
            formId: tasks[i].details.formId,
        };
    }

    return taskMap;
}
