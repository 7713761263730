// @flow

function convertAccessLevel(accessLevel: string): string {
    switch (accessLevel) {
        case 'SysAdmin':
            return 'System Administrator';
        case 'Sales':
            return 'Sales';
        case 'OrgManager':
            return 'Organization Manager';
        case 'OrgMember':
            return 'Organization Member';
        case 'BasicUser':
            return 'Basic User';
        case 'Archived':
            return 'Archived';
        default:
            return 'Unknown';
    }
}

export default convertAccessLevel;
