import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore, persistCombineReducers } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';

import arrayMiddleware from './Middleware/ArrayMiddleware';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [
        'application',
        //
        // Blacklist loading reducer since in-progress requests will be cancelled when app is closed
        //  and one should re-initiate the calls after app is in the foreground again.
        'loading',
        //
        // Blacklisting navigation so we can control it ourselves
        'navigation',
        //
        // Blacklist everything (consider removing persistor in the future)
        'auth',
        'client',
        'donor',
        'organization',
        'user'
    ],
    stateReconciler: autoMergeLevel2,
};

export default function configureStore(rootReducer) {
    const middleware = [
        arrayMiddleware
    ];

    // Create Saga Middleware
    const sagaMiddleware = createSagaMiddleware();
    middleware.push(sagaMiddleware);

    // Add logger if in development
    // Note: the output is not pretty (in the terminal)
    // using the following packages as alternatives might provide better DX
    // - https://github.com/zalmoxisus/remote-redux-devtools
    // - https://github.com/jhen0409/react-native-debugger
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger({
            duration: true,
        });
        middleware.push(logger);
    }

    // Create persist-ready reducer from rootReducer
    const combinedReducer = persistCombineReducers(persistConfig, rootReducer);

    // Create the store
    const store = createStore(combinedReducer, compose(applyMiddleware(...middleware)));

    // Make replaceReducer available directly on the return value
    const replaceReducer = (r) => store.replaceReducer(persistCombineReducers(persistConfig, r));

    // Add redux-persist to the store
    const persistor = persistStore(store);

    return {
        store,
        persistor,
        sagaMiddleware,
        replaceReducer,
    };
}
