// @flow
import type { Saga } from 'redux-saga';
import {
    select,
    put,
    delay,
} from 'redux-saga/effects';

import api from '../Services/Api';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import {
    apiDelete, apiFetch, apiPost,
} from './ApiSaga';
import { setSagaMessage } from '../Redux/ApplicationActions';
import {
    receiveAdminPortalPermissions,
    receiveResourcePermissions,
} from '../Redux/PermissionActions';
import type {
    AddPermission,
    GetResourcePermissions,
    RemovePermission,
} from '../Redux/PermissionActions';
import { setUserEditStatus } from '../Redux/UserActions';
import { parseResponseTextError } from '../Utils/flattenError';
import { UIPermissions } from '../Utils/types';

export function* getResourcePermissionsSaga(action: GetResourcePermissions): Saga<void> {
    const {
        entityType,
        entityId,
    } = action;

    yield put(startLoading('resourcePermissions'));

    const { result, error, } = yield apiFetch(api.txp.resourcePermission, {
        entityType,
        entityId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('Denied', `${errorMessage}`, '', false));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Denied', 'Loading resource permissions failed, are you online?', '', false));
        } else {
            yield put(setSagaMessage('Denied', 'Loading resource permissions failed, try again later', '', false));
        }
        yield put(finishLoading('resourcePermissions'));
    } else {
        const { permissions, } = result;
        const resourcePermissionsMap = {};
        for (let i = 0; i < permissions.length; i += 1) {
            const userType = permissions[i].authorized.authorized_type;
            if (userType === 'User') {
                const userId = permissions[i].authorized.authorized_id;

                resourcePermissionsMap[userId] = {
                    permissionId: permissions.permission_id,
                    resourcePermissions: permissions[i].target.target_id,
                };
            }
        }

        yield put(receiveResourcePermissions(resourcePermissionsMap));

        yield put(finishLoading('resourcePermissions'));
    }
}

export function* getAdminPortalPermissionsSaga(): Saga<void> {
    const userId = yield select((state) => state.auth.profile.userId);
    const organizationId = yield select((state) => state.auth.profile.organizationId ?? -1);

    yield put(startLoading('adminPortalPermissions'));

    // Currently our only permission we need to check for to enable/disable in the Admin Portal is the
    // workflow builder, so retrieve that and update our state
    const entityType = 'UI';
    const entityId = 17;
    const { result, error, } = yield apiFetch(api.txp.resourcePermission, {
        entityType,
        entityId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('Denied', `${errorMessage}`, '', false));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Denied', 'Loading resource permissions failed, are you online?', '', false));
        } else {
            yield put(setSagaMessage('Denied', 'Loading resource permissions failed, try again later', '', false));
        }
        yield put(finishLoading('resourcePermissions'));
    } else {
        const { permissions, } = result;
        const apPermissions = {};
        for (let i = 0; i < permissions.length; i += 1) {
            const authorizedType = permissions[i].authorized.authorized_type;
            const authorizedId = permissions[i].authorized.authorized_id;
            if ((authorizedType === 'User' && authorizedId === userId) || (authorizedType === 'Organization' && authorizedId === organizationId)) {
                apPermissions[UIPermissions.workflowBuilder.id] = permissions[i].rights.read;
            }
        }

        yield put(receiveAdminPortalPermissions(apPermissions));

        yield put(finishLoading('adminPortalPermissions'));
    }
}

export function* addPermissionSaga(action: AddPermission): Saga<void> {
    const {
        entityType,
        entityId,
        subEntityType,
        subEntityId,
        cascade,
        read,
        update,
        canDelete,
        authorizedType,
        authorizedId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { result, error, } = yield apiPost(api.txp.addPermission, null, {
        target_type: entityType,
        target_id: entityId,
        subtarget_type: subEntityType,
        subtarget_id: subEntityId,
        cascade,
        read,
        update,
        delete: canDelete,
        authorized_type: authorizedType,
        authorized_id: authorizedId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('Denied', `${errorMessage}`, '', false));
        } else if (error.isInvalidResponseCode) {
            yield put(setSagaMessage('Denied', parseResponseTextError(error.responseText), '', false));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Denied', 'Adding permission failed, are you online?', '', false));
        } else {
            yield put(setSagaMessage('Denied', 'Adding permission failed, try again later', '', false));
        }
    } else {
        const permissionId = result.permission_id;

        const currentPermissions = yield select((state) => state.permission.resourcePermissions);
        if (!currentPermissions[authorizedId]) {
            currentPermissions[authorizedId] = {
                permissionId,
                resourcePermissions: entityId,
            };
        }

        yield put(receiveResourcePermissions(currentPermissions));

        yield put(setSagaMessage('Success', 'Successfully added permission', '', false));

        yield put(setUserEditStatus(false));
    }
}

export function* removePermissionSaga(action: RemovePermission): Saga<void> {
    const {
        permissionId,
        authorizedId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiDelete(api.txp.removePermission, {
        permissionId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(setSagaMessage('Denied', `${errorMessage}`, '', false));
        } else if (error.isNetworkError) {
            yield put(setSagaMessage('Denied', 'Removing permission failed, are you online?', '', false));
        } else {
            yield put(setSagaMessage('Denied', 'Removing permission failed, try again later', '', false));
        }
    } else {
        const currentPermissions = yield select((state) => state.permission.resourcePermissions);
        if (currentPermissions[authorizedId]) {
            delete currentPermissions[authorizedId];
        }

        yield put(receiveResourcePermissions(currentPermissions));

        yield put(setSagaMessage('Success', 'Successfully removed permission', '', false));

        yield put(setUserEditStatus(false));
    }
}
