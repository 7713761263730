// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import ReactTooltip from 'react-tooltip';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import MenuStyles from './Styles/MenuStyles';
import Images from '../Themes/Images';
import type { UIPermissionsMap, UserProfile } from '../Utils/types';
import constants from '../Utils/constants';
import {
    toggleUserDetailsPanel as _toggleUserDetailsPanel,
    toggleAddUserToOrganizationPanel as _toggleAddUserToOrganizationPanel,
} from '../Redux/UserActions';
import { getReferenceData as _getReferenceData } from '../Redux/ReferenceDataActions';
import { isUserSuperAdmin, isUserAdmin } from '../Utils/user';
import { UIPermissions } from '../Utils/types';

type Props = {
    nestedContainer: string,
    profile: UserProfile,
    adminPortalPermissions: UIPermissionsMap,

    getReferenceData: () => *,
    switchNestedContainer: (nestedContainer: string) => *,
    toggleUserDetailsPanel: (isUserDetailsPanelOpen: boolean,) => *,
    toggleAddUserToOrganizationPanel: (isAddUserToOrganizationPanelOpen: boolean,) => *,
};

class MenuPanel extends PureComponent<Props> {
    onSwitchNestedContainer = (container: string) => {
        const {
            toggleUserDetailsPanel,
            toggleAddUserToOrganizationPanel,
            switchNestedContainer,
        } = this.props;

        toggleUserDetailsPanel(false);
        toggleAddUserToOrganizationPanel(false);
        switchNestedContainer(container);
    };

    onConfigurationClick = () => {
        const {
            getReferenceData,
            switchNestedContainer,
        } = this.props;

        getReferenceData();

        switchNestedContainer(constants.CONFIG_TABLE);
    };

    onWorkflowBuilderClick = () => {
        const {
            getReferenceData,
            switchNestedContainer,
        } = this.props;

        getReferenceData();

        switchNestedContainer(constants.WORKFLOW_BUILDER);
    }

    render() {
        const {
            nestedContainer,
            switchNestedContainer,
            profile,
            adminPortalPermissions,
        } = this.props;
        const isSuperAdmin = isUserSuperAdmin(profile.accessLevel ?? '');
        const isAdmin = isUserAdmin(profile.accessLevel ?? '');
        const userOrgId = profile.organizationId ?? -1;
        const workflowBuilderAllowed = adminPortalPermissions[UIPermissions.workflowBuilder.id];

        const researchMenu = isSuperAdmin ? (
            <div>
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="scatter_plot"
                    flat
                    primary
                    raised={nestedContainer === constants.RESEARCH_TABLE}
                    onClick={() => switchNestedContainer(constants.RESEARCH_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Research
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;

        const configurationMenu = isAdmin ? (
            <div>
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="settings"
                    flat
                    primary
                    raised={nestedContainer === constants.CONFIG_TABLE}
                    onClick={this.onConfigurationClick}
                >
                    <span style={MenuStyles.paddingTop}>
                        Configuration
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;

        const workflowBuilderMenu = (userOrgId === 255 || workflowBuilderAllowed) ? (
            <div>
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="edit_note"
                    flat
                    primary
                    raised={nestedContainer === constants.WORKFLOW_BUILDER}
                    onClick={this.onWorkflowBuilderClick}
                >
                    <span style={MenuStyles.paddingTop}>
                        Workflow Builder
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;

        const organizationMenu = isAdmin ? (
            <div>
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="business"
                    flat
                    primary
                    raised={nestedContainer === constants.ORGANIZATION_TABLE}
                    onClick={() => switchNestedContainer(constants.ORGANIZATION_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Organizations
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;

        const archivedUsersMenu = isAdmin ? (
            <Button
                style={MenuStyles.usersButton}
                label="Archived Users"
                flat
                primary
                raised={nestedContainer === constants.ARCHIVED_USER_TABLE}
                onClick={() => this.onSwitchNestedContainer(constants.ARCHIVED_USER_TABLE)}
            />
        ) : null;

        const registeringUsersMenu = isAdmin ? (
            <Button
                style={MenuStyles.usersButton}
                label="Registering Users"
                flat
                primary
                raised={nestedContainer === constants.REGISTERING_USER_TABLE}
                onClick={() => this.onSwitchNestedContainer(constants.REGISTERING_USER_TABLE)}
            />
        ) : null;

        const invitedUsersMenu = isAdmin ? (
            <Button
                style={MenuStyles.usersButton}
                label="Invited Users"
                flat
                primary
                raised={nestedContainer === constants.INVITED_USER_TABLE}
                onClick={() => this.onSwitchNestedContainer(constants.INVITED_USER_TABLE)}
            />
        ) : null;

        const demoUsersMenu = isAdmin ? (
            <Button
                style={MenuStyles.usersButton}
                label="Demo Users"
                flat
                primary
                raised={nestedContainer === constants.DEMO_USER_TABLE}
                onClick={() => this.onSwitchNestedContainer(constants.DEMO_USER_TABLE)}
            />
        ) : null;


        const basicUsersMenu = isAdmin ? (
            <Button
                style={MenuStyles.usersButton}
                label="Basic Users"
                flat
                primary
                raised={nestedContainer === constants.BASIC_USER_TABLE}
                onClick={() => this.onSwitchNestedContainer(constants.BASIC_USER_TABLE)}
            />
        ) : null;

        const clientMenu = isSuperAdmin ? (
            <div>
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="devices"
                    flat
                    primary
                    raised={nestedContainer === constants.CLIENTS_TABLE}
                    onClick={() => switchNestedContainer(constants.CLIENTS_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Application
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;


        const chatroomMenu = (profile.accessLevel === 'OrgManager') ? (
            <div>
                <Button
                    style={MenuStyles.noHighlight}
                    flat
                    disabled
                    icon="chat_bubble_outline"

                >
                    <span style={MenuStyles.paddingTop}>
                        Chatrooms
                    </span>
                </Button>
                <Button
                    style={MenuStyles.usersButton}
                    label="Active"
                    flat
                    primary
                    raised={nestedContainer === constants.ACTIVE_CHATROOMS_TABLE}
                    onClick={() => switchNestedContainer(constants.ACTIVE_CHATROOMS_TABLE)}
                />
                <Button
                    style={MenuStyles.usersButton}
                    label="Archived"
                    flat
                    primary
                    raised={nestedContainer === constants.ARCHIVED_CHATROOMS_TABLE}
                    onClick={() => switchNestedContainer(constants.ARCHIVED_CHATROOMS_TABLE)}
                />
                <MenuDivider />
            </div>
        ) : null;

        const homeMenu = isAdmin ? (
            <div>
                <Button
                    className="menuButton"
                    style={MenuStyles.navigationButtons}
                    icon="home"
                    raised={nestedContainer === constants.HOME_TABLE}
                    flat
                    primary
                    onClick={() => switchNestedContainer(constants.HOME_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Home
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : (
            <div>
                <Button
                    className="menuButton"
                    style={MenuStyles.navigationButtons}
                    icon="home"
                    raised={nestedContainer === constants.MESSAGES_HOME_TABLE}
                    flat
                    primary
                    onClick={() => switchNestedContainer(constants.MESSAGES_HOME_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Home
                    </span>
                </Button>
                <MenuDivider />
            </div>
        );

        const casesMenu = (profile.accessLevel === 'OrgManager') ? (
            <div>
                <Button
                    className="menuButton"
                    style={MenuStyles.navigationButtons}
                    icon="work_outline"
                    raised={nestedContainer === constants.DONORS_HOME_TABLE}
                    flat
                    primary
                    onClick={() => switchNestedContainer(constants.DONORS_HOME_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Cases
                    </span>
                </Button>
                <MenuDivider />
            </div>
        ) : null;


        return (
            <div>
                <ReactTooltip
                    multiline
                />
                <div style={MenuStyles.logo}>
                    <img src={Images.logoLarge} alt="HTS Logo" />
                </div>
                <MenuDivider />
                {homeMenu}
                <Button
                    style={MenuStyles.noHighlight}
                    flat
                    disabled
                    icon="person"
                >
                    <span style={MenuStyles.paddingTop}>
                        Users
                    </span>
                </Button>
                {invitedUsersMenu}
                {registeringUsersMenu}
                {demoUsersMenu}
                {basicUsersMenu}
                <Button
                    style={MenuStyles.usersButton}
                    label="Pending Members"
                    flat
                    primary
                    raised={nestedContainer === constants.PENDING_MEMBERS_TABLE}
                    onClick={() => this.onSwitchNestedContainer(constants.PENDING_MEMBERS_TABLE)}
                />
                <Button
                    style={MenuStyles.usersButton}
                    label="Approved Members"
                    flat
                    primary
                    raised={nestedContainer === constants.APPROVED_MEMBERS_TABLE}
                    onClick={() => this.onSwitchNestedContainer(constants.APPROVED_MEMBERS_TABLE)}
                />
                {archivedUsersMenu}
                <MenuDivider />
                {organizationMenu}
                {casesMenu}
                {chatroomMenu}
                {/* Removing analytics option from menu per Redmine issue #4975
                <Button
                    style={MenuStyles.navigationButtons}
                    icon="assessment"
                    flat
                    primary
                    raised={nestedContainer === constants.ANALYTICS_TABLE}
                    onClick={() => switchNestedContainer(constants.ANALYTICS_TABLE)}
                >
                    <span style={MenuStyles.paddingTop}>
                        Analytics
                    </span>
                </Button>
                <MenuDivider /> */}
                {clientMenu}
                {researchMenu}
                {configurationMenu}
                {workflowBuilderMenu}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    adminPortalPermissions: state.permission.adminPortalPermissions,
    isUserDetailsPanelOpen: state.user.isUserDetailsPanelOpen,
    isAddUserToOrganizationPanelOpen: state.user.isAddUserToOrganizationPanelOpen,
});

export default connect(mapStateToProps, {
    getReferenceData: _getReferenceData,
    toggleUserDetailsPanel: _toggleUserDetailsPanel,
    toggleAddUserToOrganizationPanel: _toggleAddUserToOrganizationPanel,
})(MenuPanel);

