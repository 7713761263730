// @flow
/* eslint-disable no-alert */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import List from 'react-toolbox/lib/list/List';
import ListItem from 'react-toolbox/lib/list/ListItem';
import Button from 'react-toolbox/lib/button/Button';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import Sidebar from 'react-sidebar';
import { selectProfileAccessLevel } from 'txp-core';

import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type { Organization } from '../Utils/types';
import OrganizationClientPermissionsPanel from './OrganizationClientPermissionsPanel';
import TemplatePreferencesPanel from './TemplatePreferencesPanel';
import {
    updateOrganizationEdit as _updateOrganizationEdit,
    updateOrganization as _updateOrganization,
    updateOrganizationLicense as _updateOrganizationLicense,
    setOrgEditStatus as _setOrgEditStatus,
    toggleTypeDropdownKey as _toggleTypeDropdownKey,
    toggleLicenseDropdownKey as _toggleLicenseDropdownKey,
    deleteOrganization as _deleteOrganization,
    removeAllMembers as _removeAllMembers,
    closeOrgDetailsPanel as _closeOrgDetailsPanel,
    openTemplatePreferencesPanel as _openTemplatePreferencesPanel,
    openClientPermissionsPanel as _openClientPermissionsPanel,
    openOrganizationWorkflowsPanel as _openOrganizationWorkflowsPanel,
    clearSearch as _clearSearch,
} from '../Redux/OrganizationActions';
import OrganizationDetailsWorkflowsPanel from './OrganizationDetailsWorkflowsPanel';


type Props = {
    organizationEdit: Organization,
    organizationTypes: Array<string>,
    organizations: Array<Organization>,
    headers: any,
    accessLevel: string,
    organizationEditChanged: boolean,
    typeDropdownKey: number,
    licenseDropdownKey: number,
    isTemplatePreferencesPanelOpen: boolean,
    isClientPermissionsPanelOpen: boolean,
    isOrganizationWorkflowsPanelOpen: boolean,

    removeAllMembers: (organizationId: number) => *,
    updateOrganization: (organizationId: number, organizationName: string, organizationCode: string,
        organizationType: string, clientPermissions: any, templatePreferences: ?any) => *,
    updateOrganizationLicense: (organizationId: number, license: string) => *,
    updateOrganizationEdit: (organizationEdit: Organization) => *,
    setOrgEditStatus: (organizationEditChanged: boolean) => *,
    toggleTypeDropdownKey: () => *,
    toggleLicenseDropdownKey: () => *,
    openTemplatePreferencesPanel: () => *,
    openClientPermissionsPanel: () => *,
    openOrganizationWorkflowPanel: () => *,
    deleteOrganization: (organizationId: number) => *,
    closeOrgDetailsPanel: () => *,
    clearSearch: () => *,
};

class OrganizationDetailsPanel extends PureComponent<Props> {
    onChangeOrganizationDetails = (value: string, property: string) => {
        const {
            updateOrganizationEdit,
            organizationEdit,
            toggleTypeDropdownKey,
            toggleLicenseDropdownKey,
        } = this.props;

        if (property === 'organizationType') {
            toggleTypeDropdownKey();
        } else if (property === 'license') {
            toggleLicenseDropdownKey();
        }

        updateOrganizationEdit({ ...organizationEdit, [property]: value, });
    };

    onUpdateOrganization = () => {
        const {
            organizationEdit,
            updateOrganization,
            updateOrganizationLicense,
            organizations,
            setOrgEditStatus,
        } = this.props;

        let oldLicense = '';
        for (let i = 0; i < organizations.length; i += 1) {
            if (organizations[i].organizationId === organizationEdit.organizationId) {
                oldLicense = organizations[i].license;
            }
        }

        if (organizationEdit.license !== oldLicense) {
            updateOrganizationLicense(organizationEdit.organizationId, organizationEdit.license);
        }

        updateOrganization(organizationEdit.organizationId, organizationEdit.organizationName,
            organizationEdit.organizationCode, organizationEdit.organizationType,
            organizationEdit.clientPermissions, organizationEdit.templatePreferences);

        setOrgEditStatus(false);
    };

    onConfirmDeleteOrganization = () => {
        const {
            organizationEdit,
            deleteOrganization,
            closeOrgDetailsPanel,
            setOrgEditStatus,
            clearSearch,
        } = this.props;

        const response = window.confirm(`Are you sure you want to delete ${organizationEdit.organizationName
        }? This action cannot be undone.`);

        if (response) {
            deleteOrganization(organizationEdit.organizationId);
            setOrgEditStatus(false);
            clearSearch();
            closeOrgDetailsPanel();
        }
    };

    onConfirmRemoveAllMembers = () => {
        const {
            organizationEdit,
            removeAllMembers,
        } = this.props;

        const response = window.confirm(`Are you sure you want to remove all members from ${organizationEdit.organizationName
        }? You may have to add them back individually.`);

        if (response) {
            removeAllMembers(organizationEdit.organizationId);
        }
    };

    render() {
        const {
            organizationEdit,
            organizationTypes,
            headers,
            accessLevel,
            organizationEditChanged,
            typeDropdownKey,
            licenseDropdownKey,
            openTemplatePreferencesPanel,
            openClientPermissionsPanel,
            openOrganizationWorkflowPanel,
            isTemplatePreferencesPanelOpen,
            isClientPermissionsPanelOpen,
            isOrganizationWorkflowsPanelOpen,
            closeOrgDetailsPanel,
        } = this.props;

        if (organizationEdit === null) {
            return null;
        }

        const licenses = [
            {
                value: 'Standard',
                label: 'Standard',
            },
            {
                value: 'Premium',
                label: 'Premium',
            }
        ];

        const organizationTypeValues = (organizationTypes) ? organizationTypes.map((type) => ({ value: type, label: type, })) : [];

        return (
            <Sidebar
                sidebar={(
                    <div>
                        <div style={{
                            ...ApplicationStyles.detailsHeader,
                            paddingTop: 35,
                            paddingBottom: 35,
                        }}
                        >
                            Permissions
                        </div>
                        <OrganizationClientPermissionsPanel />
                    </div>
                )}
                open={isClientPermissionsPanelOpen}
                docked={isClientPermissionsPanelOpen}
                pullRight
                sidebarClassName="sidebar"
                shadow={false}
                styles={{
                    sidebar: {
                        width: '100%',
                    },
                }}
            >
                <Sidebar
                    sidebar={(
                        <div>
                            <div style={{
                                ...ApplicationStyles.detailsHeader,
                                paddingTop: 35,
                                paddingBottom: 35,
                                paddingRight: 50,
                            }}
                            >
                                <div style={{ paddingLeft: 30, }}>
                                    Template Preferences
                                </div>
                            </div>
                            <TemplatePreferencesPanel />
                        </div>
                    )}
                    open={isTemplatePreferencesPanelOpen}
                    docked={isTemplatePreferencesPanelOpen}
                    pullRight
                    sidebarClassName="sidebar"
                    shadow={false}
                    styles={{
                        sidebar: {
                            background: 'white',
                            zIndex: 1,
                        },
                    }}
                >
                    <Sidebar
                        sidebar={(
                            <div>
                                <div style={{
                                    ...ApplicationStyles.detailsHeader,
                                    paddingTop: 35,
                                    paddingBottom: 35,
                                }}
                                >
                                    Workflows
                                </div>
                                <OrganizationDetailsWorkflowsPanel />
                            </div>
                        )}
                        open={isOrganizationWorkflowsPanelOpen}
                        docked={isOrganizationWorkflowsPanelOpen}
                        pullRight
                        sidebarClassName="sidebar"
                        shadow={false}
                        styles={{
                            sidebar: {
                                width: '100%',
                            },
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    ...ApplicationStyles.detailsHeader,
                                    paddingTop: 35,
                                    paddingBottom: 35,
                                }}
                            >
                                <div style={ApplicationStyles.textCenter}>
                                    {organizationEdit.organizationName}
                                </div>
                            </div>
                            <div style={PanelStyles.panelBodyContainer}>
                                <div style={PanelStyles.inputPadding}>
                                    <List>
                                        {headers.map((prop, index) => {
                                            if ((!prop.isUneditable)) {
                                                if (prop.dataKey === 'organizationType') {
                                                    return (
                                                        <div style={PanelStyles.editablePadding}>
                                                            <Dropdown
                                                                key={typeDropdownKey}
                                                                auto
                                                                onChange={(newVal) => this.onChangeOrganizationDetails(newVal, prop.dataKey)}
                                                                source={organizationTypeValues}
                                                                value={organizationEdit[prop.dataKey]}
                                                                label={prop.title}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                if (prop.dataKey === 'license') {
                                                    return (
                                                        <div style={PanelStyles.editablePadding}>
                                                            <Dropdown
                                                                key={licenseDropdownKey}
                                                                auto
                                                                onChange={(newVal) => this.onChangeOrganizationDetails(newVal, prop.dataKey)}
                                                                source={licenses}
                                                                value={organizationEdit[prop.dataKey]}
                                                                label={prop.title}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div style={PanelStyles.editablePadding}>
                                                        <Input
                                                            key={index.toString()}
                                                            type="text"
                                                            label={prop.title}
                                                            value={organizationEdit[prop.dataKey]}
                                                            name={prop.dataKey}
                                                            onChange={(newVal) => this.onChangeOrganizationDetails(newVal, prop.dataKey)}
                                                        />
                                                    </div>
                                                );
                                            }

                                            return (
                                                <ListItem
                                                    key={index.toString()}
                                                    caption={organizationEdit[prop.dataKey]}
                                                    legend={organizationEdit[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                    ripple={false}
                                                />
                                            );
                                        }, this)}
                                    </List>
                                </div>
                                <div style={PanelStyles.optionButtonsContainer}>
                                    <Button
                                        label="Cancel"
                                        style={ApplicationStyles.cancelButton}
                                        onClick={closeOrgDetailsPanel}
                                    />
                                    <Button
                                        label="Update"
                                        style={!organizationEditChanged ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                                        onClick={this.onUpdateOrganization}
                                        disabled={!organizationEditChanged}
                                    />
                                </div>
                                <div>
                                    <MenuDivider />
                                    <Button
                                        label="Manage Permissions"
                                        style={PanelStyles.primaryPanelButton}
                                        onClick={openClientPermissionsPanel}
                                        primary
                                    />
                                    <MenuDivider />
                                    <Button
                                        style={PanelStyles.secondaryPanelButton}
                                        label="Manage Template Preferences"
                                        onClick={openTemplatePreferencesPanel}
                                        primary
                                    />
                                    <MenuDivider />
                                    <Button
                                        style={PanelStyles.cautionPanelButton}
                                        label="Remove All Members"
                                        onClick={this.onConfirmRemoveAllMembers}
                                        primary
                                    />
                                    <MenuDivider />
                                    {
                                        (accessLevel === 'SysAdmin') ? (
                                            <div>
                                                <Button
                                                    style={PanelStyles.warningPanelButton}
                                                    label="Delete Organization"
                                                    onClick={this.onConfirmDeleteOrganization}
                                                    primary
                                                />
                                                <MenuDivider />
                                            </div>
                                        ) : null
                                    }
                                    {
                                        (accessLevel === 'SysAdmin') ? (
                                            <div>
                                                <Button
                                                    style={PanelStyles.primaryPanelButton}
                                                    // Lists all workflows made by the organization
                                                    // change to "Manage Workflows" when workflows can be edited from this panel
                                                    label="Organization Workflows"
                                                    onClick={openOrganizationWorkflowPanel}
                                                    primary
                                                />
                                                <MenuDivider />
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </Sidebar>
                </Sidebar>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => ({
    accessLevel: selectProfileAccessLevel(state.auth),
    organizationEdit: state.organization.organizationEdit,
    organizations: state.organization.organizations,
    organizationEditChanged: state.organization.organizationEditChanged,
    typeDropdownKey: state.organization.typeDropdownKey,
    licenseDropdownKey: state.organization.licenseDropdownKey,
    organizationTypes: state.organization.organizationTypes,
    isTemplatePreferencesPanelOpen: state.organization.isTemplatePreferencesPanelOpen,
    isClientPermissionsPanelOpen: state.organization.isClientPermissionsPanelOpen,
    isOrganizationWorkflowsPanelOpen: state.organization.isOrganizationWorkflowsPanelOpen,
});

export default connect(mapStateToProps, {
    updateOrganizationEdit: _updateOrganizationEdit,
    updateOrganization: _updateOrganization,
    updateOrganizationLicense: _updateOrganizationLicense,
    setOrgEditStatus: _setOrgEditStatus,
    toggleTypeDropdownKey: _toggleTypeDropdownKey,
    toggleLicenseDropdownKey: _toggleLicenseDropdownKey,
    deleteOrganization: _deleteOrganization,
    removeAllMembers: _removeAllMembers,
    closeOrgDetailsPanel: _closeOrgDetailsPanel,
    openTemplatePreferencesPanel: _openTemplatePreferencesPanel,
    openClientPermissionsPanel: _openClientPermissionsPanel,
    openOrganizationWorkflowPanel: _openOrganizationWorkflowsPanel,
    clearSearch: _clearSearch,
})(OrganizationDetailsPanel);
