// @flow
import React, { PureComponent } from 'react';
import Table from 'react-toolbox/lib/table/Table';
import TableHead from 'react-toolbox/lib/table/TableHead';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import Input from 'react-toolbox/lib/input/Input';
import Button from 'react-toolbox/lib/button/Button';

import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';

type Props = {
    version: string,

    onUpdateVersion: (version: string) => *,
    onClose: () => *,
};

type State = {
    newVersion: string,
    newVersionArray: Array<number>,
    initialVersionArray: Array<number>,
}

class UpdateVersionDialog extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newVersion: props.version,
            newVersionArray: props.version.split('.').map((element) => parseInt(element, 10)),
            initialVersionArray: props.version.split('.').map((element) => parseInt(element, 10)),
        };
    }

    onChangeVersion = (value: number, index: number) => {
        this.setState((state) => {
            const { newVersionArray, } = state;
            newVersionArray[index] = value;

            return {
                ...state,
                newVersionArray,
                newVersion: newVersionArray.join('.'),
            };
        });
    };

    onCancel = () => {
        const {
            onClose,
        } = this.props;

        const {
            initialVersionArray,
        } = this.state;

        onClose();

        this.onChangeVersion(initialVersionArray[0], 0);
        this.onChangeVersion(initialVersionArray[1], 1);
        this.onChangeVersion(initialVersionArray[2], 2);
    };

    render() {
        const {
            newVersion,
            newVersionArray,
        } = this.state;

        const {
            onUpdateVersion,
        } = this.props;

        return (
            <div>
                <Table
                    id="myTable"
                    selectable={false}
                >
                    <TableHead>
                        <TableCell
                            colSpan={3}
                            style={ApplicationStyles.tableCell}
                        >
                            Update Android Version
                        </TableCell>
                    </TableHead>
                    <TableRow>
                        <TableCell style={PanelStyles.noBorder}>
                            <Input
                                style={PanelStyles.input}
                                type="number"
                                min="0"
                                value={newVersionArray[0]}
                                onChange={(newVal) => this.onChangeVersion(newVal, 0)}
                            />
                        </TableCell>
                        <TableCell style={PanelStyles.noBorder}>
                            <Input
                                style={PanelStyles.input}
                                type="number"
                                min="0"
                                value={newVersionArray[1]}
                                onChange={(newVal) => this.onChangeVersion(newVal, 1)}
                            />
                        </TableCell>
                        <TableCell style={PanelStyles.noBorder}>
                            <Input
                                style={PanelStyles.input}
                                type="number"
                                min="0"
                                value={newVersionArray[2]}
                                onChange={(newVal) => this.onChangeVersion(newVal, 2)}
                                required
                            />
                        </TableCell>
                    </TableRow>
                </Table>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        style={ApplicationStyles.cancelButton}
                        label="Cancel"
                        onClick={() => this.onCancel()}
                    />
                    <Button
                        style={ApplicationStyles.saveButton}
                        label="Update"
                        onClick={() => onUpdateVersion(newVersion)}
                    />
                </div>
            </div>
        );
    }
}

export default UpdateVersionDialog;
