// @flow
import React, { PureComponent } from 'react';
import IconMenu from 'react-toolbox/lib/menu/IconMenu';
import MenuItem from 'react-toolbox/lib/menu/MenuItem';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import FontIcon from 'react-toolbox/lib/font_icon';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { appConfiguration } from '../AppConfiguration';
import type { UserProfile } from '../Utils/types';
import Images from '../Themes/Images';
import HeaderStyles from './Styles/HeaderStyles';

type Props = {
    profile: UserProfile,
    sidebarActive: boolean,

    onSubmitLogout: () => *,
    onLeftSidebarOpen: () => *,
    onLeftSidebarClose: () => *,
};


class Header extends PureComponent<Props> {
    launchHelp = () => {
        window.open(process.env.REACT_APP_LAUNCH_HELP_URL, '_blank');
    };

    renderArrow() {
        const {
            onLeftSidebarClose,
            onLeftSidebarOpen,
            sidebarActive,
        } = this.props;

        return (

            <div className="arrowContainer">
                <button
                    className="arrowButton"
                    type="button"
                    tabIndex="0"
                    data-tip={sidebarActive ? 'Hide Menu' : 'Show Menu'}
                    data-delay-show="500"
                    data-effect="solid"
                    onClick={sidebarActive ? onLeftSidebarClose : onLeftSidebarOpen}
                >
                    <FontIcon
                        value={sidebarActive ? 'arrow_back_ios' : 'arrow_forward_ios'}
                        alt="Arrow"
                    />
                </button>
            </div>
        );
    }

    render() {
        const copyrightMessage = `${new Date().getFullYear()} OmniLife Health`;
        const licenseLinkText = 'License & Attribution';
        const licenseLinkUrl = process.env.REACT_APP_LICENSE_ATTRIBUTION ?? '';
        const appVersionMessage = `OmniLife Admin Portal ${appConfiguration().version}`;

        const {
            profile,
            onSubmitLogout,
        } = this.props;

        const fullName = (profile.firstName && profile.lastName) ? `${profile.firstName} ${profile.lastName}`
            : (profile.firstName) ? profile.firstName
                : (profile.lastName) ? profile.lastName
                    : profile.email;

        return (
            <div>
                <ReactTooltip
                    multiline
                />
                <div className="headerContainer">
                    <div className="leftHeaderContainer">
                        {this.renderArrow()}
                        <div className="arrowContainer">
                            {`Welcome, ${fullName}!`}
                        </div>
                    </div>
                    <div className="centerHeaderContainer">
                        <div style={HeaderStyles.paddingHorizontal}>
                            <img height={30} width={30} src={Images.logo} alt="HTS Logo" />
                        </div>
                        OmniLife Admin Portal
                    </div>
                    <div className="rightHeaderContainer">
                        {/* {
                            profile.accessLevel === 'OrgManager' ? (
                                <div
                                    style={{
                                        ...HeaderStyles.textButtonWrapper,
                                        borderColor: nestedContainer === constants.MESSAGES_HOME_TABLE ? Colors.brandPrimary : Colors.white,
                                    }}
                                >
                                    <Button
                                        style={HeaderStyles.textButton}
                                        label="Home"
                                        flat
                                        onClick={() => switchNestedContainer(constants.MESSAGES_HOME_TABLE)}
                                    />
                                </div>
                            ) : null
                        }
                        {
                            profile.accessLevel === 'OrgManager' ? (
                                <div
                                    style={{
                                        ...HeaderStyles.textButtonWrapper,
                                        borderColor: nestedContainer === constants.DONORS_HOME_TABLE ? Colors.brandPrimary : Colors.white,
                                    }}
                                >
                                    <Button
                                        style={HeaderStyles.textButton}
                                        label="Cases"
                                        flat
                                        onClick={() => switchNestedContainer(constants.DONORS_HOME_TABLE)}
                                    />
                                </div>
                            ) : null
                        } */}
                        <IconMenu
                            icon="more_vert"
                            position="topRight"
                            menuRipple={false}
                        >
                            <MenuItem
                                value="help"
                                icon="help"
                                caption="Help"
                                ripple={false}
                                disabled={false}
                                onClick={this.launchHelp}
                            />
                            <MenuItem
                                value="logout"
                                icon="exit_to_app"
                                caption="Logout"
                                ripple={false}
                                onClick={onSubmitLogout}
                            />
                            <MenuDivider />
                            <div style={{ ...HeaderStyles.versionText, ...HeaderStyles.paddingHorizontal, }}>
                                <div>
                                    <span>
                                        &copy;
                                        {copyrightMessage}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        All Rights Reserved
                                    </span>
                                </div>
                                <a style={HeaderStyles.versionText} href={licenseLinkUrl}>{licenseLinkText}</a>
                                <div>
                                    <span>
                                        {appVersionMessage}
                                    </span>
                                </div>
                            </div>
                        </IconMenu>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile || {},
});
export default connect(mapStateToProps, null)(Header);
