// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import { deleteUsers as _deleteUsers } from '../../Redux/UserActions';

import type { UserProfile } from '../../Utils/types';

type Props = {
    label?: string,
    disabled: boolean,
    bulkEditUsers: Array<UserProfile>,

    deleteUsers: typeof _deleteUsers,
};

class DeactivateAccountsButton extends PureComponent<Props> {
    // Resolve flow/eslint conflict:
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        label: 'Deactivate Accounts',
    };

    onBulkDeactivateUsers = () => {
        const { bulkEditUsers, deleteUsers, } = this.props;
        const numSelected = bulkEditUsers.length;
        const confirm = window.confirm(`Are you sure you want to delete the ${
            numSelected > 1 ? `${numSelected} ` : ''
        }selected user${numSelected > 1 ? 's' : ''}? This action cannot be undone.`);

        if (confirm) {
            deleteUsers(bulkEditUsers.map(({ userId, }) => userId));
        }
    };

    render() {
        const { disabled, label, } = this.props;

        return (
            <Button
                className="warning-button"
                disabled={disabled}
                raised
                accent
                label={label}
                onClick={this.onBulkDeactivateUsers}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    bulkEditUsers: state.user.bulkEditUsers,
});

export default connect(mapStateToProps, {
    deleteUsers: _deleteUsers,
})(DeactivateAccountsButton);
