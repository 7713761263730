// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import ArchivedUsers from '../Components/UserPages/ArchivedUsers';
import InvitedUsers from '../Components/UserPages/InvitedUsers';
import ApprovedMembers from '../Components/UserPages/ApprovedMembers';
import PendingMembers from '../Components/UserPages/PendingMembers';
import DemoUsers from '../Components/UserPages/DemoUsers';
import RegisteringUsers from '../Components/UserPages/RegisteringUsers';
import BasicUsers from '../Components/UserPages/BasicUsers';
import {
    clearSearch as _clearSearch,
} from '../Redux/UserActions';
import { UserTypes } from '../Utils/constants';

type Props = {
    userType: string,

    clearSearch: () => *,
};

class UsersPage extends PureComponent<Props> {
    componentDidMount() {
        this.props.clearSearch();
    }

    render() {
        const { userType, } = this.props;

        switch (userType) {
            case UserTypes.ARCHIVED: {
                return <ArchivedUsers />;
            }
            case UserTypes.INVITED: {
                return <InvitedUsers />;
            }
            case UserTypes.APPROVED: {
                return <ApprovedMembers />;
            }
            case UserTypes.DEMO: {
                return <DemoUsers />;
            }
            case UserTypes.PENDING: {
                return <PendingMembers />;
            }
            case UserTypes.REGISTERING: {
                return <RegisteringUsers />;
            }
            case UserTypes.BASIC:
            default: {
                return <BasicUsers />;
            }
        }
    }
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
    clearSearch: _clearSearch,
})(UsersPage);
