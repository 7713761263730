/* eslint-disable import/no-self-import */
// @flow
import { envReducer, authReducer } from 'txp-core';

import allSagas from '../Saga';
import SagaManager from '../Saga/SagaManager';
import configureStore from './configureStore';
import applicationReducer from './ApplicationActions';
import loadingReducer from './LoadingActions';
import userReducer from './UserActions';
import organizationReducer from './OrganizationActions';
import clientReducer from './ClientActions';
import chatroomReducer from './ChatroomActions';
import donorReducer from './DonorActions';
import permissionReducer from './PermissionActions';
import offerReducer from './OfferActions';
import referenceReducer from './ReferenceDataActions';

export const reducers = {
    application: applicationReducer,
    auth: authReducer,
    loading: loadingReducer,
    user: userReducer,
    organization: organizationReducer,
    client: clientReducer,
    chatroom: chatroomReducer,
    donor: donorReducer,
    env: envReducer,
    permission: permissionReducer,
    offer: offerReducer,
    reference: referenceReducer,
};

const assembleStore = () => {
    const res = configureStore(reducers);
    const {
        store,
        persistor,
        sagaMiddleware,
        replaceReducer,
    } = res;

    // Kick off the root Saga
    SagaManager.run(sagaMiddleware, allSagas);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('.').reducers; // eslint-disable-line global-require
            replaceReducer(nextRootReducer);

            const newYieldedSagas = require('../Saga').default; // eslint-disable-line global-require

            SagaManager.cancel(store);
            const newManager = require('../Saga/SagaManager').default; // eslint-disable-line global-require
            newManager.run(sagaMiddleware, newYieldedSagas, true);
        });
    }

    return {
        store,
        persistor,
    };
};

export default assembleStore;
