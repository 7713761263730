const constants = {
    CLIENT_PERMISSION_TYPES: [
        {
            type: 'unsigned_integer',
            validate(value) {
                return (Number.isInteger(value) && value >= 0);
            },
        },
        {
            type: 'boolean',
            validate(value) {
                return (typeof value === 'boolean');
            },
        }
    ],
    PENDING_MEMBERS_TABLE: 'PENDING_MEMBERS_TABLE',
    APPROVED_MEMBERS_TABLE: 'APPROVED_MEMBER_TABLE',
    USER_TABLE: 'USER_TABLE',
    DEMO_USER_TABLE: 'DEMO_USER_TABLE',
    MESSAGE_TABLE: 'MESSAGE_TABLE',
    ORGANIZATION_TABLE: 'ORGANIZATION_TABLE',
    CLIENTS_TABLE: 'CLIENTS_TABLE',
    HOME_TABLE: 'HOME_TABLE',
    MESSAGES_HOME_TABLE: 'MESSAGES_HOME_TABLE',
    DONORS_HOME_TABLE: 'DONORS_HOME_TABLE',
    ANALYTICS_TABLE: 'ANALYTICS_TABLE',
    ARCHIVED_USER_TABLE: 'ARCHIVED_USER_TABLE',
    INVITED_USER_TABLE: 'INVITED_USER_TABLE',
    BASIC_USER_TABLE: 'BASIC_USER_TABLE',
    ACTIVE_CHATROOMS_TABLE: 'ACTIVE_CHATROOMS_TABLE',
    ARCHIVED_CHATROOMS_TABLE: 'ARCHIVED_CHATROOMS_TABLE',
    RESEARCH_TABLE: 'RESEARCH_TABLE',
    CONFIG_TABLE: 'CONFIG_TABLE',
    WORKFLOW_BUILDER: 'WORKFLOW_BUILDER',
};

export const UserTypes = {
    ARCHIVED: 'X',
    INVITED: 'I',
    APPROVED: 'A',
    DEMO: 'D',
    PENDING: 'P',
    REGISTERING: 'R',
    BASIC: 'B',
};

export default constants;
