import {
    all, fork, select, takeLatest, takeEvery, take,
} from 'redux-saga/effects';
import { rootAuthSaga } from 'txp-core';

import getPersistStatus from './GetPersistStatus';
import {
    loadUsersSaga,
    loadAllUsersSaga,
    loadTeamsSaga,
    loadPlatformUsersSaga,
    loadPendingMembersSaga,
    loadOrganizationMembersSaga,
    loadRegisteringUsersSaga,
    loadInvitedUsersSaga,
    approvePendingMember,
    approvePendingMembers,
    denyPendingMember,
    addOrganizationMember,
    addAndUpdateOrganizationMember,
    removeOrganizationMember,
    removeOrganizationMembers,
    updateOrganizationMember,
    createDemoUser,
    deleteUser,
    deleteUsers,
    updateUser,
    updateUserUIPermissions,
    verifyUserEmail,
    cancelEmailUpdate,
    resendVerificationEmailSaga,
    resendInvitationEmailSaga,
    downloadAvatarSaga,
    completeRegisteringUserSaga,
    deleteRegisteringUserSaga,
    deleteRegisteringUsersSaga,
    loadRemovedMembersSaga,
    inviteUsersSaga,
} from './UserSaga';
import {
    loadOrganizationsSaga,
    loadOrganizationTemplateOptions,
    addOrganization,
    updateOrganization,
    updateOrganizationLicense,
    deleteOrganization,
    removeAllMembers,
    organizationMetrics,
    homeOrganizationMetricsSaga,
    organizationUserActivity,
    loadBusinessMetricsSaga,
    loadOrganizationTypesSaga,
    loadTemplateOptions,
} from './OrganizationSaga';
import {
    loadVersionsSaga,
    updateiOSVersion,
    updateAndroidVersion,
} from './ClientSaga';
import {
    loadChatroomsSaga,
    loadActiveChatroomsData,
    loadArchivedChatroomsData,
    generateActiveChatroomPDFSaga,
    generateArchivedChatroomPDFSaga,
    deleteChatroomSaga,
} from './ChatroomSaga';
import {
    generateDonorPDFSaga,
    loadDonorTasksSaga,
    loadDonorsSaga,
    loadDonorSaga,
    loadDonorTimelineSaga,
    loadTasksSaga,
    loadDonorNetPDFFields,
    updateCaseStatusSaga,
} from './DonorSaga';
import {
    downloadCDSFileSaga,
} from './OfferSaga';
import authorized from './AuthSaga';
import {
    addPermissionSaga,
    getResourcePermissionsSaga,
    getAdminPortalPermissionsSaga,
    removePermissionSaga,
} from './PermissionSaga';
import {
    getReferenceDataSaga,
    uploadReferenceDataSaga,
    updateInactiveSaga,
    downloadReferenceDataSaga,
    updateWorkflowDetailsSaga,
    createWorkflowSaga,
    updateWorkflowSaga,
    updateWorkflowPreferencesSaga,
    createWorkflowPreferencesSaga,
} from './ReferenceDataSaga';

function* startRootAuthSaga() {
    // If not rehydrated, wait for state rehydrate to complete
    const rehydrated = yield select(getPersistStatus);
    if (!rehydrated) {
        yield take('persist/REHYDRATE');
    }

    yield all([
        takeLatest('Donors/LOAD', loadDonorsSaga),
        takeLatest('Donors/LOAD_DONOR', loadDonorSaga),
        takeLatest('Donors/LOAD_DONOR_TASKS', loadDonorTasksSaga),
        takeLatest('Donors/LOAD_TASKS', loadTasksSaga),
        takeLatest('Donors/GENERATE_PDF', generateDonorPDFSaga),
        takeEvery('Donors/LOAD_DONOR_TIMELINE', loadDonorTimelineSaga),
        takeLatest('Donors/LOAD_DONORNET_PDFFIELDS', loadDonorNetPDFFields),
        takeLatest('Donors/UPDATE_CASE_STATUS', updateCaseStatusSaga),

        takeLatest('Chatrooms/LOAD', loadChatroomsSaga),
        takeLatest('Chatrooms/LOAD_ACTIVE_CHATROOM_DATA', loadActiveChatroomsData),
        takeLatest('Chatrooms/LOAD_ARCHIVED_CHATROOM_DATA', loadArchivedChatroomsData),
        takeEvery('Chatrooms/GENERATE_ACTIVE_CHATROOM_PDF', generateActiveChatroomPDFSaga),
        takeEvery('Chatrooms/GENERATE_ARCHIVED_CHATROOM_PDF', generateArchivedChatroomPDFSaga),
        takeLatest('Chatrooms/DELETE_CHATROOM', deleteChatroomSaga),

        takeLatest('Clients/LOAD_VERSIONS', loadVersionsSaga),
        takeLatest('Clients/UPDATE_IOS_VERSION', updateiOSVersion),
        takeLatest('Clients/UPDATE_ANDROID_VERSION', updateAndroidVersion),

        takeLatest('Users/LOAD_USER_AVATARS', downloadAvatarSaga),
        takeEvery('Users/LOAD', loadUsersSaga),
        takeLatest('Users/LOAD_ALL_USERS', loadAllUsersSaga),
        takeLatest('Users/LOAD_TEAMS', loadTeamsSaga),
        takeEvery('Users/LOAD_PLATFORM_USERS', loadPlatformUsersSaga),
        takeLatest('Users/LOAD_PENDING_MEMBERS', loadPendingMembersSaga),
        takeLatest('Users/LOAD_REMOVED_MEMBERS', loadRemovedMembersSaga),
        takeLatest('Users/LOAD_ORGANIZATION_MEMBERS', loadOrganizationMembersSaga),
        takeLatest('Users/LOAD_REGISTERING_USERS', loadRegisteringUsersSaga),
        takeLatest('Users/LOAD_INVITED_USERS', loadInvitedUsersSaga),
        takeLatest('Users/LOAD_TEMPLATE_OPTIONS', loadTemplateOptions),
        takeEvery('Users/APPROVE_PENDING_MEMBER', approvePendingMember),
        takeEvery('Users/APPROVE_PENDING_MEMBERS', approvePendingMembers),
        takeEvery('Users/DENY_PENDING_MEMBER', denyPendingMember),
        takeLatest('Users/ADD_ORGANIZATION_MEMBER', addOrganizationMember),
        takeLatest('Users/REMOVE_ORGANIZATION_MEMBER', removeOrganizationMember),
        takeLatest('Users/REMOVE_ORGANIZATION_MEMBERS', removeOrganizationMembers),
        takeLatest('Users/UPDATE_ORGANIZATION_MEMBER', updateOrganizationMember),
        takeLatest('Users/ADD_AND_UPDATE_ORGANIZATION_MEMBER', addAndUpdateOrganizationMember),
        takeLatest('Users/CREATE_DEMO_USER', createDemoUser),
        takeLatest('Users/DELETE_USER', deleteUser),
        takeLatest('Users/DELETE_USERS', deleteUsers),
        takeLatest('Users/UPDATE_USER', updateUser),
        takeLatest('Users/UPDATE_USER_UI_PERMISSIONS', updateUserUIPermissions),
        takeLatest('Users/VERIFY_USER_EMAIL', verifyUserEmail),
        takeLatest('Users/CANCEL_EMAIL_UPDATE', cancelEmailUpdate),
        takeLatest('Users/RESEND_VERIFICATION_EMAIL', resendVerificationEmailSaga),
        takeLatest('Users/RESEND_INVITATION_EMAIL', resendInvitationEmailSaga),
        takeLatest('Users/COMPLETE_REGISTERING_USER', completeRegisteringUserSaga),
        takeLatest('Users/DELETE_REGISTERING_USER', deleteRegisteringUserSaga),
        takeLatest('Users/DELETE_REGISTERING_USERS', deleteRegisteringUsersSaga),
        takeLatest('Users/INVITE_USERS', inviteUsersSaga),

        takeLatest('Organizations/LOAD_TEMPLATE_OPTIONS', loadOrganizationTemplateOptions),
        takeLatest('Organizations/LOAD', loadOrganizationsSaga),
        takeLatest('Organizations/LOAD_TYPES', loadOrganizationTypesSaga),
        takeLatest('Organizations/ADD', addOrganization),
        takeLatest('Organizations/UPDATE', updateOrganization),
        takeLatest('Organizations/UPDATE_LICENSE', updateOrganizationLicense),
        takeLatest('Organizations/DELETE', deleteOrganization),
        takeLatest('Organizations/REMOVE_ALL_MEMBERS', removeAllMembers),
        takeLatest('Organizations/ORGANIZATION_METRICS', organizationMetrics),
        takeLatest('Organizations/LOAD_HOME_ORGANIZATION_METRICS', homeOrganizationMetricsSaga),
        takeLatest('Organizations/ORGANIZATION_USER_ACTIVITY', organizationUserActivity),
        takeLatest('Organizations/BUSINESS_METRICS', loadBusinessMetricsSaga),

        takeLatest('Permission/GET_RESOURCE_PERMISSIONS', getResourcePermissionsSaga),
        takeLatest('Permission/GET_ADMIN_PORTAL_PERMISSIONS', getAdminPortalPermissionsSaga),
        takeLatest('Permission/ADD', addPermissionSaga),
        takeLatest('Permission/REMOVE', removePermissionSaga),

        takeLatest('Offer/FILE_DOWNLOAD', downloadCDSFileSaga),

        takeLatest('Reference/GET_REFERENCE', getReferenceDataSaga),
        takeLatest('Reference/UPLOAD_REFERENCE_DATA', uploadReferenceDataSaga),
        takeLatest('Reference/UPDATE_INACTIVE', updateInactiveSaga),
        takeLatest('Reference/DOWNLOAD', downloadReferenceDataSaga),
        takeLatest('Reference/UPDATE_WORKFLOW_DETAILS', updateWorkflowDetailsSaga),
        takeLatest('Reference/CREATE_WORKFLOW', createWorkflowSaga),
        takeLatest('Reference/UPDATE_WORKFLOW', updateWorkflowSaga),
        takeLatest('Reference/UPDATE_WORKFLOW_PREFERENCES', updateWorkflowPreferencesSaga),
        takeLatest('Reference/CREATE_WORKFLOW_PREFERENCES', createWorkflowPreferencesSaga)
    ]);
}

export function* rootAuthenticatedSaga() {
    yield takeLatest('Auth/AUTHORIZED', authorized);

    while (true) {
        const task = yield fork(startRootAuthSaga);

        // Wait for it ...
        yield take(['Auth/LOGOUT_SUCCESS', 'Auth/TOKEN_EXPIRED', 'Auth/FORCE_RELOGIN']);

        // ... and cancel the task
        if (task.isRunning()) {
            task.cancel();
        }

        window.location.reload();
    }
}

const allSagas = [rootAuthenticatedSaga, rootAuthSaga];
export default allSagas;
