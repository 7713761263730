// @flow
import type { Saga } from 'redux-saga';
import { put, select, all } from 'redux-saga/effects';
import { Resource } from 'tg-resources';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import api from '../Services/Api';
import { apiFetch, apiPut } from './ApiSaga';
import {
    transformGeneralChatroomResponse,
    transformChatroomResponse,
    chatroomDataToPdfData,
    chatroomPdfContent,
} from './ChatroomSaga';
import { pushError } from '../Redux/ApplicationActions';
import { getFileAsDataURI } from '../Utils/downloadFile';
import { getAction, getUserName } from '../Utils/createCasePDF';
import { finishLoading, startLoading } from '../Redux/LoadingActions';
import {
    loadedDonors,
    loadedDonor,
    loadedTasks,
    loadedDonorTimeline,
    loadedDonorTasks,
    loadedDonorNetPDFFields,
    updateCaseSuccess,
} from '../Redux/DonorActions';
import type {
    LoadDonor,
    LoadDonorTasks,
    LoadDonorTimeline,
    GenerateDonorPDF,
    UpdateCaseStatus,
} from '../Redux/DonorActions';

import type {
    Donor,
    RemoteMediaUri,
    Task,
    FollowerGroup,
    ApiCaseNote,
    CaseNote,
    UserProfile,
    Chatrooms,
    Chatroom,
    ChatroomData,
    ChatroomPdfData,
    FollowerRoomTasks,
} from '../Utils/types';

import OAHeart from '../Utils/Forms/OAHeart';
import OAIntestine from '../Utils/Forms/OAIntestine';
import OAKidneys from '../Utils/Forms/OAKidneys';
import OALiver from '../Utils/Forms/OALiver';
import OALungs from '../Utils/Forms/OALungs';
import OAPancreas from '../Utils/Forms/OAPancreas';
import ORHeart from '../Utils/Forms/ORHeart';
import ORIntestine from '../Utils/Forms/ORIntestine';
import ORKidneys from '../Utils/Forms/ORKidneys';
import ORLiver from '../Utils/Forms/ORLiver';
import ORLungs from '../Utils/Forms/ORLungs';
import ORPancreas from '../Utils/Forms/ORPancreas';
import { BOOLEAN } from '../Utils/Forms/FormTypes';
import {
    getDateString, getFormattedDateTimeString, getElapsedTimeString, calculateAgeFromDOB,
} from '../Utils/datetime';
import { parseResponseTextError } from '../Utils/flattenError';

import Colors from '../Themes/Colors';
import Images from '../Themes/Images';

// URL to the media message download api
const mediaHost = process.env.REACT_APP_API_ROOT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

export function* loadDonorsSaga(): Saga<void> {
    yield put(startLoading('donors'));

    const { result, error, } = yield apiFetch(api.txp.donors);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading donors failed, are you online?', error));
        } else {
            yield put(pushError('Loading donors failed, try again later', error));
        }
        yield put(finishLoading('donors'));
    } else {
        const donorsArray = result.donors;

        const donors = {};

        for (let i = 0; i < donorsArray.length; i += 1) {
            const organsArray = donorsArray[i].organs;
            const organs = [];

            for (let j = 0; j < organsArray.length; j += 1) {
                organs.push({
                    organId: organsArray[j].organ_id,
                    organType: organsArray[j].organ_type,
                });
            }
            let age = '';
            if (donorsArray[i].date_of_birth && donorsArray[i].date_of_birth.length >= 10) {
                age = calculateAgeFromDOB(donorsArray[i].date_of_birth.substring(0, 10));
            }

            donors[`${donorsArray[i].donor_id}`] = {
                donorId: donorsArray[i].donor_id,
                opoDonorId: donorsArray[i].opo_donor_id,
                donorType: donorsArray[i].donor_type,
                unosId: donorsArray[i].unos_id,
                userId: donorsArray[i].user_id,
                createDate: donorsArray[i].create_date,
                closed: donorsArray[i].closed,
                currentLocation: donorsArray[i].current_location || '',
                dob: donorsArray[i].date_of_birth || '',
                age,
                sex: donorsArray[i].sex || '',
                workflow: donorsArray[i].workflow || '',
                organs,
                tasks: {},
                timeline: [],
                lastModified: donorsArray[i].last_modified,
            };
        }

        yield put(loadedDonors(donors));
        yield put(finishLoading('donors'));
    }
}

export function* loadDonorSaga(action: LoadDonor): Saga<void> {
    yield put(startLoading('donor'));

    const {
        donorId,
    } = action;

    const { result, error, } = yield apiFetch(api.txp.donor, {
        donorId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading donor failed, are you online?', error));
        } else {
            yield put(pushError('Loading donor failed, try again later', error));
        }
        yield put(finishLoading('donor'));
    } else {
        let age = '';
        if (result.date_of_birth && result.date_of_birth.length >= 10) {
            age = calculateAgeFromDOB(result.date_of_birth.substring(0, 10));
        }
        const currentDonor = yield select((state) => state.donor.donors[donorId]);

        const donor = {
            donorId: result.donor_id,
            opoDonorId: result.opo_donor_id,
            donorType: result.donor_type,
            unosId: result.unos_id,
            userId: result.user_id,
            createDate: result.create_date,
            closed: result.closed,
            currentLocation: result.current_location || '',
            dob: result.date_of_birth || '',
            age,
            sex: result.sex || '',
            race: result.race || '',
            height: result.height || '',
            weight: result.weight || '',
            typeOfDeath: result.type_of_death || '',
            unetLink: result.unet_link || '',
            workflow: result.workflow || '',
            lastModified: result.last_modified,
            lastModifiedBy: result.last_modified_by,
            organs: currentDonor.organs,
            tasks: currentDonor.tasks,
            timeline: currentDonor.timeline,

        };

        yield put(loadedDonor(donor));
        yield put(finishLoading('donor'));
    }
}

export function* loadTasksSaga(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.tasks);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading tasks failed, are you online?', error));
        } else {
            yield put(pushError('Loading tasks failed, try again later', error));
        }
        yield put(finishLoading('tasks'));
    } else {
        yield put(loadedTasks(result.tasks));
    }
}

export function* loadDonorNetPDFFields(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.donorNetPDFFields);

    if (error) {
        yield put(pushError('Loading DonorNet PDF fields failed, try again later', error));
    } else {
        yield put(loadedDonorNetPDFFields(result));
    }
}

export function* loadDonorTimelineSaga(action: LoadDonorTimeline): Saga<void> {
    const {
        donorId,
    } = action;

    yield put(startLoading('donorTimeline'));

    const { result, error, } = yield apiFetch(api.txp.donorTimeline, {
        donorId,
    });

    if (error) {
        // TODO
    } else {
        const timelineResult = result.timeline;

        const timeline = [];
        for (let i = 0; i < timelineResult.length; i += 1) {
            timeline.push({
                taskId: timelineResult[i].task_id,
                userId: timelineResult[i].user_id,
                completed: timelineResult[i].completed,
                notApplicable: timelineResult[i].not_applicable,
                noteContent: timelineResult[i].note_content,
                updateTime: timelineResult[i].update_time,
            });
        }

        yield put(loadedDonorTimeline(donorId, timeline));
    }

    yield put(finishLoading('donorTimeline'));
}

export function* loadDonorTasksSaga(action: LoadDonorTasks): Saga<void> {
    yield put(startLoading('donorTasks'));

    const {
        donorId,
    } = action;

    const { result, error, } = yield apiFetch(api.txp.donorTasks, {
        donorId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading donor failed, are you online?', error));
        } else {
            yield put(pushError('Loading donorsfailed, try again later', error));
        }
        yield put(finishLoading('donorTasks'));
    } else {
        const donorTasks = {};

        for (let i = 0; i < result.tasks.length; i += 1) {
            donorTasks[result.tasks[i].task_id] = {
                taskId: result.tasks[i].task_id,
                userId: result.tasks[i].user_id,
                completed: result.tasks[i].completed,
                notApplicable: result.tasks[i].not_applicable,
                noteContent: result.tasks[i].note_content,
                taskData: result.tasks[i].task_data,
            };
        }

        yield put(loadedDonorTasks(donorId, donorTasks));
        yield put(finishLoading('donorTasks'));
    }
}

function getTaskForm(task: Task, taskId: number, forms: Array<any>) {
    for (let i = 0; i < forms.length; i += 1) {
        if (task.formId === forms[i].key) {
            return forms[i];
        }
    }

    // Next check to see if it is any of the default
    //  forms based on the task id.
    switch (taskId) {
        case 13:
            return OAHeart;
        case 14:
            return OALungs;
        case 15:
            return OALiver;
        case 16:
            return OAIntestine;
        case 17:
            return OAPancreas;
        case 18:
            return OAKidneys;
        case 29:
            return ORHeart;
        case 30:
            return ORLungs;
        case 31:
            return ORLiver;
        case 32:
            return ORIntestine;
        case 33:
            return ORPancreas;
        case 34:
            return ORKidneys;
        default:
            return null;
    }
}

const getDonorMediaLocation = (donorId: number, pictureUri: string, accessToken: string): RemoteMediaUri => {
    const basePath = `donor/${donorId}/file/`;

    return {
        uri: `${mediaHost}${basePath}${encodeURIComponent(pictureUri)}`,
        headers: {
            Apikey: apiKey,
            Authorization: `Bearer ${accessToken}`,
        },
    };
};

function* downloadFile(source: RemoteMediaUri) {
    const { uri, headers, } = source;

    const downloadedFileBlob = yield getFileAsDataURI({
        fromUrl: uri,
        headers,
        useLock: true,
    });

    return downloadedFileBlob;
}

function* getImageData(donorId, imageData) {
    let imageContent;
    const accessToken = yield select((state) => state.auth.accessToken);
    const imageSource = getDonorMediaLocation(donorId, imageData.file_path, accessToken);
    let hasError = false;
    try {
        const fileResponse = yield downloadFile(imageSource);
        if (fileResponse.error) {
            hasError = true;
        } else {
            imageContent = [
                {
                    image: fileResponse.result.dataURI,
                    width: 500,
                },
                {
                    text: imageData.display_name,
                    style: 'fieldTitle',
                }
            ];
        }
    } catch (err) {
        hasError = true;
    }

    if (hasError) {
        imageContent = {
            text: [
                {
                    text: `${imageData.display_name}: `,
                    style: 'fieldTitle',
                },
                'Attached Image Unavailable'
            ],
            style: 'fieldLine',
        };
    }

    return imageContent;
}

function* getFileData(donorId, fileData) {
    const mimeType = fileData.mime_type;
    let data;
    if (mimeType === 'image/jpg' || mimeType === 'image/jpeg' || mimeType === 'image/png') {
        data = yield getImageData(donorId, fileData);
    } else if (mimeType === 'image/gif' || mimeType === 'video/mp4' || mimeType === 'video/quicktime') {
        data = { text: [{ text: `${fileData.display_name}: `, style: 'fieldTitle', }, 'Attached Video'], style: 'fieldLine', };
    } else if (mimeType === 'application/pdf') {
        data = { text: [{ text: `${fileData.display_name}: `, style: 'fieldTitle', }, 'Attached PDF'], style: 'fieldLine', };
    } else {
        data = { text: [{ text: `${fileData.display_name}: `, style: 'fieldTitle', }, 'Attached File'], style: 'fieldLine', };
    }

    return data;
}

function* getAttachmentData(donorId) {
    // Fetch the files list for the donor
    const filesResponse = yield apiFetch(api.txp.donorFiles, {
        donorId,
    });

    if (filesResponse.error || !filesResponse.result || !filesResponse.result.media_files || !Array.isArray(filesResponse.result.media_files)) {
        yield put(pushError('Loading donor files failed, try again later', filesResponse.error));

        return { text: 'An error occurred generating the attachment data.', };
    }

    const attachmentData = [];
    const fileDataList = filesResponse.result.media_files;
    for (let i = 0; i < fileDataList.length; i += 1) {
        const fileData = fileDataList[i];
        attachmentData.push(yield getFileData(donorId, fileData));
    }

    return attachmentData;
}

function* getCaseFollowerGroups(caseId: number) {
    const { result, error, } = yield apiFetch(api.txp.caseFollowersDetailed, {
        caseId,
    });

    if (error) {
        yield put(pushError('Failed to get the follower group list', error));
        return [];
    }

    const followerGroups: FollowerGroup[] = result.followers.map((group) => ({
        id: group.follower_id,
        chatroomId: group.chatroom_id,
        name: group.name,
        taskIds: group.task_ids,
        members: group.member_users.map((user) => ({
            id: user.user_id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            profilePicture: user.profile_picture,
        })),
    }));

    return followerGroups;
}

function* getCaseNotes(caseId: number) {
    const { result, error, }: { result: { notes: ApiCaseNote[] }, error: any } = yield apiFetch(api.txp.caseNotes, {
        caseId,
    });

    if (error) {
        yield put(pushError('Failed to get the case notes', error));
        return [];
    }

    const notes: CaseNote[] = result.notes.map((note) => ({
        id: note.note_id,
        userId: note.user_id,
        caseId: note.case_id,
        note: note.note,
        createDate: note.create_date,
    }));

    return notes;
}

function caseNotesToPdfText(notes: CaseNote[], members: UserProfile[]) {
    return notes.map((note) => ([
        { text: `${getFormattedDateTimeString(note.createDate)}`, style: 'caseTimelineDate', },
        {
            text: [
                `${note.note}\nby `,
                { text: `${getUserName(note.userId, members)}`, style: 'user', }
            ],
            style: 'marginBottom',
        }
    ]));
}

function getPDFCaseDetails(donor: Donor, organizationMembers: UserProfile[]) {
    return {
        stack: [
            (donor.opoDonorId) ? { text: [{ text: 'Referral ID: ', style: 'caseDetailTitle', }, `${donor.opoDonorId}`], } : null,
            (donor.unosId) ? { text: [{ text: 'UNOS ID: ', style: 'caseDetailTitle', }, `${donor.unosId}`], } : null,
            (donor.currentLocation) ? { text: [{ text: 'Hospital: ', style: 'caseDetailTitle', }, `${donor.currentLocation}`], } : null,
            (donor.typeOfDeath) ? { text: [{ text: 'Case Criteria: ', style: 'caseDetailTitle', }, `${donor.typeOfDeath}`], } : null,
            (donor.dob) ? {
                text: [{ text: 'DOB: ', style: 'caseDetailTitle', }, `${getDateString(new Date(donor.dob))}`],
                style: 'caseDetail',
            } : null,
            (donor.age) ? { text: [{ text: 'Age: ', style: 'caseDetailTitle', }, `${donor.age}`], } : null,
            (donor.race) ? { text: [{ text: 'Race: ', style: 'caseDetailTitle', }, `${donor.race}`], } : null,
            (donor.sex) ? { text: [{ text: 'Sex: ', style: 'caseDetailTitle', }, `${donor.sex}`], } : null,
            (donor.weight) ? { text: [{ text: 'Weight (kg): ', style: 'caseDetailTitle', }, `${donor.weight}`], } : null,
            (donor.height) ? { text: [{ text: 'Height (cm): ', style: 'caseDetailTitle', }, `${donor.height}`], } : null,
            (donor.unetLink) ? { text: [{ text: 'UNET Link: ', style: 'caseDetailTitle', }, `${donor.unetLink}`], } : null,
            {
                text: [{ text: 'Created: ', style: 'caseDetailTitle', }, `${getFormattedDateTimeString(donor.createDate)} by ${
                    getUserName(donor.userId, organizationMembers)
                }`],
            },
            (donor.closed) ? {
                text: [{ text: 'Closed: ', style: 'caseDetailTitle', }, `${getFormattedDateTimeString(donor.lastModified)} by ${
                    getUserName(donor.lastModifiedBy, organizationMembers)
                }`],
            } : null,

            (donor.closed) ? {
                text: [
                    { text: 'Total Time: ', style: 'caseDetailTitle', }, `${getElapsedTimeString(donor.lastModified, donor.createDate)}`
                ],
            } : null
        ],
        style: 'caseDetail',
    };
}

function getFollowerRoomTasks(followerGroups: FollowerGroup[], tasks): FollowerRoomTasks {
    return followerGroups.reduce((tasksByRoomId, group) => {
        tasksByRoomId[group.chatroomId] = group.taskIds.map((taskId) => tasks[taskId]?.description ?? taskId);
        return tasksByRoomId;
    }, {});
}

function pdfSection(title: string, data: any[], noDataText: string, breakBefore: boolean = false) {
    const pageBreak = breakBefore ? { pageBreak: 'before', } : {};

    return [
        { ...{ text: title, style: 'h2', }, ...pageBreak, },
        data.length > 0 ? data : { text: noDataText, style: 'noData', }
    ];
}

function* getCaseChatrooms(caseId: number): Generator<any, Chatrooms, any> {
    // TODO: Add endpoint for fetching active/archived
    // chatrooms for just a case instead of organization
    const chatroomsFetch = yield apiFetch(api.txp.chatrooms);

    if (chatroomsFetch.error || !chatroomsFetch.result) {
        if (chatroomsFetch.error.isValidationError) {
            const errorMessage = chatroomsFetch.error && chatroomsFetch.error.errors
                ? chatroomsFetch.error.errors
                : chatroomsFetch.error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (chatroomsFetch.error.isNetworkError) {
            yield put(pushError('Loading chatrooms failed, are you online?', chatroomsFetch.error));
        } else {
            yield put(pushError('Loading chatrooms failed, try again later', chatroomsFetch.error));
        }

        return {
            active: [],
            archived: [],
        };
    }

    return {
        active: (chatroomsFetch.result.active ?? []).filter((room) => room.donor_id === caseId)
            .map((res) => transformGeneralChatroomResponse(res)),
        archived: (chatroomsFetch.result.archived ?? []).filter((room) => room.donor_id === caseId)
            .map((res) => transformGeneralChatroomResponse(res)),
    };
}

// Get detailed chatroom information for active and archived chatrooms
// and generate an array consumable by the pdfMake tool
function* getChatroomPdfRows(chatrooms: Chatroom[], resource: Resource, tasks: FollowerRoomTasks): Generator<any, any[], any> {
    const roomsData: ChatroomData[] = (yield all(chatrooms.map((room) => apiFetch(resource, { chatroom_id: room.chatroomId, }))))
        .map((response) => transformChatroomResponse(response.result ?? {}));
    const roomsPdf: ChatroomPdfData = yield all(roomsData.map(chatroomDataToPdfData));

    return chatrooms.map((room, i) => chatroomPdfContent(room, roomsPdf[i], (tasks[room.chatroomId] ?? [])));
}

function activeRoomPdfWrapper(pdfRows: any[]): any[] {
    return [
        { text: 'Omnilife Active Room', style: 'h3', },
        ...pdfRows,
        { text: '* ROOM ACTIVE - MESSAGES MAY BE ADDED AFTER THIS TRANSCRIPT HAS BEEN PRODUCED *', style: 'footer', }
    ];
}

function archivedRoomPdfWrapper(pdfRows: any[]): any[] {
    return [
        { text: 'Omnilife Archived Room', style: 'h3', },
        ...pdfRows,
        { text: '* ROOM CLOSED *', style: 'footer', }
    ];
}

export function* generateDonorPDFSaga(action: GenerateDonorPDF): Saga<void> {
    const {
        donor,
        profile,
        organizationMembers,
        workflowTasks,
        forms,
    } = action;

    yield put(startLoading('donorPDF'));

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const caseTimeline = donor.timeline || [];
    const timelineData = [];

    const [attachmentData, followerGroupsData, caseNotesData, caseChatrooms] = yield all([
        getAttachmentData(donor.donorId),
        getCaseFollowerGroups(donor.donorId),
        getCaseNotes(donor.donorId),
        getCaseChatrooms(donor.donorId)
    ]);

    for (let i = 0; i < caseTimeline.length; i += 1) {
        const timelineItem = caseTimeline[i];

        timelineData.push(
            { text: `${getFormattedDateTimeString(caseTimeline[i].updateTime)}`, style: 'caseTimelineDate', }
        );
        timelineData.push(
            {
                text: [{ text: `${workflowTasks[timelineItem.taskId].description || ''}`, style: 'caseTimelineTask', }, `${getAction(caseTimeline[i])}`,
                    { text: `${getUserName(caseTimeline[i].userId, organizationMembers)}`, style: 'user', }],
                style: 'marginBottom',
            }
        );
    }

    // Note: This is not the best way to get a consistent
    //  ordering of tasks.
    const workflowTaskOrder = Object.keys(workflowTasks).map((stringId) => parseInt(stringId, 10));
    const completedTasks = [];
    for (let i = 0; i < workflowTaskOrder.length; i += 1) {
        const donorTask = donor.tasks[workflowTaskOrder[i]];
        const workflowTaskDef = workflowTasks[workflowTaskOrder[i]];
        if (donorTask && donorTask.completed) {
            completedTasks.push({ text: workflowTaskDef.description, style: 'h3', });
            const form = getTaskForm(workflowTaskDef, workflowTaskOrder[i], forms);
            const donorTaskData = donorTask.taskData;
            if (form && form.sections) {
                for (let j = 0; j < form.sections.length; j += 1) {
                    const fieldData = [];
                    const section = form.sections[j];
                    if (section) {
                        if (section.title) {
                            completedTasks.push({ text: section.title, style: 'h4', });
                        }

                        // Iterate through the form fields
                        for (let k = 0; k < section.fields.length; k += 1) {
                            const field = section.fields[k];
                            if (donorTaskData && donorTaskData[field.id] && donorTaskData[field.id]) {
                                let { value, } = donorTaskData[field.id];
                                if (field.type === BOOLEAN) {
                                    value = value ? 'Y' : 'N';
                                }
                                if (value) {
                                    const { lastModified, lastModifiedBy, } = donorTaskData[field.id];
                                    fieldData.push({ text: [{ text: `${field.title}: `, style: 'fieldTitle', }, `${value}`], style: 'fieldLineWithMeta', });
                                    fieldData.push({
                                        text: `Entered by ${
                                            getUserName(lastModifiedBy, organizationMembers)
                                        } on ${
                                            getFormattedDateTimeString(new Date(lastModified))
                                        }`,
                                        style: 'fieldMeta',
                                    });
                                }
                            }
                        }
                    }
                    if (fieldData.length > 0) {
                        completedTasks.push({ stack: fieldData, style: 'formSection', });
                    } else {
                        completedTasks.push({ text: 'No section data', style: ['marginLeft', 'noData', 'formSection'], });
                    }
                }
            } else if (donorTask.noteContent) {
                completedTasks.push({ text: [{ text: 'Notes: ', style: 'fieldTitle', }, donorTask.noteContent], style: 'fieldLine', });
            } else {
                completedTasks.push({ text: 'No task data', style: 'noData', });
            }
        }
    }

    const caseDetails = getPDFCaseDetails(donor, organizationMembers);
    const followerRoomTasks = getFollowerRoomTasks(followerGroupsData, workflowTasks);
    const caseNotes = caseNotesToPdfText(caseNotesData, organizationMembers);
    const activeChatroomsPdf = (yield getChatroomPdfRows(caseChatrooms.active, api.txp.activeChatroomData, followerRoomTasks))
        .map(activeRoomPdfWrapper);
    const archivedChatroomsPdf = (yield getChatroomPdfRows(caseChatrooms.archived, api.txp.archivedChatroomData, followerRoomTasks))
        .map(archivedRoomPdfWrapper);

    const reportData = {
        pageOrientation: 'portrait',
        content: [
            { text: 'Case Report', style: 'header', },
            { image: Images.logoLarge, style: 'logo', },
            {
                text: `Generated on ${getFormattedDateTimeString(new Date()).replace(',', ' at')} by ${getUserName(profile.userId, organizationMembers)}`,
                style: 'creationDetails',
            },
            {
                text: 'Confidentiality Notice',
                style: 'confidentialityNoticeHeader',
            },
            {
                text: 'The information contained in report may contain protected health information (PHI) and '
                    + 'should only be viewed and used by those who are authorized to do so.  Distributing this report to others may be a violation of HIPAA.',
                style: 'confidentialityNotice',
            },

            ...pdfSection('Case Details', [caseDetails], 'No case details', true),
            ...pdfSection('Case Notes', caseNotes, 'No case notes'),
            ...pdfSection('Completed Task Details', completedTasks, 'No completed tasks'),
            ...pdfSection('Case Timeline', timelineData, 'No timeline data'),
            ...pdfSection('Attachments', attachmentData, 'No attachments', true),
            ...pdfSection('Chatrooms', [...activeChatroomsPdf, ...archivedChatroomsPdf], 'No chatrooms', true)
        ],
        styles: {
            logo: {
                alignment: 'center',
                marginTop: 20,
                marginBottom: 20,
            },
            header: {
                fontSize: 24,
                bold: true,
                alignment: 'center',
                marginTop: 200,
            },
            footer: {
                fontSize: 11,
                bold: true,
                margin: [0, 5, 0, 5],
                alignment: 'center',
            },
            chatSpacer: {
                fontSize: 18,
                margin: [0, 0, 0, 10],
            },
            legendTitle: {
                fontSize: 14,
                bold: true,
                margin: [0, 10, 0, 5],
            },
            creationDetails: {
                fontSize: 14,
                italics: true,
                alignment: 'center',
            },
            confidentialityNoticeHeader: {
                marginTop: 300,
                italics: true,
                alignment: 'center',
                bold: true,
                color: Colors.gray,
            },
            confidentialityNotice: {
                italics: true,
                alignment: 'center',
            },
            warning: {
                fontSize: 12,
                lineHeight: 1.5,
                color: Colors.red,
            },
            caseDetail: {
                fontSize: 12,
                lineHeight: 1.5,
            },
            caseDetailTitle: {
                bold: true,
                color: Colors.gray,
            },
            h2: {
                fontSize: 18,
                bold: true,
                marginTop: 20,
                marginBottom: 10,
            },
            h3: {
                fontSize: 16,
                bold: true,
                marginTop: 20,
                marginBottom: 10,
            },
            h4: {
                fontSize: 14,
                bold: true,
                marginTop: 12,
                marginBottom: 8,
                marginLeft: 20,
            },
            fieldTitle: {
                bold: true,
                color: Colors.gray,
            },
            fieldLine: {
                marginLeft: 20,
                marginBottom: 6,
            },
            fieldLineWithMeta: {
                marginLeft: 20,
            },
            fieldMeta: {
                fontSize: 10,
                italics: true,
                color: Colors.lightGray,
                lineHeight: 1,
                marginLeft: 20,
                marginTop: 0,
                marginBottom: 6,
            },
            formSection: {
                marginBottom: 10,
            },
            noData: {
                italics: true,
            },
            caseTimelineDate: {
                color: Colors.brandPrimary,
            },
            caseTimelineTask: {
                bold: true,
            },
            user: {
                color: Colors.brandSecondary,
            },
            groupList: {
                margin: [20, 4, 10, 10],
            },
            marginBottom: {
                marginBottom: 10,
            },
            marginLeft: {
                marginLeft: 20,
            },
            boldText: {
                bold: true,
            },
            table: {
                margin: [0, 5, 0, 15],
            },
            fillColorOrange: {
                background: Colors.pdfFillOrange,
            },
            fillColorPurple: {
                background: Colors.pdfFillPurple,
            },
            fillColorYellow: {
                background: Colors.pdfFillYellow,
            },
            fillColorGreen: {
                background: Colors.pdfFillGreen,
            },
            fillColorRed: {
                background: Colors.pdfFillRed,
            },
            fillColorBlue: {
                background: Colors.pdfFillBlue,
            },
        },
    };

    pdfMake.createPdf(reportData).open();
    yield put(finishLoading('donorPDF'));
}

export function* updateCaseStatusSaga(action: UpdateCaseStatus): Saga<void> {
    const {
        caseId,
        closed,
        lastModified,
    } = action;

    yield put(startLoading('updateCaseStatus'));

    const {
        result,
        error,
    } = yield apiPut(api.txp.updateCaseStatus, {
        caseId,
    }, {
        closed,
        last_modified: lastModified,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isInvalidResponseCode) {
            yield put(pushError(parseResponseTextError(error.responseText)));
        } else if (error.isNetworkError) {
            yield put(pushError(closed ? 'Closing case failed, are you online?' : 'Reopening case failed, are you online?', error));
        } else {
            yield put(pushError(closed ? 'Closing case failed, try again later' : 'Reopening case failed, try again later', error));
        }
    } else {
        const currentCases = yield select((state) => state.donor.donors || {});

        currentCases[caseId] = {
            ...currentCases[caseId],

            closed,
            lastModified: result.last_modified,
        };

        yield put(updateCaseSuccess(caseId, currentCases[caseId]));

        yield put(finishLoading('updateCaseStatus'));
    }
}
