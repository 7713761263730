// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import FontIcon from 'react-toolbox/lib/font_icon';
import Sidebar from 'react-sidebar';

import ApplicationStyles from '../Themes/ApplicationStyles';
import ClientStyles from './Styles/ClientStyles';
import UpdateIosVersionPanel from '../Components/UpdateIosVersionPanel';
import UpdateAndroidVersionPanel from '../Components/UpdateAndroidVersionPanel';
import {
    loadVersions as _loadVersions,
    updateiOSVersion as _updateiOSVersion,
    updateAndroidVersion as _updateAndroidVersion,
} from '../Redux/ClientActions';
import { UIPermissions } from '../Utils/types';

type Props = {
    iosVersion: string,
    androidVersion: string,
    iosReleaseTime: string,
    androidReleaseTime: string,

    loadVersions: () => *,
    updateiOSVersion: (version: string) => *,
    updateAndroidVersion: (version: string) => *,
};

type State = {
    isiOsSidebarOpen: boolean,
    isAndroidSidebarOpen: boolean,
    isClientSidebarOpen: boolean,
};

class ClientPage extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            isiOsSidebarOpen: false,
            isAndroidSidebarOpen: false,
            isClientSidebarOpen: false,
        };
    }

    componentDidMount() {
        const {
            loadVersions,
        } = this.props;

        loadVersions();
    }

    onUpdateiOSVersion = (version: string) => {
        const {
            updateiOSVersion,
        } = this.props;

        updateiOSVersion(version);

        this.onClose();
    };

    onUpdateAndroidVersion = (version: string) => {
        const {
            updateAndroidVersion,
        } = this.props;

        updateAndroidVersion(version);

        this.onClose();
    };

    onUpdateiOSVersionClick = () => {
        this.setState({
            isiOsSidebarOpen: true,
            isAndroidSidebarOpen: false,
            isClientSidebarOpen: false,
        });
    };

    onUpdateAndroidVersionClick = () => {
        this.setState({
            isiOsSidebarOpen: false,
            isAndroidSidebarOpen: true,
            isClientSidebarOpen: false,
        });
    };

    onClose = () => {
        this.setState({
            isAndroidSidebarOpen: false,
            isiOsSidebarOpen: false,
            isClientSidebarOpen: false,
        });
    };

    render() {
        const {
            iosVersion,
            androidVersion,
            iosReleaseTime,
            androidReleaseTime,
        } = this.props;

        const {
            isiOsSidebarOpen,
            isAndroidSidebarOpen,
            isClientSidebarOpen,
        } = this.state;

        const keys = Object.keys(UIPermissions);
        const UIPermissionsList = keys.map((key) => (
            <li style={ApplicationStyles.noBull} key={UIPermissions[key].id.toString()}>{UIPermissions[key].name}</li>
        ));

        const showSidebar = isAndroidSidebarOpen || isiOsSidebarOpen || isClientSidebarOpen;

        const sidebar = isAndroidSidebarOpen ? (
            <UpdateAndroidVersionPanel
                version={androidVersion}
                onClose={this.onClose}
                onUpdateVersion={this.onUpdateAndroidVersion}
            />
        ) : isiOsSidebarOpen ? (
            <UpdateIosVersionPanel
                version={iosVersion}
                onClose={this.onClose}
                onUpdateVersion={this.onUpdateiOSVersion}
            />
        ) : null;

        const sidebarName = isAndroidSidebarOpen ? 'Update Android Version'
            : isiOsSidebarOpen ? 'Update iOS Version'
                : isClientSidebarOpen ? 'Update Client Permissions' : null;

        const sidebarOpen = isAndroidSidebarOpen ? this.onUpdateAndroidVersion
            : isiOsSidebarOpen ? this.onUpdateiOSVersionClick : null;

        return (
            <Sidebar
                sidebar={(sidebar)}
                open={showSidebar}
                docked={showSidebar}
                title={sidebarName}
                onSetOpen={sidebarOpen}
                pullRight
                shadow={false}
                sidebarClassName="rightSidebar"
            >
                <div style={{ paddingTop: 50, paddingLeft: 10, }}>
                    <div>
                        <div
                            style={ClientStyles.rowItems}
                            onClick={this.onUpdateAndroidVersionClick}
                            role="presentation"
                        >
                            <FontIcon
                                value="android"
                                style={ClientStyles.iconStyle}
                            />
                            <div>
                                <div style={ClientStyles.title}>Android</div>
                                <li style={ClientStyles.titleNoBull}>{androidVersion}</li>
                                <li style={ApplicationStyles.noBull}>{androidReleaseTime}</li>
                            </div>
                        </div>
                        <div
                            style={ClientStyles.rowItems}
                            onClick={this.onUpdateiOSVersionClick}
                            role="presentation"
                        >
                            <FontIcon
                                value="phone_iphone"
                                style={ClientStyles.iconStyle}
                            />
                            <div>
                                <div style={ClientStyles.title}>iOS</div>
                                <li style={ClientStyles.titleNoBull}>{iosVersion}</li>
                                <li style={ApplicationStyles.noBull}>{iosReleaseTime}</li>
                            </div>
                        </div>

                        <div
                            style={ClientStyles.rowItems}
                        >
                            <FontIcon
                                value="settings"
                                style={ClientStyles.iconStyle}
                            />
                            <div>
                                <div style={ClientStyles.title}>UI Permissions</div>
                                {UIPermissionsList}
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => ({
    iosVersion: state.client.iosVersion,
    androidVersion: state.client.androidVersion,
    iosReleaseTime: state.client.iosReleaseTime,
    androidReleaseTime: state.client.androidReleaseTime,
});

export default connect(mapStateToProps, {
    loadVersions: _loadVersions,
    updateiOSVersion: _updateiOSVersion,
    updateAndroidVersion: _updateAndroidVersion,
})(ClientPage);
