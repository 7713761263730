// @flow
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './Styles/WorkflowPreviewStyles';

type Props = {
    disabled: boolean,
    isSelect: boolean,
    label: string,
};

type State = {
    isChecked: boolean,
};

class FormCheckbox extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);
        this.state = {
            isChecked: false,
        };
    }

    onCheckClick = () => {
        const { isChecked, } = this.state;
        this.setState({ isChecked: !isChecked, });
    }

    render() {
        const {
            disabled,
            label,
            isSelect,
        } = this.props;

        const {
            isChecked,
        } = this.state;

        let style;
        if (!isSelect) {
            style = disabled ? styles.checkboxWrapperDisabled : styles.checkboxWrapper;
        }


        return (
            <div
                style={style}
                role="button"
                tabIndex={0}
                onClick={this.onCheckClick}
                onKeyDown={() => {}}
            >
                <FontAwesomeIcon
                    style={styles.iconBlue24}
                    icon={isChecked ? faCircleCheck : faCircle}
                    size="lg"
                />
                <span
                    style={styles.label}
                >
                    {label}
                </span>
            </div>
        );
    }
}

export default FormCheckbox;
