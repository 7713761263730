// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import EditSelectionText from './EditSelectionText';
import UserDetailsPanel from '../UserDetailsPanel';
import AddUserToOrganizationPanel from '../AddUserToOrganizationPanel';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import DeactivateAccountsButton from './DeactivateAccountsButton';
import { onClosePanel } from '../../Utils/panels';
import { isUserTypeLoaded } from '../../Utils/user';
import {
    updateUser as _updateUser,
    toggleAddUserToOrganizationPanel as _toggleAddUserToOrganizationPanel,
    loadUsers as _loadUsers,
} from '../../Redux/UserActions';
import ApplicationStyles from '../../Themes/ApplicationStyles';

import type {
    UserProfile,
    UserProfileWithInvite,
    Organization,
} from '../../Utils/types';

const dialogType = 'NonMember';
const contentType = 'basic users';
const basicUserHeaders = [
    {
        dataKey: 'joinDate',
        title: 'Registration Date',
        isUneditable: true,
    },
    {
        dataKey: 'firstName',
        title: 'First Name',
    },
    {
        dataKey: 'lastName',
        title: 'Last Name',
    },
    {
        dataKey: 'email',
        title: 'Email',
    },
    {
        dataKey: 'emailUpdate',
        title: 'Pending Email',
        isUneditable: true,
    },
    {
        dataKey: 'phone',
        title: 'Phone',
        isUneditable: true,
    },
    {
        dataKey: 'accessLevel',
        title: 'Permission',
        isUneditable: true,
    },
    {
        dataKey: 'invitation',
        title: 'Invitation',
        isUneditable: true,
    }
];

function userInvitationText(user) {
    return user.invite
        ? `From ${user.inviter.organization} on ${
            new Date(user.invite.date).toLocaleDateString()
        }`
        : '';
}

type Props = {
    profile: UserProfile,
    users: Array<UserProfileWithInvite>,
    usersLoaded: boolean,
    bulkEditUsers: Array<UserProfile>,
    isAddUserToOrganizationPanelOpen: boolean,

    organizations: Array<Organization>,
    organizationNames: Array<string>,

    loadUsers: typeof _loadUsers,
    updateUser: (userId: number, firstName: ?string, lastName: ?string, email: ?string, templatePreferences: ?any,
        accessLevel: ?string, organizationalRole: ?string) => *,
};

class BasicUsers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('basic');
    }

    onUpdateUser = ({
        userId, firstName, lastName, email, accessLevel, role,
    }: UserProfileWithInvite) => {
        this.props.updateUser(userId, firstName, lastName, email, null, accessLevel, role);
    };

    render() {
        const {
            profile,
            users,
            usersLoaded,
            bulkEditUsers,
            organizations,
            organizationNames,
            isAddUserToOrganizationPanelOpen,
        } = this.props;
        const numSelected = bulkEditUsers.length;
        const disableActions = numSelected < 1;
        const isAdmin = profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales';
        const headers = basicUserHeaders;

        return (
            <>
                <Sidebar
                    sidebar={(
                        <div>
                            <div
                                style={{
                                    ...ApplicationStyles.detailsHeader,
                                    paddingTop: 35,
                                    paddingBottom: 35,
                                }}
                            >
                                    Add to Organization
                            </div>
                            <AddUserToOrganizationPanel
                                organizationNames={organizationNames}
                                organizations={organizations}
                                closeUserDetailsPanel={onClosePanel}
                            />
                        </div>
                    )}
                    open={isAddUserToOrganizationPanelOpen}
                    docked={isAddUserToOrganizationPanelOpen}
                    pullRight
                    sidebarClassName="rightSidebar"
                    shadow={false}
                    styles={{
                        sidebar: {
                            background: 'white',
                            marginTop: 'var(--header-height)',
                            zIndex: 2,
                        },
                    }}
                >
                    <></>
                </Sidebar>

                <UserPanel
                    sidebar={(
                        <UserDetailsPanel
                            headers={headers}
                            dialogType={dialogType}
                            contentType={contentType}
                            onUpdateUser={this.onUpdateUser}
                            onClose={onClosePanel}
                        />
                    )}
                >
                    <div className="user-actions">
                        <EditSelectionText numSelected={numSelected} disableActions={disableActions} />

                        {isAdmin ? (
                            <DeactivateAccountsButton
                                disabled={disableActions}
                                label="Deactivate Accounts"
                            />
                        ) : null}
                    </div>

                    <UserTable
                        users={users.map((user) => ({
                            ...user,
                            invitation: userInvitationText(user),
                        }))}
                        headers={headers}
                        contentType={contentType}
                        usersLoaded={usersLoaded}
                        multiSelect
                    />
                </UserPanel>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations, } = state.organization;

    const organizationNames = organizations.map((organization) => organization.organizationName);

    return {
        profile: state.auth.profile,
        bulkEditUsers: state.user.bulkEditUsers,
        organizations,
        organizationNames,
        users: state.user.loadedUsers.basic ?? [],
        usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'basic'),
        isAddUserToOrganizationPanelOpen: state.user.isAddUserToOrganizationPanelOpen,
    };
};

export default connect(mapStateToProps, {
    updateUser: _updateUser,
    toggleAddUserToOrganizationPanel: _toggleAddUserToOrganizationPanel,
    loadUsers: _loadUsers,
})(BasicUsers);
