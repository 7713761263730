// @flow
import React from 'react';

import LoadingIndicatorStyles from './Styles/LoadingIndicatorStyles';
import Images from '../Themes/Images';

const LoadingIndicator = () => (
    <img id="loading" style={LoadingIndicatorStyles.loading} src={Images.logoLarge} alt="" />
);

export default LoadingIndicator;
