import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './Navigation/Routes';
import { store, persistor } from './store';
import './App.css';

class App extends PureComponent {
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    // Some keystrokes are undesirable for all users.
    // Turn off CTRL-S (windows/chrome=Save As)
    // Turn off CTRL-U (windows/chrome&firefox=View Source)(windows/Edge=Debugger mode)
    handleKeyDown = (event) => {
        const charCode = String.fromCharCode(event.which).toLowerCase();
        if (event.ctrlKey && charCode === 'u') {
            event.preventDefault();
        } else if (event.ctrlKey && charCode === 's') {
            event.preventDefault();
        }

        // For MAC we can use metaKey to detect cmd key. More research needed. Included here as hint.
        // if(event.metaKey && charCode === 'c') {
        //   console.log("Cmd + C pressed");
        // }
    };

    render() {
        return (
            <div>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <Router>
                            <Routes />
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        );
    }
}

export default App;
