// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'react-toolbox/lib/table/Table';
import TableHead from 'react-toolbox/lib/table/TableHead';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import Checkbox from 'react-toolbox/lib/checkbox';
import Button from 'react-toolbox/lib/button/Button';

import {
    setUserEdit as _setUserEdit,
    setUserEditStatus as _setUserEditStatus,
    toggleClientPermissionsPanel as _toggleClientPermissionsPanel,
    updateUserUIPermissions as _updateUserUIPermissions,
} from '../Redux/UserActions';
import type { UserProfile, UIPermissionsMap } from '../Utils/types';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import { UserUIPermissions } from '../Utils/types';

type Props = {
    userEdit: UserProfile,
    userEditChanged: boolean,
    clientPermissions: ?number,

    toggleClientPermissionsPanel: (isClientPermissionsPanelOpen: boolean) => *,
    setUserEdit: (userId: number) => *,
    setUserEditStatus: (userChanged: boolean) => *,
    updateUserUIPermissions: (userId: number, permissions: UIPermissionsMap) => *,
};

type State = {
    currentPermissions: UIPermissionsMap,
};

class UserClientPermissionsPanel extends Component<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            currentPermissions: this.getCurrentPermissions(props.clientPermissions),
        };
    }

    componentDidUpdate(prevProps: Props) {
        const {
            clientPermissions,
        } = this.props;

        if (prevProps.clientPermissions !== clientPermissions) {
            this.onUpdatePermissions(clientPermissions);
        }
    }

    onSaveClientPermissions = () => {
        const {
            toggleClientPermissionsPanel,
            updateUserUIPermissions,
            userEdit,
            clientPermissions,
        } = this.props;

        const {
            currentPermissions,
        } = this.state;

        updateUserUIPermissions(userEdit.userId, currentPermissions);
        toggleClientPermissionsPanel(false);

        this.onUpdatePermissions(clientPermissions);
    };

    onCloseClearFields = () => {
        const {
            setUserEdit,
            userEdit,
            clientPermissions,
            toggleClientPermissionsPanel,
        } = this.props;

        setUserEdit(userEdit.userId);
        toggleClientPermissionsPanel(false);

        this.onUpdatePermissions(clientPermissions);
    };

    /**
     * Method to call when checkboxes change value.
     */
    onChangeBooleanValuePermissions = (value: string, id: number) => {
        const {
            setUserEditStatus,
            clientPermissions,
        } = this.props;

        let tempPermissions = null;
        if (value) {
            tempPermissions = id;
        }

        if (tempPermissions !== clientPermissions) {
            setUserEditStatus(true);
        } else {
            setUserEditStatus(false);
        }

        this.onUpdatePermissions(tempPermissions);
    };

    onUpdatePermissions = (newPermissions: ?number) => {
        this.setState({
            currentPermissions: this.getCurrentPermissions(newPermissions),
        });
    };

    getCurrentPermissions = (permissions: ?number) => {
        const uiPermissionKeys = Object.keys(UserUIPermissions);
        const currentPermissions = {};
        for (let i = 0; i < uiPermissionKeys.length; i += 1) {
            const uiPermission = UserUIPermissions[uiPermissionKeys[i]];
            // NOTE newPermissions can only be a single number at the
            //   moment, if this ever changes into an array we will need to
            //   check to see if the current id exists in the newPermissions array
            //   so something like ( updatedPermissions[i] = newPermissions.indexOf(i) !== -1)
            currentPermissions[uiPermission.id] = permissions != null;
        }

        return currentPermissions;
    };

    render() {
        const {
            userEditChanged,
        } = this.props;

        const {
            currentPermissions,
        } = this.state;

        const keys = Object.keys(UserUIPermissions);

        return (
            <div>
                <Table
                    id="table"
                    selectable={false}
                >
                    <TableHead>
                        <TableCell>
                            Permission
                        </TableCell>
                        <TableCell>
                            Value
                        </TableCell>
                    </TableHead>
                    {
                        keys.map((key) => (
                            <TableRow
                                key={key}
                            >
                                <TableCell>
                                    {UserUIPermissions[key].name}
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        style={{ marginTop: 8, }}
                                        key={UserUIPermissions[key].id}
                                        checked={currentPermissions[UserUIPermissions[key].id]}
                                        onChange={(val) => this.onChangeBooleanValuePermissions(val, UserUIPermissions[key].id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </Table>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        style={ApplicationStyles.cancelButton}
                        label="Cancel"
                        onClick={this.onCloseClearFields}
                    />
                    <Button
                        style={!userEditChanged ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                        label="Update"
                        onClick={this.onSaveClientPermissions}
                        disabled={!userEditChanged}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { userEdit, } = state.user;
    const userId = userEdit && userEdit.userId ? userEdit.userId : -1;

    const clientPermissions = state.permission.resourcePermissions[userId] && state.permission.resourcePermissions[userId].resourcePermissions
        ? state.permission.resourcePermissions[userId].resourcePermissions : null;
    return {
        userEdit,
        clientPermissions,
        userEditChanged: state.user.userEditChanged,
    };
};
export default connect(mapStateToProps, {
    setUserEdit: _setUserEdit,
    setUserEditStatus: _setUserEditStatus,
    toggleClientPermissionsPanel: _toggleClientPermissionsPanel,
    updateUserUIPermissions: _updateUserUIPermissions,
})(UserClientPermissionsPanel);
