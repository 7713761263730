// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import moment from 'moment';
import { selectProfileAccessLevel } from 'txp-core';

import api from '../Services/Api';
import {
    apiFetch, apiPost, apiPut, apiDelete,
} from './ApiSaga';
import { isObject } from '../Utils/Object';
import {
    finishLoading,
    startLoading,
} from '../Redux/LoadingActions';
import { loadUsers } from '../Redux/UserActions';
import {
    loadOrganizations,
    loadedOrganizations,
    loadedTemplateOptions,
    loadedOrganizationMetrics,
    setOrganizationMetricsComplete,
    loadedHomeOrganizationMetrics,
    loadedOrganizationUserActivity,
    loadedBusinessMetrics,
    loadedOrganizationTypes,
    resetMetrics,
    setOrganizationEdit,
} from '../Redux/OrganizationActions';
import {
    pushError,
    setSagaMessage,
} from '../Redux/ApplicationActions';
import { getQuarterIntervals, getQuarters } from '../Utils/metrics';
import type { Organization, BusinessMetrics } from '../Utils/types';
import type {
    AddOrganization,
    DeleteOrganization,
    RemoveAllMembers,
    OrganizationMetrics,
    LoadHomeOrganizationMetrics,
    OrganizationUserActivity,
    UpdateOrganization,
    UpdateOrganizationLicense,
} from '../Redux/OrganizationActions';

export function* loadOrganizationsSaga(): Saga<void> {
    yield put(startLoading('organizations'));

    const accessLevel = yield select((state) => selectProfileAccessLevel(state.auth));
    const { result, error, } = yield apiFetch((accessLevel && (accessLevel === 'Sales' || accessLevel === 'SysAdmin')
        ? api.txp.organizations : api.txp.partialOrganizations));

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading organizations failed, are you online?', error));
        } else {
            yield put(pushError('Loading organizations failed, try again later', error));
        }
        yield put(finishLoading('organizations'));
    } else {
        const remoteOrganizations = (isObject(result) ? result.organizations : result) || [];
        const organizations: Array<Organization> = [];

        for (let i = 0; i < remoteOrganizations.length; i += 1) {
            const permissions = remoteOrganizations[i].permissions ? remoteOrganizations[i].permissions.map((permission) => permission.target.target_id) : [];

            const organizationData = {
                visible: true,
                organizationId: remoteOrganizations[i].organization_id,
                organizationName: remoteOrganizations[i].organization_name,
                organizationCode: remoteOrganizations[i].organization_code,
                organizationType: remoteOrganizations[i].organization_type,
                license: remoteOrganizations[i].license,
                clientPermissions: permissions,
                templatePreferences: remoteOrganizations[i].template_preferences || {},
            };
            organizations.push({
                ...organizationData,
            });
        }

        yield put(loadedOrganizations(organizations));
        yield put(finishLoading('organizations'));
    }
}

export function* loadOrganizationTypesSaga(): Saga<void> {
    yield put(startLoading('organizationTypes'));

    const { result, error, } = yield apiFetch(api.txp.organizationTypes);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading organization types failed, are you online?', error));
        } else {
            yield put(pushError('Loading organization types failed, try again later', error));
        }
        yield put(finishLoading('organizationTypes'));
    } else {
        yield put(loadedOrganizationTypes(result.organization_types));
        yield put(finishLoading('organizationTypes'));
    }
}

export function* loadOrganizationTemplateOptions(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.templateOptions, null);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading template options failed, are you online?', error));
        } else {
            yield put(pushError('Loading template options failed, try again later', error));
        }
    } else {
        const templateOptions = {
            donor: isObject(result) && result.donor ? result.donor : {},
            recipient: isObject(result) && result.recipient ? result.recipient : {},
        };

        yield put(loadedTemplateOptions(templateOptions));
    }
}

export function* addOrganization(action: AddOrganization): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.addOrganization, null, {
        organization_name: action.organizationName,
        organization_code: action.organizationCode,
        organization_type: action.organizationType,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Adding organization failed, are you online?', error));
        } else {
            yield put(pushError('Adding organization failed, try again later', error));
        }
    } else {
        yield put(loadOrganizations());
    }
}

export function* updateOrganization(action: UpdateOrganization): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateOrganization, { organizationId: action.organizationId, }, {
        organization_name: action.organizationName,
        organization_code: action.organizationCode,
        organization_type: action.organizationType,
        permission_target_ids: action.clientPermissions,
        template_preferences: action.templatePreferences,
    });

    if (error) {
        // Reset organization details if updating fails
        yield put(setOrganizationEdit(action.organizationId));

        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating organization failed, are you online?', error));
        } else {
            yield put(pushError('Updating organization failed, try again later', error));
        }
    } else {
        yield put(loadOrganizations());
    }
}

export function* updateOrganizationLicense(action: UpdateOrganizationLicense): Saga<void> {
    const { error, } = yield apiPost(api.txp.updateOrganizationLicense, { organizationId: action.organizationId, }, {
        organization_license: action.license,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating organization license failed, are you online?', error));
        } else {
            yield put(pushError('Updating organization license failed, try again later', error));
        }
    } else {
        yield put(loadOrganizations());
    }
}

export function* deleteOrganization(action: DeleteOrganization): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.deleteOrganization, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting organization failed, are you online?', error));
        } else {
            try {
                const { error: errorMessage, } = JSON.parse(error.responseText);
                yield put(pushError(errorMessage, error));
            } catch (parseError) {
                yield put(pushError('Deleting organization failed', parseError));
            }
        }
    } else {
        yield put(loadOrganizations());
        yield put(loadUsers());
    }
}

export function* removeAllMembers(action: RemoveAllMembers): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.removeAllMembers, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Removing all members failed, are you online?', error));
        } else {
            try {
                const { error: errorMessage, } = JSON.parse(error.responseText);
                yield put(pushError(errorMessage, error));
            } catch (parseError) {
                yield put(pushError('Removing all members from organization failed', parseError));
            }
        }
    } else {
        yield put(loadUsers());
        yield put(setSagaMessage('Success', 'Successfully removed all members from organization', '', false));
    }
}

export function* organizationMetrics(action: OrganizationMetrics): Saga<void> {
    yield put(resetMetrics(action.organizationId, action.quarterA, action.quarterB));

    let {
        quarterA,
        quarterB,
    } = action;

    let endB; let startB; let endA; let startA;
    const quarterIntervals = getQuarterIntervals();

    if (quarterA && quarterB) {
        endA = moment({
            y: quarterIntervals[quarterA].end.year,
            M: quarterIntervals[quarterA].end.month - 1,
            d: 1,
        }).toISOString();
        endB = moment({
            y: quarterIntervals[quarterB].end.year,
            M: quarterIntervals[quarterB].end.month - 1,
            d: 1,
        }).toISOString();
        startB = moment({
            y: quarterIntervals[quarterB].start.year,
            M: quarterIntervals[quarterB].start.month - 1,
            d: 1,
        }).toISOString();
        startA = moment({
            y: quarterIntervals[quarterA].start.year,
            M: quarterIntervals[quarterA].start.month - 1,
            d: 1,
        }).toISOString();
    } else {
        const quarterNames = getQuarters();
        quarterA = quarterNames[1];
        quarterB = quarterNames[0];

        endA = moment({
            y: quarterIntervals[quarterA].end.year,
            M: quarterIntervals[quarterA].end.month - 1,
            d: 1,
        }).toISOString();
        endB = moment({
            y: quarterIntervals[quarterB].end.year,
            M: quarterIntervals[quarterB].end.month - 1,
            d: 1,
        }).toISOString();
        startB = moment({
            y: quarterIntervals[quarterB].start.year,
            M: quarterIntervals[quarterB].start.month - 1,
            d: 1,
        }).toISOString();
        startA = moment({
            y: quarterIntervals[quarterA].start.year,
            M: quarterIntervals[quarterA].start.month - 1,
            d: 1,
        }).toISOString();
    }

    const { organizationId, } = action;

    const metricsCache = yield select((state) => state.organization.metricsCache);

    const quarterBInterval = { interval_start: startB, interval_end: endB, };
    const quarterAInterval = { interval_start: startA, interval_end: endA, };

    if (!metricsCache[organizationId] || !metricsCache[organizationId][quarterA]) {
        const { result, error, } = yield apiFetch(api.txp.organizationMetrics, { organizationId: action.organizationId, }, quarterAInterval);

        if (error || !result) {
            // TODO: CREATE ERROR TOAST
            yield put(loadedOrganizationMetrics({}, organizationId, quarterA));
        } else {
            yield put(loadedOrganizationMetrics(result, organizationId, quarterA));
        }
    }
    yield put(setOrganizationMetricsComplete('A'));

    if (!metricsCache[organizationId] || !metricsCache[organizationId][quarterB]) {
        const { result, error, } = yield apiFetch(api.txp.organizationMetrics, { organizationId: action.organizationId, }, quarterBInterval);

        if (error || !result) {
            // TODO: CREATE ERROR TOAST
            yield put(loadedOrganizationMetrics({}, organizationId, quarterB));
        } else {
            yield put(loadedOrganizationMetrics(result, organizationId, quarterB));
        }
    }
    yield put(setOrganizationMetricsComplete('B'));
}

export function* homeOrganizationMetricsSaga(action: LoadHomeOrganizationMetrics): Saga<void> {
    const currentEnd = moment().startOf('day').toISOString();
    const currentStart = moment().subtract(90, 'days').startOf('day').toISOString();
    const currentInterval = { interval_start: currentStart, interval_end: currentEnd, };

    yield put(startLoading('metrics'));

    const { result, error, } = yield apiFetch(api.txp.homeOrganizationMetrics, {
        organizationId: action.organizationId,
    }, currentInterval);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating organization failed, are you online?', error));
        } else {
            yield put(pushError('Updating organization failed, try again later', error));
        }
        yield put(finishLoading('metrics'));
    } else {
        yield put(loadedHomeOrganizationMetrics(result, 'current', currentStart, currentEnd));
        yield put(finishLoading('metrics'));
    }
}

export function* organizationUserActivity(action: OrganizationUserActivity): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.organizationUserActivity, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Retrieving user activity failed, are you online?', error));
        } else {
            yield put(pushError('Retrieving user activity failed, try again later', error));
        }
    } else {
        const metrics = {};
        metrics.userActivity = result.user_activity;
        const loginMetricsKeys = Object.keys(metrics.userActivity);

        for (let i = 0; i < loginMetricsKeys.length; i += 1) {
            for (let j = 0; j < result.user_activity[loginMetricsKeys[i]].length; j += 1) {
                metrics.userActivity[loginMetricsKeys[i]][j].actionDate = result.user_activity[loginMetricsKeys[i]][j].action_date;
                delete metrics.userActivity[loginMetricsKeys[i]][j].action_date;
                metrics.userActivity[loginMetricsKeys[i]][j].actionName = result.user_activity[loginMetricsKeys[i]][j].action_name;
                delete metrics.userActivity[loginMetricsKeys[i]][j].action_name;
            }
        }

        yield put(loadedOrganizationUserActivity(metrics));
    }
}

export function* loadBusinessMetricsSaga(): Saga<void> {
    // optional arguments are number_months and number_days (defaults are 6 and 90 respectively)
    const { result, error, } = yield apiFetch(api.txp.businessMetrics);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Retrieving business metrics failed, are you online?', error));
        } else {
            yield put(pushError('Retrieving business metrics failed, try again later', error));
        }
    } else {
        const businessMetrics: BusinessMetrics = {
            runningPremiumUserCounts: result.running_premium_user_counts || {},
            runningStandardUserCounts: result.running_standard_user_counts || {},
            newUserCounts: result.new_user_counts || {},
            convertedUserCounts: result.converted_user_counts || {},
            sentMessageCounts: result.sent_message_counts || {},
            readMessageCounts: result.read_message_counts || {},
            createdChatroomCounts: result.created_chatroom_counts || {},
            monthlyActiveUserCounts: result.monthly_active_user_counts || {},
            organizationalRoleCounts: result.organizational_role_counts || {},
            sentMessagesByUser: result.sent_messages_by_user || [],
            readMessagesByUser: result.read_messages_by_user || [],
            sentMessagesByOrganization: result.sent_messages_by_organization || {},
            readMessagesByOrganization: result.read_messages_by_organization || {},
        };

        yield put(loadedBusinessMetrics(businessMetrics));
    }
}

export function* loadTemplateOptions(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.templateOptions, null);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading template options failed, are you online?', error));
        } else {
            yield put(pushError('Loading template options failed, try again later', error));
        }
    } else {
        const templateOptions = {
            donor: isObject(result) && result.donor ? result.donor : {},
            recipient: isObject(result) && result.recipient ? result.recipient : {},
        };

        yield put(loadedTemplateOptions(templateOptions));
    }
}
