// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-toolbox/lib/card';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';

import type {
    Donor, Form, TaskMap, UserProfile, Workflow,
} from '../Utils/types';
import ApplicationStyles from '../Themes/ApplicationStyles';
import MessagesHomeStyles from '../Containers/Styles/MessagesHomeStyles';
import RecoveredOrganPane from './RecoveredOrganPane';
import InfoButton from './InfoButton';
import DonorMetricPane from './DonorMetricPane';
import GraphicalDonorTimeline from './GraphicalDonorTimeline';
import TabularDonorTimeline from './TabularDonorTimeline';
import LoadingIndicator from './LoadingIndicator';
import { loadUsers as _loadUsers } from '../Redux/UserActions';
import {
    loadDonor as _loadDonor,
    loadDonorTimeline as _loadDonorTimeline,
    loadDonorTasks as _loadDonorTasks,
    generateDonorPDF as _generateDonorPDF,
    updateCaseStatus as _updateCaseStatus,
} from '../Redux/DonorActions';
import {
    selectDonorWorkflow,
    getWorkflowTaskMap,
} from '../Utils/donorWorkflow';
import Colors from '../Themes/Colors';

type Props = {
    isLoading: boolean,
    profile: UserProfile,
    defaultTasks: TaskMap,
    workflows: Array<Workflow>,
    forms: Array<Form>,
    selectedDonorId: number,
    selectedDonor: ?Donor,
    organizationId: number,
    organizationMembers: Array<UserProfile>,
    pdfLoading: boolean,
    referenceLoading: boolean,
    updateCaseStatusLoading: boolean,
    generateDonorPDF: (
        donor: Donor,
        workflowTasks: TaskMap,
        forms: Array<Form>,
        profile: UserProfile,
        organizationMembers: Array<UserProfile>
    ) => *,
    updateCaseStatus: typeof _updateCaseStatus,
    loadDonor: (donorId: number) => *,
    loadDonorTimeline: (donorId: number) => *,
    loadDonorTasks: (donorId: number) => *,
    loadUsers: typeof _loadUsers,
};

class SelectedDonorPane extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('approved', this.props.organizationId);
    }

    componentDidUpdate(prevProps: Props) {
        const {
            selectedDonorId: prevSelectedDonorId,
        } = prevProps;

        const {
            selectedDonorId,
            loadDonor,
            loadDonorTimeline,
            loadDonorTasks,
        } = this.props;

        if (selectedDonorId && selectedDonorId !== prevSelectedDonorId) {
            loadDonor(selectedDonorId);
            loadDonorTimeline(selectedDonorId);
            loadDonorTasks(selectedDonorId);
        }
    }

    onDownloadDonorPdf = () => {
        const {
            profile,
            selectedDonor,
            defaultTasks,
            organizationMembers,
            workflows,
            forms,
            generateDonorPDF,
        } = this.props;

        if (!selectedDonor) { return; }

        let taskMap = defaultTasks;
        if (selectedDonor.workflow !== 'OPODEFAULT') {
            const workflow = selectDonorWorkflow(selectedDonor.workflow, workflows);
            taskMap = workflow ? getWorkflowTaskMap(workflow) : {};
        }

        generateDonorPDF(selectedDonor, taskMap, forms, profile, organizationMembers);
    };

    onUpdateCaseStatus = () => {
        const {
            selectedDonor,
            updateCaseStatus,
        } = this.props;

        if (!selectedDonor) { return; }

        updateCaseStatus(selectedDonor.donorId, !selectedDonor.closed, selectedDonor.lastModified);
    };

    render() {
        const {
            selectedDonorId,
            selectedDonor,
            isLoading,
            pdfLoading,
            referenceLoading,
            updateCaseStatusLoading,
        } = this.props;

        const downloadButtonDisabled = (isLoading || pdfLoading || referenceLoading);

        if (!selectedDonorId) {
            return (
                <div style={{ ...MessagesHomeStyles.column, flex: 2, }}>
                    <div style={MessagesHomeStyles.noDataWrapper}>
                        Select a case
                    </div>
                </div>
            );
        }

        if (isLoading) {
            return (
                <div style={{ ...MessagesHomeStyles.column, flex: 2, }}>
                    <div style={MessagesHomeStyles.noDataWrapper}>
                        <LoadingIndicator />
                    </div>
                </div>
            );
        }

        return (
            <div style={{ ...MessagesHomeStyles.column, flex: 2, }}>
                <div style={{ ...MessagesHomeStyles.cardRow, flex: 0.5, }}>
                    <Card style={{ ...MessagesHomeStyles.card, marginRight: 20, flex: 2, }}>
                        <div style={MessagesHomeStyles.cardTitle}>
                            Recovered Organs
                        </div>
                        <RecoveredOrganPane />
                    </Card>
                    <Card style={{ ...MessagesHomeStyles.card, marginRight: 20, flex: 1, }}>
                        <div style={ApplicationStyles.flexCenter}>
                            <div style={MessagesHomeStyles.cardTitle}>
                                OR to Cross-clamp
                            </div>
                            <InfoButton infoId={1} />
                        </div>
                        <DonorMetricPane />
                    </Card>
                    <div style={MessagesHomeStyles.caseActionButtonContainer}>
                        <Button
                            disabled={downloadButtonDisabled}
                            style={MessagesHomeStyles.caseActionButton}
                            onClick={this.onDownloadDonorPdf}
                            primary
                            raised
                        >
                            {(pdfLoading) ? <LoadingIndicator /> : (
                                <>
                                    <div
                                        style={{
                                            ...MessagesHomeStyles.cardTitle,
                                            textTransform: 'none',
                                        }}
                                    >
                                        Download
                                    </div>
                                    <FontIcon style={{ fontSize: 40, }} value="get_app" />
                                </>
                            )}
                        </Button>
                    </div>
                    <div style={MessagesHomeStyles.caseActionButtonContainer}>
                        <Button
                            disabled={false}
                            style={{
                                ...MessagesHomeStyles.caseActionButton,
                                backgroundColor: (selectedDonor?.closed ? Colors.green : Colors.red),
                                color: 'white',
                            }}
                            onClick={this.onUpdateCaseStatus}
                            raised
                        >
                            {updateCaseStatusLoading ? <LoadingIndicator /> : (
                                <>
                                    <div
                                        style={{
                                            ...MessagesHomeStyles.cardTitle,
                                            textTransform: 'none',
                                        }}
                                    >
                                        {selectedDonor?.closed ? 'Reopen' : 'Close'}
                                        {' '}
                                        Case
                                    </div>
                                    <FontIcon style={{ fontSize: 40, }} value={selectedDonor?.closed ? 'meeting_room' : 'door_front'} />
                                </>
                            )}
                        </Button>
                    </div>

                </div>
                <Card style={{ ...MessagesHomeStyles.card, flex: 2, }}>
                    <div style={MessagesHomeStyles.cardTitle}>
                        Case Timeline
                    </div>
                    <GraphicalDonorTimeline />
                </Card>
                <Card style={{ ...MessagesHomeStyles.card, flex: 1, }}>
                    <TabularDonorTimeline />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.loading.donor || state.loading.donorTimeline || state.loading.donorTasks
            || !state.donor.donors[state.donor.selectedDonorId]
            || !state.donor.donors[state.donor.selectedDonorId].timeline
            || !state.donor.donors[state.donor.selectedDonorId].tasks,
    selectedDonorId: state.donor.selectedDonorId,
    selectedDonor: state.donor.donors[state.donor.selectedDonorId],
    defaultTasks: state.donor.tasks,
    workflows: state.reference.workflows || [],
    forms: state.reference.forms || [],
    organizationId: state.auth.profile.organizationId,
    organizationMembers: state.user.loadedUsers.approved ?? [],
    pdfLoading: state.loading.donorPDF,
    updateCaseStatusLoading: state.loading.updateCaseStatus,
    referenceLoading: state.loading.referenceData,
    profile: state.auth.profile || {},
});

export default connect(mapStateToProps, {
    loadDonor: _loadDonor,
    loadDonorTasks: _loadDonorTasks,
    loadDonorTimeline: _loadDonorTimeline,
    generateDonorPDF: _generateDonorPDF,
    loadUsers: _loadUsers,
    updateCaseStatus: _updateCaseStatus,
})(SelectedDonorPane);
