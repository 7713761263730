// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import { toast } from 'react-toastify';

import ApplicationStyles from '../Themes/ApplicationStyles';
import Images from '../Themes/Images';

import {
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';

type Props = {
    toastId: number,
    sagaHeading: string,
    sagaMessage: string,
    sagaLabel: string,
    sagaError: boolean,
    setSagaMessage: (heading: string, message: string, label: string, isError: boolean) => *,
};

class SagaMessage extends PureComponent<Props> {
    onClose = () => {
        const {
            setSagaMessage,
        } = this.props;

        setSagaMessage('', '', '', false);
    };

    render() {
        const {
            sagaHeading,
            sagaMessage,
            sagaLabel,
            sagaError,
            toastId,
        } = this.props;

        const active: boolean = sagaHeading.length > 0;

        if (active && !sagaError) {
            // If no button is required then just create a toast
            toast(
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                    <p>{sagaMessage}</p>
                </div>,
                { toastId, onClose: this.onClose, }
            );

            return '';
        }
        return (
            <Dialog
                active={active}
                type="small"
                onEscKeyDown={this.onClose}
                onOverlayClick={this.onClose}
            >
                <div style={ApplicationStyles.horizontalCenter}>
                    <img src={Images.logo} alt="HTS Logo" />
                    <h4>{sagaHeading}</h4>
                    <p style={ApplicationStyles.whiteSpaceWrap}>{sagaMessage}</p>
                    <Button
                        onClick={this.onClose}
                        style={{ ...ApplicationStyles.margin20, ...ApplicationStyles.button, }}
                    >
                        {sagaLabel}
                    </Button>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    sagaHeading: state.application.sagaHeading || '',
    sagaMessage: state.application.sagaMessage,
    sagaLabel: state.application.sagaLabel,
    sagaError: state.application.sagaError,
});
export default connect(mapStateToProps, {
    setSagaMessage: _setSagaMessage,
})(SagaMessage);
