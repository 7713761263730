// @flow
import React, { PureComponent } from 'react';

type Props = {
    numSelected: number,
    disableActions: boolean,
};

export default class EditSelectionText extends PureComponent<Props> {
    render() {
        const { numSelected, disableActions, } = this.props;

        return (
            <p className={`selected-text ${disableActions ? 'disabled' : ''}`}>
                {`${numSelected} user${numSelected === 1 ? '' : 's'}`}
                {' '}
                selected
            </p>
        );
    }
}
