// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    TableHead, Table, TableRow, TableCell,
} from 'react-toolbox/lib/table';
import FontIcon from 'react-toolbox/lib/font_icon';
import type { TaskMap, TimelineItem } from '../Utils/types';
import MessagesHomeStyles from '../Containers/Styles/MessagesHomeStyles';
import { getFormattedDateTimeString } from '../Utils/datetime';
import {
    selectDonorWorkflow,
    getWorkflowTaskMap,
} from '../Utils/donorWorkflow';
import TablePanelStyles from './Styles/TablePanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';


type Props = {
    tasks: TaskMap,
    donorTimeline: Array<TimelineItem>,
};

type State = {
    sortBy: string,
    sortDirection: string
};

class TabularDonorTimeline extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sortBy: 'Time',
            sortDirection: 'asc',
        };
    }

    onSetSortBy = (by: string, direction: string) => {
        this.setState((state) => ({
            sortDirection: state.sortBy === by ? direction : 'asc',
            sortBy: by,
        }));
    };

    getCompletedTasks = () => {
        const {
            tasks,
            donorTimeline,
        } = this.props;

        const {
            sortBy,
            sortDirection,
        } = this.state;

        const completedTaskObjects = {};
        for (let i = 0; i < donorTimeline.length; i += 1) {
            if (donorTimeline[i].completed === true) {
                completedTaskObjects[donorTimeline[i].taskId] = i;
            }
            if (donorTimeline[i].completed === false) {
                delete completedTaskObjects[donorTimeline[i].taskId];
            }
        }

        const completedTasks = Object.values(completedTaskObjects);

        if (sortBy === 'Description') {
            if (sortDirection === 'asc') {
                completedTasks.sort((a: any, b: any) => (tasks[donorTimeline[a].taskId].description > tasks[donorTimeline[b].taskId].description ? 1 : -1));
            } else {
                completedTasks.sort((a: any, b: any) => (tasks[donorTimeline[a].taskId].description > tasks[donorTimeline[b].taskId].description ? -1 : 1));
            }
        } else if (sortBy === 'Time') {
            if (sortDirection === 'asc') {
                completedTasks.sort((a: any, b: any) => (donorTimeline[a].updateTime > donorTimeline[b].updateTime ? 1 : -1));
            } else {
                completedTasks.sort((a: any, b: any) => (donorTimeline[a].updateTime > donorTimeline[b].updateTime ? -1 : 1));
            }
        }
        return completedTasks;
    };

    createListItem = (index: number) => {
        const {
            tasks,
            donorTimeline,
        } = this.props;

        const donorTimelineItem = donorTimeline[index];
        return (
            <TableRow key={`${index}`} selectable={false} style={TablePanelStyles.tableCell}>
                <TableCell>
                    <FontIcon value="check_circle" style={MessagesHomeStyles.icon} />
                </TableCell>
                <TableCell>
                    {tasks[donorTimelineItem.taskId].description}
                </TableCell>
                <TableCell>
                    {getFormattedDateTimeString(donorTimelineItem.updateTime)}
                </TableCell>
                <TableCell>
                    {donorTimelineItem.noteContent}
                </TableCell>
            </TableRow>
        );
    };

    render() {
        const {
            sortBy,
            sortDirection,
        } = this.state;

        const completedTasks = this.getCompletedTasks();

        if (completedTasks.length === 0) {
            return (
                <div style={ApplicationStyles.relativeVerticalCenter}>
                    No data for selected donor
                </div>
            );
        }

        return (
            <div style={{ maxHeight: 300, overflow: 'scroll', }}>
                <Table selectable={false} id="table">
                    <TableHead style={TablePanelStyles.tableCell}>
                        <TableCell />
                        <TableCell
                            sorted={sortBy === 'Description' ? sortDirection : null}
                            onClick={() => this.onSetSortBy('Description', (sortDirection === 'desc') ? 'asc' : 'desc')}
                            style={MessagesHomeStyles.cardTitle}
                        >
                            Task Description
                        </TableCell>
                        <TableCell
                            sorted={sortBy === 'Time' ? sortDirection : null}
                            onClick={() => this.onSetSortBy('Time', (sortDirection === 'desc') ? 'asc' : 'desc')}
                            style={MessagesHomeStyles.cardTitle}
                        >
                            Completion Time
                        </TableCell>
                    </TableHead>
                    {
                        completedTasks.map((taskIndex) => {
                            if (typeof taskIndex === 'number') {
                                return this.createListItem(taskIndex);
                            }

                            return null;
                        })
                    }
                </Table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        selectedDonorId,
    } = state.donor;
    const selectedWorkflowName = selectedDonorId ? state.donor.donors[selectedDonorId].workflow : '';
    const selectedWorkflow = selectDonorWorkflow(selectedWorkflowName, state.reference.workflows);

    return {
        tasks: selectedWorkflow ? getWorkflowTaskMap(selectedWorkflow) : state.donor.tasks,
        donorTimeline: selectedDonorId && state.donor.donors[selectedDonorId]
            ? state.donor.donors[selectedDonorId].timeline : [],
    };
};

export default connect(mapStateToProps, null)(TabularDonorTimeline);
