// @flow
import type {
    ActiveChatroom,
    ArchivedChatroom,
    ChatroomData,
} from '../Utils/types';
import sort from '../Utils/sort';
import search from '../Utils/search';

type ChatroomState = {
    activeChatrooms: Array<ActiveChatroom>,
    archivedChatrooms: Array<ArchivedChatroom>,
    activeChatroomData: ChatroomData,
    archivedChatroomData: ChatroomData,
    sortHeader: string,
    sortDirection: string,
    searchTerm: string,
    activeChatroomDataLoaded: boolean,
    archivedChatroomDataLoaded: boolean,
    selectedChatroom: ?ActiveChatroom | ?ArchivedChatroom
};

const initialState: ChatroomState = {
    activeChatrooms: [],
    archivedChatrooms: [],
    activeChatroomData: {
        organizations: [],
        chatroomMembers: [],
        guestUsers: [],
        instantMessages: [],
        acknowledgements: {},
        instantMessageStatuses: {},
    },
    archivedChatroomData: {
        organizations: [],
        chatroomMembers: [],
        guestUsers: [],
        instantMessages: [],
        acknowledgements: {},
        instantMessageStatuses: {},
    },
    sortHeader: 'chatroomId',
    sortDirection: 'asc',
    searchTerm: '',
    activeChatroomDataLoaded: false,
    archivedChatroomDataLoaded: false,
    selectedChatroom: null,
};

export type LoadChatrooms = { type: 'Chatrooms/LOAD' };
export type LoadedChatrooms = {
    type: 'Chatrooms/LOADED', activeChatrooms: Array<ActiveChatroom>, archivedChatrooms: Array<ArchivedChatroom>,
};
export type LoadActiveChatroomData = { type: 'Chatrooms/LOAD_ACTIVE_CHATROOM_DATA', chatroomId: number };
export type LoadedActiveChatroomData = { type: 'Chatrooms/LOADED_ACTIVE_CHATROOM_DATA', activeChatroomData: ChatroomData };

export type LoadArchivedChatroomData = { type: 'Chatrooms/LOAD_ARCHIVED_CHATROOM_DATA', chatroomId: number };
export type LoadedArchivedChatroomData = { type: 'Chatrooms/LOADED_ARCHIVED_CHATROOM_DATA', archivedChatroomData: ChatroomData };

export type GenerateActiveChatroomPDF = {
    type: 'Chatrooms/GENERATE_ACTIVE_CHATROOM_PDF',
    activeChatroom: ActiveChatroom,
    activeChatroomData: ChatroomData,
};
export type GenerateArchivedChatroomPDF = {
    type: 'Chatrooms/GENERATE_ARCHIVED_CHATROOM_PDF',
    archivedChatroom: ArchivedChatroom,
    archivedChatroomData: ChatroomData,
};

export type SearchChatrooms = { type: 'Chatrooms/SEARCH_CHATROOMS', searchTerm: string, searchHeaders: Array<string>, };
export type SetSortParameters = { type: 'Chatrooms/SET_SORT_PARAMETERS', sortHeader: string, sortDirection: string, };
export type ClearSearch = { type: 'Chatrooms/CLEAR_SEARCH', };
export type SetSelectedChatroomRow = { type: 'Chatrooms/SET_SELECTED_CHATROOM_ROW', selectedChatroom: ActiveChatroom | ArchivedChatroom};

export type DeleteChatroom = { type: 'Chatrooms/DELETE_CHATROOM', chatroomId: number};


type Action =
    | LoadActiveChatroomData
    | LoadArchivedChatroomData
    | LoadedActiveChatroomData
    | LoadedArchivedChatroomData
    | SetSortParameters
    | SearchChatrooms
    | ClearSearch
    | LoadChatrooms
    | LoadedChatrooms
    | SetSelectedChatroomRow
    | DeleteChatroom;

export const generateActiveChatroomPDF = (activeChatroom: ActiveChatroom, activeChatroomData: ChatroomData): GenerateActiveChatroomPDF => ({
    type: 'Chatrooms/GENERATE_ACTIVE_CHATROOM_PDF',
    activeChatroom,
    activeChatroomData,
});

export const generateArchivedChatroomPDF = (archivedChatroom: ArchivedChatroom, archivedChatroomData: ChatroomData): GenerateArchivedChatroomPDF => ({
    type: 'Chatrooms/GENERATE_ARCHIVED_CHATROOM_PDF',
    archivedChatroom,
    archivedChatroomData,
});

export const loadChatrooms = (): LoadChatrooms => ({
    type: 'Chatrooms/LOAD',
});

export const loadedChatrooms = (activeChatrooms: Array<ActiveChatroom>, archivedChatrooms: Array<ArchivedChatroom>): LoadedChatrooms => ({
    type: 'Chatrooms/LOADED',
    activeChatrooms,
    archivedChatrooms,
});

export const searchChatrooms = (searchTerm: string, searchHeaders: Array<string>): SearchChatrooms => ({
    type: 'Chatrooms/SEARCH_CHATROOMS',
    searchTerm,
    searchHeaders,
});

export const setSortParameters = (
    sortHeader: string,
    sortDirection: string
): SetSortParameters => ({
    type: 'Chatrooms/SET_SORT_PARAMETERS',
    sortHeader,
    sortDirection,
});

export const clearSearch = (): ClearSearch => ({
    type: 'Chatrooms/CLEAR_SEARCH',
});

export const loadActiveChatroomData = (chatroomId: number): LoadActiveChatroomData => ({
    type: 'Chatrooms/LOAD_ACTIVE_CHATROOM_DATA',
    chatroomId,
});

export const loadArchivedChatroomData = (chatroomId: number): LoadArchivedChatroomData => ({
    type: 'Chatrooms/LOAD_ARCHIVED_CHATROOM_DATA',
    chatroomId,
});


export const loadedArchivedChatroomData = (archivedChatroomData: ChatroomData): LoadedArchivedChatroomData => ({
    type: 'Chatrooms/LOADED_ARCHIVED_CHATROOM_DATA',
    archivedChatroomData,
});

export const loadedActiveChatroomData = (activeChatroomData: ChatroomData): LoadedActiveChatroomData => ({
    type: 'Chatrooms/LOADED_ACTIVE_CHATROOM_DATA',
    activeChatroomData,
});

export const setSelectedChatroomRow = (selectedChatroom: ActiveChatroom | ArchivedChatroom): SetSelectedChatroomRow => ({
    type: 'Chatrooms/SET_SELECTED_CHATROOM_ROW',
    selectedChatroom,
});

export const deleteChatroom = (chatroomId: number): DeleteChatroom => ({
    type: 'Chatrooms/DELETE_CHATROOM',
    chatroomId,
});

const reducer = (state: ChatroomState = initialState, action: Action): ChatroomState => {
    switch (action.type) {
        case 'Chatrooms/LOADED': {
            return {
                ...state,
                archivedChatrooms: sort(action.archivedChatrooms, state.sortDirection, state.sortHeader),
                activeChatrooms: sort(action.activeChatrooms, state.sortDirection, state.sortHeader),


            };
        }

        case 'Chatrooms/LOADED_ACTIVE_CHATROOM_DATA': {
            return {
                ...state,
                activeChatroomData: action.activeChatroomData,
                activeChatroomDataLoaded: true,
            };
        }

        case 'Chatrooms/LOADED_ARCHIVED_CHATROOM_DATA': {
            return {
                ...state,
                archivedChatroomData: action.archivedChatroomData,
                archivedChatroomDataLoaded: true,
            };
        }

        case 'Chatrooms/SEARCH_CHATROOMS': {
            return {
                ...state,

                searchTerm: action.searchTerm,
                archivedChatrooms: search(state.archivedChatrooms, action.searchTerm, action.searchHeaders),
                activeChatrooms: search(state.activeChatrooms, action.searchTerm, action.searchHeaders),
            };
        }

        case 'Chatrooms/SET_SORT_PARAMETERS': {
            return {
                ...state,

                archivedChatrooms: sort(state.archivedChatrooms, action.sortDirection, action.sortHeader),
                activeChatrooms: sort(state.activeChatrooms, action.sortDirection, action.sortHeader),

                sortHeader: action.sortHeader,
                sortDirection: action.sortDirection,
            };
        }

        case 'Chatrooms/CLEAR_SEARCH': {
            return {
                ...state,

                searchTerm: '',
            };
        }

        case 'Chatrooms/SET_SELECTED_CHATROOM_ROW': {
            return {
                ...state,

                selectedChatroom: action.selectedChatroom,
            };
        }


        default:
            return state;
    }
};

export default reducer;

