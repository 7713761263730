// @flow
import type { Saga } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import { finishLoading, startLoading } from '../Redux/LoadingActions';
import type { DownloadFile } from '../Redux/OfferActions';
import { downloadFileFailed, downloadFileSuccess } from '../Redux/OfferActions';

// eslint-disable-next-line import/prefer-default-export
export function* downloadCDSFileSaga(action: DownloadFile): Saga<void> {
    const {
        fileType,
        fileName,
    } = action;

    yield put(startLoading('downloadCDS'));

    try {
        const token = yield select((state) => state.auth.accessToken);

        const root = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');
        const apiPath = `${root}/cds/files/${fileType}/${fileName}`;

        const headers = {
            Apikey: process.env.REACT_APP_API_KEY || '',
            Authorization: `Bearer ${token}`,
        };

        const finalResult = yield fetch(apiPath, {
            method: 'GET',
            headers,
        }).then((response) => {
            if (response.ok) {
                return response.blob();
            }
            return response.json();
        }).then((result) => {
            if (result) {
                if (result.error) {
                    return result.error;
                }

                return saveAs(result, fileName);
            }
            return null;
        });

        if (finalResult) {
            if (typeof finalResult === 'string') {
                yield put(downloadFileFailed(finalResult));
            } else {
                yield put(downloadFileFailed('Failed to download selected file'));
            }
        } else {
            yield put(downloadFileSuccess());
        }

        yield put(finishLoading('downloadCDS'));
    } catch (err) {
        yield put(downloadFileFailed(err.toString()));
        yield put(finishLoading('downloadCDS'));
    }
}
