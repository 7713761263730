// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import List from 'react-toolbox/lib/list/List';
import ListItem from 'react-toolbox/lib/list/ListItem';
import Button from 'react-toolbox/lib/button/Button';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import Sidebar from 'react-sidebar';
import Images from '../Themes/Images';

import ApplicationStyles from '../Themes/ApplicationStyles';
import PanelStyles from './Styles/PanelStyles';
import type { AvatarMap, UserProfile } from '../Utils/types';
import UserActivityPanel from './UserActivityPanel';
import AccessLevelDropdown from './AccessLevelDropdown';
import MemberRoleDropdown from './MemberRoleDropdown';
import {
    updateUserEdit as _updateUserEdit,
    setUserEdit as _setUserEdit,
    setUserEditStatus as _setUserEditStatus,
    setAddUserOrgEdit as _setAddUserOrgEdit,
    cancelEmailUpdate as _cancelEmailUpdate,
    toggleUserActivitySidebar as _toggleUserActivitySidebar,
    removeOrganizationMember as _removeOrganizationMember,
    toggleAddUserToOrganizationPanel as _toggleAddUserToOrganizationPanel,
    denyPendingMember as _denyPendingMember,
    approvePendingMember as _approvePendingMember,
    verifyUserEmail as _verifyUserEmail,
    deleteUser as _deleteUser,
    toggleClientPermissionsPanel as _toggleClientPermissionsPanel,
    loadUserAvatars as _loadUserAvatars,
} from '../Redux/UserActions';
import {
    setSagaMessage as _setSagaMessage,
} from '../Redux/ApplicationActions';
import UserClientPermissionsPanel from './UserClientPermissionsPanel';
import DepartmentDropdown from './DepartmentDropdown';

type Props = {
    dialogType: string,
    accessLevel: ?string,
    user: UserProfile,
    organizationNames: Array<string>,
    headers: any,
    contentType: any,
    userEditChanged: boolean,
    avatars: AvatarMap,
    isUserActivitySidebarOpen: boolean,
    isClientPermissionsPanelOpen: boolean,

    onClose: () => *,
    onUpdateUser: (updatedUser: UserProfile) => *,
    cancelEmailUpdate: (userId: number) => *,
    updateUserEdit: (user: UserProfile) => *,
    removeOrganizationMember: (organizationId: number, userId: number) => *,
    setUserEditStatus: (userEditChanged: boolean) => *,
    toggleUserActivitySidebar: () => *,
    denyPendingMember: (organizationId: number, userId: number) => *,
    approvePendingMember: (organizationId: number, userId: number) => *,
    verifyUserEmail: (userId: number, email: string) => *,
    setAddUserOrgEdit: (user: UserProfile) => *,
    deleteUser: (userId: number) => *,
    toggleAddUserToOrganizationPanel: (isAddUserToOrganizationPanelOpen: boolean) => *,
    toggleClientPermissionsPanel: (isClientPermissionsPanelOpen: boolean) => *,
    setSagaMessage: (heading: string, message: string, label: string, isError: boolean) => *,
    loadUserAvatars: typeof _loadUserAvatars,
};

class UserDetailsPanel extends PureComponent<Props> {
    componentDidMount() {
        this.maybeLoadAvatar();
    }

    componentDidUpdate(prevProps: Props) {
        const { user, } = this.props;

        if (user?.userId !== prevProps.user?.userId) {
            this.maybeLoadAvatar();
        }
    }

    onSaveClick = () => {
        const {
            onUpdateUser,
            setUserEditStatus,
            user,
        } = this.props;

        onUpdateUser(user);
        setUserEditStatus(false);
    };

    onToggleAddOrganizationMemberDialog = (user: UserProfile) => {
        const {
            setAddUserOrgEdit,
            toggleAddUserToOrganizationPanel,
        } = this.props;

        setAddUserOrgEdit(user);
        toggleAddUserToOrganizationPanel(true);
    };

    onChangeUserDetails = (value: string, property: string) => {
        const {
            updateUserEdit,
            user,
        } = this.props;

        updateUserEdit({ ...user, [property]: value, });
    };

    onRemoveOrganizationMember = () => {
        const {
            removeOrganizationMember,
            onClose,
            user,
        } = this.props;


        let selectedUserFullName = 'OmniLife User';
        if (user.firstName || user.lastName) {
            selectedUserFullName = ((user.firstName) ? `${user.firstName} ` : '')
                + ((user.lastName) ? user.lastName : '');
        }

        const response = window.confirm(`Are you sure you want to remove ${selectedUserFullName} from ${
            user.organizationName}? You can add them back later as long as they are an Omnilife user.`);

        if (response) {
            if (user.organizationId) {
                removeOrganizationMember(user.organizationId, user.userId);
            }
            onClose();
        }
    };

    onDenyPendingMember = () => {
        const {
            user,

            denyPendingMember,
            onClose,
        } = this.props;

        if (user.organizationId) {
            denyPendingMember(user.organizationId, user.userId);
            onClose();
        }
    };

    onApprovePendingMember = () => {
        const {
            user,

            onClose,
            approvePendingMember,
            setSagaMessage,
        } = this.props;

        if (!user.role) {
            setSagaMessage('Unable to approve membership', 'Please select a role for the user before approving membership.', 'OK', true);
        } else if (user.organizationId) {
            approvePendingMember(user.organizationId, user.userId);
            onClose();
        }
    };

    onVerifyEmailAddress = () => {
        const {
            user,
            verifyUserEmail,
        } = this.props;

        const response = window.confirm(`Are you sure you want to verify ${user.emailUpdate}? This action cannot be undone.`);

        if (response) {
            verifyUserEmail(user.userId, user.emailUpdate);
        }
    };

    onCancelEmailUpdate = () => {
        const {
            cancelEmailUpdate,
            user,
        } = this.props;

        const response = window.confirm(`Are you sure you want to cancel verification of ${user.emailUpdate}? This action cannot be undone.`);

        if (response) {
            cancelEmailUpdate(user.userId);
        }
    };

    onDeleteUser = () => {
        const {
            deleteUser,
            onClose,
            user,
        } = this.props;

        const fullName = (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}`
            : (user.firstName) ? user.firstName : (user.lastName) ? user.lastName : user.email;

        const response = window.confirm(`Are you sure you want to delete ${fullName}? This action cannot be undone.`);

        if (response) {
            deleteUser(user.userId);
            onClose();
        }
    };

    maybeLoadAvatar() {
        const { user, avatars, loadUserAvatars, } = this.props;

        if (user && !avatars[user.userId]) {
            loadUserAvatars([user]);
        }
    }

    render() {
        const {
            user,
            dialogType,
            accessLevel,
            organizationNames,
            headers,
            onClose,
            contentType,
            userEditChanged,
            avatars,
            isUserActivitySidebarOpen,
            isClientPermissionsPanelOpen,
            toggleUserActivitySidebar,
            toggleClientPermissionsPanel,
        } = this.props;

        if (!user) {
            return null;
        }

        const phone = user.phone || '';
        const phoneNumberFormat = phone.replace(/[^\d]+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
        const menuDivider = <MenuDivider />;
        const activityMenuDivider = contentType === 'organization members' ? <MenuDivider /> : null;

        const managePermissions = accessLevel === 'SysAdmin'
            ? (
                <div>
                    <Button
                        label="Manage Permissions"
                        style={PanelStyles.secondaryPanelButton}
                        onClick={() => toggleClientPermissionsPanel(true)}
                        primary
                    />
                </div>
            ) : null;

        const avatar = avatars[user.userId] || Images.circledDefaultProfile;

        return (
            <Sidebar
                sidebar={(
                    <div>
                        <div style={{
                            ...ApplicationStyles.detailsHeader,
                            paddingTop: 35,
                            paddingBottom: 35,
                        }}
                        >
                            Permissions
                        </div>
                        <UserClientPermissionsPanel />
                    </div>
                )}
                open={isClientPermissionsPanelOpen}
                docked={isClientPermissionsPanelOpen}
                pullRight
                sidebarClassName="sidebar"
                shadow={false}
                styles={{
                    sidebar: {
                        width: '100%',
                    },
                }}
            >
                <Sidebar
                    sidebar={(
                        <div>
                            <div style={ApplicationStyles.sidebarDetailsHeader}>
                                User Activity (Beta)
                            </div>
                            <UserActivityPanel
                                onClose={toggleUserActivitySidebar}
                            />
                        </div>
                    )}
                    open={isUserActivitySidebarOpen}
                    docked={isUserActivitySidebarOpen}
                    pullRight
                    sidebarClassName="rightSidebarUserActivity"
                    shadow={false}
                >
                    <div style={ApplicationStyles.userDetailsHeader}>
                        <img
                            style={ApplicationStyles.profilePicture}
                            src={avatar}
                            alt=""
                        />
                    </div>
                    <div style={PanelStyles.panelBodyContainer}>
                        <div style={PanelStyles.inputPadding}>
                            <List>
                                {headers.map((prop, index) => {
                                    const isHidden = prop.isInternal && accessLevel !== 'SysAdmin' && accessLevel !== 'Sales';
                                    const editable = !((prop.isUneditable) || (prop.dataKey === 'email' && user.emailUpdate));
                                    if (!isHidden) {
                                        if (editable) {
                                            switch (prop.dataKey) {
                                                case 'accessLevel':
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <AccessLevelDropdown
                                                                value={user[prop.dataKey]}
                                                                name={prop.dataKey}
                                                                onChange={(newVal) => this.onChangeUserDetails(newVal, prop.dataKey)}
                                                                accessLevel={accessLevel || ''}
                                                                dialogType={dialogType}
                                                                organizationId={user.organizationId}
                                                            />
                                                        </div>
                                                    );
                                                case 'organizationName':
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <Autocomplete
                                                                direction="down"
                                                                onChange={
                                                                    (newVal) => this.onChangeUserDetails(newVal, 'organizationName')
                                                                }
                                                                label="Organization"
                                                                multiple={false}
                                                                suggestionMatch="anywhere"
                                                                source={organizationNames}
                                                                value={user.organizationName}
                                                            />
                                                        </div>
                                                    );
                                                case 'role':
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <MemberRoleDropdown
                                                                value={user[prop.dataKey]}
                                                                name={prop.dataKey}
                                                                onChange={(newVal) => this.onChangeUserDetails(newVal, prop.dataKey)}
                                                            />
                                                        </div>
                                                    );
                                                case 'email':
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <div style={PanelStyles.rowItems}>
                                                                <div style={ApplicationStyles.flex1}>
                                                                    <Input
                                                                        type="text"
                                                                        label={prop.title}
                                                                        value={user[prop.dataKey]}
                                                                        name={prop.dataKey}
                                                                        onChange={(newVal) => this.onChangeUserDetails(newVal, prop.dataKey)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );

                                                case 'department':
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <DepartmentDropdown
                                                                value={user[prop.dataKey]}
                                                                name={prop.dataKey}
                                                                onChange={(newVal) => this.onChangeUserDetails(newVal, prop.dataKey)}
                                                            />
                                                        </div>
                                                    );

                                                default:
                                                    return (
                                                        <div style={PanelStyles.editablePadding} key={index.toString()}>
                                                            <Input
                                                                type="text"
                                                                label={prop.title}
                                                                value={user[prop.dataKey] ? user[prop.dataKey] : ' '}
                                                                name={prop.dataKey}
                                                                onChange={(newVal) => this.onChangeUserDetails(newVal, prop.dataKey)}
                                                            />
                                                        </div>
                                                    );
                                            }
                                        } else if (prop.dataKey === 'emailUpdate') {
                                            if (user.emailUpdate) {
                                                if (user.email !== 'No Verified Email') {
                                                    return (
                                                        <div style={PanelStyles.rowItems} key={index.toString()}>
                                                            <ListItem
                                                                caption={user[prop.dataKey]}
                                                                legend={prop.title}
                                                                ripple={false}
                                                            />
                                                            <div>
                                                                <Button
                                                                    floating
                                                                    accent
                                                                    mini
                                                                    icon="check"
                                                                    onClick={this.onVerifyEmailAddress}
                                                                    style={ApplicationStyles.checkIconButton}
                                                                />

                                                                <Button
                                                                    floating
                                                                    accent
                                                                    mini
                                                                    icon="clear"
                                                                    onClick={this.onCancelEmailUpdate}
                                                                    style={ApplicationStyles.clearIconButton}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div style={PanelStyles.rowItems} key={index.toString()}>
                                                        <ListItem
                                                            caption={user[prop.dataKey]}
                                                            legend={user[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                            ripple={false}
                                                        />
                                                        <Button
                                                            floating
                                                            accent
                                                            mini
                                                            icon="check"
                                                            onClick={this.onVerifyEmailAddress}
                                                            style={ApplicationStyles.checkIconButton}
                                                        />
                                                    </div>
                                                );
                                            }
                                            return null;
                                        } else if (prop.dataKey === 'phone') {
                                            return (
                                                <ListItem
                                                    key={index.toString()}
                                                    caption={phoneNumberFormat}
                                                    legend={user[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                    ripple={false}
                                                />
                                            );
                                        } else if (prop.dataKey === 'joinDate') {
                                            return (
                                                <ListItem
                                                    key={index.toString()}
                                                    caption={new Date(user[prop.dataKey]).toLocaleString()}
                                                    legend={user[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                    ripple={false}
                                                />
                                            );
                                        } else {
                                            if (prop.dataKey === 'accessLevel') {
                                                return (
                                                    <ListItem
                                                        key={index.toString()}
                                                        caption={user[prop.dataKey]}
                                                        legend={user[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                        ripple={false}
                                                    />
                                                );
                                            }
                                            return (
                                                <ListItem
                                                    key={index.toString()}
                                                    caption={user[prop.dataKey]}
                                                    legend={user[prop.dataKey] ? prop.title : `No ${prop.title}`}
                                                    ripple={false}
                                                />
                                            );
                                        }
                                    } else {
                                        return null;
                                    }
                                }, this)}
                            </List>
                        </div>
                        {
                            dialogType === 'PendingMember' && accessLevel !== 'Sales' && accessLevel !== 'SysAdmin'
                                ? (
                                    <Button
                                        label="Close"
                                        style={ApplicationStyles.cancelButton}
                                        onClick={onClose}
                                    />
                                ) : (
                                    <div style={PanelStyles.optionButtonsContainer}>
                                        <Button
                                            label="Cancel"
                                            style={ApplicationStyles.cancelButton}
                                            onClick={onClose}
                                        />
                                        <Button
                                            label="Update"
                                            style={!userEditChanged ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                                            onClick={this.onSaveClick}
                                            disabled={!userEditChanged}
                                        />
                                    </div>
                                )
                        }
                        <div>
                            {activityMenuDivider}
                            {
                                (contentType === 'organization members')
                                    ? (
                                        <Button
                                            label="Show Activity"
                                            style={PanelStyles.primaryPanelButton}
                                            onClick={() => toggleUserActivitySidebar()}
                                            primary
                                        />
                                    ) : null
                            }
                            <MenuDivider />
                            {
                                ((contentType === 'basic users'))
                                    ? (
                                        <Button
                                            label="Add to Organization"
                                            style={PanelStyles.primaryPanelButton}
                                            onClick={() => this.onToggleAddOrganizationMemberDialog(user)}
                                        />
                                    ) : (contentType === 'pending members') ? (
                                        <div>
                                            <Button
                                                style={PanelStyles.primaryPanelButton}
                                                label="Approve Membership Request"
                                                onClick={this.onApprovePendingMember}
                                            />
                                            <MenuDivider />
                                            <Button
                                                style={PanelStyles.secondaryPanelButton}
                                                label="Deny Membership Request"
                                                onClick={this.onDenyPendingMember}
                                            />
                                        </div>
                                    ) : (contentType === 'organization members')
                                        ? (
                                            <div>
                                                {managePermissions}
                                                <MenuDivider />
                                                <Button
                                                    style={PanelStyles.cautionPanelButton}
                                                    label="Remove From Organization"
                                                    onClick={this.onRemoveOrganizationMember}
                                                />
                                            </div>
                                        ) : null
                            }
                            {menuDivider}
                            {
                                accessLevel === 'Sales' || accessLevel === 'SysAdmin'
                                    ? (
                                        <div>
                                            <Button
                                                style={PanelStyles.warningPanelButton}
                                                label="Deactivate Account"
                                                onClick={this.onDeleteUser}
                                                primary
                                            />
                                            <MenuDivider />
                                        </div>
                                    ) : null
                            }
                        </div>
                    </div>
                </Sidebar>
            </Sidebar>
        );
    }
}

const mapStateToProps = (state) => {
    const { organizations, } = state.organization;
    const organizationNames = organizations.map((organization) => organization.organizationName);

    return {
        organizationNames,
        accessLevel: state.auth.profile.accessLevel,
        user: state.user.userEdit,
        avatars: state.user.avatars || {},
        userEditChanged: state.user.userEditChanged,
        isUserActivitySidebarOpen: state.user.isUserActivitySidebarOpen,
        isClientPermissionsPanelOpen: state.user.isClientPermissionsPanelOpen,
    };
};

export default connect(mapStateToProps, {
    updateUserEdit: _updateUserEdit,
    setUserEdit: _setUserEdit,
    setUserEditStatus: _setUserEditStatus,
    setAddUserOrgEdit: _setAddUserOrgEdit,
    cancelEmailUpdate: _cancelEmailUpdate,
    toggleUserActivitySidebar: _toggleUserActivitySidebar,
    removeOrganizationMember: _removeOrganizationMember,
    denyPendingMember: _denyPendingMember,
    approvePendingMember: _approvePendingMember,
    verifyUserEmail: _verifyUserEmail,
    deleteUser: _deleteUser,
    toggleClientPermissionsPanel: _toggleClientPermissionsPanel,
    toggleAddUserToOrganizationPanel: _toggleAddUserToOrganizationPanel,
    setSagaMessage: _setSagaMessage,
    loadUserAvatars: _loadUserAvatars,
})(UserDetailsPanel);
