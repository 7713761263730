// @flow
import type { Saga } from 'redux-saga';
import {
    call,
    all,
    put,
    select,
    delay,
} from 'redux-saga/effects';
import trycatch from 'doublet';
import { selectProfileAccessLevel, selectProfileOrganizationId, parseProfile } from 'txp-core';

import api from '../Services/Api';
import {
    apiFetch, apiPost, apiPut, apiDelete,
} from './ApiSaga';
import { isObject, hasOwnProperty } from '../Utils/Object';
// import { isUserTypeLoaded } from '../Utils/user';
import { pushError, setSagaMessage } from '../Redux/ApplicationActions';
import {
    finishLoading,
    startLoading,
} from '../Redux/LoadingActions';

import type {
    ApprovePendingMember,
    ApprovePendingMembers,
    DenyPendingMember,
    LoadOrganizationMembers,
    LoadInvitedUsers,
    LoadPendingMembers,
    AddOrganizationMember,
    CreateDemoUser,
    DeleteUser,
    DeleteUsers,
    UpdateUser,
    UpdateUserUIPermissions,
    UpdateOrganizationMember,
    AddAndUpdateOrganizationMember,
    VerifyUserEmail,
    CancelEmailUpdate,
    RemoveOrganizationMember,
    RemoveOrganizationMembers,
    LoadUserAvatars,
    CompleteRegisteringUser,
    DeleteRegisteringUser,
    DeleteRegisteringUsers,
    LoadRemovedMembers,
    LoadUsers,
    LoadPlatformUsers,
    ResendRegistrationEmail,
    InviteUsers,
    ResendInvitationEmail,
} from '../Redux/UserActions';
import {
    loadPlatformUsers,
    loadedUsersOfType,
    loadAllUsers,
    loadRegisteringUsers,
    loadInvitedUsers,
    loadPendingMembers,
    loadOrganizationMembers,
    loadUsers,
    loadedAllUsers,
    updateUserEdit,
    receiveUserAvatars,
    loadRemovedMembers,
    setUserEdit,
    setUserEditStatus,
    setBulkEditUsers,
    PlatformUsers,
    OtherUsers,
    receiveTeams,
} from '../Redux/UserActions';
import type {
    UserProfile,
    RegisteringUser,
    InvitedUser,
    InvitedUserDisplay,
    UserProfileWithInvite,
    Team,
} from '../Utils/types';
import { getFormattedDateTimeString, getDateString } from '../Utils/datetime';
import serverAccessLevel from '../Utils/serverAccessLevel';
import convertAccessLevel from '../Utils/convertAccessLevel';
import selectOtherProfileImage from '../Redux/MediaActions';
import downloadAvatar from '../Utils/downloadFile';
import { getResourcePermissions } from '../Redux/PermissionActions';

export function* downloadAvatarSaga(action: LoadUserAvatars): Saga<void> {
    const { users, } = action;

    const accessToken = yield select((state) => state.auth.accessToken);
    const currentAvatars = yield select((state) => state.user.avatars);

    for (const user of users) {
        const { userId, } = user;
        const profileImage = selectOtherProfileImage(user, accessToken);

        if (typeof profileImage === 'string') {
            currentAvatars[userId] = '';
        } else if (profileImage) {
            if (!currentAvatars[userId]) {
                const { uri, headers, } = profileImage;
                const result = yield call(downloadAvatar, {
                    fromUrl: uri,
                    headers,
                    useLock: true,
                });

                if (result) {
                    currentAvatars[userId] = result;
                } else {
                    currentAvatars[userId] = '';
                }
            }
        }
    }

    yield put(receiveUserAvatars(currentAvatars));
}

export function* loadUsersSaga(action: LoadUsers): Saga<void> {
    yield delay(100);

    yield put(startLoading('users'));

    const { userType, } = action;
    const loadUserByType = userType && hasOwnProperty({ ...PlatformUsers, ...OtherUsers, }, userType);
    const accessLevel = yield select((state) => selectProfileAccessLevel(state.auth));
    const isAdminLevel = accessLevel && (accessLevel === 'SysAdmin' || accessLevel === 'Sales');
    const callerOrganizationId = yield select((state) => selectProfileOrganizationId(state.auth));
    // Only allow admin-level users to specify organization to load
    const organizationId = isAdminLevel ? action.organizationId : callerOrganizationId;

    if (userType && loadUserByType) {
        if (hasOwnProperty(PlatformUsers, userType)) {
            // $FlowFixMe: In checking userType is a property of PlatformUsers, we're confirming it is of type PlatformUserType
            yield put(loadPlatformUsers(userType, organizationId));
        } else if (userType === 'removed') {
            if (typeof organizationId === 'number') {
                yield put(loadRemovedMembers(organizationId));
            } else {
                yield put(pushError('Missing organization id and cannot load removed users'));
            }
        } else if (userType === 'registering') {
            yield put(loadRegisteringUsers());
        } else if (userType === 'invited') {
            yield put(loadInvitedUsers(organizationId));
        } else {
            yield put(pushError(`Unknown user type "${userType}" could not be loaded`));
        }
    } else if (isAdminLevel) {
        yield all([
            put(loadAllUsers()),
            put(loadRegisteringUsers()),
            put(loadInvitedUsers())
        ]);
    } else if (typeof organizationId === 'number') {
        yield all([
            put(loadPendingMembers(organizationId)),
            put(loadOrganizationMembers(organizationId)),
            put(loadRemovedMembers(organizationId)),
            put(loadInvitedUsers(organizationId))
        ]);
    } else {
        yield put(pushError('Missing organization id and unable to load users'));
    }

    yield put(getResourcePermissions('UI', 11));

    yield put(finishLoading('users'));
}

// Certain users (PlatformUsers) all use the same API endpoint
export function* loadPlatformUsersSaga(action: LoadPlatformUsers): Saga<void> {
    const { userType: type, } = action;
    const accessLevel = yield select((state) => selectProfileAccessLevel(state.auth));
    const isAdminLevel = accessLevel && (accessLevel === 'SysAdmin' || accessLevel === 'Sales');
    const callerOrganizationId = yield select((state) => selectProfileOrganizationId(state.auth));
    // Only allow admin-level users to specify organization to load
    const organizationId = isAdminLevel ? action.organizationId : callerOrganizationId;
    const { result, error, } = yield apiFetch(api.txp.users, null, { type, organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError(`Loading ${type} users failed, are you online?`, error));
        } else {
            yield put(pushError(`Loading ${type} users failed, try again later`, error));
        }
    } else {
        const { users, } = result;
        yield put(
            /* @deprecated old version of parseProfile doesn't include department here, so this should be fixed
            when txp-core is updated */
            loadedUsersOfType(type, (users && users.length > 0 ? users : []).map((user) => ({
                ...parseProfile(user),
                accessLevel: convertAccessLevel(user.access_level),
                department: user.department,
            })))
        );
    }
}

export function* loadRegisteringUsersSaga(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.registeringUsers);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading registering users failed, are you online?', error));
        } else {
            yield put(pushError('Loading registering users failed, try again later', error));
        }
    } else {
        const remoteUsers = (isObject(result) ? result.registrations : result) || [];

        const registeringUsers: Array<RegisteringUser> = [];

        for (let i = 0; i < remoteUsers.length; i += 1) {
            const userData = {
                userId: remoteUsers[i].user_id,
                email: remoteUsers[i].email,
                phone: remoteUsers[i].phone,
                isEmailVerified: remoteUsers[i].is_email_verified,
                isPhoneVerified: remoteUsers[i].is_phone_verified,
                oktaId: remoteUsers[i].okta_id,
                factorId: remoteUsers[i].factor_id,
                registrationTime: getFormattedDateTimeString(remoteUsers[i].registration_time),
                visible: true,
            };
            registeringUsers.push({
                ...userData,
            });
        }

        yield put(loadedUsersOfType('registering', registeringUsers));
    }
}

export function* loadInvitedUsersSaga(action: LoadInvitedUsers): Saga<void> {
    const { organizationId, } = action;
    const { result, error, } = yield apiFetch(api.txp.invitedUsers, null, organizationId ? { organizationId, } : null);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading invited users failed, are you online?', error));
        } else {
            yield put(pushError('Loading invited users failed, try again later', error));
        }
    } else {
        const invitedUsers: Array<InvitedUser> = result || [];
        const users: Array<InvitedUserDisplay> = invitedUsers.map((user) => ({
            visible: true,
            email: user.invite.email,
            inviteDate: getDateString(new Date(user.invite.date)),
            inviterName: `${user.inviter.firstName} ${user.inviter.lastName}`,
            inviterOrganization: user.inviter.organization,
            firstName: user.user?.firstName ?? '',
            lastName: user.user?.lastName ?? '',
            joinDate: user.user?.joinDate ?? '',
        }));

        yield put(loadedUsersOfType('invited', users));
    }
}

// NOTE: creating a custom function to parse team members, because the parseProfile from txp-core is deprecated and from
// an old version of txp-core
// Also, not a full profile is returned with teams, so setting defaults if the properties are missing
function parseTeamMember(profile: any): UserProfile {
    return {
        visible: true,
        userId: profile.user_id,
        firstName: profile.first_name || '',
        lastName: profile.last_name || '',
        profilePicture: profile.profile_picture,
        email: profile.email || '',
        phone: profile.phone || '',
        joinDate: profile.join_date,
        templatePreferences: profile.template_preferences,
        organizationId: profile.organization_id || null,
        organizationName: profile.organization_name || '',
        role: profile.organizational_role,
        department: profile.department,
        verifiedOrgMember: profile.verified_organization_member,
        isEmailVerified: profile.is_email_verified,
        emailUpdate: !profile.email_update && !profile.is_email_verified ? profile.email : profile.email_update,
        accessLevel: profile.access_level,
        lastLogin: profile.lastLogin,
        isGuest: false,
    };
}


export function* loadTeamsSaga(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.teams);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading teams failed, are you online?', error));
        } else {
            yield put(pushError('Loading teams failed, try again later', error));
        }
    } else if (result.teams) {
        const teams: Array<Team> = result.teams.map((team) => ({
            teamId: team.team_id,
            creatorId: team.creator_id,
            teamName: team.team_name,
            shared: team.shared,
            teamMembers: team.team_members.map((user) => parseTeamMember(user.user_information)),
        }));

        yield put(receiveTeams(teams));
    }
}

export function* loadAllUsersSaga(): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.users);

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading users failed, are you online?', error));
        } else {
            yield put(pushError('Loading users failed, try again later', error));
        }
    } else {
        const remoteUsers = (isObject(result) ? result.users : result) || [];
        const organizations = yield select((state) => state.organization.organizations);
        const demoOrganizations = organizations.filter((item) => item.organizationType === 'Demo');
        const demoOrganizationIds = demoOrganizations.map((item) => item.organizationId);
        const archivedUsers: Array<UserProfile> = [];
        const basicUsers: Array<UserProfileWithInvite> = [];
        const demoUsers: Array<UserProfile> = [];
        const pendingMembers: Array<UserProfile> = [];
        const organizationMembers: Array<UserProfile> = [];

        /* @deprecated */
        // TODO: once we update txp-core for admin portal, we should add joinDate, invite, and inviter
        // to the parseProfile function imported from txp-core, instead of adding it separately
        for (const user of remoteUsers) {
            const userData = {
                ...parseProfile(user),
                joinDate: user.join_date,
                invite: user.invite,
                inviter: user.inviter,
                department: user.department,
            };
            userData.accessLevel = convertAccessLevel(userData.accessLevel);
            if (userData.accessLevel === 'Archived') {
                archivedUsers.push(userData);
            } else if (!userData.organizationId) {
                basicUsers.push(userData);
            } else if (!userData.verifiedOrgMember) {
                pendingMembers.push(userData);
            } else if (demoOrganizationIds.includes(userData.organizationId)) {
                demoUsers.push(userData);
            } else {
                organizationMembers.push(userData);
            }
        }

        yield put(loadedAllUsers({
            approved: organizationMembers,
            archived: archivedUsers,
            basic: basicUsers,
            demo: demoUsers,
            pending: pendingMembers,
        }));
    }
}


export function* loadPendingMembersSaga(action: LoadPendingMembers): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.pendingMembers, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading pending members failed, are you online?', error));
        } else {
            yield put(pushError('Loading pending members failed, try again later', error));
        }
    } else {
        const remoteUsers = (isObject(result) ? result.pending_organization_members : result) || [];
        const pendingMembers: Array<UserProfile> = [];

        for (let i = 0; i < remoteUsers.length; i += 1) {
            // user data in this API is not structured as the parser expects - flatten if first
            const expandedUser = { ...remoteUsers[i].user_information, ...remoteUsers[i], };
            /* @deprecated old version of parseProfile doesn't include department here, so this should be fixed
            when txp-core is updated */
            const userData = parseProfile(expandedUser);
            userData.accessLevel = convertAccessLevel(userData.accessLevel);
            userData.department = expandedUser.department;
            pendingMembers.push({
                ...userData,
            });
        }

        yield put(loadedUsersOfType('pending', pendingMembers));
    }
}

export function* loadRemovedMembersSaga(action: LoadRemovedMembers): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.removedMembers, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading removed members failed, are you online?', error));
        } else {
            yield put(pushError('Loading removed members failed, try again later', error));
        }
    } else {
        const remoteUsers = (isObject(result) ? result.removed_organization_members : result) || [];
        const removedMembers: Array<UserProfile> = [];

        for (let i = 0; i < remoteUsers.length; i += 1) {
            // user data in this API is not structured as the parser expects - flatten if first
            const expandedUser = { ...remoteUsers[i].user_information, ...remoteUsers[i], };
            /* @deprecated old version of parseProfile doesn't include department here, so this should be fixed
            when txp-core is updated */
            const userData = parseProfile(expandedUser);
            userData.department = expandedUser.department;
            removedMembers.push({
                ...userData,
            });
        }

        yield put(loadedUsersOfType('removed', removedMembers));
    }
}

export function* loadOrganizationMembersSaga(action: LoadOrganizationMembers): Saga<void> {
    const { result, error, } = yield apiFetch(api.txp.organizationMembers, { organizationId: action.organizationId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading organization members failed, are you online?', error));
        } else {
            yield put(pushError('Loading organization members failed, try again later', error));
        }
    } else {
        const remoteUsers = (isObject(result) ? result.organization_members : result) || [];
        const organizationMembers: Array<UserProfile> = [];

        for (let i = 0; i < remoteUsers.length; i += 1) {
            // user data in this API is not structured as the parser expects - flatten if first
            const expandedUser = { ...remoteUsers[i].user_information, ...remoteUsers[i], };
            /* @deprecated old version of parseProfile doesn't include department here, so this should be fixed
            when txp-core is updated */
            const userData = parseProfile(expandedUser);
            userData.accessLevel = convertAccessLevel(userData.accessLevel);
            userData.department = expandedUser.department;
            organizationMembers.push({
                ...userData,
            });
        }

        yield put(loadedUsersOfType('approved', organizationMembers));
    }
}

export function* approvePendingMember(action: ApprovePendingMember): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.handlePendingMember, { organizationId: action.organizationId, }, {
        user_id: action.userId,
        approve: true,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Approving member failed, are you online?', error));
        } else {
            yield put(pushError('Approving member failed, try again later', error));
        }
    } else {
        yield put(setSagaMessage('Approved', 'Successfully approved pending member', '', false));
        yield put(loadPlatformUsers('pending'));
    }
}

export function* approvePendingMembers(action: ApprovePendingMembers): Saga<void> {
    const { approvals, } = action;
    // Assuming approvals are all approvals or denials
    const approving = approvals[0].approve;
    const text = approving
        ? ['Approving', 'Approved', 'approved']
        : ['Denying', 'Denied', 'denied'];
    const { result, error, } = yield apiPost(api.txp.handlePendingMembers, null, {
        membership_approvals: approvals.map(({ organizationId, userId, approve, }) => ({
            organization_id: organizationId,
            user_id: userId,
            approve,
        })),
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError(`${text[0]} members failed, are you online?`, error));
        } else {
            yield put(pushError(`${text[0]} members failed, try again later`, error));
        }
    } else {
        const num = approvals.length;
        const plural = approvals.length === 1 ? '' : 's';

        yield put(setBulkEditUsers([]));
        yield put(setSagaMessage(text[1], `Successfully ${text[2]} ${num} pending member${plural}`, '', false));
        yield put(loadPlatformUsers('pending'));
    }
}

export function* denyPendingMember(action: DenyPendingMember): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.handlePendingMember, { organizationId: action.organizationId, }, {
        user_id: action.userId,
        approve: false,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Denying member failed, are you online?', error));
        } else {
            yield put(pushError('Denying member failed, try again later', error));
        }
    } else {
        yield put(setSagaMessage('Denied', 'Successfully denied pending member', '', false));
        yield put(loadPlatformUsers('pending'));
    }
}

export function* addOrganizationMember(action: AddOrganizationMember): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.addOrganizationMember, { organizationId: action.organizationId, }, {
        user_id: action.userId,
        organizational_role: action.role,
        access_level: action.accessLevel,
        department: action.department,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Adding user to organization failed, are you online?', error));
        } else {
            yield put(pushError('Adding user to organization failed, try again later', error));
        }
    } else {
        yield put(loadPlatformUsers('basic'));
    }
}

export function* removeOrganizationMember(action: RemoveOrganizationMember): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.removeOrganizationMember, {
        organizationId: action.organizationId,
        userId: action.userId,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Removing user from organization failed, are you online?', error));
        } else {
            yield put(pushError('Removing user from organization failed, try again later', error));
        }
    } else {
        yield put(loadPlatformUsers('approved'));
        yield put(setSagaMessage('Success', 'Successfully removed user from organization', '', false));
    }
}

export function* removeOrganizationMembers({ users, }: RemoveOrganizationMembers): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.removeOrganizationMembers, null, {
        users: users.map(({ userId, organizationId, }) => ({
            user_id: userId,
            organization_id: organizationId,
        })),
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Removing users from organization failed, are you online?', error));
        } else {
            yield put(pushError('Removing users from organization failed, try again later', error));
        }
    } else {
        yield put(setBulkEditUsers([]));
        yield put(loadUsers());
        yield put(setSagaMessage('Success', `Successfully removed ${users.length} users from organization`, '', false));
    }
}

export function* createDemoUser(action: CreateDemoUser): Saga<void> {
    yield put(startLoading('createDemoUser'));

    const { result, error, } = yield apiPost(api.txp.addUser, null, {
        first_name: action.firstName,
        last_name: action.lastName,
        password: action.password,
        access_level: serverAccessLevel(action.accessLevel),
        organization_id: action.organizationId,
        organizational_role: action.role,
    });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Creating demo account failed, are you online?', error));
        } else {
            yield put(pushError('Creating demo account failed, try again later', error));
        }
        yield put(finishLoading('createDemoUser'));
    } else {
        yield put(loadPlatformUsers('demo'));
        yield put(finishLoading('createDemoUser'));
    }
}

export function* deleteUser(action: DeleteUser): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.deleteUser, { userId: action.userId, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting user failed, are you online?', error));
        } else {
            yield put(pushError('Deleting user failed, try again later', error));
        }
    } else {
        yield put(loadUsers());
    }
}

export function* deleteUsers(action: DeleteUsers): Saga<void> {
    const { result, error, } = yield apiDelete(api.txp.deleteUsers, null, { user_ids: action.userIds, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting users failed, are you online?', error));
        } else {
            yield put(pushError('Deleting users failed, try again later', error));
        }
    } else {
        yield put(setBulkEditUsers([]));
        yield put(loadUsers());
        yield put(setSagaMessage('Success', `Successfully deleted ${action.userIds.length} users`, '', false));
    }
}

export function* updateUser(action: UpdateUser): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateUser, { userId: action.userId, }, {
        first_name: action.firstName,
        last_name: action.lastName,
        email: action.email,
        template_preferences: action.templatePreferences,
        access_level: serverAccessLevel(action.accessLevel),
        organizational_role: action.role,
        department: action.department,
    });

    if (error) {
        // Reset user details if updating fails
        yield put(setUserEdit(action.userId));

        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating user failed, are you online?', error));
        } else {
            yield put(pushError('Updating user failed, try again later', error));
        }
    } else {
        yield put(loadUsers());
    }
}

export function* updateUserUIPermissions(action: UpdateUserUIPermissions): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateUserUIPermissions, { userId: action.userId, }, {
        permissions: action.permissions,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating user permissions failed, are you online?', error));
        } else {
            yield put(pushError('Updating user permissions failed, try again later', error));
        }
    } else {
        yield put(getResourcePermissions('UI', 11));
        yield put(setUserEditStatus(false));
        yield put(setSagaMessage('Success', 'Successfully added permission', '', false));
    }
}

export function* addAndUpdateOrganizationMember(action: AddAndUpdateOrganizationMember): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.addOrganizationMember, { organizationId: action.organizationId, }, {
        user_id: action.userId,
        organizational_role: action.role,
        department: action.department,
        access_level: 'OrgMember',
    });

    if (error || !result) {
        // Reset user details if updating fails
        yield put(setUserEdit(action.userId));

        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Adding user to organization failed, are you online?', error));
        } else {
            yield put(pushError('Adding user to organization failed, try again later', error));
        }
    } else {
        const { error: updateError, } = yield apiPut(api.txp.updateOrganizationMember, {
            organizationId: action.organizationId,
            userId: action.userId,
        }, {
            organizational_role: action.role,
            first_name: action.firstName,
            last_name: action.lastName,
            email: action.email,
            template_preferences: action.templatePreferences,
            access_level: serverAccessLevel(action.accessLevel),
        });

        // Always reload users, since organization was successfully updated
        yield put(loadUsers());

        if (updateError) {
            // Wait for reloading of users
            yield delay(300);

            // Reset user details if updating fails (loading users before this will make sure organization is still updated)
            yield put(setUserEdit(action.userId));

            if (updateError.isValidationError) {
                const errorMessage = 'Only organization was updated. Other attributes failed.';
                yield put(pushError(`${errorMessage}`));
            } else if (updateError.isNetworkError) {
                yield put(pushError('Updating user failed, are you online?', updateError));
            } else {
                yield put(pushError('Updating user failed, try again later', updateError));
            }
        }
    }
}

export function* updateOrganizationMember(action: UpdateOrganizationMember): Saga<void> {
    const { error, } = yield apiPut(api.txp.updateOrganizationMember, {
        organizationId: action.organizationId,
        userId: action.userId,
    }, {
        organizational_role: action.role,
        first_name: action.firstName,
        last_name: action.lastName,
        email: action.email,
        template_preferences: action.templatePreferences,
        access_level: serverAccessLevel(action.accessLevel),
        department: action.department,
    });

    if (error) {
        // Reset user details if updating fails
        yield put(setUserEdit(action.userId));

        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Updating user failed, are you online?', error));
        } else {
            yield put(pushError('Updating user failed, try again later', error));
        }
    } else {
        yield put(loadUsers());
    }
}

export function* verifyUserEmail(action: VerifyUserEmail): Saga<void> {
    const { result, error, } = yield apiPost(api.txp.verifyEmail, { userId: action.userId, }, { email: action.email, });

    if (error || !result) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Verifying email failed, are you online?', error));
        } else {
            yield put(pushError('Verifying email failed, try again later', error));
        }
    } else {
        const currentUserEdit = yield select((state) => state.user.userEdit);

        yield put(updateUserEdit({
            ...currentUserEdit,

            email: result.email,
            emailUpdate: null,
            isEmailVerified: true,
        }));

        yield put(loadUsers());

        yield put(setSagaMessage('Success', 'Email verified', '', false));
    }
}

export function* cancelEmailUpdate(action: CancelEmailUpdate): Saga<void> {
    const { error, } = yield apiPost(api.txp.cancelEmailUpdate, { userId: action.userId, });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Canceling your email update failed, are you online?', error));
        } else {
            yield put(pushError('Canceling your email update failed, try again later', error));
        }
    } else {
        const currentUserEdit = yield select((state) => state.user.userEdit);

        yield put(updateUserEdit({
            ...currentUserEdit,

            emailUpdate: null,
            isEmailVerified: true,
        }));

        yield put(loadUsers());

        yield put(setSagaMessage('Success', 'Email verification cancelled', '', false));
    }
}

export function* resendVerificationEmailSaga(action: ResendRegistrationEmail): Saga<void> {
    const {
        email,
    } = action;

    yield put(startLoading('resendEmail'));

    yield delay(50);

    const { error, } = yield call(apiPost, api.txp.resendVerificationEmail, null, {
        email,
    });

    if (error) {
        if (error.isInvalidResponseCode) {
            // An invalid response code (412) from the resend email server route indicates the user has not first
            // verified their phone number and thus has not been sent an initial verification email
            yield put(pushError('The user must first validate their phone number before validating their email address'));
        } else {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        }
    }

    yield put(finishLoading('resendEmail'));
}

export function* resendInvitationEmailSaga(action: ResendInvitationEmail): Saga<void> {
    const {
        email,
    } = action;

    yield put(startLoading('resendInvitation'));

    yield delay(50);

    const { result, error, } = yield call(apiPost, api.txp.resendInvitationEmail, null, {
        email,
    });

    if (error) {
        let errorMessage = 'Something went wrong';
        if (error.isInvalidResponseCode && error.responseText) {
            const errorBody = JSON.parse(error.responseText);
            errorMessage = errorBody.error;
        }
        yield put(pushError(`${errorMessage}`));
    } else {
        const successText = result && result.email ? `Successfully resent invitation to ${result.email}` : 'Successfully resent invitation';
        yield put(setSagaMessage('Success', successText, '', false));
    }

    yield put(finishLoading('resendInvitation'));
}

export function* completeRegisteringUserSaga(action: CompleteRegisteringUser): Saga<void> {
    const {
        userId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiPost(api.txp.completeRegisteringUser, {
        userId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Completing user registration failed, are you online?', error));
        } else {
            const [parseError, parsedObject] = trycatch(JSON.parse, error.responseText);
            const message = parseError || !parsedObject.error ? 'try again later' : parsedObject.error;
            yield put(pushError(`Completing user registration failed: ${message}`, error));
        }
    } else {
        yield put(loadUsers());

        yield put(setSagaMessage('Success', 'User registration completed', '', false));
    }
}

export function* deleteRegisteringUserSaga(action: DeleteRegisteringUser): Saga<void> {
    const {
        userId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiDelete(api.txp.deleteRegisteringUser, {
        userId,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting registering user failed, are you online?', error));
        } else {
            yield put(pushError('Deleting registering user failed, try again later', error));
        }
    } else {
        yield put(loadUsers());

        yield put(setSagaMessage('Success', 'Registering user deleted', '', false));
    }
}

export function* deleteRegisteringUsersSaga(action: DeleteRegisteringUsers): Saga<void> {
    const { userIds, } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, } = yield apiDelete(api.txp.deleteRegisteringUsers, null, {
        user_ids: userIds,
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Deleting registering user failed, are you online?', error));
        } else {
            yield put(pushError('Deleting registering user failed, try again later', error));
        }
    } else {
        yield put(setBulkEditUsers([]));
        yield put(loadUsers());
        yield put(setSagaMessage('Success', `Successfully deleted ${userIds.length} registering users`, '', false));
    }
}

export function* inviteUsersSaga(action: InviteUsers): Saga<void> {
    const { fileRead, } = action;

    const nameEmailList = [];
    for (let i = 0; i < fileRead.length; i += 1) {
        const fileLine = fileRead[i];
        if (fileLine.length === 2) {
            nameEmailList.push({ name: fileLine[0], email: fileLine[1], });
        } else {
            yield put(setSagaMessage('Error', 'Please ensure each row in your uploaded file only has two columns', 'Close', true));
            return;
        }
    }

    if (nameEmailList.length > 0) {
        const { result, error, } = yield apiPost(api.txp.inviteUsers, {}, {
            name_email_list: nameEmailList,
        });

        if (error || !result) {
            // eslint-disable-next-line no-underscore-dangle
            if (error.isValidationError && error.errors && error._errors._errKeys && error._errors._errKeys.includes('email')) {
                yield put(pushError('Inviting users failed, please make sure all emails are valid', error));
            } else {
                yield put(pushError('Inviting users failed, try again later', error));
            }
        } else {
            let successMessage = '';
            if (result.invited_emails.length > 0) {
                successMessage += `Successfully invited users:\n${result.invited_emails.join('\n')}\n\n`;
            }
            if (result.existing_users.length > 0) {
                successMessage += `The following individuals already have accounts and \
                    were not sent invitation emails:\n${result.existing_users.join('\n')}\n\n`;
            }
            if (result.existing_invites.length > 0) {
                successMessage += `The following individuals have already been sent invitations and \
                    were not sent another invitation email:\n${result.existing_invites.join('\n')}`;
            }
            yield put(setSagaMessage('Success', successMessage, 'Close', true));
        }
    } else {
        // NOTE: this likely shouldn't happen because an empty file wouldn't have the right number of columns
        yield put(setSagaMessage('Error', 'No records found in uploaded file, please try again.', '', false));
    }
}
