// @flow
import Colors from '../../Themes/Colors';

const ResearchStyles = {
    instructionContainer: {
        marginTop: 100,
        marginBottom: 10,
        textAlign: 'center',
    },
    inputContainer: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '40%',
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    submitButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    submitButton: {
        backgroundColor: Colors.brandPrimary,
        color: Colors.white,
    },
    submitButtonDisabled: {
        backgroundColor: Colors.buttonBackgroundDisabled,
        color: Colors.buttonTextDisabled,
    },
};

export default ResearchStyles;
