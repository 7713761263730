// @flow
export type LoadingState = {
    login: boolean,
    users: boolean,
    createDemoUser: boolean,
    donors: boolean,
    donor: boolean,
    donorTasks: boolean,
    donorTimeline: boolean,
    donorPDF: boolean,
    tasks: boolean,
    organizations: boolean,
    chatrooms: boolean,
    permissions: boolean,
    organizationTypes: boolean,
    activeChatroomData: boolean,
    archivedChatroomData: boolean,
    metrics: boolean,
    resourcePermissions: boolean,
    adminPortalPermissions: boolean,
    downloadCDS: boolean,
    referenceData: boolean,
    uploadReferenceData: boolean,
    updateInactive: boolean,
    downloadReference: boolean,
    updateWorkflowDetails: boolean,
    resendEmail: boolean,
    resendInvitation: boolean,
    updateWorkflowPreferences: boolean,
};

export type Identifier =
    | 'login'
    | 'organizations'
    | 'chatrooms'
    | 'permissions'
    | 'organizationTypes'
    | 'users'
    | 'createDemoUser'
    | 'donors'
    | 'donor'
    | 'donorTasks'
    | 'donorTimeline'
    | 'donorPDF'
    | 'tasks'
    | 'versions'
    | 'activeChatroomData'
    | 'archivedChatroomData'
    | 'closingChatroom'
    | 'metrics'
    | 'resourcePermissions'
    | 'adminPortalPermissions'
    | 'downloadCDS'
    | 'referenceData'
    | 'uploadReferenceData'
    | 'updateInactive'
    | 'downloadReference'
    | 'updateWorkflowDetails'
    | 'resendEmail'
    | 'resendInvitation'
    | 'updateCaseStatus'
    | 'createWorkflow'
    | 'updateWorkflow'
    | 'updateWorkflowPreferences';

const initialState: LoadingState = {
    login: false,
    users: false,
    createDemoUser: false,
    donors: false,
    donor: false,
    donorTasks: false,
    donorTimeline: false,
    donorPDF: false,
    tasks: false,
    organizations: false,
    chatrooms: false,
    permissions: false,
    versions: false,
    organizationTypes: false,
    activeChatroomData: false,
    archivedChatroomData: false,
    metrics: false,
    resourcePermissions: false,
    adminPortalPermissions: false,
    downloadCDS: false,
    referenceData: false,
    uploadReferenceData: false,
    updateInactive: false,
    downloadReference: false,
    updateWorkflowDetails: false,
    resendEmail: false,
    resendInvitation: false,
    updateWorkflowPreferences: false,
};

export type StartLoading = { type: 'Loading/START_LOADING', identifier: string };
export type FinishLoading = { type: 'Loading/FINISH_LOADING', identifier: string };

type Action =
    | StartLoading
    | FinishLoading;

export const startLoading = (identifier: Identifier): StartLoading => ({
    type: 'Loading/START_LOADING',
    identifier,
});

export const finishLoading = (identifier: Identifier): FinishLoading => ({
    type: 'Loading/FINISH_LOADING',
    identifier,
});

const reducer = (state: LoadingState = initialState, action: Action): LoadingState => {
    switch (action.type) {
        case 'Loading/START_LOADING':
            return {
                ...state,

                [action.identifier]: true,
            };

        case 'Loading/FINISH_LOADING':
            return {
                ...state,

                [action.identifier]: false,
            };

        default:
            return state;
    }
};

export default reducer;
