import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import ThemeProvider from 'react-toolbox/lib/ThemeProvider';

import App from './App';
import { unregister } from './registerServiceWorker';
import theme from './toolbox/theme';

import './toolbox/theme.css';
import { appConfiguration } from './AppConfiguration';
import Redirect from './Navigation/Redirect';

const { version, } = appConfiguration();
Sentry.init({
    dsn: 'https://68e5f2dd4a754170b762b54b366eb198@sentry.io/1827921',
    environment: process.env.NODE_ENV,
    release: version,
    beforeSend(event) {
        return process.env.NODE_ENV === 'development' ? null : event;
    },
});

const root = document.getElementById('root');

unregister();

if (root !== null) {
    ReactDOM.render(
        <Redirect>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Redirect>,
        root
    );
}
