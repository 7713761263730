// @flow
import type { Logout } from 'txp-core';

import { hasOwnProperty } from '../Utils/Object';
import search from '../Utils/search';
import sort from '../Utils/sort';
import { objectArrayToDictionary } from '../Utils/arrays';
import type {
    AvatarMap,
    UserProfile,
    UserProfileWithInvite,
    RegisteringUser,
    InvitedUserDisplay,
    UIPermissionsMap,
    Team,
} from '../Utils/types';

export const PlatformUsers = Object.freeze({
    approved: 'approved',
    archived: 'archived',
    basic: 'basic',
    demo: 'demo',
    pending: 'pending',
});

type PlatformUserType = $Keys<typeof PlatformUsers>;

export const OtherUsers = Object.freeze({
    removed: 'removed',
    registering: 'registering',
    invited: 'invited',
});

export type AnyUserType = PlatformUserType | $Keys<typeof OtherUsers>;

type AnyUserCollection = UserProfile[] | UserProfileWithInvite[] | RegisteringUser[] | InvitedUserDisplay[];

type AllPlatformUsers = {
    approved: UserProfile[],
    archived: UserProfile[],
    basic: UserProfileWithInvite[],
    demo: UserProfile[],
    pending: UserProfile[],
};

// Missing utility type to make properties optional
// export type LoadedUsersType = Partial<AllPlatformUsers> & {
export type LoadedUsersType = {
    approved?: UserProfile[],
    archived?: UserProfile[],
    basic?: UserProfileWithInvite[],
    demo?: UserProfile[],
    pending?: UserProfile[],
    removed?: UserProfile[],
    registering?: RegisteringUser[],
    invited?: InvitedUserDisplay[],
};

type UserState = {
    avatars: AvatarMap,
    userId: number,
    selectedUser: any,
    bulkEditUsers: Array<UserProfile>,

    loadedUsers: LoadedUsersType;
    teams: Array<Team>;

    searchTerm: string,
    sortHeader: string,
    sortDirection: string,

    templateOptions: any,

    userEdit: ?UserProfile,
    userEditChanged: boolean,
    addUserOrgEdit: ?UserProfile,

    isUserActivitySidebarOpen: boolean,
    isUserDetailsPanelOpen: boolean,
    isAddUserToOrganizationPanelOpen: boolean,
    isCreateDemoUserPanelOpen: boolean,
    isClientPermissionsPanelOpen: boolean,
};

const initialState: UserState = {
    avatars: {},
    userId: -1,
    selectedUser: null,
    bulkEditUsers: [],

    loadedUsers: {},
    teams: [],

    searchTerm: '',
    sortHeader: 'joinDate',
    sortDirection: 'desc',

    templateOptions: {},

    userEdit: null,
    userEditChanged: false,
    addUserOrgEdit: null,

    isUserActivitySidebarOpen: false,
    isUserDetailsPanelOpen: false,
    isAddUserToOrganizationPanelOpen: false,
    isCreateDemoUserPanelOpen: false,
    isClientPermissionsPanelOpen: false,
};

export type LoadUserAvatars = {
    type: 'Users/LOAD_USER_AVATARS',
    users: Array<UserProfileWithInvite | UserProfile | UserProfile>,
};

export type ReceiveUserAvatars = {
    type: 'Users/RECEIVE_USER_AVATARS',
    avatars: AvatarMap,
};

export type LoadUsers = { type: 'Users/LOAD', userType?: AnyUserType, organizationId?: number };
export type LoadTeams = { type: 'Users/LOAD_TEAMS' };
export type ReceiveTeams = { type: 'Users/RECEIVE_TEAMS', teams: Array<Team> };

export type LoadAllUsers = { type: 'Users/LOAD_ALL_USERS' };

export type LoadedAllUsers = {
    type: 'Users/LOADED_ALL_USERS',
    users: AllPlatformUsers,
};

export type LoadRegisteringUsers = { type: 'Users/LOAD_REGISTERING_USERS' };

export type LoadInvitedUsers = {
    type: 'Users/LOAD_INVITED_USERS',
    organizationId?: number,
};

export type LoadPlatformUsers = {
    type: 'Users/LOAD_PLATFORM_USERS',
    userType: PlatformUserType,
    organizationId?: number,
};

export type LoadedUsersOfType = {
    type: 'Users/LOADED_USERS_OF_TYPE',
    userType: AnyUserType,
    users: AnyUserCollection,
};

export type SetSortParameters = {
    type: 'Users/SET_SORT_PARAMETERS',
    sortHeader: string,
    sortDirection: string,
};

export type LoadPendingMembers = {
    type: 'Users/LOAD_PENDING_MEMBERS',
    organizationId: number,
};

export type LoadRemovedMembers = {
    type: 'Users/LOAD_REMOVED_MEMBERS',
    organizationId: number,
};

export type LoadOrganizationMembers = {
    type: 'Users/LOAD_ORGANIZATION_MEMBERS',
    organizationId: number,
};

export type LoadTemplateOptions = {
    type: 'Users/LOAD_TEMPLATE_OPTIONS',
};

export type LoadedTemplateOptions = {
    type: 'Users/LOADED_TEMPLATE_OPTIONS',
    templateOptions: any,
};

export type ApprovePendingMember = {
    type: 'Users/APPROVE_PENDING_MEMBER',
    organizationId: number,
    userId: number,
};

export type ApprovePendingMembers = {
    type: 'Users/APPROVE_PENDING_MEMBERS',
    approvals: {
        organizationId: number,
        userId: number,
        approve: boolean,
    }[],
};

export type DenyPendingMember = {
    type: 'Users/DENY_PENDING_MEMBER',
    organizationId: number,
    userId: number,
};

export type AddOrganizationMember = {
    type: 'Users/ADD_ORGANIZATION_MEMBER',
    organizationId: number,
    userId: number,
    accessLevel: string,
    role: ?string,
    department: ?string,
};

export type RemoveOrganizationMember = {
    type: 'Users/REMOVE_ORGANIZATION_MEMBER',
    organizationId: number,
    userId: number,
};

export type RemoveOrganizationMembers = {
    type: 'Users/REMOVE_ORGANIZATION_MEMBERS',
    users: UserProfile[],
};

export type UpdateOrganizationMember = {
    type: 'Users/UPDATE_ORGANIZATION_MEMBER',
    organizationId: number,
    userId: number,
    role: ?string,
    firstName: ?string,
    lastName: ?string,
    email: ?string,
    templatePreferences: ?any,
    accessLevel: ?string,
    department: ?string,
};

export type AddAndUpdateOrganizationMember = {
    type: 'Users/ADD_AND_UPDATE_ORGANIZATION_MEMBER',
    organizationId: number,
    userId: number,
    role: ?string,
    firstName: ?string,
    lastName: ?string,
    email: ?string,
    templatePreferences: ?any,
    accessLevel: ?string,
    department: ?string,
};

export type CreateDemoUser = {
    type: 'Users/CREATE_DEMO_USER',
    firstName: string,
    lastName: string,
    password: string,
    accessLevel: string,
    organizationId: number,
    role: string
};

export type DeleteUser = { type: 'Users/DELETE_USER', userId: number, };
export type DeleteUsers = { type: 'Users/DELETE_USERS', userIds: number[], };

export type UpdateUser = {
    type: 'Users/UPDATE_USER',
    userId: number,
    firstName: string,
    lastName: string,
    email: string,
    templatePreferences: any,
    accessLevel: string,
    role: string,
    department: string,
};

export type UpdateUserUIPermissions = {
    type: 'Users/UPDATE_USER_UI_PERMISSIONS',
    userId: number,
    permissions: UIPermissionsMap,
}

export type VerifyUserEmail = {
    type: 'Users/VERIFY_USER_EMAIL',
    userId: number,
    email: string
};

export type CancelEmailUpdate = {
    type: 'Users/CANCEL_EMAIL_UPDATE',
    userId: number
};

export type SearchUsers = {
    type: 'Users/SEARCH_USERS',
    searchTerm: string,
    searchHeaders: Array<string>,
};

export type ClearSearch = { type: 'Users/CLEAR_SEARCH' };

export type SetUserEdit = {
    type: 'Users/SET_USER_EDIT',
    userId: number,
};

export type UpdateUserEdit = {
    type: 'Users/UPDATE_USER_EDIT',
    user: UserProfile,
};

export type SetUserEditStatus = {
    type: 'Users/SET_USER_EDIT_STATUS',
    userEditChanged: boolean
};

export type ToggleUserActivitySidebar = {
    type: 'Users/TOGGLE_USER_ACTIVITY_SIDEBAR',
};

export type ToggleAddUserToOrganizationPanel = {
    type: 'Users/TOGGLE_ADD_USER_TO_ORG_PANEL',
    isAddUserToOrganizationPanelOpen: boolean,
};

export type ToggleCreateDemoUserPanel = {
    type: 'Users/TOGGLE_CREATE_DEMO_USER_PANEL',
    isCreateDemoUserPanelOpen: boolean,
};


export type ToggleUserDetailsPanel = {
    type: 'Users/TOGGLE_USER_DETAILS_PANEL',
    isUserDetailsPanelOpen: boolean,
};

export type ToggleClientPermissionsPanel = {
    type: 'Users/TOGGLE_CLIENT_PERMISSIONS_PANEL',
    isClientPermissionsPanelOpen: boolean,
};

export type SetAddUserOrgEdit = {
    type: 'Users/SET_ADD_USER_ORG_EDIT',
    user: UserProfile,
};

export type SetSelectedUserRow = {
    type: 'Users/SET_SELECTED_USER_ROW',
    row: any,
};

export type SetBulkEditUsers = {
    type: 'Users/SET_BULK_EDIT_USERS',
    users: UserProfile[],
};

export type AddBulkEditUsers = {
    type: 'Users/ADD_BULK_EDIT_USERS',
    users: UserProfile[],
};

export type RemoveBulkEditUsers = {
    type: 'Users/REMOVE_BULK_EDIT_USERS',
    users: UserProfile[],
};

export type CompleteRegisteringUser = {
    type: 'Users/COMPLETE_REGISTERING_USER',
    userId: number,
};

export type DeleteRegisteringUser = {
    type: 'Users/DELETE_REGISTERING_USER',
    userId: number,
};

export type ResendRegistrationEmail = {
    type: 'Users/RESEND_VERIFICATION_EMAIL',
    email: string,
};

export type ResendInvitationEmail = {
    type: 'Users/RESEND_INVITATION_EMAIL',
    email: string,
};

export type DeleteRegisteringUsers = {
    type: 'Users/DELETE_REGISTERING_USERS',
    userIds: number[],
};

export type InviteUsers = {
    type: 'Users/INVITE_USERS',
    fileRead: any,
};

type Action =
    | Logout
    | LoadUserAvatars
    | ReceiveUserAvatars
    | LoadUsers
    | LoadTeams
    | ReceiveTeams
    | LoadAllUsers
    | LoadedAllUsers
    | LoadPlatformUsers
    | LoadedUsersOfType
    | SetSortParameters
    | LoadPendingMembers
    | LoadRemovedMembers
    | LoadOrganizationMembers
    | LoadRegisteringUsers
    | LoadInvitedUsers
    | LoadTemplateOptions
    | LoadedTemplateOptions
    | ApprovePendingMember
    | ApprovePendingMembers
    | DenyPendingMember
    | AddOrganizationMember
    | RemoveOrganizationMember
    | RemoveOrganizationMembers
    | UpdateOrganizationMember
    | AddAndUpdateOrganizationMember
    | CreateDemoUser
    | DeleteUser
    | DeleteUsers
    | UpdateUser
    | UpdateUserUIPermissions
    | VerifyUserEmail
    | CancelEmailUpdate
    | ResendRegistrationEmail
    | ResendInvitationEmail
    | SearchUsers
    | ClearSearch
    | SetUserEdit
    | UpdateUserEdit
    | SetUserEditStatus
    | ToggleUserActivitySidebar
    | SetSelectedUserRow
    | SetBulkEditUsers
    | AddBulkEditUsers
    | RemoveBulkEditUsers
    | ToggleUserDetailsPanel
    | ToggleAddUserToOrganizationPanel
    | ToggleCreateDemoUserPanel
    | ToggleClientPermissionsPanel
    | SetAddUserOrgEdit
    | CompleteRegisteringUser
    | DeleteRegisteringUser
    | DeleteRegisteringUsers
    | InviteUsers;

export const loadUserAvatars = (users: Array<UserProfileWithInvite | UserProfile | UserProfile>): LoadUserAvatars => ({
    type: 'Users/LOAD_USER_AVATARS',
    users,
});

export const receiveUserAvatars = (avatars: AvatarMap): ReceiveUserAvatars => ({
    type: 'Users/RECEIVE_USER_AVATARS',
    avatars,
});

export const loadUsers = (userType?: AnyUserType, organizationId?: number): LoadUsers => ({
    type: 'Users/LOAD',
    userType,
    organizationId,
});

export const loadTeams = (): LoadTeams => ({ type: 'Users/LOAD_TEAMS', });

export const receiveTeams = (teams: Array<Team>): ReceiveTeams => ({
    type: 'Users/RECEIVE_TEAMS',
    teams,
});

export const loadAllUsers = (): LoadAllUsers => ({ type: 'Users/LOAD_ALL_USERS', });

export const loadedAllUsers = (users: AllPlatformUsers): LoadedAllUsers => ({
    type: 'Users/LOADED_ALL_USERS',
    users,
});

export const loadRegisteringUsers = (): LoadRegisteringUsers => ({ type: 'Users/LOAD_REGISTERING_USERS', });

export const loadInvitedUsers = (
    organizationId?: number
): LoadInvitedUsers => ({ type: 'Users/LOAD_INVITED_USERS', organizationId, });

export const loadPlatformUsers = (userType: PlatformUserType, organizationId?: number): LoadPlatformUsers => ({
    type: 'Users/LOAD_PLATFORM_USERS',
    userType,
    organizationId,
});

export const loadedUsersOfType = (
    userType: AnyUserType,
    users: AnyUserCollection
): LoadedUsersOfType => ({
    type: 'Users/LOADED_USERS_OF_TYPE',
    userType,
    users,
});

export const setSortParameters = (
    sortHeader: string,
    sortDirection: string
): SetSortParameters => ({
    type: 'Users/SET_SORT_PARAMETERS',
    sortHeader,
    sortDirection,
});

export const loadPendingMembers = (organizationId: number): LoadPendingMembers => ({
    type: 'Users/LOAD_PENDING_MEMBERS',
    organizationId,
});

export const loadRemovedMembers = (organizationId: number): LoadRemovedMembers => ({
    type: 'Users/LOAD_REMOVED_MEMBERS',
    organizationId,
});

export const loadOrganizationMembers = (organizationId: number): LoadOrganizationMembers => ({
    type: 'Users/LOAD_ORGANIZATION_MEMBERS',
    organizationId,
});

export const loadedTemplateOptions = (
    templateOptions: any
): LoadedTemplateOptions => ({
    type: 'Users/LOADED_TEMPLATE_OPTIONS',
    templateOptions,
});

export const approvePendingMember = (organizationId: number, userId: number): ApprovePendingMember => ({
    type: 'Users/APPROVE_PENDING_MEMBER',
    organizationId,
    userId,
});

export const approvePendingMembers = (approvals: { organizationId: number, userId: number, approve: boolean }[]): ApprovePendingMembers => ({
    type: 'Users/APPROVE_PENDING_MEMBERS',
    approvals,
});

export const denyPendingMember = (organizationId: number, userId: number): DenyPendingMember => ({
    type: 'Users/DENY_PENDING_MEMBER',
    organizationId,
    userId,
});

export const addOrganizationMember = (
    organizationId: number,
    userId: number,
    accessLevel: string,
    role: ?string,
    department: ?string
): AddOrganizationMember => ({
    type: 'Users/ADD_ORGANIZATION_MEMBER',
    organizationId,
    userId,
    accessLevel,
    role,
    department,
});

export const removeOrganizationMember = (organizationId: number, userId: number): RemoveOrganizationMember => ({
    type: 'Users/REMOVE_ORGANIZATION_MEMBER',
    organizationId,
    userId,
});

export const removeOrganizationMembers = (users: UserProfile[]): RemoveOrganizationMembers => ({
    type: 'Users/REMOVE_ORGANIZATION_MEMBERS',
    users,
});

export const updateOrganizationMember = (
    organizationId: number,
    userId: number,
    role: ?string,
    firstName: ?string,
    lastName: ?string,
    email: ?string,
    templatePreferences: ?any,
    accessLevel: ?string,
    department: ?string
): UpdateOrganizationMember => ({
    type: 'Users/UPDATE_ORGANIZATION_MEMBER',
    organizationId,
    userId,
    role,
    firstName,
    lastName,
    email,
    templatePreferences,
    accessLevel,
    department,
});

export const addAndUpdateOrganizationMember = (
    organizationId: number,
    userId: number,
    role: ?string,
    firstName: ?string,
    lastName: ?string,
    email: ?string,
    templatePreferences: ?any,
    accessLevel: ?string,
    department: ?string
): AddAndUpdateOrganizationMember => ({
    type: 'Users/ADD_AND_UPDATE_ORGANIZATION_MEMBER',
    organizationId,
    userId,
    role,
    firstName,
    lastName,
    email,
    templatePreferences,
    accessLevel,
    department,
});

export const createDemoUser = (
    firstName: string,
    lastName: string,
    password: string,
    accessLevel: string,
    organizationId: number,
    role: string
): CreateDemoUser => ({
    type: 'Users/CREATE_DEMO_USER',
    firstName,
    lastName,
    password,
    accessLevel,
    organizationId,
    role,
});

export const deleteUser = (userId: number): DeleteUser => ({
    type: 'Users/DELETE_USER',
    userId,
});

export const deleteUsers = (userIds: number[]): DeleteUsers => ({
    type: 'Users/DELETE_USERS',
    userIds,
});

export const updateUser = (
    userId: number,
    firstName: string,
    lastName: string,
    email: string,
    templatePreferences: any,
    accessLevel: string,
    role: string,
    department: string
): UpdateUser => ({
    type: 'Users/UPDATE_USER',
    userId,
    firstName,
    lastName,
    email,
    templatePreferences,
    accessLevel,
    role,
    department,
});

export const updateUserUIPermissions = (userId: number, permissions: UIPermissionsMap) => ({
    type: 'Users/UPDATE_USER_UI_PERMISSIONS',
    userId,
    permissions,
});

export const verifyUserEmail = (userId: number, email: string): VerifyUserEmail => ({
    type: 'Users/VERIFY_USER_EMAIL',
    userId,
    email,
});

export const cancelEmailUpdate = (userId: number): CancelEmailUpdate => ({
    type: 'Users/CANCEL_EMAIL_UPDATE',
    userId,
});

export const resendRegistrationEmail = (email: string): ResendRegistrationEmail => ({
    type: 'Users/RESEND_VERIFICATION_EMAIL',
    email,
});

export const resendInvitationEmail = (email: string): ResendInvitationEmail => ({
    type: 'Users/RESEND_INVITATION_EMAIL',
    email,
});

export const searchUsers = (searchTerm: string, searchHeaders: Array<string>): SearchUsers => ({
    type: 'Users/SEARCH_USERS',
    searchTerm,
    searchHeaders,
});

export const clearSearch = (): ClearSearch => ({
    type: 'Users/CLEAR_SEARCH',
});

export const setUserEdit = (userId: number): SetUserEdit => ({
    type: 'Users/SET_USER_EDIT',
    userId,
});

export const updateUserEdit = (user: UserProfile): UpdateUserEdit => ({
    type: 'Users/UPDATE_USER_EDIT',
    user,
});

export const setUserEditStatus = (userEditChanged: boolean): SetUserEditStatus => ({
    type: 'Users/SET_USER_EDIT_STATUS',
    userEditChanged,
});

export const toggleUserActivitySidebar = (): ToggleUserActivitySidebar => ({
    type: 'Users/TOGGLE_USER_ACTIVITY_SIDEBAR',
});

export const toggleCreateDemoUserPanel = (isCreateDemoUserPanelOpen: boolean): ToggleCreateDemoUserPanel => ({
    type: 'Users/TOGGLE_CREATE_DEMO_USER_PANEL',
    isCreateDemoUserPanelOpen,
});

export const toggleAddUserToOrganizationPanel = (isAddUserToOrganizationPanelOpen: boolean): ToggleAddUserToOrganizationPanel => ({
    type: 'Users/TOGGLE_ADD_USER_TO_ORG_PANEL',
    isAddUserToOrganizationPanelOpen,
});

export const toggleUserDetailsPanel = (isUserDetailsPanelOpen: boolean): ToggleUserDetailsPanel => ({
    type: 'Users/TOGGLE_USER_DETAILS_PANEL',
    isUserDetailsPanelOpen,
});

export const toggleClientPermissionsPanel = (isClientPermissionsPanelOpen: boolean): ToggleClientPermissionsPanel => ({
    type: 'Users/TOGGLE_CLIENT_PERMISSIONS_PANEL',
    isClientPermissionsPanelOpen,
});

export const setAddUserOrgEdit = (user: UserProfile): SetAddUserOrgEdit => ({
    type: 'Users/SET_ADD_USER_ORG_EDIT',
    user,
});

export const setSelectedUserRow = (row: any): SetSelectedUserRow => ({
    type: 'Users/SET_SELECTED_USER_ROW',
    row,
});

export const setBulkEditUsers = (users: UserProfile[]): SetBulkEditUsers => ({
    type: 'Users/SET_BULK_EDIT_USERS',
    users,
});

export const addBulkEditUsers = (users: UserProfile[]): AddBulkEditUsers => ({
    type: 'Users/ADD_BULK_EDIT_USERS',
    users,
});

export const removeBulkEditUsers = (users: UserProfile[]): RemoveBulkEditUsers => ({
    type: 'Users/REMOVE_BULK_EDIT_USERS',
    users,
});

export const completeRegisteringUser = (userId: number): CompleteRegisteringUser => ({
    type: 'Users/COMPLETE_REGISTERING_USER',
    userId,
});

export const deleteRegisteringUser = (userId: number): DeleteRegisteringUser => ({
    type: 'Users/DELETE_REGISTERING_USER',
    userId,
});

export const deleteRegisteringUsers = (userIds: number[]): DeleteRegisteringUsers => ({
    type: 'Users/DELETE_REGISTERING_USERS',
    userIds,
});

export const inviteUsers = (fileRead: any): InviteUsers => ({
    type: 'Users/INVITE_USERS',
    fileRead,
});

const reducer = (state: UserState = initialState, action: Action): UserState => {
    switch (action.type) {
        case 'Auth/LOGOUT': {
            return {
                ...initialState,
            };
        }

        case 'Users/RECEIVE_USER_AVATARS':
            return {
                ...state,

                avatars: action.avatars,
            };

        case 'Users/LOADED_ALL_USERS': {
            const {
                approved, archived, basic, demo, pending,
            } = action.users;
            const { sortDirection, sortHeader, } = state;

            // As noted in Users/SET_SORT_PARAMETERS case, should users always
            // be in a sorted state or should only users currently being displayed
            // in SortableTable be sorted at they time they're being displayed?
            return {
                ...state,
                loadedUsers: {
                    ...state.loadedUsers,
                    approved: sort(approved, sortDirection, sortHeader),
                    archived: sort(archived, sortDirection, sortHeader),
                    basic: sort(basic, sortDirection, sortHeader),
                    demo: sort(demo, sortDirection, sortHeader),
                    pending: sort(pending, sortDirection, sortHeader),
                },
            };
        }

        case 'Users/LOADED_USERS_OF_TYPE': {
            return {
                ...state,
                loadedUsers: {
                    ...state.loadedUsers,
                    // $FlowFixMe
                    [action.userType]: sort(action.users, state.sortDirection, state.sortHeader),
                },
            };
        }

        case 'Users/SET_SORT_PARAMETERS': {
            const { loadedUsers, } = state;
            const { sortHeader, sortDirection, } = action;

            return {
                ...state,

                sortHeader,
                sortDirection,

                // Why are all users always sorted? Perhaps better
                // to track sorting information in SortableTable and only sort
                // content there or is it important for all loaded users to always be sorted?
                loadedUsers: Object.keys(loadedUsers).reduce((sortedLoadedUsers, userType) => {
                    const users = loadedUsers[userType];

                    if (users) {
                        sortedLoadedUsers[userType] = sort(users, sortDirection, sortHeader);
                    }

                    return sortedLoadedUsers;
                }, {}),
            };
        }

        case 'Users/LOADED_TEMPLATE_OPTIONS': {
            return {
                ...state,

                templateOptions: action.templateOptions,
            };
        }

        case 'Users/SEARCH_USERS': {
            const { searchTerm, searchHeaders, } = action;
            const { loadedUsers, } = state;

            return {
                ...state,

                searchTerm,

                // Should all users be searched or should this be more local to a component
                // that is displaying the searched users?
                loadedUsers: Object.keys(loadedUsers).reduce((filteredUsers, userType) => {
                    const users = loadedUsers[userType];

                    if (users) {
                        filteredUsers[userType] = search(users, searchTerm, searchHeaders);
                    }

                    return filteredUsers;
                }, {}),
            };
        }

        case 'Users/CLEAR_SEARCH': {
            return {
                ...state,

                searchTerm: '',
            };
        }

        case 'Users/SET_USER_EDIT': {
            const editUserId = action.userId;
            const { loadedUsers, } = state;
            const users = [
                ...(loadedUsers.demo ?? []),
                ...(loadedUsers.basic ?? []),
                ...(loadedUsers.pending ?? []),
                ...(loadedUsers.approved ?? [])
            ];
            const userEdit = users.find((user) => user.userId === editUserId);

            return {
                ...state,
                userEdit,
                userEditChanged: false,
            };
        }

        case 'Users/UPDATE_USER_EDIT': {
            return {
                ...state,

                userEdit: action.user,
                userEditChanged: true,
            };
        }

        case 'Users/SET_USER_EDIT_STATUS': {
            return {
                ...state,

                userEditChanged: action.userEditChanged,
            };
        }

        case 'Users/TOGGLE_USER_ACTIVITY_SIDEBAR': {
            const { isUserActivitySidebarOpen, } = state;
            return {
                ...state,

                isUserActivitySidebarOpen: !isUserActivitySidebarOpen,
            };
        }

        case 'Users/TOGGLE_CREATE_DEMO_USER_PANEL': {
            return {
                ...state,

                isCreateDemoUserPanelOpen: action.isCreateDemoUserPanelOpen,
            };
        }

        case 'Users/TOGGLE_USER_DETAILS_PANEL': {
            return {
                ...state,

                isUserDetailsPanelOpen: action.isUserDetailsPanelOpen,
            };
        }

        case 'Users/TOGGLE_ADD_USER_TO_ORG_PANEL': {
            return {
                ...state,

                isAddUserToOrganizationPanelOpen: action.isAddUserToOrganizationPanelOpen,
            };
        }

        case 'Users/TOGGLE_CLIENT_PERMISSIONS_PANEL':
            return {
                ...state,

                isClientPermissionsPanelOpen: action.isClientPermissionsPanelOpen,
            };

        case 'Users/SET_ADD_USER_ORG_EDIT': {
            return {
                ...state,

                addUserOrgEdit: action.user,
            };
        }

        case 'Users/SET_SELECTED_USER_ROW': {
            return {
                ...state,

                selectedUser: action.row,
            };
        }

        case 'Users/SET_BULK_EDIT_USERS': {
            return {
                ...state,

                bulkEditUsers: action.users,
            };
        }

        case 'Users/ADD_BULK_EDIT_USERS': {
            const bulkEditUsersDictionary = objectArrayToDictionary('userId', state.bulkEditUsers);
            const usersToAdd = action.users.filter((user) => !hasOwnProperty(bulkEditUsersDictionary, user.userId));

            return {
                ...state,

                bulkEditUsers: [...state.bulkEditUsers, ...usersToAdd],
            };
        }

        case 'Users/REMOVE_BULK_EDIT_USERS': {
            const removeUsersDictionary = objectArrayToDictionary('userId', action.users);
            const bulkEditUsersToKeep = state.bulkEditUsers.filter((user) => !hasOwnProperty(removeUsersDictionary, user.userId));

            return {
                ...state,

                bulkEditUsers: [...bulkEditUsersToKeep],
            };
        }

        case 'Users/RECEIVE_TEAMS': {
            const { teams, } = action;

            return {
                ...state,
                teams,
            };
        }

        default:
            return state;
    }
};

export default reducer;
