// @flow
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Input from 'react-toolbox/lib/input/Input';
import Checkbox from 'react-toolbox/lib/checkbox';
import debounce from 'lodash.debounce';
import { selectProfileName } from 'txp-core';
import CasePreferencesStyles from './Styles/CasePreferencesStyles';
import type { FollowerPreference, MainTask, Team } from '../Utils/types';

type Props = {
  mainTasks: MainTask[],
  follower: FollowerPreference,
  teams: Team[],
  onFollowerChange: (follower: FollowerPreference) => *,
};

export default function CasePreferencesFollower(props: Props) {
    const {
        mainTasks,
        follower,
        teams,
        onFollowerChange,
    } = props;

    const [followerName, setFollowerName] = useState<string>('');

    useEffect(() => {
        setFollowerName(follower.name);
    }, []);

    const onFollowerNameChange = useCallback((val: string) => {
        const newFollower = { ...follower, name: val, };
        onFollowerChange(newFollower);
    }, [follower]);

    const debouncedFollowerNameChange = useMemo(() => debounce(onFollowerNameChange, 350), [onFollowerNameChange]);

    const onFollowerCanEditChange = () => {
        const newFollower = { ...follower, canEdit: !follower.canEdit, };
        onFollowerChange(newFollower);
    };

    const onFollowerTaskUpdate = (task: MainTask) => {
        const newFollowerTasks = [...follower.tasks];
        if (follower.tasks.includes(task.id)) {
            const taskIdx = follower.tasks.findIndex((item) => item === task.id);
            newFollowerTasks.splice(taskIdx, 1);
        } else {
            newFollowerTasks.push(task.id);
        }
        const newFollower = { ...follower, tasks: newFollowerTasks, };
        onFollowerChange(newFollower);
    };

    const onFollowerTeamUpdate = (team: Team) => {
        const newFollowerTeams = [...follower.teams];
        if (follower.teams.includes(team.teamId)) {
            const teamIdx = follower.teams.findIndex((item) => item === team.teamId);
            newFollowerTeams.splice(teamIdx, 1);
        } else {
            newFollowerTeams.push(team.teamId);
        }
        const newFollower = { ...follower, teams: newFollowerTeams, };
        onFollowerChange(newFollower);
    };

    return (
        <div style={CasePreferencesStyles.preferencesWrapper}>
            <Input
                type="text"
                label="Follower Name"
                value={followerName}
                onChange={(val) => {
                    setFollowerName(val);
                    debouncedFollowerNameChange(val);
                }}
            />
            <Checkbox
                label="Can Edit"
                checked={follower.canEdit}
                onChange={() => onFollowerCanEditChange()}
            />
            <h4>Tasks</h4>
            <div>
                {mainTasks.map((task: MainTask) => (
                    <Checkbox
                        key={task.id}
                        label={task.name}
                        checked={follower.tasks.includes(task.id)}
                        onChange={() => onFollowerTaskUpdate(task)}
                    />
                ))}
            </div>
            <h4>Teams</h4>
            <div>
                {teams.map((team: Team) => (
                    <div key={team.teamId}>
                        <Checkbox
                            label={team.teamName}
                            checked={follower.teams.includes(team.teamId)}
                            onChange={() => onFollowerTeamUpdate(team)}
                        />
                        <p>{(team.teamMembers.map((member) => selectProfileName(member))).join(', ')}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
