// @flow
import type {
    DonorTaskMap,
    TaskMap,
    DonorMap,
    Form,
    TimelineItem,
    UserProfile,
    Donor,
} from '../Utils/types';

type DonorState = {
    selectedDonorId: number,
    donors: DonorMap,
    tasks: TaskMap,
};

const initialState: DonorState = {
    donors: {},
    selectedDonorId: 0,
    tasks: {},
};

export type LoadDonors = { type: 'Donors/LOAD' };
export type LoadedDonors = { type: 'Donors/LOADED', donors: DonorMap };

export type LoadDonor = { type: 'Donors/LOAD_DONOR', donorId: number };
export type LoadedDonor = { type: 'Donors/LOADED_DONOR', donor: Donor };

export type LoadDonorTasks = { type: 'Donors/LOAD_DONOR_TASKS', donorId: number };
export type LoadedDonorTasks = { type: 'Donors/LOADED_DONOR_TASKS', donorId: number, donorTasks: DonorTaskMap };

export type LoadTasks = { type: 'Donors/LOAD_TASKS' };
export type LoadedTasks = { type: 'Donors/LOADED_TASKS', tasks: TaskMap };

export type LoadDonorTimeline = { type: 'Donors/LOAD_DONOR_TIMELINE', donorId: number };
export type LoadedDonorTimeline = { type: 'Donors/LOADED_DONOR_TIMELINE', donorId: number, timeline: Array<TimelineItem> };

export type SetSelectedDonor = { type: 'Donors/SET_SELECTED_DONOR', donorId: number };
export type ClearSelectedDonor = { type: 'Donors/CLEAR_SELECTED_DONOR' };

export type GenerateDonorPDF = {
    type: 'Donors/GENERATE_PDF',
    donor: Donor,
    workflowTasks: TaskMap,
    forms: Array<Form>,
    profile: UserProfile,
    organizationMembers: Array<UserProfile>,
};

export type LoadDonorNetPDFFields = { type: 'Donors/LOAD_DONORNET_PDFFIELDS'};
export type LoadedDonorNetPDFFields = { type: 'Donors/LOADED_DONORNET_PDFFIELDS', fields: any };

export type UpdateCaseStatus = { type: 'Donors/UPDATE_CASE_STATUS', caseId: number, closed: boolean, lastModified: string };
export type UpdateCaseSuccess = { type: 'Donors/UPDATE_CASE_SUCCESS', caseId: number, workflowCase: Donor };

type Action =
    | LoadDonors
    | LoadedDonors
    | LoadDonor
    | LoadedDonor
    | LoadDonorTasks
    | LoadedDonorTasks
    | LoadDonorTimeline
    | LoadedDonorTimeline
    | SetSelectedDonor
    | ClearSelectedDonor
    | GenerateDonorPDF
    | LoadTasks
    | LoadedTasks
    | LoadDonorNetPDFFields
    | LoadedDonorNetPDFFields
    | UpdateCaseStatus
    | UpdateCaseSuccess;

export const loadDonors = (): LoadDonors => ({
    type: 'Donors/LOAD',
});

export const loadedDonors = (donors: DonorMap): LoadedDonors => ({
    type: 'Donors/LOADED',
    donors,
});

export const loadDonor = (donorId: number): LoadDonor => ({
    type: 'Donors/LOAD_DONOR',
    donorId,
});

export const loadedDonor = (donor: Donor): LoadedDonor => ({
    type: 'Donors/LOADED_DONOR',
    donor,
});

export const loadDonorTasks = (donorId: number): LoadDonorTasks => ({
    type: 'Donors/LOAD_DONOR_TASKS',
    donorId,
});

export const loadedDonorTasks = (donorId: number, donorTasks: DonorTaskMap): LoadedDonorTasks => ({
    type: 'Donors/LOADED_DONOR_TASKS',
    donorId,
    donorTasks,
});


export const loadTasks = (): LoadTasks => ({
    type: 'Donors/LOAD_TASKS',
});

export const loadedTasks = (tasks: TaskMap): LoadedTasks => ({
    type: 'Donors/LOADED_TASKS',
    tasks,
});

export const loadDonorNetPDFFields = () => ({
    type: 'Donors/LOAD_DONORNET_PDFFIELDS',
});

export const loadedDonorNetPDFFields = (fields: any) => ({
    type: 'Donors/LOADED_DONORNET_PDFFIELDS',
    fields,
});

export const loadDonorTimeline = (donorId: number): LoadDonorTimeline => ({
    type: 'Donors/LOAD_DONOR_TIMELINE',
    donorId,
});

export const loadedDonorTimeline = (donorId: number, timeline: Array<TimelineItem>): LoadedDonorTimeline => ({
    type: 'Donors/LOADED_DONOR_TIMELINE',
    donorId,
    timeline,
});

export const setSelectedDonor = (donorId: number): SetSelectedDonor => ({
    type: 'Donors/SET_SELECTED_DONOR',
    donorId,
});

export const clearSelectedDonor = (): ClearSelectedDonor => ({
    type: 'Donors/CLEAR_SELECTED_DONOR',
});

export const generateDonorPDF = (
    donor: Donor,
    workflowTasks: TaskMap,
    forms: Array<Form>,
    profile: UserProfile,
    organizationMembers: Array<UserProfile>
): GenerateDonorPDF => ({
    type: 'Donors/GENERATE_PDF',
    donor,
    workflowTasks,
    forms,
    profile,
    organizationMembers,
});

export const updateCaseStatus = (caseId: number, closed: boolean, lastModified: string): UpdateCaseStatus => ({
    type: 'Donors/UPDATE_CASE_STATUS',
    caseId,
    closed,
    lastModified,
});

export const updateCaseSuccess = (caseId: number, workflowCase: Donor): UpdateCaseSuccess => ({
    type: 'Donors/UPDATE_CASE_SUCCESS',
    caseId,
    workflowCase,
});

const reducer = (state: DonorState = initialState, action: Action): DonorState => {
    switch (action.type) {
        case 'Donors/LOADED': {
            return {
                ...state,
                donors: action.donors,
            };
        }

        case 'Donors/LOADED_DONOR': {
            return {
                ...state,
                donors: {
                    ...state.donors,
                    [action.donor.donorId]: action.donor,
                },
            };
        }

        case 'Donors/LOADED_DONOR_TASKS':
            return {
                ...state,
                donors: {
                    ...state.donors,
                    [action.donorId]: {
                        ...state.donors[action.donorId],
                        tasks: action.donorTasks,
                    },
                },
            };

        case 'Donors/LOADED_DONOR_TIMELINE':
            return {
                ...state,
                donors: {
                    ...state.donors,
                    [action.donorId]: {
                        ...state.donors[action.donorId],
                        timeline: action.timeline,
                    },
                },
            };

        case 'Donors/SET_SELECTED_DONOR':
            return {
                ...state,

                selectedDonorId: action.donorId,
            };

        case 'Donors/CLEAR_SELECTED_DONOR':
            return {
                ...state,

                selectedDonorId: 0,
            };

        case 'Donors/LOADED_TASKS':
            return {
                ...state,

                tasks: action.tasks,
            };
        case 'Donors/LOADED_DONORNET_PDFFIELDS':
            return {
                ...state,

                fields: action.fields,
            };

        case 'Donors/UPDATE_CASE_SUCCESS':
            return {
                ...state,

                donors: {
                    ...state.donors,

                    [action.caseId]: {
                        ...state.donors[action.caseId],

                        ...action.workflowCase,
                    },
                },
            };

        default:
            return state;
    }
};

export default reducer;

