// @flow
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import Button from 'react-toolbox/lib/button/Button';
import List from 'react-toolbox/lib/list/List';
import ListItem from 'react-toolbox/lib/list/ListItem';
import MenuDivider from 'react-toolbox/lib/menu/MenuDivider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    updateInactive,
    downloadReferenceData,
    updateWorkflowDetails,
} from '../Redux/ReferenceDataActions';
import ApplicationStyles from '../Themes/ApplicationStyles';
import PanelStyles from './Styles/PanelStyles';
import { workflowToBuilderJSON } from '../Utils/referenceData';
import { jsonObjectToBlob, downloadBlobAsAttachment } from '../Utils/downloadFile';

type Props = {
    allReferenceData: any,
    selectedReferenceData: any,
    onClose: () => *,
    onUpdateReferenceData: (data: any) => *,
}

export default function ReferenceDetailsPanel(props: Props) {
    const dispatch = useDispatch();
    const { selectedReferenceData, allReferenceData, } = props;
    const organizations = useSelector((state) => state.organization.organizations);

    const selectedOrganization = organizations.find((org) => org.organizationId === selectedReferenceData.ownerId);
    const selectedOwnerName = selectedReferenceData && selectedReferenceData.ownerId && selectedOrganization
        ? selectedOrganization.organizationName : 'Organization Not Found';

    const loadingUploadReferenceData = useSelector((state) => state.loading.uploadReferenceData);
    const loadingUpdateInactive = useSelector((state) => state.loading.updateInactive);
    const loadingDownloadReference = useSelector((state) => state.loading.downloadReference);
    const loadingUpdateWorkflowDetails = useSelector((state) => state.loading.updateWorkflowDetails);

    const origSelectedOwnerId = selectedReferenceData && selectedReferenceData.ownerId ? selectedReferenceData.ownerId : -1;
    const [ownerName, setOwnerName] = useState(selectedOwnerName);
    const [ownerId, setOwnerId] = useState(origSelectedOwnerId);
    const [detailsUpdated, setDetailsUpdated] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    useEffect(() => {
        setOwnerName(selectedOwnerName);
    }, [selectedOwnerName]);

    useEffect(() => {
        if (loadingUploadReferenceData
            || loadingUpdateInactive
            || loadingDownloadReference
            || loadingUpdateWorkflowDetails
        ) {
            setDisableButtons(true);
        } else {
            setDisableButtons(false);
        }
    }, [loadingUploadReferenceData, loadingUpdateInactive, loadingDownloadReference, loadingUpdateWorkflowDetails]);

    const organizationSearch = organizations.map((org) => org.organizationName);

    const updateReferenceDataOwner = (value: string) => {
        const newOwner = organizations.find((org) => org.organizationName === value);
        if (newOwner) {
            const newOwnerId = newOwner.organizationId || -1;
            if (newOwnerId !== origSelectedOwnerId) {
                setOwnerName(value);
                setOwnerId(newOwnerId);
                setDetailsUpdated(true);
            } else {
                setDetailsUpdated(false);
            }
        }
    };

    const closeReferenceDetailsPanel = () => {
        props.onClose();
    };

    const onUpdateReferenceDataDetails = () => {
        const updatedReference = {
            ...selectedReferenceData,
            ownerId,
        };
        dispatch(updateWorkflowDetails(updatedReference));
        setDetailsUpdated(false);
    };

    const onUpdateInactive = (referenceData: any) => {
        if (referenceData) {
            dispatch(updateInactive(referenceData.domain, referenceData.key, !referenceData.inactive));
        }
    };

    const onDownload = (referenceData: any) => {
        if (referenceData) {
            dispatch(downloadReferenceData(referenceData.domain, referenceData.key));
        }
    };

    const onDownloadBuilderJSON = (referenceData: any) => {
        if (referenceData) {
            const workflowJSON = workflowToBuilderJSON(selectedOwnerName, referenceData, allReferenceData);

            downloadBlobAsAttachment(jsonObjectToBlob(workflowJSON), `${referenceData.name} - Workflow Builder.json`);
        }
    };

    const handleCloseAlertDialog = () => {
        setAlertDialogOpen(false);
    };

    const headerText = `${selectedReferenceData.domain}: ${selectedReferenceData.key}`;

    const details = selectedReferenceData ? (
        <>
            <div
                style={{
                    ...ApplicationStyles.detailsHeader,
                    paddingTop: 35,
                    paddingBottom: 35,
                }}
            >
                <div style={ApplicationStyles.textCenter}>
                    {headerText}
                </div>
            </div>
            <div style={PanelStyles.panelBodyContainer}>
                <div style={PanelStyles.inputPadding}>
                    <List>
                        {selectedReferenceData.name
                            ? (
                                <ListItem
                                    key={selectedReferenceData.name}
                                    caption={selectedReferenceData.name}
                                    legend="Name"
                                    ripple={false}
                                />
                            )
                            : null}
                        {selectedReferenceData.type
                            ? (
                                <ListItem
                                    key={selectedReferenceData.type}
                                    caption={selectedReferenceData.type}
                                    legend="Type"
                                    ripple={false}
                                />
                            )
                            : null}
                        {selectedReferenceData.domain === 'WORKFLOW'
                            // TODO: might want to check for selectedReferenceData.ownerType === 'Organization',
                            // but not sure that's consistently set (I suppose I could force user to set it it isn't)
                            ? (
                                <div style={PanelStyles.editablePadding}>
                                    <Autocomplete
                                        disabled={!selectedOrganization}
                                        direction="down"
                                        selectedPosition="above"
                                        label="Owner"
                                        multiple={false}
                                        onChange={updateReferenceDataOwner}
                                        source={organizationSearch}
                                        value={ownerName}
                                    />
                                </div>
                            )
                            : null}
                        {!selectedOrganization && selectedReferenceData.domain === 'WORKFLOW'
                            ? (
                                <span style={{ ...PanelStyles.infoText, ...PanelStyles.editablePadding, }}>
                                    This workflow does not have an owner,
                                    or the organization no longer exists.
                                    Please upload a revised workflow with a valid owner ID.
                                </span>
                            ) : null}
                    </List>
                </div>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        disabled={disableButtons}
                        label="Cancel"
                        style={disableButtons ? ApplicationStyles.disabledButton : ApplicationStyles.cancelButton}
                        onClick={closeReferenceDetailsPanel}
                    />
                    {selectedReferenceData.domain === 'WORKFLOW'
                        // NOTE: right now this button is only for workflows
                        // because that's the only time anything can be updated (likely temporary)
                        ? (
                            <Button
                                label="Update"
                                style={!detailsUpdated ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                                onClick={onUpdateReferenceDataDetails}
                                disabled={!detailsUpdated || disableButtons}
                            />
                        )
                        : null}

                </div>
                <MenuDivider />
                <Button
                    disabled={disableButtons}
                    label="Update JSON"
                    style={disableButtons ? ApplicationStyles.disabledButton : PanelStyles.secondaryPanelButton}
                    onClick={() => props.onUpdateReferenceData(selectedReferenceData)}
                />
                <MenuDivider />
                <Button
                    disabled={disableButtons}
                    label={selectedReferenceData.inactive ? 'Activate' : 'Inactivate'}
                    style={disableButtons ? ApplicationStyles.disabledButton : PanelStyles.warningPanelButton}
                    onClick={() => onUpdateInactive(selectedReferenceData)}
                />
                <MenuDivider />
                <Button
                    disabled={disableButtons}
                    label="Download"
                    style={disableButtons ? ApplicationStyles.disabledButton : PanelStyles.primaryPanelButton}
                    onClick={() => onDownload(selectedReferenceData)}
                />
                {selectedReferenceData.domain === 'WORKFLOW' ? (
                    <>
                        <MenuDivider />
                        <Button
                            disabled={disableButtons}
                            label="Download Workflow Builder JSON"
                            style={disableButtons ? ApplicationStyles.disabledButton : PanelStyles.primaryPanelButton}
                            onClick={() => setAlertDialogOpen(true)}
                        />
                        <Dialog
                            open={alertDialogOpen}
                            onClose={handleCloseAlertDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Potential Workflow Feature Loss</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Editing this Workflow in the Workflow Builder may result in some loss of
                                    Workflow features that can be added manually but aren&apos;t yet available
                                    in the Workflow Builder.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAlertDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        onDownloadBuilderJSON(selectedReferenceData);
                                        handleCloseAlertDialog();
                                    }}
                                    color="primary"
                                    autoFocus
                                >
                                    Download
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : null}
            </div>
        </>
    ) : null;

    return details;
}
