// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Table from 'react-toolbox/lib/table/Table';
import TableHead from 'react-toolbox/lib/table/TableHead';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import Button from 'react-toolbox/lib/button/Button';
import { Card, CardTitle } from 'react-toolbox/lib/card';

import {
    closeOrganizationWorkflowsPanel as _closeOrganizationWorkflowsPanel,
} from '../Redux/OrganizationActions';
import type { Organization, Workflow } from '../Utils/types';
import PanelStyles from './Styles/PanelStyles';

type Props = {
    workflows: Array<Workflow>,
    organizationEdit: Organization,
    closeOrganizationWorkflowsPanel: () => *,
};

class OrganizationDetailsWorkflowsPanel extends PureComponent<Props> {
    onCloseClearFields = () => {
        const {
            closeOrganizationWorkflowsPanel,
        } = this.props;
        closeOrganizationWorkflowsPanel();
    }

    filterWorkflows = (workflows, organizationId) => workflows.filter((workflow) => workflow.ownerId === organizationId);

    render() {
        const {
            organizationEdit,
            workflows,
        } = this.props;

        const organizationWorkflows = this.filterWorkflows(workflows, organizationEdit.organizationId);
        const orgSubtitleText = `${organizationWorkflows.length} ${organizationWorkflows.length === 1 ? 'Workflow' : 'Workflows'}`;

        return (
            <div>
                <Card>
                    <CardTitle
                        title={organizationEdit.organizationName}
                        subtitle={orgSubtitleText}
                    />
                </Card>
                <Button style={PanelStyles.closeButton} onClick={this.onCloseClearFields} raised primary>Back</Button>
                {
                    organizationWorkflows.length === 0 ? (
                        <div style={PanelStyles.noWorkflows}>No Organization Workflows</div>
                    ) : (
                        <Table style={PanelStyles.table} selectable={false}>
                            <TableHead>
                                <TableCell>Name</TableCell>
                                <TableCell>Key</TableCell>
                                <TableCell>Status</TableCell>
                            </TableHead>
                            {organizationWorkflows.map((workflow) => (
                                <TableRow
                                    key={`workflow-row-${workflow.key}`}
                                    theme={PanelStyles.tableRow}
                                >
                                    <TableCell>{workflow.name}</TableCell>
                                    <TableCell>{workflow.key}</TableCell>
                                    <TableCell>{workflow.inactive ? 'Inactive' : 'Active'}</TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    workflows: state.reference.workflows,
    organizationEdit: state.organization.organizationEdit,
});
export default connect(mapStateToProps, {
    closeOrganizationWorkflowsPanel: _closeOrganizationWorkflowsPanel,
})(OrganizationDetailsWorkflowsPanel);
