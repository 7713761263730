// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-toolbox/lib/card';

import {
    loadDonors as _loadDonors,
    loadTasks as _loadTasks,
} from '../Redux/DonorActions';
import {
    getReferenceData as _getReferenceData,
} from '../Redux/ReferenceDataActions';
import MessagesHomeStyles from './Styles/MessagesHomeStyles';
import DonorList from '../Components/DonorList';
import SelectedDonorPane from '../Components/SelectedDonorPane';


type Props = {
    getReferenceData: () => *,
    loadDonors: () => *,
    loadTasks: () => *,
}

class DonorsHomePage extends PureComponent<Props> {
    componentDidMount() {
        const {
            loadDonors,
            loadTasks,
            getReferenceData,
        } = this.props;

        loadDonors();
        loadTasks();
        getReferenceData();
    }

    render() {
        return (
            <div style={MessagesHomeStyles.pageWrapper}>
                <div style={MessagesHomeStyles.columnsWrapper}>
                    <div style={{ ...MessagesHomeStyles.column, flex: 1, }}>
                        <Card style={{ ...MessagesHomeStyles.card, flex: 1, }}>
                            <div style={MessagesHomeStyles.cardTitle}>
                                Cases
                            </div>
                            <DonorList />
                        </Card>
                    </div>
                    <SelectedDonorPane />
                </div>
            </div>
        );
    }
}

export default connect(null, {
    loadDonors: _loadDonors,
    loadTasks: _loadTasks,
    getReferenceData: _getReferenceData,
})(DonorsHomePage);

