// @flow
import React, { Component } from 'react';
import type { ElementRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Input from 'react-toolbox/lib/input';
import Button from 'react-toolbox/lib/button/Button';
import { login, setEnvContext as _setEnvContext } from 'txp-core';
import type { Login, LoginError, EnvContext } from 'txp-core';

import Images from '../Themes/Images';
import AuthStyles from './Styles/AuthStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import { appConfiguration } from '../AppConfiguration';


type Props = {
    loginError: ?LoginError,
    loginRequestActive: boolean,
    isAuthenticated: boolean,

    setEnvContext: (envContext: EnvContext) => *,
    onSubmitLogin: (email: string, password: string, application: string, requiredLicense: string, requiredAccessLevel: string) => Login,
};

type State = {
    formValid: boolean,

    emailInput: string,
    passwordInput: string,
};

type RefNodes = {
    emailInput: ?ElementRef<*>,
    passwordInput: ?ElementRef<*>,
};

class LoginPage extends Component<Props, State> {
    nodes: RefNodes = {
        emailInput: null,
        passwordInput: null,
    };

    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            formValid: false,

            emailInput: '',
            passwordInput: '',
        };
    }

    componentDidMount() {
        const {
            setEnvContext,
        } = this.props;
        setEnvContext({
            API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
            API_ROOT: process.env.REACT_APP_API_ROOT,
            API_KEY: process.env.REACT_APP_API_KEY,
            locale: '',
        });
    }

    onSubmitLogin = () => {
        const {
            loginRequestActive,
            onSubmitLogin,
        } = this.props;

        const {
            formValid,
            emailInput,
            passwordInput,
        } = this.state;

        if (!formValid || loginRequestActive) {
            return;
        }

        onSubmitLogin(emailInput, passwordInput, 'ADMIN_PORTAL', 'Premium', 'OrgManager');
    };

    onKey = (e: { which: number }) => {
        if (e.which === 13) {
            this.onSubmitLogin();
        }
    };

    setNodeValue(key: 'emailInput' | 'passwordInput', value: string) {
        this.setState((previousState) => {
            const email = key === 'emailInput' ? value : previousState.emailInput;
            const password = key === 'passwordInput' ? value : previousState.passwordInput;
            const newState = previousState;
            newState[key] = value;
            newState.formValid = email.length > 0 && password.length >= 8;
            return newState;
        });
    }

    setNodeRef(key: string, ref: ?ElementRef<*>) {
        this.nodes[key] = ref;
    }

    hasFieldError(fieldName: string, allowNonField: boolean): boolean {
        const {
            loginError,
        } = this.props;

        if (!loginError) {
            return false;
        }

        return !!loginError[fieldName] || !!(allowNonField && loginError.nonFieldErrors);
    }

    renderFieldError(fieldName: string, allowNonField: boolean) {
        const {
            loginError,
        } = this.props;

        if (!loginError) {
            return null;
        }

        let error = loginError[fieldName];

        if (!error && allowNonField) {
            error = loginError.nonFieldError;
        }

        if (!error) {
            return null;
        }

        return (
            <span
                style={AuthStyles.formError}
            >
                {error}
            </span>
        );
    }

    render() {
        const {
            loginRequestActive,
            isAuthenticated,
        } = this.props;

        const {
            formValid,
            emailInput,
            passwordInput,
        } = this.state;

        const errors = {
            email: this.hasFieldError('email', true),
            password: this.hasFieldError('password', false),
        };

        const submitDisabled = !formValid || loginRequestActive;

        const copyrightMessage = `${new Date().getFullYear()} OmniLife Health. All Rights Reserved. `;
        const licenseLinkText = 'License & Attribution.';
        const licenseLinkUrl = process.env.REACT_APP_LICENSE_ATTRIBUTION ?? '';
        const appVersionMessage = `OmniLife Admin Portal ${appConfiguration().version}`;

        if (!isAuthenticated) {
            return (
                <div>
                    <form style={ApplicationStyles.verticalCenter}>
                        <div>
                            <img height={120} width={400} src={Images.logoHeader} alt="OmniLife Health Logo" />
                        </div>
                        <h1 style={ApplicationStyles.Loginh1}>OmniLife Admin Portal</h1>
                        <span style={AuthStyles.formLabel}>Enter your email and password to log in.</span>
                        <Input
                            style={errors.email ? ApplicationStyles.textInputError : null}
                            type="text"
                            label="Your email"
                            name="emailInput"

                            ref={(n) => this.setNodeRef('emailInput', n)}
                            onChange={(text) => this.setNodeValue('emailInput', text)}

                            value={emailInput}
                        />
                        {this.renderFieldError('email', false)}
                        <Input
                            style={errors.password ? ApplicationStyles.textInputError : null}

                            type="password"
                            label="Your password"
                            name="passwordInput"

                            ref={(n) => this.setNodeRef('passwordInput', n)}
                            onChange={(text) => this.setNodeValue('passwordInput', text)}
                            onKeyPress={this.onKey}

                            value={passwordInput}
                        />
                        {this.renderFieldError('password', true)}
                        <div>
                            <Button
                                style={submitDisabled ? ApplicationStyles.buttonDisabled : ApplicationStyles.button}

                                type="button"
                                disabled={submitDisabled}
                                label="Log in"
                                flat

                                onClick={this.onSubmitLogin}
                            />
                        </div>
                        <div style={{ paddingTop: 25, }}>
                            <span style={AuthStyles.version}>
                                &copy;
                                {copyrightMessage}
                                <a style={AuthStyles.version} href={licenseLinkUrl}>{licenseLinkText}</a>
                            </span>
                        </div>
                        <div>
                            <span style={AuthStyles.version}>
                                {appVersionMessage}
                            </span>
                        </div>
                    </form>
                </div>
            );
        }
        return <Redirect to="/portal" />;
    }
}

const mapStateToProps = (state) => ({
    loginError: state.auth.loginError,
    loginRequestActive: state.loading.login,
    isAuthenticated: state.auth.authenticated,

});

export default connect(mapStateToProps, {
    onSubmitLogin: login,
    setEnvContext: _setEnvContext,
})(LoginPage);
