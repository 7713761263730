// @flow

import Colors from '../../Themes/Colors';

const MetricsGraphicsStyles = {

    submitButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    organizationSelector: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    dropdown: {
        width: '40%',
        alignSelf: 'center',
        paddingRight: 50,
    },
    quarterDropdownA: {
        width: '13%',
        paddingRight: 15,
        paddingLeft: 50,
        alignSelf: 'center',
    },
    quarterDropdownB: {
        width: '13%',
        paddingRight: 175,
        alignSelf: 'center',
    },
    versus: {
        fontSize: 10,
        paddingRight: 15,
        alignSelf: 'center',
    },
    submitButton: {
        backgroundColor: Colors.brandPrimary,
        color: Colors.white,
    },
    paddingLeft: {
        paddingLeft: 15,
    },
    rowPadding: {
        padding: 25,
    },
    legend: {
        display: 'inline-block',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
    legendContainer: {
        borderBottomStyle: 'solid',
        borderColor: Colors.bottomNavigationBorder,
        bottomBorderWidth: '2px',
        borderRightStyle: 'solid',
        rightBorderWidth: '2px',
        textAlign: 'left',
        float: 'left',
        paddingLeft: 10,
        paddingTop: 10,
        paddingRight: 10,
        marginBottom: 0,
        paddingBottom: 0,
        minWidth: 95,
    },
    organizationNameList: {
        textAlign: 'center',
        color: Colors.gray,
        fontSize: 12,
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    },
};

export default MetricsGraphicsStyles;
