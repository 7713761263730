// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Table from 'react-toolbox/lib/table/Table';
import TableHead from 'react-toolbox/lib/table/TableHead';
import TableRow from 'react-toolbox/lib/table/TableRow';
import TableCell from 'react-toolbox/lib/table/TableCell';
import Checkbox from 'react-toolbox/lib/checkbox';
import Button from 'react-toolbox/lib/button/Button';

import {
    updateOrganization as _updateOrganization,
    updateOrganizationEdit as _updateOrganizationEdit,
    setOrganizationEdit as _setOrganizationEdit,
    closeClientPermissionsPanel as _closeClientPermissionsPanel,
} from '../Redux/OrganizationActions';
import type { Organization, RemoteOrganizationPermission } from '../Utils/types';
import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import { UIPermissions } from '../Utils/types';

type Props = {
    organizationEdit: Organization,
    organizationEditChanged: boolean,
    clientPermissions: Array<RemoteOrganizationPermission>,
    closeClientPermissionsPanel: () => *,
    updateOrganization: (organizationId: number, organizationName: string, organizationCode: string,
        organizationType: string, clientPermissions: any, templatePreferences: ?any) => *,
    updateOrganizationEdit: (organization: Organization) => *,
    setOrganizationEdit: (organizationId: number) => *,
};

class OrganizationClientPermissionsPanel extends PureComponent<Props> {
    onSaveClientPermissions = () => {
        const {
            updateOrganization,
            closeClientPermissionsPanel,
            organizationEdit,
        } = this.props;

        updateOrganization(organizationEdit.organizationId, organizationEdit.organizationName,
            organizationEdit.organizationCode, organizationEdit.organizationType,
            organizationEdit.clientPermissions, organizationEdit.templatePreferences);

        closeClientPermissionsPanel();
    };

    onCloseClearFields = () => {
        const {
            closeClientPermissionsPanel,
            setOrganizationEdit,
            organizationEdit,
        } = this.props;

        setOrganizationEdit(organizationEdit.organizationId);
        closeClientPermissionsPanel();
    };

    /**
     * Method to call when checkboxes change value.
     */
    onChangeBooleanValuePermissions = (value: string, id: number) => {
        const {
            updateOrganizationEdit,
            organizationEdit,
        } = this.props;

        let updatedPermissions = organizationEdit.clientPermissions;

        if (value) {
            updatedPermissions.push(id);
        } else {
            updatedPermissions = updatedPermissions.filter((element) => element !== id);
        }

        updateOrganizationEdit({ ...organizationEdit, clientPermissions: updatedPermissions, });
    };

    render() {
        const {
            organizationEdit,
            organizationEditChanged,
        } = this.props;

        const keys = Object.keys(UIPermissions);

        return (
            <div>
                <Table
                    id="table"
                    selectable={false}
                >
                    <TableHead>
                        <TableCell>
                            Permission
                        </TableCell>
                        <TableCell>
                            Value
                        </TableCell>
                    </TableHead>
                    {
                        keys.map((key) => (
                            <TableRow
                                key={key}
                            >
                                <TableCell>
                                    {UIPermissions[key].name}
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        style={{ marginTop: 8, }}
                                        key={UIPermissions[key].id}
                                        checked={organizationEdit.clientPermissions.includes(UIPermissions[key].id)}
                                        onChange={(val) => this.onChangeBooleanValuePermissions(val, UIPermissions[key].id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </Table>
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        style={ApplicationStyles.cancelButton}
                        label="Cancel"
                        onClick={this.onCloseClearFields}
                    />
                    <Button
                        style={!organizationEditChanged ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                        label="Update"
                        onClick={this.onSaveClientPermissions}
                        disabled={!organizationEditChanged}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    organizationEdit: state.organization.organizationEdit,
    clientPermissions: state.organization.clientPermissions,
    organizationEditChanged: state.organization.organizationEditChanged,
});
export default connect(mapStateToProps, {
    updateOrganizationEdit: _updateOrganizationEdit,
    updateOrganization: _updateOrganization,
    setOrganizationEdit: _setOrganizationEdit,
    closeClientPermissionsPanel: _closeClientPermissionsPanel,
})(OrganizationClientPermissionsPanel);
