// @flow

export function getProlificNames(metrics: any, pendingMembers: any, organizationMembers: any) {
    // get a total list of members with ids and names
    const nameMap = pendingMembers.map((a) => ({
        id: a.userId, firstName: a.firstName, lastName: a.lastName, email: a.email,
    }))
        .concat(organizationMembers.map((a) => ({
            id: a.userId, firstName: a.firstName, lastName: a.lastName, email: a.email,
        })));

    // metric counts with ids but no names
    const prolificReaders = (metrics && metrics.user_read_counts) ? metrics.user_read_counts : [];
    const prolificSenders = (metrics && metrics.user_sent_counts) ? metrics.user_sent_counts : [];

    // containers to store ids with names
    const collatedProlificReaders = [];
    const collatedProlificSenders = [];

    for (let i = 0; i < prolificReaders.length; i += 1) {
        const curId = prolificReaders[i][0];
        const curCount = prolificReaders[i][1];
        const curProfile = nameMap.find((user) => user.id === curId);
        if (curProfile) {
            const user = (curProfile.firstName && curProfile.lastName) ? `${curProfile.firstName} ${curProfile.lastName}`
                : (curProfile.firstName) ? curProfile.firstName
                    : (curProfile.lastName) ? curProfile.lastName
                        : curProfile.email;
            const newObj = {
                user, count: curCount, id: i, visible: true,
            };
            collatedProlificReaders.push(newObj);
        }
    }

    for (let i = 0; i < prolificSenders.length; i += 1) {
        const curId = prolificSenders[i][0];
        const curCount = prolificSenders[i][1];
        const curProfile = nameMap.find((user) => user.id === curId);
        if (curProfile) {
            const user = (curProfile.firstName && curProfile.lastName) ? `${curProfile.firstName} ${curProfile.lastName}`
                : (curProfile.firstName) ? curProfile.firstName
                    : (curProfile.lastName) ? curProfile.lastName
                        : curProfile.email;
            const newObj = {
                user, count: curCount, id: i, visible: true,
            };
            collatedProlificSenders.push(newObj);
        }
    }

    return {
        collatedProlificReaders,
        collatedProlificSenders,
    };
}

export function getLabels(data: any, limit: number = 0) {
    const labels = [];
    for (let i = 0; i < data.length; i += 1) {
        // limit the list if requested
        if (limit > 0 && limit === i) break;

        if (data[i].user) {
            labels.push(data[i].user);
        } else {
            labels.push(String.fromCodePoint(data[i].emoji));
        }
    }
    return labels;
}

export function getCounts(data: any, limit: number = 0) {
    const counts = [];
    for (let i = 0; i < data.length; i += 1) {
        // limit the list if requested
        if (limit > 0 && limit === i) break;
        counts.push(data[i].count);
    }
    return counts;
}

export function getAcknowledgementData(quarterBMetrics: any) {
    const ackData = [];
    for (const oldEmojiCode in quarterBMetrics.ack_message_counts) {
        if (Object.prototype.hasOwnProperty.call(quarterBMetrics.ack_message_counts, oldEmojiCode)) {
            const newEmojiCode = oldEmojiCode.replace('U+', '0x');
            const emojiObj = { emoji: newEmojiCode, count: quarterBMetrics.ack_message_counts[oldEmojiCode], };
            ackData.push(emojiObj);
        }
    }

    return { ackData, };
}

export function getQuarters() {
    const quarterList = [];
    let curDate = new Date();
    const beginMetrics = new Date(2018, 9, 1);
    while (curDate > beginMetrics) {
        const m = Math.floor(curDate.getMonth() / 3) + 1;
        const y = curDate.getFullYear();
        switch (m) {
            case 1:
                quarterList.push([y, m].join(' Q'));
                break;
            case 2:
                quarterList.push([y, m].join(' Q'));
                break;
            case 3:
                quarterList.push([y, m].join(' Q'));
                break;
            case 4:
                quarterList.push([y, m].join(' Q'));
                break;
            default:
                break;
        }
        curDate = new Date(curDate.getFullYear(), curDate.getMonth() - 3, curDate.getDate());
    }
    return quarterList;
}

export function getQuarterIntervals() {
    const quarterIntervals = {};
    let curDate = new Date();
    const beginMetrics = new Date(2018, 9, 1);
    while (curDate > beginMetrics) {
        const m = Math.floor(curDate.getMonth() / 3) + 1;
        const y = curDate.getFullYear();

        switch (m) {
            case 1:
                quarterIntervals[[y, m].join(' Q')] = { start: { month: 1, year: y, }, end: { month: 4, year: y, }, };
                break;
            case 2:
                quarterIntervals[[y, m].join(' Q')] = { start: { month: 4, year: y, }, end: { month: 7, year: y, }, };
                break;
            case 3:
                quarterIntervals[[y, m].join(' Q')] = { start: { month: 7, year: y, }, end: { month: 10, year: y, }, };
                break;
            case 4:
                quarterIntervals[[y, m].join(' Q')] = { start: { month: 10, year: y, }, end: { month: 1, year: y + 1, }, };
                break;
            default:
                break;
        }
        curDate = new Date(curDate.getFullYear(), curDate.getMonth() - 3, curDate.getDate());
    }
    return quarterIntervals;
}
