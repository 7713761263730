// @flow
import React, { PureComponent } from 'react';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';

type Props = {
    accessLevel: string,
    dialogType: string,
    value: string,
    organizationId: ?number,
    onChange: (newVal: string) => *
};

type State = {
    dropdownKey: number,
};

class AccessLevelDropdown extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownKey: 0,
        };
    }

    onSelect = (newVal: string) => {
        const {
            onChange,
        } = this.props;

        this.setState((state) => ({
            dropdownKey: (state.dropdownKey) ? 0 : 1,
        }));

        onChange(newVal);
    };

    render() {
        const {
            accessLevel,
            dialogType,
            value,
            organizationId,
        } = this.props;

        const {
            dropdownKey,
        } = this.state;

        const accessLevels = [
            {
                value: 'System Administrator',
                label: 'System Administrator',
                disabled: (accessLevel !== 'SysAdmin') || (organizationId !== 255) || dialogType === 'DemoUsers',
            },
            {
                value: 'Sales',
                label: 'Sales',
                disabled: (accessLevel !== 'SysAdmin' && accessLevel !== 'Sales') || (organizationId !== 255) || dialogType === 'DemoUsers',
            },
            {
                value: 'Organization Manager',
                label: 'Organization Manager',
                disabled: dialogType === 'NonMember',
            },
            {
                value: 'Organization Member',
                label: 'Organization Member',
                disabled: dialogType === 'NonMember',
            },
            {
                value: 'BasicUser',
                label: 'Basic User',
                disabled: dialogType === 'PendingMember' || dialogType === 'ApprovedMember' || dialogType === 'DemoUsers',
            },
            {
                value: 'Archived',
                label: 'Archived',
                disabled: true,
            }
        ];
        // FIXME the onMouseDown warning in the console refers to an issue inherent in React Toolbox 2.0.0-beta.13
        // at the time of this writing, we cannot 'fix' the code in such a way as to eliminate the warning
        // https://github.com/react-toolbox/react-toolbox/issues/1909

        return (
            <Dropdown
                key={dropdownKey}
                auto
                onChange={(newVal) => this.onSelect(newVal)}
                source={accessLevels}
                value={value}
                label="Permission"
            />
        );
    }
}

export default AccessLevelDropdown;
