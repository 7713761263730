/* eslint-disable object-curly-newline */
// @flow
import {
    BOOLEAN, STRING, UI_TYPE_TEXT, UI_TYPE_SWITCH, UI_TYPE_CHECK, UI_TYPE_TXC_LIST, KB_TYPE_PHONE, KB_TYPE_EMAIL,
} from './FormTypes';

const formDef = {
    formId: 'OAKidneys',
    sections: [
        {
            id: 1,
            title: '',
            fields: [
                { id: 1, title: 'No intended recovery', type: BOOLEAN, uiType: UI_TYPE_SWITCH, behavior: 'DISABLE-NOT-N', tags: ['N'], },
                { id: 2, title: 'Recovering, not for transplant', type: BOOLEAN, uiType: UI_TYPE_SWITCH, behavior: 'DISABLE-A', tags: [], },
                { id: 3, title: 'Recovery protocols received', type: BOOLEAN, uiType: UI_TYPE_SWITCH, tags: ['A'], },
                { id: 4, title: 'Notes', type: STRING, uiType: UI_TYPE_TEXT, maxLines: 3, maxLength: 255, tags: ['N'], }
            ],
        },
        {
            id: 2,
            title: 'Primary Acceptance 1',
            fields: [
                { id: 5, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 6, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 7, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], },
                { id: 8, title: 'Request', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 9, title: 'TT Blood Sent', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], }
            ],
        },
        {
            id: 3,
            title: 'Backup Center 1',
            fields: [
                { id: 10, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 11, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 12, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], },
                { id: 13, title: 'Request', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 14, title: 'TT Blood Sent', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], }
            ],
        },
        {
            id: 4,
            title: 'Primary Acceptance 2',
            fields: [
                { id: 15, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 16, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 17, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], },
                { id: 18, title: 'Request', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 19, title: 'TT Blood Sent', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], }
            ],
        },
        {
            id: 5,
            title: 'Backup Center 2',
            fields: [
                { id: 20, title: 'Tx Ctr', type: STRING, uiType: UI_TYPE_TXC_LIST, tags: ['A'], },
                { id: 21, title: 'Tx Ctr Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: ['A'], },
                { id: 22, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: ['A'], },
                { id: 23, title: 'Request', type: STRING, uiType: UI_TYPE_TEXT, tags: ['A'], },
                { id: 24, title: 'TT Blood Sent', type: BOOLEAN, uiType: UI_TYPE_CHECK, tags: ['A'], }
            ],
        },
        {
            id: 6,
            title: 'Research Center',
            fields: [
                { id: 25, title: 'Research Center', type: STRING, uiType: UI_TYPE_TEXT, tags: [], },
                { id: 26, title: 'Contact', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_EMAIL, tags: [], },
                { id: 27, title: 'Phone', type: STRING, uiType: UI_TYPE_TEXT, kbType: KB_TYPE_PHONE, tags: [], }
            ],
        }
    ],
};

export default formDef;
