// @flow

import Colors from '../../Themes/Colors';

const AuthStyles = {

    formLabel: {
        color: Colors.formLabel,
        fontSize: 17,
        lineHeight: 2,
    },

    formError: {
        color: Colors.formError,
        fontSize: 11,
        marginTop: 2,
    },

    version: {
        color: Colors.lightGray,
    },

    drawerButton: {
        marginBottom: 5,
        textAlign: 'left',
        width: '100%',
    },

    logoutButton: {
        marginTop: 15,
        textAlign: 'center',
        width: '100%',
    },
    marginTop: {
        marginTop: 'var(--header-height)',
        height: '100%',
    },
};

export default AuthStyles;
