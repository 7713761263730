// @flow
import { PureComponent } from 'react';
import * as ReactDOM from 'react-dom';

type Props = {
    children: any,
}

// Some of the implementation for copying styles was adapted from the source below
// https://blog.scottlogic.com/2019/10/29/popout-windows-in-react.html
class WindowPortal extends PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        // Create an element that will be the target for the React portal
        this.containerEl = document.createElement('div');
        this.containerEl.style.cssText = 'overflow-y:scroll;height:100%';
        this.externalWindow = null;

        // Set up a mutation observer to watch for style additions to the main
        // window <head>
        const documentHead = document.head;
        const observerConfig = { childList: true, };
        this.mutationObserver = new MutationObserver((mutationList) => {
            for (let i = mutationList.length - 1; i >= 0; i -= 1) {
                const mutation = mutationList[i];
                if (mutation.type === 'childList') {
                    // any added nodes with a style sheet should get copied to the external window
                    mutation.addedNodes.forEach((node: any) => {
                        if (node.sheet) {
                            const styleSheet: CSSStyleSheet = node.sheet;
                            if (node.attributes && node.attributes['data-meta']) {
                                const dataMetaAttribute = node.attributes['data-meta'].nodeValue;
                                this.addStyle(styleSheet, dataMetaAttribute);
                            } else {
                                this.addStyle(styleSheet);
                            }
                        }
                    });
                    // Any removed nodes should get removed from the external window, matching on
                    // data-meta attribute (relevant when the picker UI type is selected and deselected)
                    mutation.removedNodes.forEach((node: any) => {
                        if (node.attributes && node.attributes['data-meta']) {
                            const dataMetaAttribute = node.attributes['data-meta'].nodeValue;
                            const elementToRemove = this.externalWindow.document.querySelector(`[data-meta="${dataMetaAttribute}"]`);
                            if (elementToRemove) {
                                elementToRemove.remove();
                            }
                        }
                    });
                }
            }
        });
        if (documentHead) {
            this.mutationObserver.observe(documentHead, observerConfig);
        }
    }

    componentDidMount() {
        this.externalWindow = window.open('', '', 'width=400,height=800,left=200,top=200');
        this.copyStyles();
        this.externalWindow.document.body.appendChild(this.containerEl);
    }

    componentWillUnmount() {
        // When component unmounts, disconnect the observer
        this.mutationObserver.disconnect();
        this.externalWindow.close();
    }

    // Class members
    containerEl: HTMLDivElement;

    externalWindow: any;

    mutationObserver: MutationObserver;

    // NOTE: copyStyles is only used in componenetDidMount hook, so this will only
    // copy styles to the external window with an empty <head>
    // Running copyStyles again will add duplicate <style> elements to the external window
    copyStyles() {
        const stylesheets = Array.from(document.styleSheets);
        stylesheets.forEach((stylesheet) => {
            this.addStyle(stylesheet);
        });
    }

    addStyle(stylesheet: any, attribute?: any) {
        const css: CSSStyleSheet = stylesheet;
        if (stylesheet.href) {
            const newStyleElement = document.createElement('link');
            newStyleElement.rel = 'stylesheet';
            newStyleElement.href = stylesheet.href;
            this.externalWindow.document.head.appendChild(newStyleElement);
        } else if (css && css.cssRules && css.cssRules.length > 0) {
            const newStyleElement = document.createElement('style');
            if (attribute) {
                newStyleElement.setAttribute('data-meta', attribute);
            }
            Array.from(css.cssRules).forEach((rule) => {
                newStyleElement.appendChild(document.createTextNode(rule.cssText));
            });
            this.externalWindow.document.head.appendChild(newStyleElement);
        }
    }

    render() {
        // Create a portal to render any children in our created <div>
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }
}

export default WindowPortal;
