import { store } from '../store';
import {
    setSelectedUserRow,
    toggleUserDetailsPanel,
    toggleAddUserToOrganizationPanel,
    toggleCreateDemoUserPanel,
    setBulkEditUsers,
} from '../Redux/UserActions';

// eslint-disable-next-line import/prefer-default-export
export function onClosePanel() {
    const { dispatch, } = store;

    dispatch(setSelectedUserRow(null));
    dispatch(toggleUserDetailsPanel(false));
    dispatch(toggleAddUserToOrganizationPanel(false));
    dispatch(toggleCreateDemoUserPanel(false));
    dispatch(setBulkEditUsers([]));
}
