// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';
import Button from 'react-toolbox/lib/button/Button';

import {
    setFileType as _setFileType,
    setFileName as _setFileName,
    downloadFile as _downloadFile,
    downloadFileFailed as _downloadFileFailed,
    resetCDSDownload as _resetCDSDownload,
} from '../Redux/OfferActions';
import type { CDSFileType } from '../Utils/types';
import ResearchStyles from './Styles/ResearchStyles';
import AuthStyles from './Styles/AuthStyles';

type Props = {
    fileType: CDSFileType,
    fileName: string,
    error: ?string,
    isLoading: boolean,

    setFileType: (fileType: CDSFileType) => *,
    setFileName: (fileName: string) => *,
    downloadFile: (fileType: CDSFileType, fileName: string) => *,
    downloadFileFailed: (error: string) => *,
    resetCDSDownload: () => *,
};

type State = {
    dropdownKey: number,
};

class ResearchPage extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownKey: 0,
        };
    }

    componentDidMount() {
        const {
            resetCDSDownload,
        } = this.props;

        resetCDSDownload();
    }

    componentWillUnmount() {
        const {
            resetCDSDownload,
        } = this.props;

        resetCDSDownload();
    }

    onSetFileType = (type: CDSFileType) => {
        const {
            setFileType,
        } = this.props;

        this.setState((state) => ({
            dropdownKey: (state.dropdownKey) ? 0 : 1,
        }));

        setFileType(type);
    };

    onSetFileName = (name: string) => {
        const {
            setFileName,
        } = this.props;

        setFileName(name);
    };

    onDownloadFile = () => {
        const {
            fileType,
            fileName,
            downloadFile,
            downloadFileFailed,
        } = this.props;

        if (fileType === 'offer' || fileType === 'candidate') {
            if (!fileName.includes('.json')) {
                downloadFileFailed('Please include the .json extension with the file name');
            } else {
                downloadFile(fileType, fileName);
            }
        } else if (fileType === 'roo') {
            if (!fileName.includes('.csv')) {
                downloadFileFailed('Please include the .csv extension with the file name');
            } else {
                downloadFile(fileType, fileName);
            }
        }
    };

    render() {
        const {
            fileType,
            fileName,
            error,
            isLoading,
        } = this.props;

        const {
            dropdownKey,
        } = this.state;

        const fileTypes = [
            {
                value: 'roo',
                label: 'ROO',
            },
            {
                value: 'candidate',
                label: 'Candidate',
            },
            {
                value: 'offer',
                label: 'Offer',
            }
        ];

        const downloadDisabled = !fileType || !fileName || isLoading;

        return (
            <div>
                <div style={ResearchStyles.instructionContainer}>
                    <span style={AuthStyles.formLabel}>
                        Select the file type and enter the file name of the file you would like to download.
                    </span>
                </div>
                <div style={ResearchStyles.inputContainer}>
                    <div>
                        <Dropdown
                            key={dropdownKey}
                            auto
                            onChange={(newVal) => this.onSetFileType(newVal)}
                            source={fileTypes}
                            value={fileType}
                            label="File Type"
                            required
                        />
                    </div>
                    <div>
                        <Input
                            type="text"
                            label="File Name"
                            value={fileName}
                            onChange={(newVal) => this.onSetFileName(newVal)}
                            required
                        />
                    </div>
                </div>
                <div style={ResearchStyles.errorContainer}>
                    <span style={AuthStyles.formError}>
                        {error}
                    </span>
                </div>
                <div style={ResearchStyles.submitButtonContainer}>
                    <Button
                        style={downloadDisabled ? ResearchStyles.submitButtonDisabled : ResearchStyles.submitButton}
                        disabled={downloadDisabled}
                        label="Download"
                        flat
                        primary
                        onClick={this.onDownloadFile}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fileType: state.offer.fileType,
    fileName: state.offer.fileName || '',
    error: state.offer.error,
    isLoading: state.loading.downloadCDS,
});

export default connect(mapStateToProps, {
    setFileType: _setFileType,
    setFileName: _setFileName,
    downloadFile: _downloadFile,
    downloadFileFailed: _downloadFileFailed,
    resetCDSDownload: _resetCDSDownload,
})(ResearchPage);
