// @flow
import Colors from '../../Themes/Colors';


const MenuStyles = {
    navigationButtons: {
        fontSize: 22,
        textAlign: 'left',
        width: '100%',
        textIndent: 5,
        textTransform: 'none',
        margin: 0,
        verticalAlign: 'middle',
    },
    users: {
        fontSize: 22,
        textAlign: 'left',
        width: '100%',
        textIndent: 5,
        textTransform: 'none',
    },
    usersButton: {
        fontSize: 20,
        textAlign: 'left',
        width: '100%',
        textIndent: 45,
        textTransform: 'none',
    },
    logo: {
        marginTop: 30,
        marginBottom: 30,
        textAlign: 'center',
        width: '100%',
    },
    border: {
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderTopColor: 'black',
        borderTopRadius: 0.5,
        height: 900,
    },
    menuBorder: {
        borderRightStyle: 'solid',
        borderRightWidth: 1,
        borderRightColor: 'black',
        borderRightRadius: 0.5,
        height: 900,
    },
    noHighlight: {
        fontSize: 22,
        textAlign: 'left',
        width: '100%',
        textIndent: 5,
        textTransform: 'none',
        cursor: 'initial',
        backgroundColor: 'initial',
        color: Colors.black,
        verticalAlign: 'middle',
    },
    paddingTop: {
        paddingTop: '3px',
    },
};

export default MenuStyles;
