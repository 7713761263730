// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Input from 'react-toolbox/lib/input/Input';
import Button from 'react-toolbox/lib/button/Button';
import Dropdown from 'react-toolbox/lib/dropdown/Dropdown';

import PanelStyles from './Styles/PanelStyles';
import ApplicationStyles from '../Themes/ApplicationStyles';
import type { NewOrganization } from '../Utils/types';

type Props = {
    organizationTypes: Array<string>,
    onClose: () => *,
    onAddOrganization: (newOrganization: NewOrganization) => *
};

type State = {
    newOrganization: NewOrganization,
    typeDropdownKey: string,
}

class AddOrganizationPanel extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            typeDropdownKey: 'typeKey0',
            newOrganization: {
                organizationName: '',
                organizationCode: '',
                organizationType: '',
                templatePreferences: {},
            },
        };
    }

    onChangeOrganizationDetails = (value: string, property: string) => {
        this.setState((state) => {
            // FIXME When PR #1872 is published and released to npm, this can be removed
            const typeDropdownKey = (property !== 'organizationType') ? (state.typeDropdownKey)
                : (state.typeDropdownKey === 'typeKey0') ? 'typeKey1' : 'typeKey0';
            return {
                ...state,
                typeDropdownKey,
                newOrganization: {
                    ...state.newOrganization,
                    [property]: value,
                },
            };
        });
    };

    resetInputs = (newOrganization: Object) => {
        const {
            onAddOrganization,
        } = this.props;

        onAddOrganization(newOrganization);

        this.setState({
            typeDropdownKey: 'typeKey0',
            newOrganization: {
                organizationName: '',
                organizationCode: '',
                organizationType: '',
                templatePreferences: {},
            },
        });
    };

    resetInputsCancel = () => {
        const {
            onClose,
        } = this.props;

        onClose();

        this.setState({
            typeDropdownKey: 'typeKey0',
            newOrganization: {
                organizationName: '',
                organizationCode: '',
                organizationType: '',
                templatePreferences: {},
            },
        });
    };

    render() {
        const {
            organizationTypes,
        } = this.props;

        const {
            newOrganization,
        } = this.state;

        if (!organizationTypes) {
            return (
                <div />
            );
        }

        const organizationTypeValues = organizationTypes.map((type) => ({ value: type, label: type, }));

        const isEnabled = newOrganization.organizationName.length > 0
        && newOrganization.organizationCode.length > 0
        && newOrganization.organizationType.length > 0;

        return (
            <div style={ApplicationStyles.inputPadding}>
                <Input
                    type="text"
                    label="Organization Name"
                    value={newOrganization.organizationName}
                    onChange={(newVal) => this.onChangeOrganizationDetails(newVal, 'organizationName')}
                    required
                />
                <Input
                    type="text"
                    label="Organization Code"
                    value={newOrganization.organizationCode}
                    onChange={(newVal) => this.onChangeOrganizationDetails(newVal, 'organizationCode')}
                    required
                />
                <Dropdown
                    key={this.state.typeDropdownKey}
                    auto
                    onChange={(newVal) => this.onChangeOrganizationDetails(newVal, 'organizationType')}
                    source={organizationTypeValues}
                    value={newOrganization.organizationType}
                    label="Organization Type"
                    required
                />
                <div style={PanelStyles.optionButtonsContainer}>
                    <Button
                        label="Cancel"
                        style={ApplicationStyles.cancelButton}
                        onClick={() => this.resetInputsCancel()}
                    />
                    <Button
                        disabled={!isEnabled}
                        style={!isEnabled ? ApplicationStyles.disabledButton : ApplicationStyles.saveButton}
                        label="Save"
                        onClick={() => this.resetInputs(newOrganization)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    organizationTypes: state.organization.organizationTypes,
});

export default connect(mapStateToProps, {
})(AddOrganizationPanel);
