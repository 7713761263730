// @flow

function getTaskOrgan(taskId: number): string {
    switch (taskId) {
        case 13:
        case 29:
            return 'Heart';
        case 14:
        case 30:
            return 'Lungs';
        case 15:
        case 31:
            return 'Liver';
        case 16:
        case 32:
            return 'Intestine';
        case 17:
        case 33:
            return 'Pancreas';
        case 18:
        case 34:
            return 'Kidneys';
        default:
            return 'Unknown';
    }
}

export default getTaskOrgan;
