// @flow
import type { Logout } from 'txp-core';

import type {
    Form,
    RefDataSurvey,
    ReferenceDomain,
    Workflow,
    FollowerPreference,
} from '../Utils/types';
import search from '../Utils/search';
import sort from '../Utils/sort';

type WorkflowKey = string;
export type BuilderMode = 'create' | 'update' | 'preferences';
type BuilderData = {
    workflow: WorkflowKey,
    mode: BuilderMode,
};
type ReferenceDataState = {
    lastModifiedDate: string,
    workflows: Array<Workflow>,
    surveys: Array<RefDataSurvey>,
    forms: Array<Form>,
    researchers: Array<any>,

    domain: ?ReferenceDomain,
    name: string,
    data: string, // copy/paste json

    success: boolean,
    error: string,

    sortHeader: string,
    sortDirection: string,
    searchTerm: string,
    selectedReferenceData: any,
    builder: BuilderData,
};

const initialState: ReferenceDataState = {
    lastModifiedDate: '',
    workflows: [],
    surveys: [],
    forms: [],
    researchers: [],

    domain: null,
    name: '',
    data: '',

    success: false,
    error: '',

    sortHeader: 'domain',
    sortDirection: 'asc',
    searchTerm: '',
    selectedReferenceData: {},
    builder: {
        workflow: '',
        mode: 'create',
    },
};

export type GetReferenceData = { type: 'Reference/GET_REFERENCE' };
export type ReceiveLastModifiedDate = {
    type: 'Reference/RECEIVE_LAST_MODIFIED_DATE',
    lastModifiedDate: string,
};
export type ReceiveWorkflows = { type: 'Reference/RECEIVE_WORKFLOWS', workflows: Array<Workflow> };
export type ReceiveSingleWorkflow = { type: 'Reference/RECEIVE_SINGLE_WORKFLOW', workflow: Workflow };
export type ReceiveSurveys = { type: 'Reference/RECEIVE_SURVEYS', surveys: Array<RefDataSurvey> };
export type ReceiveForms = { type: 'Reference/RECEIVE_FORMS', forms: Array<Form> };
export type ReceiveResearchers = { type: 'Reference/RECEIVE_RESEARCHERS', researchers: Array<any> };

export type DownloadReferenceData = { type: 'Reference/DOWNLOAD', domain: ReferenceDomain, name: string };

export type SetDomain = { type: 'Reference/SET_DOMAIN', domain: ReferenceDomain };
export type SetName = { type: 'Reference/SET_NAME', name: string };
export type SetData = { type: 'Reference/SET_DATA', data: string };
export type SetBuilderWorkflow = { type: 'Reference/SET_BUILDER_WORKFLOW', workflow: WorkflowKey };
export type SetBuilderData = { type: 'Reference/SET_BUILDER_DATA', data: $Shape<BuilderData> };

export type UpdateInactive = {
    type: 'Reference/UPDATE_INACTIVE',
    domain: ReferenceDomain,
    name: string,
    inactive: boolean,
};

export type UploadReferenceData = {
    type: 'Reference/UPLOAD_REFERENCE_DATA',
    domain: ReferenceDomain,
    name: string,
    data: string,
};
export type UploadReferenceSuccess = { type: 'Reference/UPLOAD_REFERENCE_SUCCESS' };
export type UploadReferenceFailed = { type: 'Reference/UPLOAD_REFERENCE_FAILED', error: string };
export type ResetUpload = { type: 'Reference/RESET_UPLOAD' };

export type SearchReferenceData = { type: 'Reference/SEARCH', searchTerm: string, searchHeaders: Array<string>, };
export type SetSortParameters = { type: 'Reference/SET_SORT_PARAMETERS', sortHeader: string, sortDirection: string, };
export type ClearSearch = { type: 'Reference/CLEAR_SEARCH', };

export type ResetReference = { type: 'Reference/RESET' };
export type SetSelectedReferenceDataRow = { type: 'Reference/SET_SELECTED_REFERENCE_DATA_ROW', row: any };
export type UpdateWorkflowDetails = { type: 'Reference/UPDATE_WORKFLOW_DETAILS', workflow: Workflow };
export type CreateWorkflow = { type: 'Reference/CREATE_WORKFLOW', workflow: any, followerPreferences: FollowerPreference[] };
export type UpdateWorkflow = { type: 'Reference/UPDATE_WORKFLOW', workflow: any, followerPreferences: FollowerPreference[] };
export type UpdateWorkflowPreferences = {
    type: 'Reference/UPDATE_WORKFLOW_PREFERENCES',
    preferencesId: number,
    followerPreferences: FollowerPreference[]
};
export type CreateWorkflowPreferences = {
    type: 'Reference/CREATE_WORKFLOW_PREFERENCES',
    workflow: WorkflowKey,
    followerPreferences: FollowerPreference[],
};

type Action =
    | GetReferenceData
    | ReceiveLastModifiedDate
    | ReceiveWorkflows
    | ReceiveSingleWorkflow
    | ReceiveSurveys
    | ReceiveForms
    | ReceiveResearchers
    | DownloadReferenceData
    | SetDomain
    | SetName
    | SetData
    | UpdateInactive
    | UploadReferenceData
    | UploadReferenceSuccess
    | UploadReferenceFailed
    | ResetUpload
    | SearchReferenceData
    | SetSortParameters
    | ClearSearch
    | ResetReference
    | SetSelectedReferenceDataRow
    | SetBuilderWorkflow
    | SetBuilderData
    | UpdateWorkflowDetails
    | CreateWorkflow
    | UpdateWorkflow
    | UpdateWorkflowPreferences
    | CreateWorkflowPreferences
    | Logout;

export const getReferenceData = (): GetReferenceData => ({
    type: 'Reference/GET_REFERENCE',
});

export const receiveLastModifiedDate = (lastModifiedDate: string): ReceiveLastModifiedDate => ({
    type: 'Reference/RECEIVE_LAST_MODIFIED_DATE',
    lastModifiedDate,
});

export const receiveWorkflows = (workflows: Array<Workflow>): ReceiveWorkflows => ({
    type: 'Reference/RECEIVE_WORKFLOWS',
    workflows,
});

export const receiveSingleWorkflow = (workflow: Workflow): ReceiveSingleWorkflow => ({
    type: 'Reference/RECEIVE_SINGLE_WORKFLOW',
    workflow,
});

export const receiveSurveys = (surveys: Array<RefDataSurvey>): ReceiveSurveys => ({
    type: 'Reference/RECEIVE_SURVEYS',
    surveys,
});

export const receiveForms = (forms: Array<Form>): ReceiveForms => ({
    type: 'Reference/RECEIVE_FORMS',
    forms,
});

export const receiveResearchers = (researchers: Array<any>): ReceiveResearchers => ({
    type: 'Reference/RECEIVE_RESEARCHERS',
    researchers,
});

export const downloadReferenceData = (domain: ReferenceDomain, name: string): DownloadReferenceData => ({
    type: 'Reference/DOWNLOAD',
    domain,
    name,
});

export const setDomain = (domain: ReferenceDomain): SetDomain => ({
    type: 'Reference/SET_DOMAIN',
    domain,
});

export const setName = (name: string): SetName => ({
    type: 'Reference/SET_NAME',
    name,
});

export const setData = (data: string): SetData => ({
    type: 'Reference/SET_DATA',
    data,
});

export const setBuilderWorkflow = (workflow: WorkflowKey): SetBuilderWorkflow => ({
    type: 'Reference/SET_BUILDER_WORKFLOW',
    workflow,
});

export const setBuilderData = (data: $Shape<BuilderData>): SetBuilderData => ({
    type: 'Reference/SET_BUILDER_DATA',
    data,
});

export const updateInactive = (domain: ReferenceDomain, name: string, inactive: boolean): UpdateInactive => ({
    type: 'Reference/UPDATE_INACTIVE',
    domain,
    name,
    inactive,
});

export const uploadReferenceData = (domain: ReferenceDomain, name: string, data: string): UploadReferenceData => ({
    type: 'Reference/UPLOAD_REFERENCE_DATA',
    domain,
    name,
    data,
});

export const uploadReferenceSuccess = (): UploadReferenceSuccess => ({
    type: 'Reference/UPLOAD_REFERENCE_SUCCESS',
});

export const uploadReferenceFailed = (error: string): UploadReferenceFailed => ({
    type: 'Reference/UPLOAD_REFERENCE_FAILED',
    error,
});

export const resetUpload = () => ({
    type: 'Reference/RESET_UPLOAD',
});

export const searchReferenceData = (searchTerm: string, searchHeaders: Array<string>): SearchReferenceData => ({
    type: 'Reference/SEARCH',
    searchTerm,
    searchHeaders,
});

export const setSortParameters = (
    sortHeader: string,
    sortDirection: string
): SetSortParameters => ({
    type: 'Reference/SET_SORT_PARAMETERS',
    sortHeader,
    sortDirection,
});

export const clearSearch = (): ClearSearch => ({
    type: 'Reference/CLEAR_SEARCH',
});


export const resetReference = (): ResetReference => ({
    type: 'Reference/RESET',
});

export const setSelectedReferenceDataRow = (row: any): SetSelectedReferenceDataRow => ({
    type: 'Reference/SET_SELECTED_REFERENCE_DATA_ROW',
    row,
});

export const updateWorkflowDetails = (workflow: Workflow): UpdateWorkflowDetails => ({
    type: 'Reference/UPDATE_WORKFLOW_DETAILS',
    workflow,
});

export const createWorkflow = (workflow: any, followerPreferences: FollowerPreference[]): CreateWorkflow => (
    {
        type: 'Reference/CREATE_WORKFLOW',
        workflow,
        followerPreferences,
    });

export const updateWorkflow = (workflow: any, followerPreferences: FollowerPreference[]): UpdateWorkflow => (
    {
        type: 'Reference/UPDATE_WORKFLOW',
        workflow,
        followerPreferences,
    });

export const updateWorkflowPreferences = (preferencesId: number, followerPreferences: FollowerPreference[]): UpdateWorkflowPreferences => ({
    type: 'Reference/UPDATE_WORKFLOW_PREFERENCES',
    preferencesId,
    followerPreferences,
});

export const createWorkflowPreferences = (workflow: WorkflowKey, followerPreferences: FollowerPreference[]): CreateWorkflowPreferences => ({
    type: 'Reference/CREATE_WORKFLOW_PREFERENCES',
    workflow,
    followerPreferences,
});

const reducer = (state: ReferenceDataState = initialState, action: Action): ReferenceDataState => {
    switch (action.type) {
        case 'Auth/LOGOUT': {
            // if logout or registration then no action required
            if (!action.fullReset) return { ...state, };
            return {
                ...initialState,
            };
        }

        case 'Reference/RECEIVE_LAST_MODIFIED_DATE':
            return {
                ...state,

                lastModifiedDate: action.lastModifiedDate,
            };

        case 'Reference/RECEIVE_WORKFLOWS':
            return {
                ...state,

                workflows: action.workflows.slice(),
            };

        case 'Reference/RECEIVE_SINGLE_WORKFLOW': {
            const workflowIdx = state.workflows.findIndex((item) => item.key === action.workflow.key);

            const newWorkflows = state.workflows.slice();
            newWorkflows[workflowIdx] = action.workflow;

            return {
                ...state,

                workflows: newWorkflows,
            };
        }

        case 'Reference/RECEIVE_SURVEYS':
            return {
                ...state,

                surveys: action.surveys.slice(),
            };

        case 'Reference/RECEIVE_RESEARCHERS':
            return {
                ...state,

                researchers: action.researchers.slice(),
            };

        case 'Reference/RECEIVE_FORMS':
            return {
                ...state,

                forms: action.forms.slice(),
            };

        case 'Reference/SET_DOMAIN':
            return {
                ...state,

                domain: action.domain,
            };

        case 'Reference/SET_NAME':
            return {
                ...state,

                name: action.name,
            };

        case 'Reference/SET_DATA':
            return {
                ...state,

                data: action.data,
            };

        case 'Reference/SET_BUILDER_MODE':
            return reducer(state, setBuilderData({ mode: action.mode, }));

        case 'Reference/SET_BUILDER_WORKFLOW':
            return reducer(state, setBuilderData({ workflow: action.workflow, }));

        case 'Reference/SET_BUILDER_DATA':
            return {
                ...state,

                builder: {
                    ...state.builder,
                    ...action.data,
                },
            };

        case 'Reference/UPLOAD_REFERENCE_SUCCESS':
            return {
                ...state,

                domain: initialState.domain,
                name: initialState.name,
                data: initialState.data,
                success: true,
                error: initialState.error,
            };

        case 'Reference/UPLOAD_REFERENCE_FAILED':
            return {
                ...state,

                success: false,
                error: action.error,
            };

        case 'Reference/SEARCH':
            return {
                ...state,

                searchTerm: action.searchTerm,
                workflows: search(state.workflows, action.searchTerm, action.searchHeaders),
                surveys: search(state.surveys, action.searchTerm, action.searchHeaders),
                forms: search(state.forms, action.searchTerm, action.searchHeaders),
                researchers: search(state.researchers, action.searchTerm, action.searchHeaders),
            };

        case 'Reference/SET_SORT_PARAMETERS': {
            return {
                ...state,

                workflows: sort(state.workflows, action.sortDirection, action.sortHeader),
                surveys: sort(state.surveys, action.sortDirection, action.sortHeader),
                forms: sort(state.forms, action.sortDirection, action.sortHeader),

                sortHeader: action.sortHeader,
                sortDirection: action.sortDirection,
            };
        }

        case 'Reference/CLEAR_SEARCH': {
            return {
                ...state,

                searchTerm: '',
            };
        }

        case 'Reference/RESET_UPLOAD':
            return {
                ...state,

                domain: initialState.domain,
                name: initialState.name,
                data: initialState.data,

                success: initialState.success,
                error: initialState.error,
            };

        case 'Reference/RESET':
            return {
                ...state,

                domain: initialState.domain,
                name: initialState.name,
                data: initialState.data,

                success: initialState.success,
                error: initialState.error,

                sortHeader: initialState.sortHeader,
                sortDirection: initialState.sortDirection,
                searchTerm: initialState.searchTerm,
            };

        case 'Reference/SET_SELECTED_REFERENCE_DATA_ROW':
            return {
                ...state,

                selectedReferenceData: action.row,
            };
            // case 'Reference/CREATE_WORKFLOW':
            //     return {
            //         ...state,

            //     }

        default:
            return state;
    }
};

export default reducer;
