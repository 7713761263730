// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { logout as _logout, verifyAccessToken as _verifyAccessToken } from 'txp-core';
import type { Logout } from 'txp-core';

// import MessagesHomePage from './MessagesHomePage';
import DonorsHomePage from './DonorsHomePage';
import UsersPage from './UsersPage';
import OrganizationsPage from './OrganizationsPage';
import ClientsPage from './ClientPage';
import InternalDashboard from '../Components/InternalDashboard';
import ChatroomPage from './ChatroomPage';
import Header from '../Components/Header';
import AuthStyles from './Styles/AuthStyles';
import MetricsGraphics from '../Components/MetricsGraphics';
import MenuPanel from '../Components/MenuPanel';
import {
    loadOrganizations as _loadOrganizations,
    organizationMetrics as _organizationMetrics,
    organizationUserActivity as _organizationUserActivity,
    loadBusinessMetrics as _loadBusinessMetrics,
    loadOrganizationTypes as _loadOrganizationTypes,
    loadTemplateOptions as _loadTemplateOptions,
    setSelectedOrganizationRow as _setSelectedOrganizationRow,
} from '../Redux/OrganizationActions';
import {
    setSelectedReferenceDataRow as _setSelectedReferenceDataRow,
} from '../Redux/ReferenceDataActions';
import {
    getAdminPortalPermissions as _getAdminPortalPermissions,
} from '../Redux/PermissionActions';
import type {
    UserProfile,
} from '../Utils/types';
import {
    clearSearch as _clearSearch,
    setSelectedUserRow as _setSelectedUserRow,
} from '../Redux/UserActions';
import { setSelectedChatroomRow as _setSelectedChatroomRow } from '../Redux/ChatroomActions';
import constants, { UserTypes } from '../Utils/constants';
import { getQuarters } from '../Utils/metrics';
import ResearchPage from './ResearchPage';
import ConfigurationPage from './ConfigurationPage';
import WorkflowBuilderPage from './WorkflowBuilderPage';

type Props = {
    isAuthenticated: ?boolean,
    expirationInterval: number,
    refreshToken: string,
    profile: UserProfile,

    verifyAccessToken: (application: string) => void,
    onSubmitLogout: (refreshToken: string, deviceToken: string, application: string) => Logout,
    loadOrganizations: () => *,
    loadOrganizationTypes: () => *,
    loadTemplateOptions: () => *,
    getAdminPortalPermissions: () => *,
    organizationMetrics: (organizationId: ?number, quarterA: string, quarterB: string) => *,
    loadBusinessMetrics: () => *,
    organizationUserActivity: (organizationId: ?number) => *,
    clearSearch: () => *,
    setSelectedUserRow: (row: any) => *,
    setSelectedOrganizationRow: (row: any) => *,
    setSelectedChatroomRow: (row: any) => *,
    setSelectedReferenceDataRow: (row: any) => *,
};

type State = {
    sidebarActive: boolean,
    nestedContainer: string,
};

class AuthenticatedContainer extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            nestedContainer: props.profile.accessLevel === 'Sales' || props.profile.accessLevel === 'SysAdmin'
                ? constants.HOME_TABLE : constants.MESSAGES_HOME_TABLE,
            sidebarActive: true,
        };
    }

    componentDidMount() {
        const {
            verifyAccessToken,
            expirationInterval,
            loadOrganizations,
            getAdminPortalPermissions,
            organizationMetrics,
            organizationUserActivity,
            loadBusinessMetrics,
            profile,
            clearSearch,
            isAuthenticated,
            loadOrganizationTypes,
            loadTemplateOptions,
        } = this.props;

        if (!isAuthenticated) {
            return;
        }

        loadTemplateOptions();
        loadOrganizationTypes();
        loadOrganizations();
        getAdminPortalPermissions();

        if (profile.organizationId) {
            organizationUserActivity(profile.organizationId);
            const quarters = getQuarters();
            organizationMetrics(profile.organizationId, quarters[1], quarters[0]);
        }

        if (profile.accessLevel === 'Sales' || profile.accessLevel === 'SysAdmin') {
            loadBusinessMetrics();
            clearSearch();
        }

        // Trigger the session verification saga w/ an interval which is defined in SessionSaga
        this.interval = setInterval(() => {
            verifyAccessToken('ADMIN_PORTAL');
        }, expirationInterval - (60 * 1000));
    }

    componentWillUnmount() {
        // Clear the interval function so that it no longer dispatches the verifyAccessToken action
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    onLogout = () => {
        const {
            refreshToken,
            onSubmitLogout,
        } = this.props;

        onSubmitLogout(refreshToken, '', 'ADMIN_PORTAL');
    };

    onSetLeftSidebarOpen = () => {
        this.setState({
            sidebarActive: true,
        });
    };

    onSetLeftSidebarClose = () => {
        this.setState({
            sidebarActive: false,
        });
    };

    getNestedContainer() {
        const {
            nestedContainer,
        } = this.state;

        switch (nestedContainer) {
            case constants.USER_TABLE:
                return <UsersPage />;
            case constants.ORGANIZATION_TABLE:
                return <OrganizationsPage />;
            case constants.CLIENTS_TABLE:
                return <ClientsPage />;
            case constants.MESSAGES_HOME_TABLE:
                return <MetricsGraphics />;
            case constants.DONORS_HOME_TABLE:
                return <DonorsHomePage />;
            case constants.HOME_TABLE:
                return (
                    <InternalDashboard />
                );
            case constants.ANALYTICS_TABLE:
                return <MetricsGraphics />;

            case constants.ARCHIVED_USER_TABLE:
                return <UsersPage userType={UserTypes.ARCHIVED} />;
            case constants.INVITED_USER_TABLE:
                return <UsersPage userType={UserTypes.INVITED} />;
            case constants.DEMO_USER_TABLE:
                return <UsersPage userType={UserTypes.DEMO} />;
            case constants.BASIC_USER_TABLE:
                return <UsersPage userType={UserTypes.BASIC} />;
            case constants.REGISTERING_USER_TABLE:
                return <UsersPage userType={UserTypes.REGISTERING} />;
            case constants.PENDING_MEMBERS_TABLE:
                return <UsersPage userType={UserTypes.PENDING} />;
            case constants.APPROVED_MEMBERS_TABLE:
                return <UsersPage userType={UserTypes.APPROVED} />;
            case constants.ACTIVE_CHATROOMS_TABLE:
                return <ChatroomPage chatroomType="Active" />;
            case constants.ARCHIVED_CHATROOMS_TABLE:
                return <ChatroomPage chatroomType="Archived" />;
            case constants.RESEARCH_TABLE:
                return <ResearchPage />;
            case constants.CONFIG_TABLE:
                return <ConfigurationPage />;
            case constants.WORKFLOW_BUILDER:
                return <WorkflowBuilderPage />;
            default:
                return <UsersPage />;
        }
    }

    switchNestedContainer = (newContainer: string) => {
        const {
            profile,
            loadOrganizations,
            clearSearch,
            setSelectedOrganizationRow,
            setSelectedUserRow,
            setSelectedChatroomRow,
            setSelectedReferenceDataRow,
        } = this.props;

        this.setState({
            nestedContainer: newContainer,
        });

        if (profile.accessLevel === 'Sales' || profile.accessLevel === 'SysAdmin') {
            loadOrganizations();
        }

        setSelectedOrganizationRow(null);
        setSelectedUserRow(null);
        setSelectedChatroomRow(null);
        setSelectedReferenceDataRow({});
        clearSearch();
    };

    interval = null;

    renderLeftPanel() {
        const {
            profile,
        } = this.props;

        const {
            nestedContainer,
            sidebarActive,
        } = this.state;

        if (sidebarActive) {
            return (
                <MenuPanel
                    profile={profile}
                    nestedContainer={nestedContainer}
                    switchNestedContainer={this.switchNestedContainer}
                    sidebarActive={sidebarActive}
                    onLeftSidebarClose={this.onSetLeftSidebarClose}
                    onLeftSidebarOpen={this.onSetLeftSidebarOpen}
                />
            );
        }
        return ('');
    }

    render() {
        const {
            isAuthenticated,
        } = this.props;

        const {
            sidebarActive,
            nestedContainer,
        } = this.state;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        return (
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                    newestOnTop={false}
                    hideProgressBar
                    transition={Slide}
                />
                <Header
                    sidebarActive={sidebarActive}
                    nestedContainer={nestedContainer}
                    onSubmitLogout={this.onLogout}
                    onLeftSidebarClose={this.onSetLeftSidebarClose}
                    onLeftSidebarOpen={this.onSetLeftSidebarOpen}
                    switchNestedContainer={this.switchNestedContainer}
                />
                <div style={{ paddingTop: 65, }}>
                    <Sidebar
                        sidebar={(
                            <div>
                                {this.renderLeftPanel()}
                            </div>
                        )}
                        shadow={false}
                        open={sidebarActive}
                        docked={sidebarActive}
                        sidebarClassName="navigationSidebar"
                    >
                        <div style={AuthStyles.marginTop}>
                            {this.getNestedContainer()}
                        </div>
                    </Sidebar>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.authenticated,
    profile: state.auth.profile || {},
    expirationInterval: state.auth.expiration,
    refreshToken: state.auth.refreshToken,
});

export default connect(mapStateToProps, {
    verifyAccessToken: _verifyAccessToken,
    onSubmitLogout: _logout,
    organizationMetrics: _organizationMetrics,
    loadOrganizations: _loadOrganizations,
    organizationUserActivity: _organizationUserActivity,
    loadBusinessMetrics: _loadBusinessMetrics,
    clearSearch: _clearSearch,
    loadOrganizationTypes: _loadOrganizationTypes,
    loadTemplateOptions: _loadTemplateOptions,
    getAdminPortalPermissions: _getAdminPortalPermissions,
    setSelectedOrganizationRow: _setSelectedOrganizationRow,
    setSelectedUserRow: _setSelectedUserRow,
    setSelectedChatroomRow: _setSelectedChatroomRow,
    setSelectedReferenceDataRow: _setSelectedReferenceDataRow,
})(AuthenticatedContainer);
