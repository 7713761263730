// @flow

import Colors from '../../Themes/Colors';

const TablePanelStyles = {

    tableWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between',
    },

    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
    },

    icon: {
        fontSize: 40,
        color: Colors.blue,
    },

    disabledIcon: {
        fontSize: 40,
        color: Colors.lightGray,
    },

    headerText: {
        color: Colors.gray,
    },

    tableRow: {
        cursor: 'default',
        backgroundColor: Colors.white,
    },

    tableCell: {
        fontSize: 14,
        paddingTop: 8,
        paddingBottom: 8,
        height: '100%',
    },
};

export default TablePanelStyles;

