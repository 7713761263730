// @flow

export const BOOLEAN = 'boolean';
export const STRING = 'string';

export const UI_TYPE_SWITCH = 'switch';
export const UI_TYPE_CHECK = 'check';
export const UI_TYPE_TEXT = 'text';
export const UI_TYPE_TXC_LIST = 'txclist';

export const KB_TYPE_PHONE = 'phone-pad';
export const KB_TYPE_NUMBER = 'number-pad';
export const KB_TYPE_EMAIL = 'email-address';
