// @flow
const pjson = require('../package.json');

type AppConfiguration = {
    version: string,
};


export const appConfiguration = (): AppConfiguration => ({
    version: pjson.version,
});

export default appConfiguration;
