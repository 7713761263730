// @flow
import Fonts from '../../Themes/Fonts';
import Colors from '../../Themes/Colors';

// Note: Pixel values are scaled based on dimension resize constants
const LoadingIndicatorStyles = {
    loading: {
        marginTop: 20,
        width: 60,
        height: 60,
    },
    verticalCenter: {
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    loginLabel: {
        ...Fonts.style.h1,
        color: Colors.black,
        textAlign: 'center',
    },
    formLabel: {
        color: Colors.formLabel,
        fontSize: 14,
        lineHeight: 2,
    },
};

export default LoadingIndicatorStyles;
