// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import Button from 'react-toolbox/lib/button/Button';
import EditSelectionText from './EditSelectionText';
import UserDetailsPanel from '../UserDetailsPanel';
import CreateDemoUserPanel from '../CreateDemoUserPanel';
import UserPanel from './UserPanel';
import UserTable from './UserTable';
import DeactivateAccountsButton from './DeactivateAccountsButton';
import LoadingIndicator from '../LoadingIndicator';
import { onClosePanel } from '../../Utils/panels';
import { isUserTypeLoaded } from '../../Utils/user';
import {
    updateOrganizationMember,
    organizationMemberHeadersSalesSys,
} from './ApprovedMembers';
import {
    loadUsers as _loadUsers,
    createDemoUser as _createDemoUser,
    updateOrganizationMember as _updateOrganizationMemberAction,
    addAndUpdateOrganizationMember as _addAndUpdateOrganizationMemberAction,
    setSelectedUserRow as _setSelectedUserRow,
    toggleUserDetailsPanel as _toggleUserDetailsPanel,
    toggleCreateDemoUserPanel as _toggleCreateDemoUserPanel,
} from '../../Redux/UserActions';
import ApplicationStyles from '../../Themes/ApplicationStyles';
import Colors from '../../Themes/Colors';

import type { NewDemoUser, UserProfile, Organization } from '../../Utils/types';

const dialogType = 'DemoUsers';
const contentType = 'demo users';

type Props = {
    profile: UserProfile,
    selectedUser: UserProfile,
    users: Array<UserProfile>,
    usersLoaded: boolean,
    bulkEditUsers: Array<UserProfile>,
    loadingCreateDemoUser: boolean,
    isCreateDemoUserPanelOpen: boolean,

    organizations: Array<Organization>,

    setSelectedUserRow: (row: any) => *,
    toggleUserDetailsPanel: (isUserDetailsPanelOpen: boolean) => *,
    toggleCreateDemoUserPanel: (isCreateDemoUserPanelOpen: boolean) => *,

    loadUsers: typeof _loadUsers,
    createDemoUser: typeof _createDemoUser,
    updateOrganizationMemberAction: typeof _updateOrganizationMemberAction,
    addAndUpdateOrganizationMemberAction: typeof _addAndUpdateOrganizationMemberAction,
};

class DemoUsers extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadUsers('demo');
    }

    onUpdateUser = (updatedUser: UserProfile) => {
        const {
            organizations,
            profile,
            addAndUpdateOrganizationMemberAction,
            updateOrganizationMemberAction,
            selectedUser,
        } = this.props;

        updateOrganizationMember(updatedUser, {
            organizations,
            profile,
            addAndUpdateOrganizationMemberAction,
            updateOrganizationMemberAction,
            selectedUser,
        });
    };

    onCreateDemoUser = (newDemoUser: NewDemoUser) => {
        const {
            organizations,
            createDemoUser,
        } = this.props;

        const demoUserOrganization = newDemoUser.organizationName;
        const organization = organizations.find((org) => org.organizationName === demoUserOrganization);

        if (organization) {
            const {
                firstName, lastName, password, accessLevel, role,
            } = newDemoUser;

            createDemoUser(firstName, lastName, password, accessLevel, organization.organizationId, role);
        }

        onClosePanel();
    };

    onAddUserButtonClick = () => {
        const {
            setSelectedUserRow,
            toggleUserDetailsPanel,
            toggleCreateDemoUserPanel,
        } = this.props;

        setSelectedUserRow(null);
        toggleUserDetailsPanel(false);
        toggleCreateDemoUserPanel(true);
    };

    render() {
        const {
            profile,
            users,
            usersLoaded,
            bulkEditUsers,
            isCreateDemoUserPanelOpen,
            loadingCreateDemoUser,
        } = this.props;
        const numSelected = bulkEditUsers.length;
        const disableActions = numSelected < 1;
        const isAdmin = profile.accessLevel === 'SysAdmin' || profile.accessLevel === 'Sales';

        return (
            <>
                <Sidebar
                    sidebar={(
                        <div>
                            <div style={{
                                ...ApplicationStyles.detailsHeader,
                                paddingTop: 35,
                                paddingBottom: 35,
                            }}
                            >
                                Create Demo User
                            </div>
                            <CreateDemoUserPanel
                                onCreateDemoUser={this.onCreateDemoUser}
                                onClose={onClosePanel}
                            />
                        </div>
                    )}
                    open={isCreateDemoUserPanelOpen}
                    docked={isCreateDemoUserPanelOpen}
                    pullRight
                    sidebarClassName="rightSidebar"
                    shadow={false}
                    styles={{
                        sidebar: {
                            background: 'white',
                            marginTop: 'var(--header-height)',
                            zIndex: 1,
                        },
                    }}
                >
                    <></>
                </Sidebar>

                <UserPanel
                    sidebar={(
                        <UserDetailsPanel
                            headers={organizationMemberHeadersSalesSys}
                            dialogType={dialogType}
                            contentType={contentType}
                            onUpdateUser={this.onUpdateUser}
                            onClose={onClosePanel}
                        />
                    )}
                >
                    <div className="user-actions">
                        <EditSelectionText numSelected={numSelected} disableActions={disableActions} />

                        {isAdmin ? (
                            <DeactivateAccountsButton
                                disabled={disableActions}
                                label="Deactivate Accounts"
                            />
                        ) : null}
                    </div>

                    <UserTable
                        users={users}
                        headers={organizationMemberHeadersSalesSys}
                        contentType={contentType}
                        usersLoaded={usersLoaded}
                        multiSelect
                    />

                    <div style={ApplicationStyles.footerMessage}>
                        <Button
                            onClick={this.onAddUserButtonClick}
                            icon="add"
                            floating
                            style={{
                                backgroundColor: Colors.brandPrimary,
                                color: 'white',
                            }}
                        />
                    </div>
                </UserPanel>

                {
                    loadingCreateDemoUser ? (
                        <div style={ApplicationStyles.overlayWrapper}>
                            <div style={ApplicationStyles.overlay} />
                            <LoadingIndicator />
                        </div>
                    ) : null
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.auth.profile,
    users: state.user.loadedUsers.demo ?? [],
    usersLoaded: isUserTypeLoaded(state.user.loadedUsers, 'demo'),
    bulkEditUsers: state.user.bulkEditUsers,
    organizations: state.organization.organizations,
    isCreateDemoUserPanelOpen: state.user.isCreateDemoUserPanelOpen,
    loadingCreateDemoUser: state.loading.createDemoUser,
    selectedUser: state.user.selectedUser,
});

export default connect(mapStateToProps, {
    createDemoUser: _createDemoUser,
    loadUsers: _loadUsers,
    updateOrganizationMemberAction: _updateOrganizationMemberAction,
    addAndUpdateOrganizationMemberAction: _addAndUpdateOrganizationMemberAction,
    setSelectedUserRow: _setSelectedUserRow,
    toggleUserDetailsPanel: _toggleUserDetailsPanel,
    toggleCreateDemoUserPanel: _toggleCreateDemoUserPanel,
})(DemoUsers);
