// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import type { DonorTaskMap } from '../Utils/types';
import ApplicationStyles from '../Themes/ApplicationStyles';
import LoadingIndicator from './LoadingIndicator';
import { OrganRecoveryTasks } from '../Utils/types';
import MessagesHomeStyles from '../Containers/Styles/MessagesHomeStyles';
import getTaskOrgan from '../Utils/getTaskOrgan';

type Props = {
    isLoading: boolean,
    donorTasks: DonorTaskMap,
    selectedDonorId: number,
};

class RecoveredOrganPane extends PureComponent<Props> {
    getRecoveredOrgans = () => {
        const {
            donorTasks,
        } = this.props;

        const recoveredOrgans: Array<string> = [];

        Object.keys(donorTasks).forEach((key) => {
            const taskId = parseInt(key, 10);
            if (OrganRecoveryTasks.includes(donorTasks[taskId].taskId) && donorTasks[taskId].completed) {
                recoveredOrgans.push(getTaskOrgan(donorTasks[taskId].taskId));
            }
        });

        return recoveredOrgans;
    };

    render() {
        const {
            selectedDonorId,
            isLoading,
        } = this.props;

        if (!selectedDonorId) {
            return (
                <div style={MessagesHomeStyles.noDataText}>
                    Select a donor
                </div>
            );
        }

        if (isLoading) {
            return (
                <div style={MessagesHomeStyles.noDataText}>
                    <LoadingIndicator />
                </div>
            );
        }

        const recoveredOrgans: Array<string> = this.getRecoveredOrgans();

        if (recoveredOrgans.length === 0) {
            return (
                <div style={MessagesHomeStyles.noDataText}>
                    No organs have been recovered
                </div>
            );
        }

        return (
            <div style={ApplicationStyles.flexRow}>
                {
                    recoveredOrgans.map((organ: string) => (
                        <div key={organ} style={MessagesHomeStyles.dataText}>
                            {organ}
                        </div>
                    ))
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.loading.donorTasks,
    donorTasks: state.donor.selectedDonorId && state.donor.donors[state.donor.selectedDonorId] ? state.donor.donors[state.donor.selectedDonorId].tasks : [],
    selectedDonorId: state.donor.selectedDonorId,
});

export default connect(mapStateToProps, null)(RecoveredOrganPane);
