// @flow
import { selectProfileName } from 'txp-core';
import OAHeart from './Forms/OAHeart';
import OAIntestine from './Forms/OAIntestine';
import OAKidneys from './Forms/OAKidneys';
import OALiver from './Forms/OALiver';
import OALungs from './Forms/OALungs';
import OAPancreas from './Forms/OAPancreas';
import ORHeart from './Forms/ORHeart';
import ORIntestine from './Forms/ORIntestine';
import ORKidneys from './Forms/ORKidneys';
import ORLiver from './Forms/ORLiver';
import ORLungs from './Forms/ORLungs';
import ORPancreas from './Forms/ORPancreas';
import type {
    TimelineItem,
    UserProfile,
} from './types';

export function getCurrentDefaultForm(taskId: number) {
    switch (taskId) {
        case 13:
            return OAHeart;
        case 14:
            return OALungs;
        case 15:
            return OALiver;
        case 16:
            return OAIntestine;
        case 17:
            return OAPancreas;
        case 18:
            return OAKidneys;
        case 29:
            return ORHeart;
        case 30:
            return ORLungs;
        case 31:
            return ORLiver;
        case 32:
            return ORIntestine;
        case 33:
            return ORPancreas;
        case 34:
            return ORKidneys;
        default:
            return {};
    }
}

export function getAction(timelineItem: TimelineItem) {
    if (timelineItem.completed === null) {
        if (timelineItem.notApplicable === null) {
            if (timelineItem.noteContent) {
                return ` note updated to:\n${timelineItem.noteContent || ''}\nby `;
            }
            return ' note removed by ';
        }
        if (timelineItem.notApplicable) {
            return ' marked as not applicable by ';
        }
        if (!timelineItem.notApplicable) {
            return ' marked as applicable by ';
        }
    }
    if (timelineItem.completed) {
        return ' marked as complete by ';
    }
    if (!timelineItem.completed) {
        return ' marked as incomplete by ';
    }

    return ' by ';
}

export function getUserName(userId: number, organizationMembers: Array<UserProfile>) {
    for (let i = 0; i < organizationMembers.length; i += 1) {
        if (organizationMembers[i].userId === userId) {
            return selectProfileName(organizationMembers[i]);
        }
    }
    return '';
}
